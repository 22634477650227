import Toggle from '@molecules/Toggle';
import { IS_PWA_IOS_MOBILE } from '@utils/deviceUtils';
import useMessagingService from '@utils/hooks/useMessagingService';
import { useTranslation } from 'react-i18next';

/**
 * Shows toggle component for PWA iOS devices only.
 */
const ToggleNotificationSettings = () => {
  const { token, messaging, getMessagingToken, initMessaging, disableMessaging } = useMessagingService();
  const { t } = useTranslation();

  const value = !!token;

  const onChange = async (newValue: boolean) => {
    let messagingInstance = messaging;
    if (!messagingInstance) {
      messagingInstance = await initMessaging();
    }
    if (newValue) {
      getMessagingToken();
    } else {
      disableMessaging();
    }
  };

  if (!IS_PWA_IOS_MOBILE) {
    return null;
  }

  return (
    <div className="pt-2">
      <Toggle value={value} label={t('togglePushNotificationsLabel')} onChange={onChange} fullWidth />
    </div>
  );
};

export default ToggleNotificationSettings;
