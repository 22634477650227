import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

const useScreenHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const calcInnerHeight = throttle(() => {
      setHeight(window.innerHeight);
    }, 200);
    window.addEventListener('resize', calcInnerHeight);
    return () => window.removeEventListener('resize', calcInnerHeight);
  }, []);

  return height;
};

export default useScreenHeight;
