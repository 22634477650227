import React from 'react';
import Spinner from '@atoms/Spinner';
import { useTranslation } from 'react-i18next';

interface Props {
  content?: string;
  className?: string;
}

function LoadingMessage({ className, content }: Props) {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-row items-center ${className}`}>
      <Spinner className={'text-gray-500 w-4 h-4'} />
      {content || t('loading')}
    </div>
  );
}

export default LoadingMessage;
