import TaskView from '@organisms/TaskView';
import TaskLayout from '@templates/TaskLayout';
import useSearchParam from '@utils/hooks/useSearchParam';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function TaskPage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [propertyId] = useSearchParam({ key: 'propertyId' });

  if (!propertyId) {
    return null;
  }

  if (!id) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }
  return <TaskLayout content={<TaskView id={id} propertyId={propertyId} />} />;
}
