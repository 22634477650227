import React from 'react';

interface Props {
  content: JSX.Element;
}

export default function TaskLayout({ content }: Props) {
  return (
    <div className="h-screen flex flex-col flex-1 bg-gray-50">
      <div className={'w-full flex flex-col items-center md:px-0 overflow-y-auto'}>{content}</div>
    </div>
  );
}
