import React, { useCallback } from 'react';
import Button, { ButtonType } from '@atoms/Button';
import { useGetNextMidClean, useUpdateMidClean } from '@api/api';
import { useTranslation } from 'react-i18next';
import Spinner from '@atoms/Spinner';
import dateUtils, { DATE_AND_DAY_OF_WEEK, formatDate, toDate } from '@utils/dateUtils';
import { useQueryClient } from 'react-query';
import { NotificationType, ServerStateKey } from '@typings/enums';
import useNotifications from '@utils/hooks/useNotifications';
import { isSameDay, subDays } from 'date-fns';

interface Props {
  reservationId: string;
  propertyId: string;
  onSubmit: () => void;
}

function MoveMidstayCleanDialog({ propertyId, reservationId, onSubmit }: Props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: getNextMidCleanData, isLoading, isFetching, isFetched } = useGetNextMidClean(propertyId, reservationId);
  const data = getNextMidCleanData?.data;
  const { showNotification } = useNotifications();
  const midCleanDate = data?.midClean?.date && toDate(data.midClean.date);
  const moveMidstayNotPossible = isFetched && !data?.midClean?.date;

  const yesterday = subDays(dateUtils.now(), 1);
  const isMovedYesterday = !!data?.midClean.scheduledAt && isSameDay(toDate(data?.midClean.scheduledAt), yesterday);

  const updateMidCleanMutation = useUpdateMidClean();

  const onConfirm = useCallback(async () => {
    if (updateMidCleanMutation.isLoading || !midCleanDate) {
      return;
    }
    const { success } = await updateMidCleanMutation.mutateAsync({
      data: {
        ...data?.midClean,
        propertyId,
        reservationId: data.reservationId,
      },
    });
    if (!success) {
      showNotification(t('anErrorHasOccurred'), NotificationType.ERROR);
      return;
    }

    showNotification(
      t('moveMidstayNotification', {
        date: formatDate(midCleanDate, DATE_AND_DAY_OF_WEEK),
      }),
      NotificationType.SUCCESS,
    );
    queryClient.invalidateQueries(ServerStateKey.DAILY_VIEW_UNITS);
    onSubmit();
  }, [updateMidCleanMutation, data, midCleanDate, onSubmit, queryClient]);

  const renderDescription = useCallback(() => {
    if (isMovedYesterday) {
      return t('pleaseContactOps');
    }
    if (moveMidstayNotPossible) {
      return t('moveMidstayNotPossible');
    }
    if (isLoading || isFetching) {
      return <Spinner className={'w-6 h-6 w-full text-th-primary'} />;
    }
    if (midCleanDate) {
      return t('moveMidstayDescription', { date: formatDate(midCleanDate, DATE_AND_DAY_OF_WEEK) });
    }
    return null;
  }, [isMovedYesterday, moveMidstayNotPossible, isLoading, isFetching, midCleanDate]);

  return (
    <>
      <div className={'flex flex-col items-center px-3 md:w-80'}>
        <div className={'text-th-brown font-serif text-lg font-bold mb-2'}>{t('moveMidstayTitle')}</div>
        <div className={'text-th-secondary text-center mb-6'}>{renderDescription()}</div>
        <Button
          isLoading={updateMidCleanMutation.isLoading}
          disabled={moveMidstayNotPossible || isMovedYesterday}
          className={'w-full'}
          onClick={onConfirm}
        >
          {t('confirm')}
        </Button>
        <Button type={ButtonType.SECONDARY} className={'w-full py-2 my-1'} onClick={onSubmit}>
          {t('cancel')}
        </Button>
      </div>
    </>
  );
}

export default MoveMidstayCleanDialog;
