/**
 * Calculates the variance of the Laplacian of the input image to determine the level of blur.
 *
 * Source: variation of the Laplacian by Pech-Pacheco et al. in their 2000 ICPR paper,
 * Diatom autofocusing in brightfield microscopy: a comparative study.
 *
 * Source: https://pyimagesearch.com/2015/09/07/blur-detection-with-opencv/
 */

export function calculateLaplacianVariance(imageData: ImageData): number {
  const { width, height, data } = imageData;
  const grayData = new Uint8ClampedArray(width * height);

  // Convert image to grayscale
  for (let i = 0; i < data.length; i += 4) {
    const gray = data[i] * 0.299 + data[i + 1] * 0.587 + data[i + 2] * 0.114;
    grayData[i / 4] = gray;
  }

  // Laplacian filter kernel
  const kernel = [0, 1, 0, 1, -4, 1, 0, 1, 0];

  const laplacianData = new Float32Array(width * height);

  // Apply the Laplacian filter
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      let laplacian = 0;
      for (let ky = -1; ky <= 1; ky++) {
        for (let kx = -1; kx <= 1; kx++) {
          const pixelVal = grayData[(y + ky) * width + (x + kx)];
          laplacian += pixelVal * kernel[(ky + 1) * 3 + (kx + 1)];
        }
      }
      laplacianData[y * width + x] = laplacian;
    }
  }

  // Calculate the variance of the Laplacian
  const mean = laplacianData.reduce((sum, val) => sum + val, 0) / laplacianData.length;
  const variance = laplacianData.reduce((sum, val) => sum + (val - mean) ** 2, 0) / laplacianData.length;

  return variance;
}

export function isImageBlurry(imageSrc: string, threshold: number = 50): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageSrc;
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const variance = calculateLaplacianVariance(imageData);
      resolve(variance < threshold);
    };
    img.onerror = (err) => {
      reject(err);
    };
  });
}

export const checkImagesForBlur = async (files: File[]) => {
  const blurryChecks = await Promise.all(
    files.map(async (file) => {
      return await isImageBlurry(URL.createObjectURL(file));
    }),
  );
  return blurryChecks.some((isBlurry) => isBlurry);
};
