import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '@atoms/Button';
import Popover from '@molecules/Popover';
import { datesBetween } from '@utils/dateUtils';
import { CalendarIcon } from '@heroicons/react/outline';
import useBreakpoint from '@utils/hooks/useBreakpoint';
import { format, isSameSecond } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface Props {
  date?: Date;
  from: Date;
  to: Date;
  onChange: (day: Date) => void;
}

function DatePickerContent({ from, to, date, onChange, close }: Props & { close?: () => void }) {
  const selectedItemRef = useRef<HTMLDivElement>(null);

  const [selectedDate, setSelectedDate] = useState(date);
  const breakpoint = useBreakpoint();
  const { t } = useTranslation();

  const isSmallScreen = breakpoint === 'xs' || breakpoint === 'sm';

  const dates = useMemo(() => datesBetween(from, to), []);

  const dayFormat = useMemo(() => (isSmallScreen ? 'eee' : 'eeee'), [isSmallScreen]);

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({ inline: 'center', block: 'center' });
    }
  }, []);
  return (
    <div className={'flex flex-col md:max-h-96 md:w-48 bg-white'}>
      <>
        <div className={'p-4 text-md font-serif font-bold text-th-brown border-b hidden md:block'}>
          {t('chooseDate')}
        </div>
        <div className={'pb-4 text-md font-serif font-bold text-th-brown border-b text-center md:hidden'}>
          {t('selectADate')}
        </div>
        <div
          className={'flex flex-row md:flex-col px-4 overflow-x-auto space-x-1 md:space-x-0 md:overflow-y-auto pt-4'}
        >
          {dates.map((d, i) => {
            const selected = selectedDate && isSameSecond(d, selectedDate);
            return (
              <div
                key={i}
                className={`flex flex-col md:flex-row items-center rounded-md mb-3 shadow-md justify-between space-x-1 md:space-x-0 px-4 py-1 border cursor-pointer 
                    ${selected ? 'bg-th-primary text-white border-th-primary border-opacity-50' : 'text-gray-400'}`}
                onClick={() => setSelectedDate(d)}
                ref={selected ? selectedItemRef : undefined}
              >
                <div className={'flex'}>{format(d, dayFormat)}</div>
                <div className={'flex flex-col items-center'}>
                  <div className={`text-xl font-serif font-bold ${selected ? 'text-white' : 'text-th-brown'}`}>
                    {format(d, 'd')}
                  </div>
                  <div className={'text-tiny uppercase'}>{format(d, 'MMM')}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={'p-4'}>
          <Button
            className={'w-full'}
            onClick={() => {
              onChange(selectedDate!);
              close?.();
            }}
            disabled={!selectedDate}
          >
            {t('select')}
          </Button>
        </div>
      </>
    </div>
  );
}

export default function ListDatePicker(props: Props) {
  const { t } = useTranslation();
  return (
    <Popover
      target={
        <div className={'flex flex-row items-center space-x-2'}>
          <CalendarIcon className="h-5 w-5 text-th-secondary" />
          <span className={'text-md font-serif font-bold text-th-dark-green'}>
            {props.date ? format(props.date, 'eee, MMM d') : t('selectDate')}
          </span>
        </div>
      }
      contentFn={(close) => <DatePickerContent close={close} {...props} />}
      targetClasses={'rounded bg-gray-100 flex flex-row items-center px-3 py-1 space-x-2 cursor-pointer'}
      showArrow
    />
  );
}
