import React from 'react';
import icons from '@constants/icons';
import { useTranslation } from 'react-i18next';

type Props = {
  search?: string;
};

function EmptyTaskSection({ search }: Props) {
  const { t } = useTranslation();
  return (
    <div className={'p-4 flex flex-col justify-center items-center'}>
      <icons.search className={'h-24 w-24'} />
      <div className={'text-gray-400'}>{search ? t('noResultsSearchMessage') : t('noTasksMessage')}</div>
    </div>
  );
}

export default EmptyTaskSection;
