import Dialog from '@molecules/Dialog';
import { ChangeEventHandler, useRef } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

interface PicturesErrorBlurryKitchenUseModalProps {
  isImageBlurry: boolean;
  onClose: () => void;
  onChangeFile: ChangeEventHandler<HTMLInputElement>;
}

export const PicturesErrorBlurryKitchenUseModal = ({
  isImageBlurry,
  onClose,
  onChangeFile,
}: PicturesErrorBlurryKitchenUseModalProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement | null>(null);

  if (!isImageBlurry) return null;

  const onUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    ref.current?.click();
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      isMobileSheet
      customHeader={
        <div className="w-full flex md:flex-row-reverse justify-between items-center px-4 pt-4 md:pt-3 pb-3 border-b border-b-[#DEDEDE]">
          <button
            type="button"
            className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      }
    >
      <div className="max-w-md">
        <div className="bg-th-gray-100 p-4 font-sans text-base font-normal flex justify-center items-start gap-2">
          <InformationCircleIcon className="w-6 h-6" />
          <div className="flex-1">{t('pictureTooBlurry')}</div>
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] py-4 px-5">
          <button
            onClick={onUpload}
            className={`w-full py-4 text-white text-base font-normal font-sans rounded-md
                  bg-th-secondary flex justify-center items-center`}
          >
            <input
              ref={ref}
              accept="image/*"
              id="file-input"
              name="file-input"
              type="file"
              capture="environment"
              hidden
              multiple
              onChange={onChangeFile}
            />
            <span>{t('tryAgain')}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};
