import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGetProperties } from '@api/api';
import { PropertyType } from '@typings/types';
import routes from '@constants/routes';
import { useHistory, useLocation } from 'react-router-dom';
import storageService from '@utils/storageService';
import useSearchParams from '@utils/hooks/useSearchParams';
import { mapArrayToObject, mapArrayToObjectById } from '@utils/arrayUtils';
import { DEFAULT_TIMEZONE } from '@utils/dateUtils';

interface PropertiesContext {
  isLoading: boolean;
  error?: unknown;
  data: PropertyType[];
  selectedProperty: PropertyType;
  selectedProperties: PropertyType[];
  getTimeZone: (propertyId: string) => string;
  getPropertiesById: (propertyIds: string[]) => PropertyType[];
  onSelectProperties: (properties: PropertyType[]) => void;
}

export const PropertiesContext = createContext({} as PropertiesContext);

interface Props {
  children: React.ReactNode;
}

export function PropertiesProvider(props: Props) {
  const { search } = useLocation();
  const history = useHistory();
  const pathName = history.location.pathname;

  const searchParams = useSearchParams();

  // todo split into separate context
  const { isLoading, data = [], error } = useGetProperties();

  const [selectedProperties, setSelectedProperties] = useState<PropertyType[]>([]);

  const propertiesById = useMemo(() => mapArrayToObjectById(data), [data]);
  const propertiesByExtId = useMemo(() => mapArrayToObject(data, 'externalId'), [data]);

  const selectedProperty = useMemo(() => selectedProperties[0], [selectedProperties]);

  const onSelectProperties = useCallback(
    (properties: PropertyType[]) => {
      const property = properties[0];
      if (!property) {
        return;
      }

      const newSearchParams = new URLSearchParams(search);
      if (routes.WEEKLY === pathName) {
        newSearchParams.set('properties', properties.map((p) => p.id).join(','));
        history.push({ search: newSearchParams.toString(), state: { from: pathName } });
      } else if (![routes.APALEO_HOUSEKEEPING, routes.NOTIFICATIONS, routes.SETTINGS].includes(pathName)) {
        newSearchParams.set('propertyId', property.id);
        history.push({ search: newSearchParams.toString(), state: { from: pathName } });
      }
      setSelectedProperties(properties);
      storageService.saveProperty(property.id);
    },
    [setSelectedProperties, search],
  );

  const getTimeZone = useCallback(
    (propertyId: string) => propertiesById[propertyId]?.timeZone ?? DEFAULT_TIMEZONE,
    [propertiesById],
  );

  const getPropertiesById = useCallback(
    (propertyIds: string[]) => propertyIds.map((id) => propertiesById[id] ?? propertiesByExtId[id]).filter((v) => !!v),
    [propertiesById],
  );

  // on init
  useEffect(() => {
    if (selectedProperties.length === 0 && data.length > 0) {
      let propertyIds: string[] = [];
      // todo later use single search param
      const properties = searchParams.get('properties');
      if (properties) {
        propertyIds = properties.split(',');
      }
      // todo consider to use propertyId only instead of property
      const propertyId = searchParams.get('property') ?? searchParams.get('propertyId') ?? storageService.getProperty();
      if (propertyId && propertyIds[0] !== propertyId) {
        propertyIds = [propertyId, ...propertyIds.filter((id) => id !== propertyId)];
      }
      if (propertyIds.length === 0) {
        propertyIds = [data[0].id];
      }
      onSelectProperties(getPropertiesById(propertyIds));
    }
  }, [data, getPropertiesById, onSelectProperties]);

  const state: PropertiesContext = {
    isLoading,
    selectedProperty,
    selectedProperties,
    onSelectProperties,
    error,
    data,
    getTimeZone,
    getPropertiesById,
  };

  return <PropertiesContext.Provider value={state} {...props} />;
}

export default function usePropertiesState() {
  const context = useContext(PropertiesContext);
  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('usePropertiesState was used outside of its Provider');
  }
  return context;
}
