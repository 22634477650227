import { useEffect, useState } from 'react';
import useCreateFileFromUrl from '@utils/hooks/useCreateFileFromUrl';

export interface ImageFile {
  image: File;
}

export default function useImageUrls(imageUrls: string[] = []) {
  const [images, setImages] = useState<ImageFile[]>([]);
  const [pickerInitialImages, setPickerInitialImages] = useState({});
  const { createFileFromUrl } = useCreateFileFromUrl();

  useEffect(() => {
    if (imageUrls.length) {
      (async () => {
        const imagesWithUrl = await Promise.all(
          imageUrls.map(async (url) => ({ url, image: await createFileFromUrl(url) })),
        );
        const imageValues = imagesWithUrl.map(({ image }) => ({ image }));
        const pickerImages = imagesWithUrl.reduce(
          (acc, curr) => {
            acc[curr.url] = curr.image;
            return acc;
          },
          {} as { [key: string]: File },
        );
        setImages(imageValues);
        setPickerInitialImages(pickerImages);
      })();
    } else if (images.length) {
      setImages([]);
      setPickerInitialImages({});
    }
  }, [imageUrls]);

  return { images, pickerInitialImages };
}
