import { Frequency } from 'rrule';

export const frequencyOptions = [
  {
    label: 'day',
    valueLabel: 'daily',
    value: Frequency.DAILY,
  },
  {
    label: 'week',
    valueLabel: 'weekly',
    value: Frequency.WEEKLY,
  },
  {
    label: 'month',
    valueLabel: 'monthly',
    value: Frequency.MONTHLY,
  },
];

export const getFrequencyLabel = (frequency: Frequency) =>
  frequencyOptions.find(({ value }) => value === frequency)?.label;

export const getFrequencyValueLabel = (frequency: Frequency) =>
  frequencyOptions.find(({ value }) => value === frequency)?.valueLabel;
