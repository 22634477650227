import React from 'react';
import cn from 'classnames';
import Checkbox from '@molecules/Checkbox';
import { AssignedRoom } from '@typings/types';
import { useTranslation } from 'react-i18next';

type Props = {
  selected: boolean;
  data: AssignedRoom;
  onSelect: (value: AssignedRoom, deselect?: boolean) => void;
};

function FloorAssignmentItem({ data, selected, onSelect }: Props) {
  const { t } = useTranslation();
  const assignedTo = data.cleaningAssignments[data.cleaningAssignments.length - 1]?.user;
  return (
    <>
      <div className={cn('flex flex-row py-2 items-center justify-between border-t')}>
        <div className={'flex flex-1 flex-row min-w-0 text-center pl-4'}>
          <div
            className={cn(
              'mr-4 text-lg items-center flex justify-center',
              'font-serif font-bold text-th-dark-green text-center',
            )}
          >
            {data.number}
          </div>
          <div
            className={cn(
              'flex items-center py-1 px-2 rounded-md min-w-0',
              assignedTo?.fullName ? 'bg-th-light-green' : 'bg-th-gray-200 text-th-gray-300',
            )}
          >
            {t('cleaner')}:&nbsp;
            <p className={'font-semibold overflow-hidden truncate'}>{assignedTo?.fullName ?? t('notAssigned')}</p>
          </div>
        </div>
        <div>
          <Checkbox
            id={`${data.floorNumber}:${data.number}`}
            name={'assignedTo'}
            onClick={() => {
              onSelect(data, selected);
            }}
            checked={selected}
            className={'pb-2 text-th-brown'}
          />
        </div>
      </div>
    </>
  );
}

export default FloorAssignmentItem;
