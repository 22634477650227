import { Option } from '../FilterTypes';
import filterStyles from '../filters.module.css';

type SelectorProps = {
  options: Option[];
  selectedOption: string | string[] | null;
  handleOptionChange: (option: Option) => void;
};

export const Selector = ({ selectedOption, handleOptionChange, options }: SelectorProps) => (
  <div>
    {options.map((option) => {
      const isOptionChecked = Array.isArray(selectedOption)
        ? selectedOption.includes(option.value)
        : selectedOption === option.value;

      return (
        <label
          key={option.value}
          className={`flex justify-between items-center text-base font-normal font-sans p-4 border-b border-b-th-light-green 
            cursor-pointer ${isOptionChecked ? 'bg-th-light-green' : 'hover:bg-th-gray-100'}`}
        >
          {option.label}
          <input
            type="checkbox"
            value={option.value}
            checked={isOptionChecked}
            className={filterStyles.selectorCheckbox}
            onChange={() => handleOptionChange(option)}
          />
        </label>
      );
    })}
  </div>
);
