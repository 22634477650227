import React, { MouseEventHandler, useCallback, useEffect, useMemo } from 'react';
import SelectionDialog, { SelectOption } from '@molecules/SelectionDialog';
import SelectInput from '@molecules/SelectInput';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import TextInput from '@molecules/TextInput';
import SelectWeekDays from '@molecules/SelectWeekDays';
import { RepetitionOptions, WeekDay } from '@typings/types';
import { useToggle } from '@utils/hooks/useToggle';
import Dialog from '@molecules/Dialog';
import { Frequency } from 'rrule';
import { frequencyOptions, getFrequencyLabel } from '@organisms/CustomRepetition/utils';
import Button, { ButtonType } from '@atoms/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: RepetitionOptions;
  onSelect: (value?: RepetitionOptions) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
}

interface FormValues {
  frequency: Frequency;
  interval: number;
  weekDays: { value: WeekDay }[];
}

const defaultFormValues = { interval: 1, frequency: Frequency.DAILY, weekDays: [] };

function CustomRepetition(props: Props) {
  const { t } = useTranslation();
  const { value = defaultFormValues, actionButton } = props;
  const [dialogOpen, toggleDialogOpen] = useToggle(false);
  const [showWeekDays, setShowWeekDays] = useToggle(false);
  const form = useForm<FormValues>({});
  const {
    formState: { isValid, errors },
  } = form;
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: 'weekDays',
    keyName: 'id',
  });

  // set default values
  useEffect(() => {
    const { interval, frequency, weekDays } = value;
    setShowWeekDays(frequency === Frequency.WEEKLY);
    form.setValue('interval', interval);
    form.setValue('frequency', frequency);
    form.setValue(
      'weekDays',
      weekDays.map((wd) => ({ value: wd })),
    );
  }, [value]);

  const weekDays = fields.map((f) => f.value);
  const onSelectWeekDay = useCallback(
    (option: WeekDay) => {
      const index = weekDays.indexOf(option);
      if (index !== -1) {
        remove(index);
      } else {
        append({
          value: option,
        });
      }
    },
    [remove, append, weekDays],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectFrequency = useCallback((onChange: (...event: any[]) => void) => {
    return (option?: SelectOption) => {
      const selectedValue = option?.value;
      const isWeekly = selectedValue === Frequency.WEEKLY;
      setShowWeekDays(isWeekly);
      if (!isWeekly) {
        form.setValue('weekDays', []);
      }
      onChange(selectedValue);
    };
  }, []);

  const onSelectRepetition = useCallback(() => {
    if (!isValid) {
      return;
    }
    const values = form.getValues();
    const { interval, frequency } = values;
    const repetitionOptions = {
      interval,
      frequency,
      weekDays,
    };
    props.onSelect(repetitionOptions);
    toggleDialogOpen();
  }, [isValid, form, toggleDialogOpen, weekDays]);

  const onClickDoesNotRepeat = useCallback(() => {
    toggleDialogOpen();
    props.onSelect();
    form.reset();
    setShowWeekDays(false);
  }, [toggleDialogOpen]);

  const freqOptions = useMemo(
    () =>
      frequencyOptions.map(({ value: v, valueLabel, label }) => ({
        value: v,
        valueLabel: t(valueLabel),
        label: t(label),
      })),
    [t],
  );

  return (
    <>
      {actionButton(toggleDialogOpen)}
      <Dialog
        isOpen={dialogOpen}
        onClose={toggleDialogOpen}
        isMobileSheet
        actionButton={
          <button
            className={`
              justify-end text-green-400 font-bold font-serif
            `}
            onClick={onSelectRepetition}
          >
            {t('select')}
          </button>
        }
      >
        <div className={'flex flex-1 flex-col items-between'}>
          <div className={'flex flex-col px-4 py-4 sm:mt-4'}>
            <div className={'flex flex-row justify-center items-center'}>
              <div className={'text-md font-medium'}>{t('repeatsEvery')}</div>

              <div className={'w-[44px] mx-2 bg-gray-50 ml2 '}>
                <Controller
                  control={form.control}
                  name={'interval'}
                  rules={{
                    required: true,
                    min: 1,
                  }}
                  render={({ field: { onChange, value: intervalValue } }) => (
                    <div className={'bg-gray-50 px-2'}>
                      <TextInput
                        className={'text-center'}
                        type={'number'}
                        value={intervalValue}
                        onChange={onChange}
                        error={errors.interval}
                      />
                    </div>
                  )}
                />
              </div>
              <div className={'flex flex-1'}>
                <div className={'pl-4 w-[90px] bg-gray-50'}>
                  <Controller
                    control={form.control}
                    name={'frequency'}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value: frequencyValue } }) => (
                      <SelectionDialog
                        includeSearch={false}
                        options={freqOptions}
                        title={t('repetitionTime')}
                        value={
                          frequencyValue
                            ? { label: t(frequencyValue.toString().toLowerCase()), value: frequencyValue }
                            : undefined
                        }
                        onSelect={onSelectFrequency(onChange)}
                        actionButton={(onClickFreq) => (
                          <SelectInput
                            value={frequencyValue ? t(getFrequencyLabel(frequencyValue)!) : undefined}
                            onClick={onClickFreq}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {showWeekDays && <SelectWeekDays value={weekDays} onSelect={onSelectWeekDay} />}
          </div>
          <div className={'px-4 flex flex-1 mt-4'}>
            {props.value && (
              <Button className={'w-full'} type={ButtonType.OUTLINED} onClick={onClickDoesNotRepeat}>
                {t('doesNotRepeat')}
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default CustomRepetition;
