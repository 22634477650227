import React from 'react';
import { WeekDay } from '@typings/types';
import { weekDayOptions } from '@utils/dateUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: WeekDay[];
  onSelect: (option: WeekDay) => void;
}

function SelectWeekDays({ value = [], onSelect }: Props) {
  const { t } = useTranslation();

  const options = weekDayOptions.map((option, index) => {
    const selected = value.includes(option);
    return (
      <div
        onClick={() => onSelect(option)}
        key={index}
        className={`
        px-2 py-2 mr-3 
        flex justify-center items-center 
        rounded-full border-gray-300 border-[1px] shadow-sm
        focus:outline-none
        ${selected ? 'bg-th-secondary' : 'bg-gray-50'}
        `}
      >
        <div className={`text-sm ${selected ? 'text-white' : 'text-th-secondary'}`}>{option}</div>
      </div>
    );
  });

  return (
    <div className={'py-4'}>
      <div className={'text-md pb-2'}>{t('repeatOn')}</div>
      <div className={'flex flex-1 flex-row'}>{options}</div>
    </div>
  );
}

export default SelectWeekDays;
