const SkeletonItem = () => (
  <div className="w-full flex space-x-4 mb-8">
    <div className="bg-gray-300 min-w-[28px] h-7 rounded-3xl"></div>
    <div className="w-full space-y-4">
      <div className="flex justify-between w-full">
        <div className="bg-gray-300 w-1/6 h-6 rounded"></div>
        <div className="bg-gray-300 w-1/12 h-4 rounded"></div>
      </div>
      <div className="flex space-x-4">
        <div className="bg-gray-300 w-1/12 h-4 rounded"></div>
        <div className="bg-gray-300 w-1/12 h-4 rounded"></div>
        <div className="bg-gray-300 w-1/6 h-4 rounded"></div>
        <div className="bg-gray-300 w-6 h-4 rounded"></div>
      </div>
    </div>
  </div>
);

export const SkeletonTasks = () => (
  <div className="w-full bg-white shadow-md rounded-lg animate-pulse">
    <div className="h-12 bg-gray-200 rounded-md"></div>
    <div className="p-4 pb-0">
      <div className="h-6 bg-gray-300 rounded w-1/6 mb-8" />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </div>
  </div>
);
