import { ExclamationCircleIcon } from '@heroicons/react/solid';
import React, { useCallback, useEffect, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import './styles.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  error?: FieldError | { message: string };
}

function TextInput(props: Props) {
  const { name, label, type, error, placeholder, onChange, className, autoFocus, ...inputProps } = props;
  const ref = useRef<HTMLInputElement>(null);
  const getInputMode = useCallback(() => {
    switch (type) {
      case 'number':
        return 'numeric';
      case 'email':
        return 'email';
      default:
        return 'text';
    }
  }, [type]);
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        // direct autoFocus on input as props pushes the overlay out of the view
        ref.current?.focus();
      }, 200);
    }
  }, [autoFocus]);
  return (
    <div>
      {label && (
        <label htmlFor={name} className="block text-xs font-medium text-gray-400 uppercase">
          {label}
        </label>
      )}
      <div className="mt-1 relative rounded-md">
        <input
          ref={ref}
          {...inputProps}
          onChange={onChange}
          type={type}
          name={name}
          id={name}
          inputMode={getInputMode()}
          className={`
            ${
              error &&
              'bg-red-100 rounded-md border-[1px] border-red-400 focus:border-red-400 text-red-400 placeholder-red-400'
            }
            block w-full p-2 focus:outline-none focus:border-th-secondary focus:ring-0 sm:text-sm border-0 border-b border-gray-300 bg-transparent
            ${className}
          `}
          placeholder={placeholder}
        />
      </div>
      {error && (
        <p className="relative mt-1 text-sm text-red-500" id={`${name}-error`}>
          {error.message}
          <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        </p>
      )}
    </div>
  );
}

export default TextInput;

TextInput.defaultProps = {
  type: 'text',
};
