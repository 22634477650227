import icons from '@constants/icons';
import NavigationItem from '@molecules/NavigationItem';
import { NavigationItemType } from '@typings/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Popover from '@molecules/Popover';
import ActionItem from '@molecules/ActionItem';
import { useHistory, useLocation } from 'react-router-dom';
import { CogIcon, DocumentTextIcon, SearchIcon } from '@heroicons/react/outline';
import routes from '@constants/routes';
import { useTranslation } from 'react-i18next';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import useApiCall from '@utils/hooks/useApiCall';
import notificationsApi from '@api/notificationsApi';
import { useQuery } from 'react-query';
import { ServerStateKey } from '@typings/enums';
import usePropertiesState from '@context/propertiesContext';

export default function Navigation() {
  const { t } = useTranslation();

  const [active, setActive] = useState<NavigationItemType>();
  const history = useHistory();
  const location = useLocation();

  const { selectedProperties } = usePropertiesState();
  const selectedPropertyIds = selectedProperties.map((i) => i.id);

  const notificationsCountApiCall = useApiCall<{ count: number }>(notificationsApi.notificationsCount);
  const { data: notificationsCount } = useQuery([ServerStateKey.NOTIFICATIONS_COUNT], () =>
    notificationsCountApiCall({}),
  );

  const { rolesWithAssignRoomsPrivileges } = useRoleBasedUI();

  const items: NavigationItemType[] = useMemo(() => {
    return [
      { name: t('daily'), link: '/', Icon: icons.calendarWithDate },
      { name: t('weekly'), link: routes.WEEKLY, Icon: icons.calendar },
      { name: t('tasks'), link: routes.TASKS, Icon: icons.tasks },
      { name: t('notifications'), link: routes.NOTIFICATIONS, Icon: icons.bell, count: notificationsCount?.count },
      {
        name: t('more'),
        link: '#',
        Icon: icons.threeDots,
        items: [
          { name: t('lostAndFound'), link: routes.LOST_AND_FOUND, Icon: SearchIcon },
          { name: t('damageReports'), link: routes.DAMAGE_REPORT, Icon: DocumentTextIcon },
          // todo icon,
          rolesWithAssignRoomsPrivileges
            ? {
                name: t('assignRooms'),
                link: routes.ASSIGN_ROOMS,
                Icon: SearchIcon,
              }
            : null,
          { name: t('more'), link: routes.SETTINGS, Icon: CogIcon },
        ].filter((v) => !!v) as NavigationItemType[],
      },
    ];
  }, [t, rolesWithAssignRoomsPrivileges, notificationsCount]);

  useEffect(() => {
    const activeItem = items.find((i) => {
      if (i.items) {
        return i.items.find((ii) => ii.link === location.pathname);
      }
      return i.link === location.pathname;
    });
    if (activeItem) {
      setActive(activeItem);
    }
  }, [location.pathname, items]);

  const goToLink = useCallback(
    (link: string) => {
      if (link === routes.WEEKLY) {
        history.push({
          pathname: link,
          search: `?properties=${selectedPropertyIds.join(',')}`,
        });
      } else if (link === routes.NOTIFICATIONS || link === routes.SETTINGS) {
        history.push({
          pathname: link,
          search: '',
        });
      } else {
        history.push({
          pathname: link,
          search: selectedPropertyIds.length ? `?propertyId=${selectedPropertyIds[0]}` : '',
        });
      }
    },
    [selectedPropertyIds, history],
  );

  const renderItem = useCallback(
    (item: NavigationItemType) =>
      item.items ? (
        <Popover
          key={item.name}
          className={'flex flex-1 flex-col items-center md:block'}
          target={
            <NavigationItem
              key={item.name}
              item={{
                ...item,
                active: item.name === active?.name,
              }}
            />
          }
          contentFn={(closePopover) => (
            <div className={'flex flex-col px-4 md:w-48 py-6 gap-y-6'}>
              {item.items?.map((i, index) => (
                <ActionItem
                  key={i.name}
                  Icon={i.Icon}
                  name={i.name}
                  action={() => {
                    goToLink(i.link);
                    closePopover?.();
                  }}
                  isLast={index === (item.items?.length || 0) - 1}
                />
              ))}
            </div>
          )}
          side={'right'}
        />
      ) : (
        <NavigationItem
          key={item.name}
          item={{
            ...item,
            active: item.name === active?.name,
            onClick: () => {
              goToLink(item.link);
            },
          }}
        />
      ),
    [active, goToLink],
  );

  return (
    <>
      <nav className="space-y-6 px-2 hidden md:block absolute max-w-[175px] left-0 top-28">{items.map(renderItem)}</nav>
      <nav className="flex md:hidden w-full absolute bottom-0 bg-white">{items.map(renderItem)}</nav>
    </>
  );
}
