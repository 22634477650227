import { useTranslation } from 'react-i18next';

interface Props {
  active?: 'CURRENT' | 'ARCHIVE';
  setActive: (v: 'CURRENT' | 'ARCHIVE') => void;
  countCurrent: number;
  countArchive: number;
}

export default function AdditionalTaskBar({ active, setActive, countCurrent, countArchive }: Props) {
  const { t } = useTranslation();
  return (
    <div className={'rounded-lg flex flex-row p-0.5 bg-[#CECECE] flex-shrink-0'}>
      <div
        className={`${
          active === 'CURRENT' ? 'text-th-primary font-bold bg-white' : 'text-th-brown'
        } flex flex-1 rounded-md p-2 justify-center cursor-pointer text-sm md:text-md`}
        onClick={() => setActive('CURRENT')}
      >
        {t('current')} ({countCurrent})
      </div>
      <div
        className={`${
          active === 'ARCHIVE' ? 'text-th-primary font-bold bg-white' : 'text-th-brown'
        } flex flex-1 rounded-md p-2 justify-center cursor-pointer text-sm md:text-md`}
        onClick={() => setActive('ARCHIVE')}
      >
        {t('archive')} ({countArchive})
      </div>
    </div>
  );
}
