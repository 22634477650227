import SelectionDialog from '@molecules/SelectionDialog';
import { GlobeAltIcon } from '@heroicons/react/outline';
import ActionItem from '@molecules/ActionItem';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '@utils/hooks/useLanguage';

export enum AppLanguage {
  ENGLISH = 'en',
  DEUTSCH = 'de',
  VIETNAMESE = 'vi',
  CZECH = 'cs',
  ITALIAN = 'it',
  SERBIAN = 'sr',
  CROATIAN = 'hr',
  UKRAINIAN = 'uk',
  RUSSIAN = 'ru',
  SPANISH = 'es',
  ROMANIAN = 'ro',
  FRENCH = 'fr',
  NORWEGIAN = 'no',
  POLISH = 'pl',
  TURKISH = 'tr',
  BULGARIAN = 'bg',
  DUTCH = 'nl',
}

const languages = [
  { value: AppLanguage.ENGLISH, label: 'English' },
  { value: AppLanguage.DEUTSCH, label: 'Deutsch' },
  { value: AppLanguage.VIETNAMESE, label: 'Vietnamese' },
  { value: AppLanguage.CZECH, label: 'Czech' },
  { value: AppLanguage.ITALIAN, label: 'Italian' },
  { value: AppLanguage.SERBIAN, label: 'Serbian' },
  { value: AppLanguage.CROATIAN, label: 'Croatian' },
  { value: AppLanguage.UKRAINIAN, label: 'Ukrainian' },
  { value: AppLanguage.RUSSIAN, label: 'Russian' },
  { value: AppLanguage.SPANISH, label: 'Spanish' },
  { value: AppLanguage.ROMANIAN, label: 'Romanian' },
  { value: AppLanguage.FRENCH, label: 'French' },
  { value: AppLanguage.NORWEGIAN, label: 'Norwegian' },
  { value: AppLanguage.POLISH, label: 'Polish' },
  { value: AppLanguage.DUTCH, label: 'Dutch' },
];

export default function SelectLanguage() {
  const { t, i18n } = useTranslation();
  const { language, setLanguage } = useLanguage();
  return (
    <SelectionDialog
      title={t('selectLanguage')}
      value={i18n.language ? languages.find((l) => l.value === language) : languages[0]}
      onSelect={(option) => {
        if (option?.value) {
          setLanguage(option?.value);
        }
      }}
      actionButton={(onClick) => (
        <ActionItem name={t('language')} Icon={GlobeAltIcon} action={onClick} withRightArrow />
      )}
      options={languages}
      includeSearch={false}
    />
  );
}
