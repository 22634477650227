import { ConfirmationDialog } from '@molecules/ConfirmationDialog';
import { useState } from 'react';

enum ConfirmationAction {
  Primary,
  Secondary,
}

type ConfirmDialogProps = {
  title: string;
  message: string;
  primaryActionLabel: string;
  onClickPrimaryAction: () => Promise<void>;
  secondaryActionLabel: string;
  onClickSecondaryAction: () => Promise<void>;
};

export function useConfirmation() {
  const [isOpen, setOpen] = useState(false);
  const [resolveFn, setResolveFn] = useState<ConfirmDialogProps | null>();

  const openConfirmationDialog = (props: ConfirmDialogProps) => {
    setResolveFn(props);
    setOpen(true);
  };

  const onClickAction = async (action: ConfirmationAction) => {
    const isPrimaryAction = action === ConfirmationAction.Primary;
    const actionFn = isPrimaryAction ? resolveFn?.onClickPrimaryAction : resolveFn?.onClickSecondaryAction;
    await actionFn?.();
  };

  const onClose = () => {
    setOpen(false);
    setResolveFn(null);
  };

  const Confirmation = () =>
    resolveFn && isOpen ? (
      <div className="z-50">
        <ConfirmationDialog
          isOpen={isOpen}
          title={resolveFn.title}
          message={resolveFn.message}
          primaryLabel={resolveFn.primaryActionLabel}
          onClickPrimaryAction={() => onClickAction(ConfirmationAction.Primary)}
          secondaryLabel={resolveFn.secondaryActionLabel}
          onClickSecondaryAction={() => onClickAction(ConfirmationAction.Secondary)}
          onClose={onClose}
        />
      </div>
    ) : null;

  return { openConfirmationDialog, ConfirmationDialog: Confirmation };
}
