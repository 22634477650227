import { DateHeader, IntervalContext, IntervalRenderer } from 'react-calendar-timeline';
import { parseDate } from '../utils';
import { format, isToday, isWeekend } from 'date-fns';

interface Data {}

export const ColumnHeader = () => {
  return (
    <DateHeader
      unit="day"
      labelFormat="MM/DD"
      height={44}
      className="flex items-center"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      intervalRenderer={({
        getIntervalProps,
        intervalContext,
      }: {
        getIntervalProps: IntervalRenderer<Data>['getIntervalProps'];
        intervalContext: IntervalContext;
      }) => {
        const dateUnix = intervalContext.intervalText;
        const parsedDate = parseDate(dateUnix);

        const isDayWeekend = isWeekend(parsedDate);
        const isDayToday = isToday(parsedDate);
        const getDayName = format(parsedDate, 'E');
        return (
          <div
            className={`flex flex-col justify-center items-center text-sm leading-[18px] font-sans h-full border border-th-gray-100 ${
              isDayToday ? 'bg-[#18333D] text-white' : isDayWeekend && 'bg-[#F3F9F7]'
            }`}
            {...getIntervalProps()}
          >
            <div>{intervalContext.intervalText.split('/')[1]}</div>
            <div>{getDayName}</div>
          </div>
        );
      }}
    />
  );
};
