import LoadingMessage from '@atoms/LoadingMessage';
import { useAuth0 } from '@auth0/auth0-react';
import routes from '@constants/routes';
import useAppContext from '@context/appContext';
import usePropertiesState from '@context/propertiesContext';
import pages from '@pages/index';
import { TaskType } from '@typings/enums';
import analyticsService, { AppInitType } from '@utils/analyticsService';
import { useChat } from '@utils/hooks';
import useMessagingService from '@utils/hooks/useMessagingService';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import useSearchParam from '@utils/hooks/useSearchParam';
import { WebViewEventType, attachOpenNotificationFn, fireWebViewEvent } from '@utils/mobileAppService';
import storageService from '@utils/storageService';
import { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

export default function AppRouter(): JSX.Element {
  const { getAccessTokenSilently, user } = useAuth0();

  const { isGxOnlyRole: isGxOnlyRoleFn } = useRoleBasedUI();
  const { selectedProperty, isLoading: isPropertiesLoading } = usePropertiesState();
  const { onLoggedInChat } = useChat();
  const queryClient = useQueryClient();

  const isGxOnlyRole = selectedProperty?.id && isGxOnlyRoleFn(selectedProperty.id);

  const { getMessagingToken } = useMessagingService();

  const history = useHistory();
  const location = useLocation();

  const { isApaleoTab } = useAppContext();

  const [taskId] = useSearchParam({ key: 'taskId' });
  const [taskType] = useSearchParam<TaskType>({ key: 'taskType', defaultValue: TaskType.STANDARD });

  const initMobileApp = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    attachOpenNotificationFn(history);
    fireWebViewEvent({
      type: WebViewEventType.ON_LOAD,
      payload: { accessToken, userId: user?.sub ?? '' },
    });
  }, [history, user]);

  const onInit = useCallback(async () => {
    if (!user) {
      return;
    }
    let appInitType;
    if (storageService.isMobileApp()) {
      initMobileApp();
      appInitType = AppInitType.WEB_VIEW;
    } else if (isApaleoTab) {
      onLoggedInChat(user);
      appInitType = AppInitType.APALEO_TAB;
    } else {
      onLoggedInChat(user);
      getMessagingToken();
      appInitType = AppInitType.REGULAR;
    }
    await analyticsService.setUser(user?.sub ?? '', appInitType);
  }, [getMessagingToken]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  useEffect(() => {
    /**
     * If Gx only role and url contains taskId, it should point user to Task Details page.
     */
    if (!isApaleoTab && isGxOnlyRole && taskId && taskType === TaskType.STANDARD) {
      history.replace({ pathname: routes.TASK_DETAILS.replace(':id', taskId), search: location.search.toString() });
    }
  }, [isApaleoTab, isGxOnlyRole, taskId, taskType, location.search]);

  /**
   * Due to chat being an iframe, when chat loads,
   * if we click anywhere outside chat,
   * it will refocus and reload all queries
   * This prevents it while it is open through task id.
   */
  useEffect(() => {
    if (isApaleoTab) {
      return;
    }
    const refetchOnWindowFocus = !taskId;
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus,
      },
    });
  }, [isApaleoTab, taskId]);

  if (isPropertiesLoading) {
    return <LoadingMessage className="flex-1 justify-center" />;
  }

  return (
    <Switch>
      {pages.map((page) => (
        <Route path={page.path} component={page.component} key={page.path} />
      ))}
    </Switch>
  );
}
