import { useCreateArea, useCreateTasks, useUpdateTask } from '@api/api';
import {
  DamageReportActionRequired,
  DamageReportArea,
  NotificationType,
  ServerStateKey,
  StandardizedDamageReport,
  TaskPriority,
  TaskType,
  Team,
} from '@typings/enums';
import { useQueryClient } from 'react-query';
import { useCallback } from 'react';
import uploadService from '@utils/uploadService';
import {
  PropertyType,
  Task,
  TaskInfoType,
  TaskSource,
  ThinArea,
  ThinUnit,
  UnitInfoType,
  UploadUrl,
} from '@typings/types';
import { isUnitType } from '@utils/unitUtils';
import { useToggle } from '@utils/hooks/useToggle';
import useApiCall from '@utils/hooks/useApiCall';
import propertiesApi from '@api/propertiesApi';
import { DateValue } from '@organisms/SelectDate/types';
import { toRRuleObject } from '@utils/repetitionUtils';
import { useTranslation } from 'react-i18next';
import useNotifications from '@utils/hooks/useNotifications';
import {
  isDueDateBeforeTodayMidnight,
  isTaskForConfirm,
  isUserInTheTeam,
  taskPriorityToNumber,
} from '@utils/taskUtils';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import routes from '@constants/routes';
import { useLocation } from 'react-router-dom';
import { resizeImage } from '@utils/imageResizeService';
import { TaskMapper } from '@utils/taskMapper';
import dateUtils from '@utils/dateUtils';
import useSearchParams from './useSearchParams';
import { TASK_CATEGORIES_DELIMITER } from '@api/taskAction.mapper';
import { TaskAction } from '@api/taskAction.types';
import useTaskActionsState from '@context/taskActionsContext';

interface Props {
  property: Pick<PropertyType, 'id' | 'timeZone'>;
  editTask?: TaskInfoType;
  type: TaskType;
  stateKeys?: ServerStateKey[][];
  onSuccess?: () => void;
}

export interface LostAndFoundItemFormValues {
  title: string;
  property: PropertyType;
  unit?: ThinUnit | ThinArea;
  images: { image: File }[];
}

export interface DamageReportFormValues {
  title: string;
  property: PropertyType;
  unit?: ThinUnit | ThinArea;
  images: { image: File }[];
  area: DamageReportArea;
  actionRequired: DamageReportActionRequired;
  priority: TaskPriority;
  dueAt: { date: Date };
  team: Team;
}

export interface StandardTaskFormValues {
  title: string;
  actionCategory?: TaskAction;
  description?: string;
  property: PropertyType;
  unit?: ThinUnit | ThinArea;
  // apaleo reservation id - in case of GX / Apaleo tab task creation only
  reservationId?: string;
  images: { image: File }[];
  team: Team;
  priority: TaskPriority;
  dueAt: DateValue;
}

const creationNotificationTranslationKeyByType = {
  [TaskType.STANDARD]: 'taskCreationNotification',
  [TaskType.LOST_AND_FOUND]: 'lostAndFoundCreationNotification',
  [TaskType.DAMAGE]: 'damageReportCreationNotification',
  [TaskType.MISCONDUCT]: '',
};

const updateNotificationTranslationKeyByType = {
  [TaskType.STANDARD]: 'taskUpdateNotification',
  [TaskType.LOST_AND_FOUND]: 'lostAndFoundUpdateNotification',
  [TaskType.DAMAGE]: 'damageReportUpdateNotification',
  [TaskType.MISCONDUCT]: '',
};

export const taskValidation = {
  titleMaxLength: 500,
};

export default function useCreateOrUpdateTask({ property, type, editTask, onSuccess }: Props) {
  const queryClient = useQueryClient();

  const searchParams = useSearchParams();
  const apaleoReservationId = searchParams.get('reservationId');

  const { t } = useTranslation();
  const location = useLocation();
  const { showNotification } = useNotifications();

  const [isSubmitting, setSubmitting] = useToggle(false);
  const { getPropertyRoles, isMaintainerOnlyRole } = useRoleBasedUI();

  const { getDefaultLangTaskActionById } = useTaskActionsState();

  const propertyId = property.id;
  const isMaintainerOnly = isMaintainerOnlyRole(propertyId);
  const isApaleoTab = location.pathname.startsWith('/apaleo');
  const timeZone = property.timeZone;
  const taskId = editTask?.id;

  const createTasksRequest = useCreateTasks(propertyId, {
    onSettled: () => {
      setSubmitting(false);
    },
    onSuccess: async (data, variables) => {
      showNotification(
        t(`popupNotifications.${creationNotificationTranslationKeyByType[type]}`),
        NotificationType.SUCCESS,
      );

      onSuccess?.();

      const { data: tasks } = variables as { data: [Task] };
      const userRoles = getPropertyRoles(propertyId);
      const task = tasks[0];
      const inTheAssignedTeam = isUserInTheTeam(task.assignedTo!, userRoles);
      const isUnitDetailsView = task.unitId && location.pathname === routes.UNIT_DETAILS.replace(':id', task.unitId);

      if (type === TaskType.STANDARD && !inTheAssignedTeam) {
        return;
      }

      if (location.pathname === routes.APALEO_HOUSEKEEPING) {
        await queryClient.invalidateQueries([ServerStateKey.RESERVATION_TASKS, type, apaleoReservationId]);
        return;
      }

      if (type === TaskType.STANDARD) {
        if (location.pathname === routes.TASKS) {
          await queryClient.invalidateQueries([ServerStateKey.TASKS, propertyId]);
          if (isTaskForConfirm(task)) {
            await queryClient.invalidateQueries([ServerStateKey.UNCONFIRMED_TASKS, propertyId]);
          }
        }

        if (isUnitDetailsView) {
          await queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS, task.unitId]);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - since this is task that is sent as request,
          // is matches the shape of expected type so for simplicity we ill just ts-ignore it
          const wrappedTask = TaskMapper.toTaskInfoType(task, timeZone);
          if (inTheAssignedTeam && isDueDateBeforeTodayMidnight(wrappedTask)) {
            const dailyOverviewStateKey = [ServerStateKey.DAILY_VIEW_UNITS, propertyId];
            if (queryClient.getQueryState(dailyOverviewStateKey)) {
              queryClient.setQueryData(dailyOverviewStateKey, (oldData?: UnitInfoType[]) =>
                oldData
                  ? oldData.map((u) =>
                      u.id === task.unitId
                        ? {
                            ...u,
                            tasksCount: u.tasksCount + 1,
                          }
                        : u,
                    )
                  : [],
              );
            }
          }
        }
      }

      if (type === TaskType.LOST_AND_FOUND) {
        await queryClient.invalidateQueries([ServerStateKey.LOST_AND_FOUND_TASKS, propertyId]);
      }

      if (type === TaskType.DAMAGE) {
        if (location.pathname === routes.DAMAGE_REPORT) {
          await queryClient.invalidateQueries([ServerStateKey.DAMAGE_REPORT_TASKS, propertyId]);
        }
        if (isMaintainerOnly && isUnitDetailsView) {
          await queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS, task.unitId]);

          const dailyOverviewStateKey = [ServerStateKey.DAILY_VIEW_UNITS, propertyId];
          if (queryClient.getQueryState(dailyOverviewStateKey)) {
            queryClient.setQueryData(dailyOverviewStateKey, (oldData?: UnitInfoType[]) =>
              oldData
                ? oldData.map((u) =>
                    u.id === task.unitId
                      ? {
                          ...u,
                          damageReportsCount: u.damageReportsCount + 1,
                        }
                      : u,
                  )
                : [],
            );
          }
        }
      }

      // todo miscounduct task
    },
  });

  const updateTaskRequest = useUpdateTask(propertyId, {
    onSettled: () => {
      setSubmitting(false);
    },
    onSuccess: async () => {
      showNotification(
        t(`popupNotifications.${updateNotificationTranslationKeyByType[type]}`),
        NotificationType.SUCCESS,
      );

      onSuccess?.();

      if (location.pathname === routes.APALEO_HOUSEKEEPING) {
        return await queryClient.invalidateQueries([ServerStateKey.RESERVATION_TASKS, type, apaleoReservationId]);
      }

      if (taskId && location.pathname === routes.TASK_DETAILS.replace(':id', taskId)) {
        return await queryClient.invalidateQueries([ServerStateKey.TASK]);
      }

      if (type === TaskType.STANDARD) {
        // invalidate all queries for now (since this can be an update of task property, unit, date or team assigned)
        if (location.pathname === routes.TASKS) {
          await queryClient.invalidateQueries([ServerStateKey.TASKS, propertyId]);
          await queryClient.invalidateQueries([ServerStateKey.UNCONFIRMED_TASKS, propertyId]);
        } else {
          // either daily unit view or unit details page
          await queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS]);
          await queryClient.invalidateQueries([ServerStateKey.DAILY_VIEW_UNITS, propertyId]);
        }
      }

      if (type === TaskType.LOST_AND_FOUND) {
        await queryClient.invalidateQueries([ServerStateKey.LOST_AND_FOUND_TASKS, propertyId]);
      }

      if (type === TaskType.DAMAGE) {
        if (location.pathname === routes.DAMAGE_REPORT) {
          await queryClient.invalidateQueries([ServerStateKey.DAMAGE_REPORT_TASKS, propertyId]);
        } else {
          // either daily unit view or unit details page
          await queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS]);
          await queryClient.invalidateQueries([ServerStateKey.DAILY_VIEW_UNITS, propertyId]);
        }
      }

      // todo miscounduct task
    },
  });

  const createAreaRequest = useCreateArea(propertyId);

  const getUploadUriRequest = useApiCall<UploadUrl>(propertiesApi.getUploadUrl);

  const saveOrGetArea = useCallback(async (unitOrArea: ThinUnit | ThinArea) => {
    if (isUnitType(unitOrArea)) {
      return { unitId: unitOrArea.id };
    }
    let areaId = unitOrArea.id;
    if (!areaId) {
      const { name } = unitOrArea as ThinArea;
      const response = await createAreaRequest.mutateAsync({ data: { name } });
      areaId = response.id;
    }
    return {
      areaId,
    };
  }, []);

  /**
   * upload images and return new urls
   * keep urls of existing images that are not deleted
   */
  const handleImages = useCallback(
    async (propertyId: string, images: File[]) => {
      const previousImageUrls = editTask?.imageUrls ?? [];

      const imagesForUpload = images.filter((image) =>
        previousImageUrls.every((previousUrl) => !previousUrl.endsWith(image.name)),
      );

      const imageUrlsForDeletion = previousImageUrls.filter((previousUrl) =>
        images.every((image) => !image.name.endsWith(previousUrl)),
      );

      const existingImageUrlsThatAreNotChanged = previousImageUrls.filter((i) =>
        images.some((ii) => i.endsWith(ii.name)),
      );

      const newUrls = await Promise.all(
        imagesForUpload.map(async (i) => {
          const file = await resizeImage(i, 1000);
          const uploadUrl = await getUploadUriRequest({
            params: { propertyId },
            query: { type },
          });
          return uploadService.uploadFile({ ...uploadUrl, file });
        }),
      );
      if (imageUrlsForDeletion?.length) {
        // todo remove if required later
        // delete images
      }

      return [
        ...existingImageUrlsThatAreNotChanged,
        // filter null values for now if some upload fail
        ...(newUrls.filter((url) => url) as string[]),
      ];
    },
    [editTask],
  );

  const onSubmitStandardTask = useCallback(
    async (formValues: StandardTaskFormValues, taskAction?: TaskAction) => {
      if (isSubmitting) return;

      setSubmitting(true);
      const {
        team,
        property: { id: propertyId },
        unit,
        priority,
        dueAt: dueAtValue,
        images,
        reservationId,
      } = formValues;

      try {
        const unitOrArea = unit ? await saveOrGetArea(unit) : {};
        // date picker accepts zoned, convert to utc
        const dueAt = dateUtils.getDueAtUtc(dueAtValue.date!, timeZone);
        const imageUrls = await handleImages(
          propertyId,
          images.map((i) => i.image),
        );

        const defaultLangTaskAction = taskAction ? getDefaultLangTaskActionById(taskAction.id) : null;

        const actionPath = [defaultLangTaskAction?.categoryAndSubcategory, defaultLangTaskAction?.name]
          .filter((v) => v)
          .join(` ${TASK_CATEGORIES_DELIMITER} `);

        let title = formValues.title;
        if (taskAction && title === taskAction.name) {
          title = defaultLangTaskAction?.name || '';
        }

        let description = formValues.description;
        if (taskAction && description === taskAction.description) {
          description = defaultLangTaskAction?.description || '';
        }

        const request: Task = {
          dueAt,
          title,
          description,
          actionPath,
          actionId: taskAction?.id,
          propertyId,
          assignedTo: team,
          priority: taskPriorityToNumber(priority),
          isStandardized: false,
          type: TaskType.STANDARD,
          imageUrls,
          reservationId,
          source: isApaleoTab ? TaskSource.APALEO : TaskSource.SHINE,
          ...unitOrArea,
        };
        if (dueAtValue?.recurrence) {
          request.recurrence = toRRuleObject(dueAtValue)?.toString();
        }
        if (editTask?.id) {
          updateTaskRequest.mutate({ id: editTask?.id, data: request });
        } else {
          createTasksRequest.mutate({ data: [request] });
        }
      } finally {
        //
      }
    },
    [isSubmitting, editTask],
  );

  const onSubmitLostAndFoundItem = useCallback(
    async (formValues: LostAndFoundItemFormValues) => {
      if (isSubmitting) return;

      setSubmitting(true);
      const {
        title,
        property: { id: propertyId },
        unit,
        images,
      } = formValues;

      try {
        const imageUrls = await handleImages(
          propertyId,
          images.map((i) => i.image),
        );
        const unitOrArea = await saveOrGetArea(unit!);
        const request: Task = {
          title,
          propertyId,
          imageUrls,
          type,
          ...unitOrArea,
        };
        if (editTask?.id) {
          updateTaskRequest.mutate({ id: editTask?.id, data: request });
        } else {
          createTasksRequest.mutate({ data: [request] });
        }
      } catch (e) {
        // todo show error message
      } finally {
        //
      }
    },
    [isSubmitting, editTask],
  );

  const onSubmitDamageReport = useCallback(
    async (formValues: DamageReportFormValues) => {
      if (isSubmitting) return;

      setSubmitting(true);
      const {
        title,
        property: { id: propertyId },
        unit,
        images,
        priority,
        area,
        actionRequired,
        dueAt: dueAtValue,
        team,
      } = formValues;

      try {
        const imageUrls = await handleImages(
          propertyId,
          images.map((i) => i.image),
        );
        const unitOrArea = await saveOrGetArea(unit!);
        const dueAt = dateUtils.getDueAtUtc(dueAtValue.date, timeZone);
        const request: Task = {
          title,
          propertyId,
          imageUrls,
          type,
          dueAt,
          assignedTo: team,
          priority: taskPriorityToNumber(priority),
          details: {
            area,
            actionRequired,
          },
          ...unitOrArea,
        };
        // task is standardized if title on current language matches some of standardized tasks translations
        const standardizedTask = Object.keys(StandardizedDamageReport).find(
          (key) => t(`standardizedDamageReports.${key}`) === title,
        );
        request.isStandardized = !!standardizedTask;
        // if it is standardized, replace title with appropriate enum
        if (standardizedTask) {
          request.title = standardizedTask;
        }
        if (editTask?.id) {
          updateTaskRequest.mutate({ id: editTask?.id, data: request });
        } else {
          createTasksRequest.mutate({ data: [request] });
        }
      } catch (e) {
        // todo show error message
      } finally {
        //
      }
    },
    [isSubmitting, editTask],
  );

  return {
    isSubmitting,
    onSubmitStandardTask,
    onSubmitLostAndFoundItem,
    onSubmitDamageReport,
  };
}
