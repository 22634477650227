import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { ThinArea, ThinUnit } from '@typings/types';
import SelectionDialog, { SelectOption, toSelectOption, toSelectOptionFrom } from '@molecules/SelectionDialog';
import { mapArrayToObjectById } from '@utils/arrayUtils';
import AddPropertyArea from '@molecules/AddPropertyArea';
import { isUnitType } from '@utils/unitUtils';
import { useTranslation } from 'react-i18next';
import { useGetPropertyUnitsAndAreas } from '@api/api';

interface Props {
  propertyId?: string;
  value?: string;
  onSelect: (unit: ThinUnit | ThinArea) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
}

const unitToSelectOption = toSelectOptionFrom({ labelAs: 'number' });

export default function SelectUnit({ propertyId, value, onSelect, actionButton }: Props) {
  const { t } = useTranslation();

  const { isLoading, isFetching, refetch, error, data } = useGetPropertyUnitsAndAreas(propertyId);

  useEffect(() => {
    if (propertyId) {
      refetch();
    }
  }, [propertyId]);

  const onUnitSelect = useCallback(
    (dataById: { [key: string]: ThinArea | ThinUnit }) => {
      return (option?: SelectOption) => {
        onSelect(dataById[option?.value]);
      };
    },
    [onSelect],
  );

  const { units, areas } = data || { units: [], areas: [] };

  const options = units.map(unitToSelectOption).concat(areas.map(toSelectOption));
  const dataById = mapArrayToObjectById([...units, ...areas] || []);

  const selectedValue = value ? dataById[value] : undefined;
  const selectedItem =
    selectedValue && (isUnitType(selectedValue) ? unitToSelectOption(selectedValue) : toSelectOption(selectedValue));

  return (
    <SelectionDialog
      customComponent={(toggleSelectUnitOverlay) => (
        <AddPropertyArea
          onConfirm={(name) => {
            onSelect({ name });
            toggleSelectUnitOverlay();
          }}
          actionButton={(onClickAddArea) => (
            <div className={'px-3 pt-1 text-md text-gray-400'}>
              {t('selectRoomOrArea')}&nbsp;
              <span onClick={onClickAddArea} className="text-green-400 font-bold">
                {t('createNewOne')}
              </span>
            </div>
          )}
        />
      )}
      options={options}
      title={t('rooms')}
      value={selectedItem}
      onSelect={onUnitSelect(dataById)}
      actionButton={actionButton}
      isLoading={isLoading || isFetching}
      error={error}
    />
  );
}
