import { useTranslation } from 'react-i18next';
import { TaskInfoType } from '@typings/types';

const MIN_PERCENTAGE_FOR_LABEL = 25;

interface Props {
  tasks: TaskInfoType[];
}

export default function DamageReportProgressBar({ tasks }: Props) {
  const { t } = useTranslation();

  const resolved = tasks.filter((task) => !!task.completedAt).length;
  const inProgress = tasks.filter((task) => !task.completedAt && task.workingTimeEntryId).length;
  const toClean = tasks.filter((task) => !task.completedAt && !task.workingTimeEntryId).length;

  const total = resolved + inProgress + toClean;

  if (total === 0) {
    return (
      <div className={'rounded-lg flex flex-row'}>
        <div className={'bg-gray-100 flex flex-1 rounded-md text-gray-400 justify-center text-sm md:text-md'}>
          {t('noDamageReportsToday')}
        </div>
      </div>
    );
  }

  const inProgressPercentage = Number(((inProgress * 100) / total).toFixed(2));
  const notStartedPercentage = Number(((toClean * 100) / total).toFixed(2));
  const resolvedPercentage = Number(((resolved * 100) / total).toFixed(2));

  return (
    <div className={'rounded-lg flex flex-row min-h-[20px] h-[20px]'}>
      {!!resolved && (
        <div
          className={'bg-green-100 flex text-green-400 justify-center text-sm'}
          style={{ width: `${resolvedPercentage}%` }}
        >
          {resolved === total ? 'All' : resolved}&nbsp;
          {resolvedPercentage > MIN_PERCENTAGE_FOR_LABEL ? t('resolved').toLowerCase() : ''}&nbsp;
          {resolved === total ? `(${resolved})` : ''}
        </div>
      )}
      {!!inProgress && (
        <div
          className={'bg-yellow-100 flex text-yellow-400 justify-center items-center text-sm'}
          style={{ width: `${inProgressPercentage}%` }}
        >
          {inProgress}&nbsp;
          {inProgressPercentage > MIN_PERCENTAGE_FOR_LABEL ? t('inProgress').toLowerCase() : ''}
        </div>
      )}
      {!!toClean && (
        <div
          className={'bg-red-100 flex text-red-400 justify-center items-center text-sm'}
          style={{ width: `${notStartedPercentage}%` }}
        >
          {toClean}&nbsp;
          {notStartedPercentage > MIN_PERCENTAGE_FOR_LABEL ? t('notStarted').toLowerCase() : ''}
        </div>
      )}
    </div>
  );
}
