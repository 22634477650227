import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FILTER_ACTIONS,
  FILTER_DATE_RANGE,
  FILTER_EXCLUDE_ACTIONS,
  FiltersParamsType,
  TasksContext,
  TeamFilterActionValues,
} from '@context/filtersTasksContext';
import usePropertiesState from '@context/propertiesContext';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';

import Dialog from '@molecules/Dialog';
import { Filters } from './Filters';

import { XIcon } from '@heroicons/react/solid';

type FiltersModalProps = {
  selectedOptionFilter: keyof FiltersParamsType | null;
  setSelectedOptionFilter: (value: keyof FiltersParamsType | null) => void;
};

export const FiltersModal = ({ selectedOptionFilter, setSelectedOptionFilter }: FiltersModalProps) => {
  const { t } = useTranslation();
  const { filterParams, updateFilterParams, resetFilterParams } = useContext(TasksContext);
  const [optionFilterValue, setOptionFilterValue] = useState<TeamFilterActionValues | null>(null);

  const { getTeamFromUserRole } = useRoleBasedUI();
  const { selectedProperty } = usePropertiesState();
  const userTeam = getTeamFromUserRole(selectedProperty.id);

  const FilterTitleModalComponent = useMemo(() => {
    const isFilterActionsActive = selectedOptionFilter === FILTER_ACTIONS;
    const isFilterExcludeActionsActive = selectedOptionFilter === FILTER_EXCLUDE_ACTIONS;

    // title Actions and ExcludeActions depends on the number of selected actions
    if ((isFilterActionsActive || isFilterExcludeActionsActive) && optionFilterValue?.value?.length) {
      const totalActionsSelected = optionFilterValue?.value?.length;
      return t('actionsSelected', { total: totalActionsSelected });
    }

    if (selectedOptionFilter) {
      return t(Filters[selectedOptionFilter].titleModal);
    }
    return null;
  }, [optionFilterValue, selectedOptionFilter, filterParams]);

  const FilterModalComponent = useMemo(() => {
    if (selectedOptionFilter) return Filters[selectedOptionFilter].modal;
    return null;
  }, [selectedOptionFilter, filterParams]);

  const onConfirm = () => {
    setSelectedOptionFilter(null);
    if (optionFilterValue) {
      updateFilterParams({
        id: optionFilterValue.id,
        label: optionFilterValue.label,
        value: optionFilterValue.value,
      });
    }
  };

  const onReset = () => {
    if (userTeam && selectedOptionFilter) {
      resetFilterParams(userTeam, t, selectedOptionFilter);
    } else if (userTeam) {
      // reset all filters
      resetFilterParams(userTeam, t);
    }
    setSelectedOptionFilter(null);
  };

  useEffect(() => {
    if (selectedOptionFilter && filterParams[selectedOptionFilter]) {
      setOptionFilterValue({ id: selectedOptionFilter, ...filterParams[selectedOptionFilter] });
    }
  }, [selectedOptionFilter, selectedOptionFilter && filterParams[selectedOptionFilter]]);

  if (selectedOptionFilter && FilterModalComponent && FilterTitleModalComponent) {
    return (
      <Dialog
        isOpen={!!selectedOptionFilter}
        onClose={() => setSelectedOptionFilter(null)}
        isMobileSheet
        sheetFullHeight={selectedOptionFilter === FILTER_DATE_RANGE}
        customHeader={
          <div
            className="w-full flex justify-between items-center px-4 pt-[22px] md:pt-3 pb-[22px] 
            border-b border-b-[#DEDEDE]"
          >
            <button
              type="button"
              className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
              onClick={() => setSelectedOptionFilter(null)}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="text-base font-bold font-serif">{FilterTitleModalComponent}</div>
            <button onClick={onReset} className="text-[#8B9DA5] text-base font-normal font-serif">
              {t('reset')}
            </button>
          </div>
        }
      >
        <FilterModalComponent
          values={filterParams[selectedOptionFilter]}
          onClick={(optionFilter) => setOptionFilterValue(optionFilter)}
          onConfirm={onConfirm}
        />
      </Dialog>
    );
  }

  return null;
};
