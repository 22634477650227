import { StandardTaskFormValues } from '@utils/hooks/useCreateOrUpdateTask';
import { Team } from '@typings/enums';
import { PropertyType, TaskInfoType, ThinUnit } from '@typings/types';
import dateUtils from '@utils/dateUtils';
import { isAfter, startOfDay } from 'date-fns';
import { ImageFile } from '@utils/hooks/useImageUrls';
import { numberToTaskPriority } from '@utils/taskUtils';
import { toRepeatOptions } from '@utils/repetitionUtils';

interface Props {
  property: PropertyType;
  unit?: ThinUnit;
  editTask?: TaskInfoType;
  images: ImageFile[];
  apaleoReservationId?: string;
  minDate?: Date;
}

export function getDefaultFormValues(props: Props): Partial<StandardTaskFormValues> {
  const { property, unit, editTask, images, apaleoReservationId, minDate } = props;

  // Datepicker logic expects property time, so we set it to midnight
  const midnightToday = startOfDay(dateUtils.now());
  const date = minDate && isAfter(minDate, midnightToday) ? minDate : midnightToday;

  const values: Partial<StandardTaskFormValues> = {
    team: Team.HOUSE_KEEPING,
    dueAt: { date },
    property,
    unit,
  };

  if (apaleoReservationId) {
    values.reservationId = apaleoReservationId;
  }

  if (editTask) {
    values.title = editTask.title;

    values.description = editTask.description ?? undefined;
    values.unit = editTask?.unit ?? editTask?.area ?? undefined;
    values.team = editTask.assignedTo;
    const priority = numberToTaskPriority(editTask.priority || 0);
    values.priority = priority;
    const dueAt = {
      date: editTask.dueAt!,
      recurrence: editTask.recurrence ? toRepeatOptions(editTask.recurrence) : undefined,
    };
    values.dueAt = dueAt;
    values.images = images;
  }

  return values;
}
