import { useCallback } from 'react';

export function useDisablePullToRefresh() {
  const enablePull = useCallback(() => {
    document.documentElement.style.overscrollBehavior = 'auto';
  }, [document]);

  const disablePull = useCallback(() => {
    document.documentElement.style.overscrollBehavior = 'none';
  }, [document]);

  return {
    enablePull,
    disablePull,
  };
}
