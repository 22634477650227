import { useCallback } from 'react';
import Dialog from '@molecules/Dialog';
import { useToggle } from '@utils/hooks/useToggle';
import MoveMidstayCleanDialog from '@organisms/MoveMidstayCleanDialog';

interface Props {
  propertyId: string;
  unitId: string;
  reservationId?: string;
  onSuccess?: () => void;
}
function useMoveMidstayClean({ propertyId, unitId, reservationId, onSuccess }: Props) {
  const [visible, toggleVisible] = useToggle(false);
  const onSubmit = useCallback(() => {
    onSuccess?.();
    toggleVisible();
  }, [unitId, propertyId, onSuccess]);
  const renderMoveMidstayCleaningDialog = useCallback(() => {
    return (
      <Dialog isOpen={visible} onClose={() => toggleVisible()} isMobileSheet>
        <>
          {reservationId && (
            <MoveMidstayCleanDialog reservationId={reservationId} propertyId={propertyId} onSubmit={onSubmit} />
          )}
        </>
      </Dialog>
    );
  }, [visible, propertyId, reservationId, toggleVisible]);
  return {
    onClickMoveMidstayClean: toggleVisible,
    renderMoveMidstayCleaningDialog,
  };
}

export default useMoveMidstayClean;
