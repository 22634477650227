/* eslint-disable @typescript-eslint/no-unused-vars */
import { Analytics, getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';
import firebaseApp from '@utils/firebaseApp';
import { deviceType } from '@utils/deviceUtils';

export enum AppInitType {
  REGULAR = 'regular',
  WEB_VIEW = 'web_view',
  APALEO_TAB = 'apaleo_tab',
}

class AnalyticsService {
  instance: Analytics = getAnalytics(firebaseApp);

  async setUser(id: string, initType: AppInitType) {
    await setUserId(this.instance, id);
    await setUserProperties(this.instance, {
      // camel case, so it doesn't overlap with reserved user_id
      userId: id,
      web_app_init_type: initType,
      device: `web_${deviceType}`,
    });
  }
}

const analyticsService = new AnalyticsService();

export default analyticsService;
