import React from 'react';
import { NotificationType } from '@typings/enums';

export interface NotificationInfo {
  content: string;
  type: NotificationType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: any;
}

interface NotificationState {
  notifications: NotificationInfo[];
}

const initialState: NotificationState = {
  notifications: [],
};

export enum NotificationActionType {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
}

type NotificationAction =
  | {
      type: NotificationActionType.SHOW_NOTIFICATION;
      payload: NotificationInfo;
    }
  | {
      type: NotificationActionType.CLOSE_NOTIFICATION;
      payload: number;
    };

const reducer = (state: NotificationState, action: NotificationAction) => {
  switch (action.type) {
    case NotificationActionType.SHOW_NOTIFICATION:
      return {
        notifications: [...state.notifications, action.payload],
      };
    case NotificationActionType.CLOSE_NOTIFICATION:
      return {
        notifications: [...state.notifications].splice(action.payload, 1),
      };
    default:
      return state;
  }
};

export const NotificationContext = React.createContext({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  dispatch: (action: NotificationAction) => {},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NotificationProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <NotificationContext.Provider value={{ state, dispatch }}>{children}</NotificationContext.Provider>;
};
