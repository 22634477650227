const backendUrl = process.env.REACT_APP_BACKEND_URL;

const isLocalDevelopment = process.env.NODE_ENV === 'development';
const env = process.env.REACT_APP_ENV;
const isProd = env === 'prod';

const auth0 = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  audience: process.env.REACT_APP_AUTH0_API_AUDIENCE!,
};

const chatApi = {
  appId: process.env.REACT_APP_TALKJS_APP_ID!,
};

const sentryDsn = process.env.REACT_APP_SENTRY_DSN!;
const firebaseVapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY!;

export default {
  env,
  isProd,
  isLocalDevelopment,
  auth0,
  backendUrl,
  sentryDsn,
  firebaseVapidKey,
  chatApi,
};
