import { TaskAction } from '@api/taskAction.types';
import { XIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

interface Props {
  errorMessage?: string;
  value: TaskAction | undefined;
  onClickDelete: (value: TaskAction) => void;
}
function TaskActionTag({ errorMessage, value, onClickDelete }: Props) {
  const { t } = useTranslation();

  if (!value)
    return (
      <div
        className={`w-full border border-dashed ${
          errorMessage ? 'border-th-accent text-th-accent' : 'border-th-brown-100 text-th-brown-100'
        } rounded-lg flex justify-center items-center h-24`}
      >
        <div className="text-center py-2 px-4">{errorMessage ?? t('noActionSelected')}</div>
      </div>
    );

  const { name, categoryAndSubcategory } = value;
  return (
    <div className="flex flex-1 px-1 rounded-md shadow-around">
      <div className={`flex w-full items-start p-4 border-b border-gray-200`}>
        <div className="flex flex-col gap-y-2 w-full">
          <div className="text-black text-base font-semibold">{name}</div>
          {categoryAndSubcategory && (
            <div className="flex flex-1 text-th-brown text-sm font-normal">{categoryAndSubcategory}</div>
          )}
        </div>
        <div className="cursor-pointer" onClick={() => onClickDelete(value)}>
          <XIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}

export default TaskActionTag;
