import { AppLanguage } from '@organisms/SelectLanguage/SelectLanguage';
import storageService from '@utils/storageService';
import { useTranslation } from 'react-i18next';

export const DEFAULT_LANGUAGE = AppLanguage.ENGLISH;

export function useLanguage() {
  const { i18n } = useTranslation();

  const language = (i18n.language as AppLanguage) || DEFAULT_LANGUAGE;

  const onChangeLanguage = (newLanguage: AppLanguage) => {
    console.log(newLanguage);
    if (newLanguage && newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
      storageService.saveLanguage(newLanguage);
    }
  };
  return {
    language,
    setLanguage: onChangeLanguage,
  };
}
