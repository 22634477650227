import Dropdown from '@molecules/Dropdown';
import React, { useMemo } from 'react';
import dateUtils, { formatDate, TIME_FORMAT, toDate } from '@utils/dateUtils';
import { SelectOption } from '@molecules/SelectionDialog';
import { addMinutes, isAfter } from 'date-fns';

export function getOptions(fromTime: string, toTime: string, stepInMinutes: number) {
  const from = dateUtils.setTime(dateUtils.now(), fromTime);
  const to = dateUtils.setTime(dateUtils.now(), toTime);
  const options: SelectOption<string>[] = [];
  let date = toDate(from);
  while (!isAfter(date, to)) {
    const timeString = formatDate(date, TIME_FORMAT);
    options.push({ value: timeString, label: timeString });
    date = addMinutes(date, stepInMinutes);
  }
  return options;
}

interface Props {
  value?: string;
  onChange: (v: string) => void;
  from: string;
  to: string;
  step?: number;
  disabled?: boolean;
}

export default function TimePicker({ value, onChange, from, to, step = 30, disabled = false }: Props) {
  const options = useMemo(() => getOptions(from, to, step), [from, to, step]);
  if (value && !options.map((o) => o.value).includes(value)) {
    return <div>-</div>;
  }
  return (
    <Dropdown
      value={value}
      items={options}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onChange}
      className={'font-bold px-1 rounded bg-gray-200'}
      disabled={disabled}
    />
  );
}
