import { useContext, useEffect, useMemo, useState } from 'react';
import { FiltersModal } from './FiltersModals';
import {
  FILTER_ACTIONS,
  FILTER_DATE_RANGE,
  FILTER_EXCLUDE_ACTIONS,
  FILTER_RECURRENCE,
  FILTER_PRIORITY,
  FILTER_ROOMS,
  FILTER_TEAMS,
  FiltersParamsType,
  TasksContext,
} from '@context/filtersTasksContext';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { SVGIconType } from '@typings/types';
import icons from '@constants/icons';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import usePropertiesState from '@context/propertiesContext';
import { updateDefaultFiltersBasedOnUserRole } from './utils';
import Dialog from '@molecules/Dialog';
import Button from '@atoms/Button';
import useTaskActionsState from '@context/taskActionsContext';
import { AutomatedTaskType } from '@typings/enums';

const FilterButton = ({
  onClick,
  children,
  FilterIcon,
  isActive,
  isDisabled = false,
  showChevron = true,
}: {
  onClick: () => void;
  children: React.ReactChild;
  FilterIcon?: SVGIconType;
  isActive: boolean;
  isDisabled?: boolean;
  showChevron?: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex justify-between items-center gap-x-2 px-2 py-[10px] rounded-[4px] bg-th-gray-50
      border border-th-brown-100 text-lg leading-5 font-serif font-bold ${
        isActive && 'border-th-primary bg-th-green-100'
      }`}
    >
      {FilterIcon && <FilterIcon className={isDisabled ? 'text-th-brown-100' : ''} />}
      {children}
      {showChevron && <ChevronDownIcon className={`w-4 h-4 ${isDisabled ? 'text-th-brown-100' : 'text-black'}`} />}
    </button>
  );
};

export const Filters = () => {
  const [hasResetFilter, setHasResetFilter] = useState<boolean>(false);
  const [selectedOptionFilter, setSelectedOptionFilter] = useState<keyof FiltersParamsType | null>(null);
  const [disabledModalContent, setDisabledModalContent] = useState<{ title: string; description: string } | null>(null);

  const { t } = useTranslation();
  const { filterParams, updateFilterParams, resetFilterParams } = useContext(TasksContext);
  const { getTeamFromUserRole } = useRoleBasedUI();
  const { selectedProperty } = usePropertiesState();
  const userTeam = getTeamFromUserRole(selectedProperty.id);

  const { label: teamsLabel } = filterParams[FILTER_TEAMS];
  const { label: priorityLabel } = filterParams[FILTER_PRIORITY];
  const { label: dateRangeLabel } = filterParams[FILTER_DATE_RANGE];
  const { value: actionsValue } = filterParams[FILTER_ACTIONS];
  const { value: excludeActionsValue } = filterParams[FILTER_EXCLUDE_ACTIONS];
  const { label: recurrenceLabel } = filterParams[FILTER_RECURRENCE];
  const { value: roomsValue } = filterParams[FILTER_ROOMS];

  const isActionFilterDisabled = useMemo(() => !!excludeActionsValue, [excludeActionsValue]);
  const isExcludeActionFilterDisabled = useMemo(() => !!actionsValue, [actionsValue]);

  const openOptionFilters = (id: keyof FiltersParamsType, isFilterDisabled?: boolean) => {
    if (isFilterDisabled) {
      const currentFilter = id === FILTER_ACTIONS ? t('action') : t('hideActions');
      const resetFilter = id === FILTER_ACTIONS ? t('hideActions') : t('action');
      const modalTitle = t('disableActionTitleModal', { currentFilter, resetFilter });
      const modalDescription = t('disableActionDescriptionModal', { currentFilter, resetFilter });

      setDisabledModalContent({ title: modalTitle, description: modalDescription });
    } else {
      setSelectedOptionFilter(id);
    }
  };

  const handleOverallReset = () => {
    if (userTeam) {
      resetFilterParams(userTeam, t);
    }
  };

  const { getTaskActionByAutomatedType } = useTaskActionsState();

  const excludeActionsId = useMemo(() => {
    const kitchenUseActionId = getTaskActionByAutomatedType(AutomatedTaskType.KITCHEN_USE)?.id;
    if (!kitchenUseActionId) return null;
    return [kitchenUseActionId];
  }, [getTaskActionByAutomatedType]);

  useEffect(() => {
    // assign default Team Filter based on user role && avoid overwriting Team Filter
    if (userTeam && !filterParams[FILTER_TEAMS].value && !filterParams[FILTER_DATE_RANGE].value) {
      updateDefaultFiltersBasedOnUserRole(userTeam, updateFilterParams, t);
    }
  }, [userTeam, filterParams]);

  useEffect(() => {
    if (
      !hasResetFilter &&
      selectedProperty?.kitchenImageAnalysis &&
      excludeActionsId &&
      !filterParams[FILTER_EXCLUDE_ACTIONS].value
    ) {
      setHasResetFilter(true);
      updateFilterParams({
        id: FILTER_EXCLUDE_ACTIONS,
        label: t('hideActions'),
        value: excludeActionsId,
      });
    }
  }, [excludeActionsId]);

  return (
    <div>
      <div className="flex md:flex-wrap justify-start items-center gap-2 overflow-auto no-scrollbar">
        <FilterButton
          onClick={() => openOptionFilters(FILTER_DATE_RANGE)}
          isActive={!!dateRangeLabel}
          FilterIcon={icons.calendarFilter}
        >
          <>
            <span className="whitespace-nowrap">{dateRangeLabel || t('dueDate')}</span>
          </>
        </FilterButton>
        <FilterButton
          onClick={() => openOptionFilters(FILTER_TEAMS)}
          isActive={!!teamsLabel}
          FilterIcon={icons.userFilter}
        >
          <>
            <span className="whitespace-nowrap">{teamsLabel?.length === 1 ? teamsLabel[0] : t('team')}</span>
            {teamsLabel && teamsLabel.length > 1 && (
              <div className="text-white text-sm bg-black rounded-full w-6 h-6 flex justify-center items-center">
                <span className="subpixel-antialiased leading-none font-sans">{teamsLabel?.length}</span>
              </div>
            )}
          </>
        </FilterButton>
        <FilterButton
          onClick={() => openOptionFilters(FILTER_PRIORITY)}
          isActive={!!priorityLabel}
          FilterIcon={icons.priorityFilter}
        >
          <>
            <span className="whitespace-nowrap">{priorityLabel || t('priority')}</span>
          </>
        </FilterButton>
        <FilterButton
          onClick={() => openOptionFilters(FILTER_ACTIONS, isActionFilterDisabled)}
          isActive={!!actionsValue}
          isDisabled={isActionFilterDisabled}
          FilterIcon={icons.listFilter}
        >
          <>
            <span className={`whitespace-nowrap ${isActionFilterDisabled && 'text-th-brown-100'}`}>
              {t('showActions')}
            </span>
            {actionsValue && actionsValue.length > 0 && (
              <div className="text-white text-sm bg-black rounded-full w-6 h-6 flex justify-center items-center">
                <span className="subpixel-antialiased leading-none font-sans">{actionsValue?.length}</span>
              </div>
            )}
          </>
        </FilterButton>
        <FilterButton
          onClick={() => openOptionFilters(FILTER_EXCLUDE_ACTIONS, isExcludeActionFilterDisabled)}
          isActive={!!excludeActionsValue}
          isDisabled={isExcludeActionFilterDisabled}
          FilterIcon={icons.hiddenFilter}
        >
          <>
            <span className={`whitespace-nowrap ${isExcludeActionFilterDisabled && 'text-th-brown-100'}`}>
              {t('hideActions')}
            </span>
            {excludeActionsValue && excludeActionsValue.length > 0 && (
              <div className={`text-white text-sm bg-black rounded-full w-6 h-6 flex justify-center items-center`}>
                <span className="subpixel-antialiased leading-none font-sans">{excludeActionsValue?.length}</span>
              </div>
            )}
          </>
        </FilterButton>
        <FilterButton
          onClick={() => openOptionFilters(FILTER_RECURRENCE)}
          isActive={!!recurrenceLabel}
          FilterIcon={icons.recurrenceFilter}
        >
          <span className="whitespace-nowrap">{recurrenceLabel || t('noRepetition')}</span>
        </FilterButton>
        <FilterButton
          onClick={() => openOptionFilters(FILTER_ROOMS)}
          isActive={!!roomsValue}
          FilterIcon={icons.roomFilter}
        >
          <>
            <span className="whitespace-nowrap">{t('rooms')}</span>
            {roomsValue && roomsValue.length > 0 && (
              <div className="text-white text-sm bg-black rounded-full w-6 h-6 flex justify-center items-center">
                <span className="subpixel-antialiased leading-none font-sans">{roomsValue?.length}</span>
              </div>
            )}
          </>
        </FilterButton>
        <button
          className="text-th-primary font-bold text-base font-serif mx-3 border-b border-b-transparent hover:border-b-th-primary transition-colors duration-100 ease-in-out"
          onClick={handleOverallReset}
        >
          <span className="whitespace-nowrap">{t('resetAllFilters')}</span>
        </button>
      </div>
      <FiltersModal selectedOptionFilter={selectedOptionFilter} setSelectedOptionFilter={setSelectedOptionFilter} />
      <Dialog
        isOpen={!!disabledModalContent}
        onClose={() => setDisabledModalContent(null)}
        customHeader={<div className="py-1" />}
      >
        <div className="flex flex-col items-center justify-center gap-4 max-w-[284px] px-3">
          <h3 className="font-serif font-bold text-lg text-th-brown">{disabledModalContent?.title}</h3>
          <p className="font-sans font-normal text-sm text-th-secondary text-center">
            {disabledModalContent?.description}
          </p>
          <Button className="w-full font-normal" onClick={() => setDisabledModalContent(null)}>
            {t('confirm')}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
