import { TaskInfoType, TaskInfoTypeDto } from '@typings/types';
import { utcToZonedTime } from 'date-fns-tz';
import dateUtils from '@utils/dateUtils';

export class TaskMapper {
  static toTaskInfoType(task: TaskInfoTypeDto, timeZone: string): TaskInfoType {
    const dueAt = task.dueAt ? utcToZonedTime(task.dueAt, timeZone) : null;
    const createdAt = task.createdAt ? utcToZonedTime(task.createdAt, timeZone) : dateUtils.now();
    const completedAt = task.completedAt ? utcToZonedTime(task.completedAt, timeZone) : null;

    const utcTime = {
      timeZone,
      dueAt: task.dueAt ? new Date(task.dueAt) : null,
      createdAt: new Date(task.createdAt),
      completedAt: task.completedAt ? new Date(task.completedAt) : null,
    };
    return {
      ...task,
      dueAt,
      createdAt,
      completedAt,
      utcTime,
    };
  }
}
