import { PropertyType, TaskInfoType, ThinUnit } from '@typings/types';
import { DamageReportFormValues } from '@utils/hooks/useCreateOrUpdateTask';
import { DamageReportActionRequired, TaskPriority, Team } from '@typings/enums';
import dateUtils from '@utils/dateUtils';
import { isAfter, startOfDay } from 'date-fns';
import { ImageFile } from '@utils/hooks/useImageUrls';
import { numberToTaskPriority } from '@utils/taskUtils';
import i18n from 'i18next';

interface Props {
  property: PropertyType;
  unit?: ThinUnit;
  editTask?: TaskInfoType;
  images: ImageFile[];
  apaleoReservationId?: string;
  minDate?: Date;
}

export function getDefaultFormValues(props: Props): Partial<DamageReportFormValues> {
  const { property, unit, editTask, images, apaleoReservationId, minDate } = props;
  const midnightToday = startOfDay(dateUtils.now());
  const date = minDate && isAfter(minDate, midnightToday) ? minDate : midnightToday;
  const values: Partial<DamageReportFormValues> = {
    property,
    team: Team.MAINTENANCE,
    priority: TaskPriority.HIGH,
    dueAt: { date },
    unit,
  };

  /**
   * Damage report created through Apaleo extension
   * will always be with CHECK action required.
   */
  if (apaleoReservationId) {
    values.actionRequired = DamageReportActionRequired.CHECK;
  }
  if (editTask) {
    values.title = (editTask.isStandardized && i18n.t(`standardizedDamageReports.${editTask.title}`)) || editTask.title;
    if (editTask.details) {
      const { area, actionRequired } = editTask.details;
      values.area = area;
      values.actionRequired = actionRequired;
    }
    values.team = editTask.assignedTo;
    const priority = numberToTaskPriority(editTask.priority || 0);
    values.priority = priority;
    if (editTask.dueAt) {
      const dueAt = { date: editTask.dueAt! };
      values.dueAt = dueAt;
    }
    values.unit = editTask?.unit ?? editTask?.area ?? undefined;
    values.images = images;
  }
  return values;
}
