import React, { MouseEventHandler } from 'react';

interface Props {
  id: string;
  name: string;
  label: string | React.ReactNode;
  onClick: MouseEventHandler<HTMLInputElement>;
  checked?: boolean;
  className?: string;
}

export default function RadioButton({ id, name, label, onClick, checked, className }: Props) {
  return (
    <div
      className={`relative flex items-start justify-between px-3 py-2 ${className ?? ''} ${
        checked ? 'bg-green-50' : ''
      }`}
    >
      <div className="flex-1 mr-3">
        <label htmlFor={id} className="flex cursor-pointer">
          {label}
        </label>
      </div>
      <div className="flex items-center h-5">
        <input
          autoComplete="off"
          readOnly
          id={id}
          name={name}
          type="radio"
          className={'h-5 w-5 focus:ring-0 text-th-primary border-gray-300 cursor-pointer'}
          onClick={onClick}
          checked={checked}
        />
      </div>
    </div>
  );
}
