import React from 'react';
import { IconType } from '@typings/types';

interface Props {
  Icon: IconType;
  text?: string;
  large?: boolean;
  className?: string;
}

export default function IconWithText({ Icon, text, large, className }: Props) {
  return (
    <div className={`flex flex-row items-center flex-shrink-0 min-w-0 ${className ?? ''}`}>
      <Icon className={`${large ? 'h-5 w-5' : 'h-4 w-4'} flex-shrink-0 text-gray-400 stroke-current mr-1`} />
      {text && (
        <span
          className={`truncate ${
            large ? 'text-lg text-th-brown font-serif font-bold' : 'text-th-secondary'
          } max-w-[100px]`}
        >
          {text}
        </span>
      )}
    </div>
  );
}
