import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import { useMemo } from 'react';
import { UserRole } from '@typings/types';
import usePropertiesState from '@context/propertiesContext';

function useAuth() {
  const { getPropertyRoles } = useRoleBasedUI();
  const { selectedProperty } = usePropertiesState();
  const selectedPropertyId = selectedProperty?.id;

  const currentPropertyRoles = useMemo(
    () => (selectedProperty ? getPropertyRoles(selectedPropertyId) : []),
    [selectedPropertyId, getPropertyRoles],
  );

  const currentPropertyRolesExcludingGx = useMemo(
    () => currentPropertyRoles.filter((r) => r !== UserRole.GX),
    [currentPropertyRoles],
  );

  const isCleaner = useMemo(() => currentPropertyRoles.includes(UserRole.CLEANER), [currentPropertyRoles]);
  const isCleanerOnly = useMemo(
    () => currentPropertyRolesExcludingGx.length === 1 && currentPropertyRolesExcludingGx.includes(UserRole.CLEANER),
    [currentPropertyRolesExcludingGx],
  );
  const isRoomChecker = useMemo(() => currentPropertyRoles.includes(UserRole.ROOMCHECKER), [currentPropertyRoles]);
  const isOperations = useMemo(() => currentPropertyRoles.includes(UserRole.OPERATIONS), [currentPropertyRoles]);

  return {
    isCleaner,
    isCleanerOnly,
    isRoomChecker,
    isOperations,
  };
}
export default useAuth;
