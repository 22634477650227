const LANGUAGE = 'language';
const PROPERTY = 'property';
const FCM_TOKEN = 'fcm_token';
const IS_MOBILE_APP = 'is_mobile_app';
const RETURN_TO_AFTER_LOGIN = 'rtal';

function getLanguage(): string | null {
  return localStorage.getItem(LANGUAGE);
}

function saveLanguage(token: string): void {
  localStorage.setItem(LANGUAGE, token);
}

function getProperty(): string | null {
  return localStorage.getItem(PROPERTY);
}

function saveProperty(property: string): void {
  localStorage.setItem(PROPERTY, property);
}

function getFcmToken(): string | null {
  return localStorage.getItem(FCM_TOKEN);
}

function saveFcmToken(token: string): void {
  localStorage.setItem(FCM_TOKEN, token);
}

function removeFcmToken(): void {
  localStorage.removeItem(FCM_TOKEN);
}

function isMobileApp(): boolean {
  return localStorage.getItem(IS_MOBILE_APP) === 'true';
}

function getReturnToUrl(): string | null {
  return localStorage.getItem(RETURN_TO_AFTER_LOGIN);
}

function setReturnToUrl(value: string): void {
  localStorage.setItem(RETURN_TO_AFTER_LOGIN, value);
}

function removeReturnToUrl(): void {
  localStorage.removeItem(RETURN_TO_AFTER_LOGIN);
}

export default {
  getLanguage,
  saveLanguage,
  getProperty,
  saveProperty,
  getFcmToken,
  removeFcmToken,
  saveFcmToken,
  isMobileApp,
  getReturnToUrl,
  setReturnToUrl,
  removeReturnToUrl,
};
