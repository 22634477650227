import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { DEFAULT_LANGUAGE, useLanguage } from '@utils/hooks/useLanguage';
import { useGetTaskCategories } from '@api/api';
import { TaskAction } from '@api/taskAction.types';
import { mapArrayToObjectById } from '@utils/arrayUtils';
import { AutomatedTaskType } from '@typings/enums';

interface TaskActionsContext {
  data: TaskAction[];
  getTaskActionById: (id: string) => TaskAction | null;
  getDefaultLangTaskActionById: (id: string) => TaskAction | null;
  getTaskActionByAutomatedType: (automatedTaskType: AutomatedTaskType) => TaskAction | null;
}

export const TaskActionsContext = createContext({} as TaskActionsContext);

interface Props {
  children: React.ReactNode;
}
export function TaskActionsProvider(props: Props) {
  const { language } = useLanguage();
  const isDefaultLanguage = language === DEFAULT_LANGUAGE;
  const [defaultLangData, setDefaultLangData] = useState<TaskAction[]>([]);
  const [data, setData] = useState<TaskAction[]>([]);
  const { data: defaultLangTaskActionData = [] } = useGetTaskCategories({ locale: DEFAULT_LANGUAGE, enabled: true });
  const { data: taskActionData = [] } = useGetTaskCategories({ locale: language, enabled: !isDefaultLanguage });
  const dataByIds = useMemo(() => mapArrayToObjectById(taskActionData), [taskActionData, language]);
  const defaultLangDataByIds = useMemo(() => mapArrayToObjectById(defaultLangData), [defaultLangData]);
  const automatedTasksActions = useMemo(() => taskActionData.filter((task) => task.automatedTask), [taskActionData]);

  const getTaskActionById = (id: string) => dataByIds[id] ?? null;

  const getDefaultLangTaskActionById = (id: string) => defaultLangDataByIds[id] ?? null;

  const getTaskActionByAutomatedType = (automatedTaskType: AutomatedTaskType) => {
    return automatedTasksActions.find((taskAction) => taskAction.automatedTask === automatedTaskType) ?? null;
  };

  useEffect(() => {
    if (taskActionData.length) {
      setData(taskActionData);
    }
  }, [taskActionData]);

  useEffect(() => {
    if (defaultLangTaskActionData.length) {
      setDefaultLangData(defaultLangTaskActionData);
      if (isDefaultLanguage) {
        setData(defaultLangTaskActionData);
      }
    }
  }, [defaultLangTaskActionData, isDefaultLanguage]);

  const state: TaskActionsContext = {
    data,
    getTaskActionById,
    getDefaultLangTaskActionById,
    getTaskActionByAutomatedType,
  };
  return <TaskActionsContext.Provider value={state} {...props} />;
}

export default function useTaskActionsState() {
  const context = useContext(TaskActionsContext);
  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useTaskActionsState was used outside of its Provider');
  }
  return context;
}
