import { ApiInfo } from '@api/types';

const basePath = (propertyId: string) => `/${propertyId}/room-rack`;

const roomRackApi: ApiInfo = {
  getRoomRack: ({ params, query }) => {
    const { propertyId } = params!;
    const { fromDate, toDate } = query! as {
      fromDate?: string;
      toDate?: string;
    };

    const queryParams = new URLSearchParams();
    if (fromDate) queryParams.append('from', fromDate);
    if (toDate) queryParams.append('to', toDate);

    return {
      url: `${basePath(propertyId)}?${queryParams.toString()}`,
    };
  },
};

export default roomRackApi;
