import { Dialog as HeadlessDialog } from '@headlessui/react';
import Sheet from 'react-modal-sheet';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import useBreakpoint from '@utils/hooks/useBreakpoint';
import useScreenHeight from '@utils/hooks/useScreenHeight';
import { useTranslation } from 'react-i18next';
import Transition from '@atoms/Transition';
import cn from 'classnames';
import { useDisablePullToRefresh } from '@utils/hooks';

const MOBILE_SHEET_HEADER_HEIGHT = 53;
// bottom offset for sheet height calculated based on the content
const MOBILE_SHEET_HEADER_OFFSET = 30;

interface Props {
  children: JSX.Element;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  isMobileSheet?: boolean;
  // mobile sheet height in percents (eg. 0.5) or pixels (eg. 200)
  sheetHeight?: number;
  // whether mobile sheet should be the full height
  sheetFullHeight?: boolean;
  customHeader?: JSX.Element;
  titleComponent?: JSX.Element;
  actionButton?: JSX.Element;
  withoutHeader?: boolean;
  fullScreen?: boolean;
  draggable?: boolean;
}

type HeaderProps = {
  withoutHeader: Props['withoutHeader'];
  customHeader: Props['customHeader'];
  title: Props['title'];
  titleComponent: Props['titleComponent'];
  actionButton: Props['actionButton'];
  onClose: Props['onClose'];
  mounted?: boolean;
};

function DesktopHeader({ withoutHeader, customHeader, title, titleComponent, actionButton, onClose }: HeaderProps) {
  if (withoutHeader) {
    return <div className="h-3" />;
  }

  if (customHeader) {
    return customHeader;
  }

  return (
    <>
      <div className="absolute top-0 right-0 pt-3 pr-3">
        <button
          type="button"
          className="bg-transparent rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <HeadlessDialog.Title>
        {title && <div className="text-lg font-bold text-th-brown font-serif px-3">{title}</div>}
        {titleComponent}
        {actionButton && <div className="absolute top-0 right-0 pt-3 mr-12">{actionButton}</div>}
      </HeadlessDialog.Title>
    </>
  );
}

function MobileHeader({
  withoutHeader,
  customHeader,
  title,
  mounted,
  titleComponent,
  actionButton,
  onClose,
}: HeaderProps) {
  const { t } = useTranslation();

  if (withoutHeader) {
    return <Sheet.Header />;
  }

  if (customHeader) {
    return customHeader;
  }

  return (
    <div className={'flex flex-row py-3 px-4 justify-between border-b border-gray-100'}>
      <div className={'flex-1 text-gray-400 font-serif'} onClick={onClose}>
        {t('close')}
      </div>
      <div className={'flex-1 font-bold text-center text-th-brown font-serif'}>{title}</div>
      <div className={'flex flex-1 flex-row justify-end'}>
        {titleComponent}
        {mounted && actionButton}
      </div>
    </div>
  );
}

export default function Dialog({
  children,
  title,
  titleComponent,
  actionButton,
  isOpen,
  onClose: onCloseAction,
  isMobileSheet,
  sheetHeight,
  sheetFullHeight,
  withoutHeader = false,
  customHeader,
  fullScreen = false,
}: Props) {
  const breakpoint = useBreakpoint();
  const screenHeight = useScreenHeight();

  const { enablePull, disablePull } = useDisablePullToRefresh();

  // if both sheetHeight and sheetFullHeight are omitted, sheet height is calculated based on the content
  const contentRef = useRef<HTMLDivElement>();
  const [mobileSheetHeight, setMobileSheetHeight] = useState(!sheetFullHeight ? sheetHeight : undefined);

  const isSmallScreen = breakpoint === 'xs' || breakpoint === 'sm';

  /**
   * needed to postpone rendering of action button
   * (prevents appearing popover behind the dialog)
   */
  const [mounted, setMounted] = useState<boolean>(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useLayoutEffect(() => {
    if (isOpen && !sheetHeight && !sheetFullHeight) {
      const childrenHeight = contentRef?.current?.children[0]?.clientHeight;
      if (childrenHeight) {
        const height = childrenHeight + MOBILE_SHEET_HEADER_HEIGHT + MOBILE_SHEET_HEADER_OFFSET;
        setMobileSheetHeight(height < screenHeight ? height : undefined);
      }
    }
  }, [isOpen, children, screenHeight, mounted, contentRef?.current?.children[0]?.clientHeight]);

  const onClose = useCallback(() => {
    onCloseAction();
    enablePull();
  }, [onCloseAction, enablePull]);

  if (isSmallScreen && isMobileSheet) {
    return (
      <Sheet
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={mobileSheetHeight ? [mobileSheetHeight] : undefined}
        disableDrag
        disableScrollLocking
        prefersReducedMotion
        onOpenStart={disablePull}
        // by defaullt react-modal-sheet sets z-index to 9999999 which disallows any other popup to be over the modal
        style={{ zIndex: 10 }}
      >
        <Sheet.Container>
          <MobileHeader
            title={title}
            mounted={mounted}
            onClose={onClose}
            customHeader={customHeader}
            actionButton={actionButton}
            withoutHeader={withoutHeader}
            titleComponent={titleComponent}
          />
          <Sheet.Content ref={contentRef}>{mounted ? children : <div />}</Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    );
  }

  return (
    <Transition show={isOpen}>
      <HeadlessDialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isOpen} onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen pt-3 pb-20 text-center sm:block sm:p-0">
          <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div
            className={cn(
              'inline-block align-bottom bg-white rounded-lg pt-3 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle',
              fullScreen ? 'w-full h-full' : 'sm:max-w-full',
            )}
          >
            <DesktopHeader
              withoutHeader={withoutHeader}
              customHeader={customHeader}
              title={title}
              titleComponent={titleComponent}
              actionButton={actionButton}
              onClose={onClose}
            />
            {children}
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
}
