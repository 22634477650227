import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export default function TimeInput({ value, onChange, name, label }: Props) {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="block text-xs font-medium text-gray-400 uppercase">
          {label}
        </label>
      )}
      <div className="mt-1 relative rounded-md">
        <input
          className={'font-bold p-1 rounded bg-gray-200 border-0 focus:outline-none focus:ring-0'}
          name={name}
          value={value}
          type={'time'}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
