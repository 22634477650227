import React, { useCallback } from 'react';
import Dialog from '@molecules/Dialog';
import { Controller, useForm, useWatch } from 'react-hook-form';
import SelectProperty from '@organisms/SelectProperty';
import SelectInput from '@molecules/SelectInput';
import SelectUnit from '@organisms/SelectUnit';
import { PropertyType, TaskInfoType, ThinUnit } from '@typings/types';
import TextArea from '@molecules/TextArea';
import { TaskType } from '@typings/enums';
import Button, { ButtonType } from '@atoms/Button';
import useCreateOrUpdateTask, { LostAndFoundItemFormValues } from '@utils/hooks/useCreateOrUpdateTask';
import { useTranslation } from 'react-i18next';
import UploadMultiple from '@molecules/UploadMultiple';
import useImageUrls from '@utils/hooks/useImageUrls';
import usePropertiesState from '@context/propertiesContext';
import { getDefaultFormValues } from './utils';
import { useAutoFocus } from '@utils/hooks/useAutoFocus';

interface Props {
  selectedUnit?: ThinUnit;
  editTask?: TaskInfoType;
  onClose?: () => void;
}

function AddLostAndFoundItem({ selectedUnit: defaultUnit, editTask, onClose }: Props) {
  const { t } = useTranslation();
  const { selectedProperty } = usePropertiesState();

  const { images, pickerInitialImages } = useImageUrls(editTask?.imageUrls);

  const defaultValues = getDefaultFormValues({
    defaultUnit,
    editTask,
    images,
    property: selectedProperty,
  });

  const isEditTask = !!editTask;

  const form = useForm<LostAndFoundItemFormValues>({
    defaultValues,
    mode: 'onChange',
  });
  const {
    formState: { errors },
  } = form;

  const property = useWatch({
    control: form.control,
    name: 'property',
  });

  const { setRef: setTitleRef } = useAutoFocus();

  const onComplete = useCallback(() => {
    form.reset();
    onClose?.();
  }, [onClose]);

  const { isSubmitting, onSubmitLostAndFoundItem: onSubmit } = useCreateOrUpdateTask({
    property,
    editTask,
    type: TaskType.LOST_AND_FOUND,
    onSuccess: onComplete,
  });

  const onSelectProperty = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (onChange: (...event: any[]) => void) => {
      return (property: PropertyType) => {
        onChange(property);
        form.setValue('unit', undefined);
      };
    },
    [form],
  );

  return (
    <Dialog
      isOpen
      onClose={onComplete}
      isMobileSheet
      sheetFullHeight
      title={editTask?.id ? t('editItem') : t('addItem')}
      actionButton={
        <Button isLoading={isSubmitting} type={ButtonType.UNSTYLED} onClick={() => form.handleSubmit(onSubmit)()}>
          {t('save')}
        </Button>
      }
    >
      <div className={'md:w-96 flex flex-col px-4 overflow-y-scroll'}>
        <div className={'flex flex-row'}>
          <div className={'flex-1'}>
            <Controller
              control={form.control}
              name={'title'}
              rules={{
                required: {
                  message: t('titleIsRequired'),
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  ref={setTitleRef}
                  value={value}
                  onChange={onChange}
                  placeholder={t('description')}
                  error={errors.title}
                />
              )}
            />
          </div>
          <div className={'py-3 pr-2'}>
            <UploadMultiple form={form} initialImages={pickerInitialImages} />
          </div>
        </div>

        <div className={'flex flex-col bg-gray-50 px-4 rounded-md'}>
          <Controller
            control={form.control}
            name={'property'}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <SelectProperty
                value={value}
                onSelect={onSelectProperty(onChange)}
                actionButton={(onClickSelectProperty) => (
                  <SelectInput
                    disabled={isEditTask}
                    onClick={onClickSelectProperty}
                    value={value?.name}
                    label={t('property')}
                    placeholder={t('selectProperty')}
                  />
                )}
              />
            )}
          />

          <Controller
            control={form.control}
            name={'unit'}
            rules={{
              required: {
                value: true,
                message: t('unitIsRequired'),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <SelectUnit
                propertyId={property.id}
                value={value?.id}
                onSelect={onChange}
                actionButton={(onClickSelectUnit) => (
                  <SelectInput
                    isLast
                    onClick={onClickSelectUnit}
                    value={value && ('number' in value ? value.number : value.name)}
                    label={t('rooms')}
                    placeholder={t('selectRooms')}
                    error={errors.unit}
                  />
                )}
              />
            )}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default AddLostAndFoundItem;
