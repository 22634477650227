import icons from '@constants/icons';
import { MaintenanceSlotType } from '@typings/types';

export const slotTypeInfo = `OOO- Out of Order, should be used when a unit is not functional and cannot be used by a guest.

&nbsp;

OOS- Out of Service, Still allows the room to be sold, not used. A unit marked as Out Of Service is fully functional and has only minor cosmetic defects.

&nbsp;

OOI- Out of Inventory (used by Launch team) reflects a long-lasting maintenance plan, such as the renovation of a whole floor.`;

export const errorOverbooking =
  'This unit group is overbooked during this time. If this block is necessary, please contact GX to relocate the guest. ';

export const defaultOptions = {
  slotType: [
    { name: 'Out of order', value: MaintenanceSlotType.OUT_OF_ORDER },
    { name: 'Out of service', value: MaintenanceSlotType.OUT_OF_SERVICE },
    { name: 'Out of inventory', value: MaintenanceSlotType.OUT_OF_INVENTORY },
  ],
  reason: [
    { icon: icons.maintenance, name: 'MTN - Maintenance', value: 'MNT' },
    { icon: icons.cleaningIssue, name: 'CL - Cleaning Issue', value: 'CL' },
    { icon: icons.roomMoveBlock, name: 'RM - Room move block', value: 'RM' },
    { icon: icons.bedBugs, name: 'BB - Bed bugs', value: 'BB' },
    { icon: icons.smoking, name: 'SMK - Smoking', value: 'SMK' },
    { icon: icons.vipGuest, name: 'VIP - VIP Guest', value: 'VIP' },
    { icon: icons.pmp, name: 'PMP', helpText: 'Preventive Maintenance Program', value: 'PMP' },
    { icon: icons.rampUp, name: 'RU - Ramp-up', value: 'RU' },
    { icon: icons.other, name: 'OTHER- OThers(e.g. photo shoot', value: 'OTHER' },
    { icon: icons.quarantine, name: 'QUAR - Quarantine', value: 'QUAR' },
  ],
};

export const defaultMinMaxDate = 365;
