import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from '@atoms/Spinner';
import { Selector } from '../Commons/Selector';
import { ConfirmButton } from '../Commons/ConfirmButton';

import { FiltersParamsType } from '@context/filtersTasksContext';
import { Option, MultiSelectorValues, MultiSelectorClick } from '../FilterTypes';

interface MultiSelectorModalProps {
  id: keyof FiltersParamsType;
  options: Option[];
  values: MultiSelectorValues;
  isLoading?: boolean;
  isError?: boolean;
  onClick: MultiSelectorClick;
  onConfirm: () => void;
}

export const MultiSelectorModal = ({
  id,
  options,
  values,
  isLoading = false,
  isError = false,
  onClick,
  onConfirm,
}: MultiSelectorModalProps) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(values.value ?? []);

  const handleOptionChange = (option: Option) => {
    let newSelectedOptions;

    if (selectedOptions.includes(option.value)) {
      newSelectedOptions = [...selectedOptions].filter((item) => item !== option.value);
    } else {
      newSelectedOptions = [...selectedOptions, option.value];
    }

    const labels = options.reduce((acc: string[], option) => {
      if (newSelectedOptions.includes(option.value)) {
        return [...acc, option.label];
      }
      return acc;
    }, []);

    setSelectedOptions(newSelectedOptions);
    onClick({ id, label: labels, value: newSelectedOptions });
  };

  if (isError) {
    return (
      <div className="md:min-w-[350px] flex justify-center items-center h-20">
        <p>{t('anErrorHasOccurred')}</p>
      </div>
    );
  }

  return (
    <div className="md:min-w-[350px]">
      <div className="max-h-60 md:max-h-96 overflow-y-auto">
        {isLoading ? (
          <div className="w-full h-60 flex justify-center items-center">
            <Spinner className="text-th-primary w-8 h-8" />
          </div>
        ) : (
          <Selector options={options} selectedOption={selectedOptions} handleOptionChange={handleOptionChange} />
        )}
      </div>
      <div className="py-4 px-5">
        <ConfirmButton isDisabled={!selectedOptions || selectedOptions?.length === 0} onConfirm={onConfirm} />
      </div>
    </div>
  );
};
