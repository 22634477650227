import { AutocompleteOption } from '@molecules/AutocompleteInput/types';
import Fuse from 'fuse.js';
import { useMemo } from 'react';

export function useFuzzySearch<T>(data: AutocompleteOption<T>[]) {
  const fuseDataObject = useMemo(
    () =>
      new Fuse<AutocompleteOption<T>>(data, {
        threshold: 0.3,
        ignoreLocation: true,
        /**
         * Starts the search when user types at least 2 chars.
         */
        minMatchCharLength: 2,
        keys: [
          { name: 'title', weight: 1 },
          { name: 'helperText', weight: 1 },
        ],
      }),
    [data],
  );

  const getSearchResults = (value: string) => {
    const results = fuseDataObject.search(value);
    return results.map((r) => r.item);
  };

  return {
    getSearchResults,
  };
}
