import { useCallback, useContext } from 'react';
import { NotificationType } from '@typings/enums';
import { NotificationActionType, NotificationContext } from '@context/NotificationContext';

export default function useNotifications() {
  const { state, dispatch } = useContext(NotificationContext);

  const closeNotification = useCallback((i) => {
    dispatch({ type: NotificationActionType.CLOSE_NOTIFICATION, payload: i });
  }, []);

  const showNotification = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (content: string, type: NotificationType = NotificationType.INFO, metadata?: any) => {
      dispatch({ type: NotificationActionType.SHOW_NOTIFICATION, payload: { content, type, metadata } });
    },
    [],
  );

  return { notifications: state.notifications, showNotification, closeNotification };
}
