import { addHours, endOfDay, format, isAfter, setHours, setMinutes, setSeconds } from 'date-fns';

export const formattedOption = (options: { id: string; title: string }[]) => {
  return options.map((option) => ({ id: option.id, name: option.title, value: option.title }));
};

export const getNextHourDefault = (now: Date) => {
  let nextHour = addHours(setMinutes(now, 0), 1);

  // Check if the next hour is after 23:00
  if (isAfter(nextHour, endOfDay(now))) {
    nextHour = setMinutes(endOfDay(now), 59);
  }
  return format(nextHour, 'HH:mm');
};

export function getDateAndTime(startDate: Date, startTime: string) {
  const [hours, minutes] = startTime.split(':');
  return setHours(setMinutes(setSeconds(startDate, 0), Number(minutes)), Number(hours));
}
