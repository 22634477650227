import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import env from '@constants/env';
import { ApiCallInfo, ApiCallParamBuilderFn } from '@api/types';

export default function useApiCall<T>(paramsBuilderFn: ApiCallParamBuilderFn) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  return useCallback(
    async (info: ApiCallInfo): Promise<T> => {
      const params = paramsBuilderFn(info);
      const accessToken = await getAccessTokenSilently();
      if (!accessToken || !isAuthenticated) {
        // todo: catch error and redirect to login (or try to refresh token)
        throw new Error('User is not authenticated');
      }
      const { url, body, ...init } = params;
      const response = await fetch(`${env.backendUrl}/housekeeping${url}`, {
        ...init,
        ...(body ? { body: JSON.stringify(body) } : {}),
        headers: {
          ...init?.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 204) {
        return {} as T;
      }
      const jsonData = await response.json();
      if (!response.ok) {
        const message = jsonData?.error || jsonData?.message || response.statusText;
        throw new Error(message);
      }
      return jsonData;
    },
    [isAuthenticated, getAccessTokenSilently, paramsBuilderFn],
  );
}
