interface Props {
  image: string;
  form: JSX.Element;
}

export default function SignInLayout({ image, form }: Props) {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col flex-1">
      <div className="flex flex-col items-center">
        <div className={'w-full bg-yellow-50 py-20 px-5'}>
          <img className="mx-auto md:max-w-md h-auto" src={image} alt="Sign in image" />
        </div>
        <div className={'w-full flex flex-col items-center px-5 md:px-0'}>{form}</div>
      </div>
    </div>
  );
}
