import React, { MouseEventHandler, useMemo } from 'react';
import SelectionDialog, { SelectOption } from '@molecules/SelectionDialog';
import { useTranslation } from 'react-i18next';
import { DamageReportArea } from '@typings/enums';

interface Props {
  value?: DamageReportArea;
  onSelect: (item: DamageReportArea) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
}

export default function SelectDamageReportArea({ value, onSelect, actionButton }: Props) {
  const { t } = useTranslation();

  const items = useMemo(
    () =>
      Object.keys(DamageReportArea).reduce(
        (acc, curr) => {
          acc[curr] = { value: curr, label: t(`damageReportAreas.${curr}`) };
          return acc;
        },
        {} as { [key: string]: SelectOption },
      ),
    [t],
  );

  const selectedItem = value && items[value];

  return (
    <SelectionDialog
      title={`${t('select')} ${t('area').toLowerCase()}`}
      value={selectedItem}
      onSelect={(item) => onSelect(item?.value)}
      actionButton={actionButton}
      options={Object.values(items)}
      includeSearch={false}
    />
  );
}
