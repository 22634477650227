import { useCallback, useRef } from 'react';

export function useAutoFocus<T extends HTMLTextAreaElement | HTMLInputElement = HTMLTextAreaElement>(autoFocus = true) {
  const ref = useRef<T>();

  const setRef = useCallback(
    (node: T) => {
      ref.current = node;
      if (autoFocus) {
        setTimeout(focus);
      }
    },
    [ref, autoFocus],
  );

  const focus = () => {
    if (!ref.current) {
      return;
    }
    ref.current.focus();
    const valueLength = ref.current.value.length || 0;
    ref.current.setSelectionRange(valueLength, valueLength);
  };

  return {
    ref,
    setRef,
    focus,
  };
}
