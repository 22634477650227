import { ExclamationCircleIcon } from '@heroicons/react/solid';
import React, { ChangeEvent, ForwardedRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import './TextArea.css';
import debounce from 'lodash.debounce';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: FieldError | { message: string };
}

function TextArea(props: Props, outerRef: ForwardedRef<HTMLTextAreaElement>) {
  const { value, name, error, placeholder, onChange, ...inputProps } = props;
  const innerRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(outerRef, () => innerRef.current!, []);
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    if (innerRef.current?.value) {
      expandTextArea();
    }
  }, [innerRef.current]);

  /** Expands text area based on the scroll height.  */
  const expandTextArea = () => {
    if (!innerRef.current) {
      return;
    }
    innerRef.current.style.height = innerRef.current.scrollHeight + 'px';
  };

  useEffect(() => {
    if (value !== innerValue) {
      setInnerValue(value);
      // await rerender
      setTimeout(() => {
        expandTextArea();
      });
    }
  }, [value]);

  const onChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setInnerValue(value);
    onChangeDebounced(e);
  };

  const onChangeDebounced = useCallback(
    debounce((e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(e);
    }, 300),
    [onChange],
  );

  return (
    <>
      <div className="mt-3 rounded-md">
        <div className="grow-wrap">
          <textarea
            {...inputProps}
            value={innerValue}
            ref={innerRef}
            onInput={(e) => {
              // todo figure out typescript fix
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (e.currentTarget.parentNode as any).dataset.replicatedValue = (e.target as any).value;
            }}
            onChange={onChangeText}
            name={name}
            id={name}
            className={`
              ${
                error
                  ? 'bg-red-100 rounded-md border-[1px] border-red-400 focus:border-red-400 text-red-400 placeholder-red-400'
                  : 'focus:border-th-secondary'
              }
              placeholder-gray-400 focus:outline-none focus:ring-0
               block w-full px-0 py-2 border-0 bg-transparent`}
            placeholder={placeholder}
          />
        </div>
      </div>
      <div className="flex justify-start items-center gap-x-1 mt-1 text-sm text-red-500" id={`${name}-error`}>
        {error && (
          <div className="flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-[18px] w-[18px] text-red-500" aria-hidden="true" />
          </div>
        )}
        {error?.message}
      </div>
    </>
  );
}

export default React.forwardRef(TextArea);
