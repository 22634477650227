import React from 'react';
import { TaskInfoType } from '@typings/types';
import { useChat } from '@utils/hooks';
import LoadingMessage from '@atoms/LoadingMessage';

type Props = Pick<TaskInfoType, 'id' | 'createdById' | 'createdByName'>;

function Chatbox(props: Props) {
  const task = props;
  const { getOrCreateTaskChat } = useChat();

  const chatbox = getOrCreateTaskChat(task);

  const onChangeRef = (element: HTMLDivElement | null) => {
    if (element) {
      chatbox?.mount(element);
    }
    return element;
  };

  return (
    <div className="pl-0 md:pl-4 h-[600px] md:h-full w-full md:max-w-lg mb-10 md:mb-0" ref={onChangeRef}>
      <LoadingMessage />
    </div>
  );
}

export default React.memo(Chatbox);
