import { NotificationDto, NotificationDtoType, TaskInfoType } from '@typings/types';
import React, { CSSProperties, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BellIcon } from '@heroicons/react/outline';
import { formatDistance } from 'date-fns';
import dateUtils, { toDate } from '@utils/dateUtils';
import { DamageReportActionRequired } from '@typings/enums';
import icons from '@constants/icons';
import { isConfirmTaskAvailable } from '@utils/taskUtils';

interface Props {
  item: NotificationDto;
  onClick: (value: NotificationDto) => void;
  style?: CSSProperties;
}

export default function NotificationItem({ item, onClick, style = {} }: Props) {
  const { t } = useTranslation();
  const currentTime = dateUtils.now();

  const getActionRequired = useCallback(
    (actionRequired) => {
      if (!actionRequired) {
        return '-';
      }
      if (Object.values(DamageReportActionRequired).includes(actionRequired)) {
        return t(`damageReportActionsRequired.${actionRequired}`);
      }
      return actionRequired;
    },
    [t],
  );

  if (item.type === NotificationDtoType.DAMAGE_REPORT_DUE) {
    const task = item.metadata.task as TaskInfoType;
    const title = task.isStandardized ? t(`standardizedDamageReports.${task.title}`) : task.title;
    return (
      <div
        onClick={() => onClick(item)}
        className={'p-4 flex flex-row items-start bg-white rounded border-b cursor-pointer'}
      >
        <div className={'flex mr-4 p-2 rounded-full bg-gray-100'}>
          {task.workingTimeEntryId ? (
            <icons.inProgress className={'h-6 w-6 text-gray-500'} />
          ) : (
            <BellIcon className={'h-6 w-6 text-gray-500'} />
          )}
        </div>
        <div className={'flex flex-col'} style={style}>
          <div>
            <span className={'font-bold'}>{t('actionRequired')}</span>
            <span>: {task.unit?.number ?? task.area?.name}: </span>
            <span>{title}: </span>
            <span>{getActionRequired(task.details?.actionRequired)}</span>
          </div>
          <div className={'text-gray-400 text-sm'}>
            {t('created')} {formatDistance(toDate(task.utcTime.createdAt), currentTime, { addSuffix: true })}
          </div>
        </div>
      </div>
    );
  }

  if (item.type === NotificationDtoType.DAMAGE_REPORT_REMINDER) {
    const task = item.metadata.task as TaskInfoType;
    return (
      <div
        onClick={() => onClick(item)}
        className={'p-4 flex flex-row items-start bg-white rounded border-b cursor-pointer'}
      >
        <div className={'mr-4 p-2 rounded-full bg-gray-100'}>
          {task.workingTimeEntryId ? (
            <icons.inProgress className={'h-6 w-6 text-gray-500'} />
          ) : (
            <BellIcon className={'h-6 w-6 text-gray-500'} />
          )}
        </div>
        <div className={'flex flex-col'} style={style}>
          <div>
            <span className={'font-bold'}>{t('reminder')}</span>
            <span>:&nbsp;{t('reminderTaskNotificationText')}</span>
          </div>
          <div className={'text-gray-400 text-sm'}>
            {t('created')} {formatDistance(toDate(task.utcTime.createdAt), currentTime, { addSuffix: true })}
          </div>
        </div>
      </div>
    );
  }

  if (item.type === NotificationDtoType.TASK_DUE) {
    const task = item.metadata.task as TaskInfoType;
    const title = task.title;
    const isConfirmTask = isConfirmTaskAvailable(task);
    return (
      <div
        onClick={() => onClick(item)}
        className={'p-4 flex flex-row items-start bg-white rounded border-b cursor-pointer'}
      >
        <div className={'flex mr-4 p-2 rounded-full bg-gray-100'}>
          <BellIcon className={'h-6 w-6 text-gray-500'} />
        </div>
        <div className={'flex flex-col'} style={style}>
          <div>
            <span className={'font-bold'}>
              {isConfirmTask && !task.confirmedAt
                ? t(task.createdByGX ? 'confirmYourTaskGX' : 'confirmYourTask')
                : t(task.createdByGX ? 'yourTaskGX' : 'yourTask')}
            </span>
            <span>: {task.unit?.number ?? task.area?.name}: </span>
            <span>{title}</span>
          </div>
          <div className={'text-gray-400 text-sm'}></div>
        </div>
      </div>
    );
  }

  return null;
}
