import { CalendarModal } from '../Modals/CalendarModal';

import { FILTER_DATE_RANGE } from '@context/filtersTasksContext';

import { CalendarValues, CalendarClick } from '../FilterTypes';

export const DateRangeFilterModal = ({
  values,
  onClick,
  onConfirm,
}: {
  values: CalendarValues;
  onClick: CalendarClick;
  onConfirm: () => void;
}) => {
  return <CalendarModal values={values} id={FILTER_DATE_RANGE} onClick={onClick} onConfirm={onConfirm} />;
};
