import { CheckIcon } from '@heroicons/react/solid';
import { MouseEventHandler } from 'react';

interface Props {
  isChecked: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  className?: string;
  disabled?: boolean;
}

export default function CheckMark({ isChecked, onClick, className, disabled }: Props) {
  return (
    <div
      className={`rounded-full w-6 h-6 flex items-center justify-center border-2 border-th-secondary cursor-pointer ${
        isChecked ? 'bg-th-secondary' : 'bg-transparent'
      }
      ${disabled ? 'opacity-50' : ''}
      ${className}
      `}
      onClick={(e) => {
        if (disabled) {
          return;
        }
        onClick(e);
      }}
    >
      {isChecked && <CheckIcon className={'h-4 w-4 text-white'} />}
    </div>
  );
}
