import { UnitStatus } from '@typings/enums';
import icons from '@constants/icons';
import { SVGIconType } from '@typings/types';

export default {
  [UnitStatus.READY_TO_CLEAN]: {
    label: 'readyToClean',
    Icon: icons.readyToClean,
  },
  [UnitStatus.OCCUPIED_CLEANING_TODAY]: {
    label: 'occupiedCleaningToday',
    Icon: icons.occupiedCleaningToday,
  },
  [UnitStatus.READY_TO_INSPECT]: {
    label: 'readyToInspect',
    Icon: icons.readyToInspect,
  },
  [UnitStatus.STAYOVER_READY_TO_CLEAN]: {
    label: 'stayoverReadyToClean',
    Icon: icons.readyToClean,
  },
  [UnitStatus.STAYOVER_READY_TO_INSPECT]: {
    label: 'stayoverInspection',
    Icon: icons.readyToInspect,
  },
  [UnitStatus.OCCUPIED]: {
    label: 'occupied',
    Icon: icons.door,
  },
  [UnitStatus.VACANT]: {
    label: 'vacant',
    Icon: icons.vacant,
  },
  OPEN_TASKS: {
    label: 'openTasks',
    // todo: change icon
    Icon: icons.vacant,
  },
} as {
  [key: string]: {
    label: string;
    Icon: SVGIconType;
  };
};
