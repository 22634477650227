import { User as Auth0User } from '@auth0/auth0-react';
import env from '@constants/env';
import useChatState from '@context/chatContext';
import { TaskInfoType } from '@typings/types';
import { CHAT_DEFAULT_ROLE, CHAT_USER_GROUPS_FOR_TEAMS } from '@utils/chatUtils';
import { useCallback } from 'react';
import Talk from 'talkjs';

export function useChat() {
  const { isReady, setReady, user, setUser, session, setSession } = useChatState();

  const initialize = useCallback(async () => {
    if (isReady) return;

    await Talk.ready;

    setReady(true);
  }, [isReady, setReady]);

  const onLoggedIn = useCallback(
    async (user: Auth0User) => {
      await initialize();

      const email = user.email!;

      const userData = {
        id: user.sub!,
        email,
        name: user.name ?? email,
        role: CHAT_DEFAULT_ROLE,
      };
      const talkUser = new Talk.User(userData);
      setUser(talkUser);

      const talkSession = new Talk.Session({
        appId: env.chatApi.appId,
        me: talkUser,
      });
      setSession(talkSession);
    },
    [setUser, setSession],
  );

  const onLogout = useCallback(() => {
    try {
      session?.destroy();
    } catch (e) {
      console.log(e);
    }
    setUser(null);
    setSession(null);
  }, [user, session, setUser]);

  const getOrCreateTaskChat = useCallback(
    (task: Pick<TaskInfoType, 'id' | 'createdById' | 'createdByName'>) => {
      if (!user || !session) {
        return null;
      }

      const conversationId = `task_${task.id}`;
      const conversation = session.getOrCreateConversation(conversationId);

      const createdByUser = new Talk.User({
        id: task.createdById,
        name: task.createdByName,
        role: CHAT_DEFAULT_ROLE,
      });
      conversation.setParticipant(user, {
        notify: 'MentionsOnly',
      });
      if (user.id !== createdByUser.id) {
        conversation.setParticipant(createdByUser, {
          notify: 'MentionsOnly',
        });
      }

      CHAT_USER_GROUPS_FOR_TEAMS.forEach(({ id, name }) => {
        const user = new Talk.User({ id, name, role: CHAT_DEFAULT_ROLE });
        conversation.setParticipant(user);
      });

      const chatbox = session?.createChatbox({
        showChatHeader: false,
      });
      chatbox?.select(conversation);

      return chatbox;
    },
    [user, session],
  );

  return {
    isReady,
    getOrCreateTaskChat,
    onLoggedInChat: onLoggedIn,
    onLogoutChat: onLogout,
  };
}
