interface TimeSelectorProps {
  label: string;
  value: string | null;
  onChangeTime: (time: string) => void;
  error?: string;
}

export const TimeSelector = ({ label, value, onChangeTime, error }: TimeSelectorProps) => {
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'hour' | 'minute') => {
    const newValue = Number(e.target.value);
    const [hours, minutes] = value ? value.split(':') : ['00', '00'];
    if (type === 'hour') {
      if (newValue < 0 || newValue > 23) return;
      onChangeTime(`${newValue}:${minutes}`);
    }
    if (type === 'minute') {
      if (newValue < 0 || newValue > 59) return;
      onChangeTime(`${hours}:${newValue}`);
    }
  };

  return (
    <div className="relative w-2/5 font-sans">
      <span className={`${error ? 'text-th-red-200' : 'text-th-brown-100'} text-xs font-normal`}>{label}</span>
      <div
        className={`flex justify-start items-center border-b ${
          error ? 'border-th-red-200' : 'border-th-brown-50'
        } pb-1 ${value ? 'text-th-brown' : 'text-th-brown-100'}`}
      >
        <input
          type="text"
          value={value ? value.split(':')[0] : ''}
          onChange={(e) => handleTimeChange(e, 'hour')}
          placeholder="00"
          className={`p-0 border-0 bg-transparent w-6 focus:outline-none focus:ring-0 text-center text-th-brown placeholder-th-brown-100`}
          min="00"
          max="23"
        />
        :
        <input
          type="text"
          value={value ? value.split(':')[1] : ''}
          onChange={(e) => handleTimeChange(e, 'minute')}
          placeholder="00"
          className={`p-0 border-0 bg-transparent w-6 focus:outline-none focus:ring-0 text-center text-th-brown placeholder-th-brown-100`}
          min="00"
          max="59"
        />
      </div>
      {error && <p className="mt-1 text-red-500 text-xs">{error}</p>}
    </div>
  );
};
