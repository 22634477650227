import { useCallback, useState } from 'react';
import { AssignedRoom, AssignedUnitsByFloor } from '@typings/types';

function useSelectRooms(data: AssignedUnitsByFloor = {}) {
  const [value, setValue] = useState<AssignedRoom[]>([]);
  const roomsByFloor = Object.values(data);
  const allRooms = roomsByFloor.flatMap(Object.values);
  const totalRooms = allRooms.length;

  const onSelectFloor = useCallback(
    (floorNumber: string, deselect?: boolean) => {
      const otherRooms = value.filter((v) => v.floorNumber !== floorNumber);
      const floorRooms = Object.values(data[floorNumber] || {});
      const newValue = otherRooms.concat(deselect ? [] : floorRooms);
      setValue(newValue);
    },
    [data, value, setValue],
  );

  const onSelectRoom = useCallback(
    (room: AssignedRoom, deselect?: boolean) => {
      let newValue = [...value, room];
      if (deselect) {
        newValue = value.filter((r) => r.number !== room.number);
      }
      setValue(newValue);
    },
    [value, setValue],
  );

  return {
    value,
    setValue,
    onSelectFloor,
    onSelectRoom,
    totalRooms,
  };
}

export default useSelectRooms;
