import Dialog from '@molecules/Dialog';
import ImagePreview from '@atoms/ImagePreview';
import { ChangeEventHandler, useCallback, useRef } from 'react';
import { ReactComponent as UploadIcon } from '@assets/images/upload.svg';
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import MarkdownRenderer from '@atoms/MarkdownRenderer';
import { UseFormReturn } from 'react-hook-form';
import { StandardTaskFormValues } from '@utils/hooks/useCreateOrUpdateTask';
import Spinner from '@atoms/Spinner';

type PicturesKitchenUseModalProps = {
  onClose: () => void;
  isSubmitting: boolean;
  saveButtonLabel: string;
  form: UseFormReturn<StandardTaskFormValues, unknown, undefined>;
  onSubmit: (data: StandardTaskFormValues) => void;
  images: { [key: string]: File };
  description: string | undefined;
  onRemoveFile: (url: string) => void;
  onChangeFile: ChangeEventHandler<HTMLInputElement>;
};

export const PicturesKitchenUseModal = ({
  onClose,
  form,
  images,
  onSubmit,
  isSubmitting,
  saveButtonLabel,
  description,
  onRemoveFile,
  onChangeFile,
}: PicturesKitchenUseModalProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const onUpload = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    ref.current?.click();
  };

  const renderKitchenUseDialogContent = useCallback(() => {
    const isSubmitDisabled = Object.keys(images).length === 0;

    return (
      <div className="max-w-md">
        {description && (
          <div className="bg-th-gray-100 p-4 font-sans text-base font-normal flex justify-center items-start gap-2">
            <InformationCircleIcon className="w-6 h-6" />
            <div className="flex-1">
              <MarkdownRenderer markdown={description}></MarkdownRenderer>
            </div>
          </div>
        )}
        <div className="flex justify-start items-center px-4 py-[10px] gap-[18px] overflow-auto">
          {Object.keys(images).map((url) => (
            <div key={url} className={'relative mt-2'}>
              <div className={'w-[84px] h-[84px] border border-th-brown-50 rounded-lg'}>
                <ImagePreview url={url} />
              </div>
              <TrashIcon
                className={
                  'absolute -top-2 -right-2 p-1 w-5 h-5 shadow text-th-accent rounded-full cursor-pointer bg-white'
                }
                onClick={() => onRemoveFile(url)}
              />
            </div>
          ))}
          <div
            className={
              'flex items-center justify-center min-w-[84px] min-h-[84px] rounded-lg border border-th-brown-50 cursor-pointer mt-2'
            }
            onClick={onUpload}
          >
            <UploadIcon width={32} height={32} />
            <input
              ref={ref}
              accept="image/*"
              id="file-upload"
              name="file-upload"
              type="file"
              hidden
              multiple
              onChange={onChangeFile}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] py-4 px-5">
          {isSubmitting ? (
            <button
              className={`w-full py-4 text-white text-base font-normal font-sans rounded-md
              bg-th-secondary flex justify-center items-center`}
            >
              <Spinner className={'w-5 h-5 text-th-primary'} />
            </button>
          ) : (
            <button
              className={`w-full py-4 text-white text-base font-normal font-sans rounded-md
              bg-th-secondary flex justify-center items-center ${isSubmitDisabled && 'opacity-50 cursor-not-allowed'}`}
              onClick={form.handleSubmit(onSubmit)}
              disabled={isSubmitDisabled}
            >
              <span>{saveButtonLabel}</span>
            </button>
          )}
        </div>
      </div>
    );
  }, [description, images, isSubmitting]);

  return (
    <Dialog
      isOpen
      onClose={onClose}
      isMobileSheet
      customHeader={
        <div className="w-full flex md:flex-row-reverse justify-between items-center px-4 pt-4 md:pt-3 pb-3 border-b border-b-[#DEDEDE]">
          <button
            type="button"
            className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      }
    >
      <>{renderKitchenUseDialogContent()}</>
    </Dialog>
  );
};
