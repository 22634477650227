import React from 'react';
import { Disclosure } from '@headlessui/react';
import Transition from '@atoms/Transition';
import SectionHeader from './SectionHeader';

type Props = {
  defaultOpen?: boolean;
  headerTitle?: React.ReactNode;
  renderHeader?: (open: boolean) => React.ReactNode;
  children: React.ReactNode;
};

function CollapsibleSection({ defaultOpen = true, headerTitle, renderHeader, children }: Props) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className={'flex flex-col bg-white shadow-md rounded-md'}>
          <Disclosure.Button>
            {headerTitle ? <SectionHeader open={open}>{headerTitle}</SectionHeader> : renderHeader?.(open)}
          </Disclosure.Button>
          <Transition show={open}>
            <Disclosure.Panel>{children}</Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
}

export default CollapsibleSection;
