import { ReactComponent as LogoImage } from '@assets/images/logo.svg';
import { ReactComponent as LogoIcon } from '@assets/images/logo-icon.svg';

interface Props {
  right?: JSX.Element;
  left?: JSX.Element;
}

export default function Header({ left, right }: Props) {
  return (
    <nav className="bg-white rounded-b-xl shadow-md flex flex-row px-2 md:px-5 py-2 justify-between h-16 md:h-[72px]">
      <LogoImage className={'w-24 mr-4 hidden md:block flex-shrink-0'} />
      <LogoIcon className={'w-8 mr-2 md:hidden flex-shrink-0'} />
      <div className={'flex flex-row items-center flex-shrink-0'}>
        {left && <div className={'mr-2 md:mr-4'}>{left}</div>}
        {right}
      </div>
    </nav>
  );
}
