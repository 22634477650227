import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import resources from './resources';

const lng = window.localStorage.getItem('language') ?? 'en';
i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
