interface Props {
  navigation?: JSX.Element;
  content: JSX.Element;
}

export default function HomeLayout({ navigation, content }: Props) {
  return (
    <div className="max-w-3xl mx-auto flex flex-col flex-1 w-full">
      <div className="flex flex-1 min-h-0 pb-20 md:pb-7 relative">
        {content}
        {navigation}
      </div>
    </div>
  );
}
