import React, { useCallback, useEffect } from 'react';
import { GxTasks } from '@organisms/Tasks';
import useSearchParams from '@utils/hooks/useSearchParams';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { GxDamageReports } from '@organisms/AdditionalTasks';
import { useGetReservationByExternalId } from '@api/api';
import GxCheckoutSection from '@organisms/GxCheckoutSection';
import dateUtils from '@utils/dateUtils';
import GxExtraServicesSection from '@organisms/GxExtraServicesSection';
import Button, { ButtonType } from '@atoms/Button';
import GxMidstayCleaning from '@organisms/GxMidstayCleaning';
import LoadingMessage from '@atoms/LoadingMessage';
import { isAfter, startOfDay } from 'date-fns';
import usePropertiesState from '@context/propertiesContext';

function HousekeepingTab() {
  const { logout } = useAuth0();

  const searchParams = useSearchParams();
  const { t } = useTranslation();

  const apaleoPropertyId = searchParams.get('propertyId');
  const apaleoReservationId = searchParams.get('reservationId');

  if (!apaleoReservationId || !apaleoPropertyId) {
    return null;
  }
  const { selectedProperty, onSelectProperties } = usePropertiesState();
  const timeZone = selectedProperty?.timeZone;
  const queryResult = useGetReservationByExternalId(apaleoReservationId, timeZone);

  const onLogout = useCallback(async () => {
    /**
     * Use local log out not to depend on adding allowed logout urls from Auth0
     * Combined with always reauthenticate prompt 'login' - together will
     * allow seamless witch of user.
     */
    logout({ localOnly: true });
  }, []);

  useEffect(() => {
    const property = queryResult.data?.property;
    if (!selectedProperty && property) {
      onSelectProperties([property]);
    }
  }, [selectedProperty, queryResult, onSelectProperties]);

  const hasNoPermission = !!apaleoReservationId && !!queryResult?.error;
  if (hasNoPermission) {
    return (
      <div className={'p-4 flex flex-1 flex-col justify-center items-center'}>
        <div>{t('noPermission')}</div>
        <Button onClick={onLogout} type={ButtonType.OUTLINED}>
          {t('logOut')}
        </Button>
      </div>
    );
  }

  if (queryResult?.isLoading || !selectedProperty) {
    return (
      <div className={'flex flex-1 justify-center'}>
        <LoadingMessage content={t('pleaseWaitAFewSeconds')} />
      </div>
    );
  }

  const reservation = queryResult?.data;
  if (!reservation) {
    return (
      <div className={'p-4 flex flex-1 flex-col justify-center items-center'}>
        <div>{t('reservationMissing')}</div>
      </div>
    );
  }
  const { id: propertyId } = selectedProperty;

  const { currentUnit } = reservation;

  const checkIn = reservation.checkIn;
  const checkOut = reservation.approvedCheckOut;
  const requestedCheckOut = reservation.requestedCheckOut;

  const midnightToday = startOfDay(dateUtils.nowInProperty(timeZone));
  const minDate = isAfter(checkIn, midnightToday) ? checkIn : midnightToday;
  return (
    <div className={'flex flex-row flex-1 p-12 min-h-0 w-full space-x-5 overflow-y-auto'}>
      <div className={'flex flex-col flex-1 space-y-5'}>
        <GxTasks
          selectedUnit={currentUnit}
          propertyId={propertyId}
          apaleoReservationId={apaleoReservationId}
          minDate={minDate}
        />
        <GxCheckoutSection
          propertyId={apaleoPropertyId}
          reservationExternalId={reservation.externalId}
          requestedCheckout={requestedCheckOut}
          approvedCheckout={checkOut}
        />
        <GxExtraServicesSection
          checkIn={checkIn}
          reservationExternalId={reservation.externalId}
          isVip={reservation.isVip}
          commentForHousekeeping={reservation.additionalInfo}
        />
      </div>
      <div className={'flex flex-col flex-1 space-y-5'}>
        <GxMidstayCleaning reservation={reservation} minDate={checkIn} maxDate={checkOut} />
        <GxDamageReports
          selectedUnit={currentUnit}
          propertyId={propertyId}
          apaleoReservationId={apaleoReservationId}
          minDate={minDate}
          reservationCheckOutDate={checkOut}
        />
      </div>
    </div>
  );
}

export default HousekeepingTab;
