import React, { useCallback, useState } from 'react';
import ImagePreview from '@atoms/ImagePreview';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import useSwipe from '@utils/hooks/useSwipe';

interface Props {
  urls: string[];
  className?: string;
  onClick?: () => void;
}

export default function Carousel({ urls, onClick, className = 'w-24 h-24' }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onClickLeft = useCallback(() => {
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : urls.length - 1);
  }, [currentIndex]);

  const onClickRight = useCallback(() => {
    setCurrentIndex(currentIndex < urls.length - 1 ? currentIndex + 1 : 0);
  }, [currentIndex]);

  const { onTouchStart, onTouchEnd, onTouchMove } = useSwipe({
    onSwipedLeft: onClickRight,
    onSwipedRight: onClickLeft,
  });

  const hasMoreUrls = urls.length > 1;

  return (
    <div className={`flex flex-1 flex-row items-center`}>
      {hasMoreUrls && (
        <ChevronLeftIcon
          onClick={onClickLeft}
          className={'w-8 h-8 p-1 mx-2 shadow-md cursor-pointer text-th-secondary bg-white rounded-full'}
        />
      )}
      <div
        className="flex flex-col flex-1 items-center justify-start"
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
      >
        <div className={cn(className)} onClick={onClick}>
          <ImagePreview url={urls[currentIndex]} />
        </div>
        <div className="flex w-full justify-center py-1 text-sm text-gray-500">
          {hasMoreUrls && `${currentIndex + 1} / ${urls.length}`}
        </div>
      </div>
      {hasMoreUrls && (
        <ChevronRightIcon
          onClick={onClickRight}
          className={'w-8 h-8 p-1 mx-2 shadow-md cursor-pointer text-th-secondary bg-white rounded-full'}
        />
      )}
    </div>
  );
}
