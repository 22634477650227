import Transition from '@atoms/Transition';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import UnitInfo from '@molecules/UnitInfo';
import { PropertyType, SVGIconType, UnitInfoType } from '@typings/types';

interface Props {
  sectionKey: string;
  Icon: SVGIconType;
  name: string;
  units: UnitInfoType[];
  onUnitClick: (unit: UnitInfoType) => void;
  defaultOpen?: boolean;
  property: PropertyType;
}

export default function DailyOverviewSection({
  sectionKey,
  name,
  Icon,
  units,
  onUnitClick,
  defaultOpen,
  property,
}: Props) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className={'flex flex-col bg-white rounded-lg p-4 shadow-lg flex-shrink-0'}>
          <Disclosure.Button className={'flex flex-row flex-1 items-center justify-between'}>
            <div className={'flex flex-row items-center space-x-3'}>
              <Icon className={'h-5 w-5 stroke-current text-th-brown'} aria-hidden="true" />
              <span className={'text-th-brown'}>{name}</span>
            </div>
            <div className={'flex flex-row items-center space-x-3'}>
              <span>{units.length}</span>
              <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} h-6 w-6`} />
            </div>
          </Disclosure.Button>
          <Transition>
            <Disclosure.Panel>
              <div className={'flex flex-row flex-wrap py-4'}>
                {units.map((u) => (
                  <UnitInfo
                    key={u.id}
                    data={u}
                    property={property}
                    dailyViewSectionKey={sectionKey}
                    onClick={() => onUnitClick(u)}
                  />
                ))}
              </div>
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
}
