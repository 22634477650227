import React, { MouseEventHandler, useCallback } from 'react';
import { addDays, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns';
import Header from '@organisms/Header';
import SelectProperty from '@organisms/SelectProperty';
import ListDatePicker from '@molecules/ListDatePicker';
import { useHistory } from 'react-router-dom';
import routes from '@constants/routes';
import { ChevronDownIcon } from '@heroicons/react/outline';
import usePropertiesState from '@context/propertiesContext';
import dateUtils from '@utils/dateUtils';
import SelectProperties from '@organisms/SelectProperties';

export interface Props {
  taskViewDate: Date;
  setTaskViewDate: (value: Date) => void;
  weeklyViewDate: Date;
  setWeeklyViewDate: (value: Date) => void;
}

export default function Navbar({ taskViewDate, setTaskViewDate, weeklyViewDate, setWeeklyViewDate }: Props) {
  const { selectedProperties, onSelectProperties } = usePropertiesState();

  const history = useHistory();
  const { location } = history;

  // todo move this into hook
  const isTasksView = location.pathname === routes.TASKS;
  const isWeeklyView = location.pathname === routes.WEEKLY;
  const isNotificationsPage = location.pathname === routes.NOTIFICATIONS;

  const [from, to] = getDateRange({
    isTasksView,
    isWeeklyView,
  });

  const date = isWeeklyView ? weeklyViewDate : taskViewDate;
  const setDate = isWeeklyView ? setWeeklyViewDate : setTaskViewDate;

  const renderActionButton = useCallback(
    (onClick: MouseEventHandler<HTMLElement>) => {
      const propertyNames = (!isWeeklyView ? selectedProperties.slice(0, 1) : selectedProperties)
        .map((p) => p?.name)
        .join(', ');
      return (
        <div
          className={
            'flex flex-row items-center cursor-pointer overflow-hidden max-w-[calc(100vw-240px)] md:max-w-[420px]'
          }
          onClick={onClick}
        >
          <span className={'text-xl text-th-brown font-serif font-bold truncate'}>{propertyNames}</span>
          <ChevronDownIcon className="h-5 w-5 text-th-brown flex-shrink-0" />
        </div>
      );
    },
    [isWeeklyView, selectedProperties],
  );

  /* render empty header on notifications page */
  if (isNotificationsPage) {
    return (
      <div className={'mb-5'}>
        <Header />
      </div>
    );
  }

  return (
    <div className={'mb-5'}>
      <Header
        right={
          isWeeklyView ? (
            <SelectProperties
              value={selectedProperties}
              onSelect={onSelectProperties}
              actionButton={renderActionButton}
            />
          ) : (
            <SelectProperty
              value={selectedProperties[0]}
              onSelect={(property) => onSelectProperties([property])}
              actionButton={renderActionButton}
            />
          )
        }
        left={
          <div>
            {isWeeklyView && <ListDatePicker date={date} from={from} to={to} onChange={setDate} />}
            {/* <SearchIcon className="h-6 w-6 ml-2 cursor-pointer" /> */}
          </div>
        }
      />
    </div>
  );
}
const today = startOfDay(dateUtils.now());

function getDateRange({ isTasksView, isWeeklyView }: { isTasksView: boolean; isWeeklyView: boolean }) {
  if (isTasksView) {
    return [subDays(today, 31), addDays(today, 7)];
  }
  if (isWeeklyView) {
    return [startOfMonth(subMonths(today, 1)), addDays(today, 14)];
  }
  // not used - placeholder
  return [today, today];
}
