import { Popover as HeadlessPopover, Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { HeadlessPopoverPanelProps } from './types';

export const HeadlessPopoverPanel = ({
  close,
  content,
  contentFn,
  desktopCustomPosition,
  onOpen,
  open,
  side,
}: HeadlessPopoverPanelProps): JSX.Element => {
  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <HeadlessPopover.Panel
        unmount
        className={'absolute z-10 mt-3'}
        style={desktopCustomPosition || (side === 'left' ? { right: 0 } : { left: 0 })}
      >
        <div className="inline-block relative overflow-hidden rounded-lg shadow-lg bg-white">
          {content || contentFn?.(close)}
        </div>
      </HeadlessPopover.Panel>
    </Transition>
  );
};
