import { useState } from 'react';
import AdditionalTaskBar from '@molecules/AdditionalTaskBar';
import SearchBar from '@molecules/SearchBar';
import { TaskType } from '@typings/enums';
import useDeleteTask from '@utils/hooks/useDeleteTask';
import LostAndFoundItemDetails from '@organisms/LostAndFoundItemDetails';
import { TaskInfoType } from '@typings/types';
import LoadingMessage from '@atoms/LoadingMessage';
import { useTranslation } from 'react-i18next';
import DamageReportDetails from '@organisms/DamageReportDetails';
import DamageReportProgressBar from '@organisms/DamageReportProgressBar';
import useGetTaskData from '@utils/hooks/useGetTaskData';
import dateUtils, { isTodayInProperty } from '@utils/dateUtils';
import { TaskSection } from '@organisms/TaskSection';
import TaskInfo from '@organisms/TaskInfo';
import FloatingAddButton from '@organisms/FloatingAddButton';
import usePropertiesState from '@context/propertiesContext';
import { GROUP_TASKS_KEY_OVERDUE } from '@utils/taskUtils';
import { startOfDay } from 'date-fns';

type Props = { type: TaskType; date: Date };

export default function AdditionalTasks({ type }: Props) {
  const { t } = useTranslation();

  const [activeFilter, setActiveFilter] = useState<'CURRENT' | 'ARCHIVE'>('CURRENT');
  const [editTask, setEditTask] = useState<TaskInfoType>();
  const [openedItem, setOpenedItem] = useState<TaskInfoType>();

  const { selectedProperty } = usePropertiesState();
  const { id: propertyId, timeZone } = selectedProperty;

  const midnightTodayUtc = dateUtils.formatISO(startOfDay(dateUtils.now()));

  const { isFetching, error, tasks, archivedTasks, overdueTasks, search, setSearch } = useGetTaskData({
    propertyId,
    timeZone,
    type,
  });

  const { setTaskForDeletion, renderDeleteConfirmationDialog } = useDeleteTask({});

  if (error) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  if (!tasks) {
    return <LoadingMessage />;
  }

  const isCurrentActive = activeFilter === 'CURRENT';
  const tasksToDisplay = isCurrentActive ? tasks : archivedTasks;

  const tasksDueToday = tasks.filter((task) => isTodayInProperty(task.dueAt!, timeZone));

  const renderTask = (task: TaskInfoType) => {
    return (
      <TaskInfo
        key={task.id}
        task={task}
        onClickEdit={setEditTask}
        onClickDelete={setTaskForDeletion}
        onClick={setOpenedItem}
      />
    );
  };

  const TaskDetailsComponent =
    openedItem?.type === TaskType.LOST_AND_FOUND ? LostAndFoundItemDetails : DamageReportDetails;

  return (
    <>
      <AdditionalTaskBar
        active={activeFilter}
        setActive={setActiveFilter}
        countCurrent={tasks.length + (overdueTasks?.length ?? 0)}
        countArchive={archivedTasks?.length ?? 0}
      />
      {type === TaskType.DAMAGE && <DamageReportProgressBar tasks={tasksDueToday} />}
      <SearchBar onChange={(v) => setSearch(v?.toLowerCase())} />
      <div className={'flex h-full flex-col space-y-4 overflow-y-auto p-3'}>
        {!!overdueTasks?.length && isCurrentActive && (
          <TaskSection
            utcDateString={GROUP_TASKS_KEY_OVERDUE}
            search={search}
            isFetching={isFetching}
            type={type}
            tasks={overdueTasks}
            renderTask={renderTask}
          />
        )}
        <TaskSection
          // placeholder value for today
          utcDateString={midnightTodayUtc}
          isFetching={isFetching}
          search={search}
          type={type}
          tasks={tasksToDisplay ?? []}
          renderTask={renderTask}
        />
      </div>
      {renderDeleteConfirmationDialog()}
      {openedItem && (
        <TaskDetailsComponent
          task={openedItem}
          closeDialog={() => setOpenedItem(undefined)}
          onClickEdit={(item) => {
            setEditTask(item);
            // must close the current one to prevent <FocusTrap /> error
            setOpenedItem(undefined);
          }}
          onClickDelete={(item) => {
            setTaskForDeletion(item);
            setOpenedItem(undefined);
          }}
        />
      )}
      <FloatingAddButton type={type} editTask={editTask} onClose={() => setEditTask(undefined)} />
    </>
  );
}
