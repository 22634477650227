import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Auth0Provider } from '@auth0/auth0-react';
import env from '@constants/env';
import { NotificationProvider } from '@context/NotificationContext';
import { AppProvider } from '@context/appContext';
import AppRouter from './AppRouter';
import NotificationsRegion from './NotificationsRegion';
import cn from 'classnames';

// import i18n (needs to be bundled)
import './i18n/i18n';
// setup sentry
import './constants/sentry';
import { ChatProvider } from '@context/chatContext';
import { IS_PWA } from '@utils/deviceUtils';
import { PropertiesProvider } from '@context/propertiesContext';
import ProtectedRoutes from '@organisms/ProtectedRoutes';
import { TaskActionsProvider } from '@context/taskActionsContext';
import { FiltersTasksProvider } from '@context/filtersTasksContext';
import { DelayRetry, NUMBER_RETRY } from '@utils/queryUtils';

const isApaleoTab = window.location.pathname.startsWith('/apaleo');

export const isSameAsCurrentUrl = (url: string) => {
  return new URL(url).pathname === window.location.pathname;
};

const queryClient = new QueryClient({
  // NOTE: uncomment in development
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !isApaleoTab,
      retry: NUMBER_RETRY,
      retryDelay: DelayRetry,
    },
  },
});

function App() {
  return (
    <Auth0Provider
      domain={env.auth0.domain}
      clientId={env.auth0.clientId}
      cacheLocation="localstorage"
      useRefreshTokens
      redirectUri={window.location.origin}
      audience={env.auth0.audience}
      onRedirectCallback={(appState) => {
        if (appState?.returnTo && !isSameAsCurrentUrl(appState.returnTo)) {
          window.location.replace(appState.returnTo!);
        }
      }}
    >
      <QueryClientProvider client={queryClient}>
        <div className={cn('flex mobile-browser-height md:h-screen', { 'pb-4': IS_PWA })}>
          <Router>
            <AppProvider>
              {/* ProtectedRoutes uses AppProvider, keep it above */}
              <ProtectedRoutes>
                <PropertiesProvider>
                  <TaskActionsProvider>
                    <NotificationProvider>
                      <ChatProvider>
                        <FiltersTasksProvider>
                          <AppRouter />
                        </FiltersTasksProvider>
                      </ChatProvider>
                    </NotificationProvider>
                  </TaskActionsProvider>
                </PropertiesProvider>
                <NotificationsRegion />
              </ProtectedRoutes>
            </AppProvider>
          </Router>
        </div>
      </QueryClientProvider>
    </Auth0Provider>
  );
}

export default App;
