import React from 'react';
import { ReactComponent as SvgSpinner } from '@assets/images/spinner.svg';

interface Props {
  className?: string;
}

function Spinner({ className = 'text-white' }: Props) {
  return <SvgSpinner className={`animate-spin mr-2 fill-current ${className}`} />;
}

export default Spinner;
