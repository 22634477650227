import { useCallback } from 'react';

export default function useCreateFileFromUrl() {
  const createFileFromUrl = useCallback(async (url) => {
    // https://stackoverflow.com/questions/55158189/cross-origin-requests-ajax-requests-to-aws-s3-sometimes-result-in-cors-error
    const response = await fetch(url, {
      cache: 'no-cache',
    });
    const data = await response.blob();
    const metadata = { type: 'image/jpeg' };
    const parts = url.split('/');
    const name = parts.length ? parts[parts.length - 1] : url;
    return new File([data], name, metadata);
  }, []);

  return { createFileFromUrl };
}
