import useApiCall from '@utils/hooks/useApiCall';
import unitsApi from '@api/unitsApi';
import { useQuery } from 'react-query';
import { ServerStateKey } from '@typings/enums';
import { useTranslation } from 'react-i18next';
import dateUtils, { formatDate } from '@utils/dateUtils';
import React from 'react';
import { DailyCleaningReportType } from '@typings/types';
import { capitalize } from '@utils/textUtils';
import LoadingMessage from '@atoms/LoadingMessage';
import usePropertiesState from '@context/propertiesContext';

interface Props {
  propertyId: string;
  onDataLoaded: () => void;
}

export default function DailyCleaningReport({ propertyId, onDataLoaded }: Props) {
  const {
    selectedProperty: { timeZone },
  } = usePropertiesState();
  const date = formatDate(dateUtils.nowInProperty(timeZone));

  const { t } = useTranslation();
  const apiCall = useApiCall<DailyCleaningReportType>(unitsApi.dailyCleaningReport);
  const { data, error, isIdle } = useQuery(
    [ServerStateKey.DAILY_CLEANING_REPORT, propertyId],
    () => apiCall({ params: { propertyId } }),
    {
      onSuccess: () => {
        onDataLoaded();
      },
    },
  );

  if (isIdle || !data) {
    return (
      <div className={'p-4 w-48'}>
        <LoadingMessage />
      </div>
    );
  }

  if (error) {
    return (
      <div className={'p-4 w-48'}>
        <div>{t('anErrorHasOccurred')}</div>
      </div>
    );
  }

  return (
    <div className={'p-4 flex flex-col w-48'}>
      <div className={'font-bold mb-3'}>
        {t('dailyCleaningReport')} - {date}
      </div>
      <div className={'flex flex-col mb-2'}>
        <div className={'font-bold mb-1'}>{capitalize('departure')}:</div>
        {data.departure.map((u) => (
          <div key={u.number}>{u.number}</div>
        ))}
      </div>
      <div className={'flex flex-col'}>
        <div className={'font-bold mb-1'}>{t('stayover')}:</div>
        {data.stayover.map((u) => (
          <div key={u.number}>{u.number}</div>
        ))}
      </div>
    </div>
  );
}
