import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as UploadIcon } from '@assets/images/upload.svg';
import ImagePreview from '@atoms/ImagePreview';
import { TrashIcon } from '@heroicons/react/outline';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import Dialog from '@molecules/Dialog';
import { ReactComponent as EditImage } from '@assets/images/edit-image.svg';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType } from '@atoms/Button';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  initialImages: { [key: string]: File };
}

function UploadMultiple({ form, initialImages }: Props) {
  const ref = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();

  const [images, setImages] = useState<{ [key: string]: File }>({});

  const [pickerOpen, setPickerOpen] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'images',
  });

  useEffect(() => {
    if (Object.keys(initialImages).length > 0) {
      setImages({ ...initialImages });
      Object.keys(initialImages).forEach((url) => {
        const initialImage = initialImages[url];
        append({ image: initialImage });
      });
    }
  }, [initialImages]);

  const onUpload = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    ref.current?.click();
  };

  const onRemoveFile = useCallback(
    (url) => {
      const newImages = { ...images };
      const fileName = newImages[url].name;
      delete newImages[url];
      setImages(newImages);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const index = fields.findIndex((f) => f.image.name === fileName);
      remove(index);
    },
    [images, fields],
  );

  const onChangeFile = useCallback(
    (e) => {
      const { files } = e.target;
      if (!files?.length) return;
      const filesArray = [...files];
      append(filesArray.map((f) => ({ image: f })));
      setImages({
        ...images,
        ...filesArray.reduce((acc, curr) => {
          acc[URL.createObjectURL(curr)] = curr;
          return acc;
        }, {}),
      });
    },
    [images],
  );

  const renderPickerDialog = useCallback(() => {
    return (
      <div className={'flex flex-row flex-wrap space-x-3 md:w-96 py-3 px-3'}>
        {Object.keys(images).map((url) => (
          <div key={url} className={'relative mb-3'}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <div className={'w-24 h-24 border border-gray-100'}>
              <ImagePreview url={url} />
            </div>
            <TrashIcon
              className={
                'absolute -top-3 -right-3 p-1 w-6 h-6 shadow text-th-accent rounded-full bg-white cursor-pointer'
              }
              onClick={() => onRemoveFile(url)}
            />
          </div>
        ))}
        <div
          className={
            'flex items-center justify-center w-24 h-24 rounded-lg border border-gray-100 bg-white cursor-pointer mb-3'
          }
          onClick={onUpload}
        >
          <UploadIcon width={32} height={32} />
          <input
            ref={ref}
            accept="image/*"
            id="file-upload"
            name="file-upload"
            type="file"
            hidden
            multiple
            onChange={onChangeFile}
          />
        </div>
      </div>
    );
  }, [images, onRemoveFile, onChangeFile]);

  const imageUrls = Object.keys(images);

  return (
    <>
      {fields.map((f, i) => (
        <div key={f.id}>
          <Controller
            control={form.control}
            name={`images.${i}.image`}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            render={({}) => null}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            defaultValue={f.image}
          />
        </div>
      ))}
      {imageUrls.length ? (
        <div className={'relative cursor-pointer'} onClick={() => setPickerOpen(true)}>
          <div className={'w-24 h-24 border border-gray-100'}>
            <ImagePreview url={imageUrls[0]} />
          </div>
          <EditImage className={'absolute -top-3 -right-3'} />
        </div>
      ) : (
        <div
          className={
            'flex items-center justify-center w-24 h-24 rounded-lg border border-gray-100 bg-white cursor-pointer'
          }
          onClick={(e) => {
            setPickerOpen(true);
            setTimeout(() => onUpload(e), 1);
          }}
        >
          <UploadIcon width={32} height={32} />
        </div>
      )}
      <Dialog
        isOpen={pickerOpen}
        onClose={() => setPickerOpen(false)}
        title={t('editImages')}
        isMobileSheet
        actionButton={
          <Button type={ButtonType.UNSTYLED} onClick={() => setPickerOpen(false)}>
            {t('select')}
          </Button>
        }
      >
        {renderPickerDialog()}
      </Dialog>
    </>
  );
}

export default UploadMultiple;
