import { useTranslation } from 'react-i18next';

export const ConfirmButton = ({ isDisabled, onConfirm }: { isDisabled: boolean; onConfirm: () => void }) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onConfirm}
      disabled={isDisabled}
      className={`w-full py-4 text-white text-base font-normal font-sans rounded-md ${
        isDisabled ? 'bg-th-gray-300' : 'bg-th-secondary'
      }`}
    >
      {t('confirm')}
    </button>
  );
};
