import SelectDate from '@organisms/SelectDate';
import React, { MouseEventHandler } from 'react';
import { DateValue } from '@organisms/SelectDate/types';
import useApiCall from '@utils/hooks/useApiCall';
import unitsApi from '@api/unitsApi';
import { useQuery } from 'react-query';
import { ServerStateKey } from '@typings/enums';

interface Props {
  value?: { date: Date };
  onChange?: (date: DateValue) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
  selectedUnit?: {
    id: string;
    propertyId: string;
  };
  submitLabelKey?: string;
  minDate?: Date;
  checkOutDate?: Date;
}

export default function SelectDateWithHighlightedCheckout({
  value,
  onChange,
  actionButton,
  selectedUnit,
  submitLabelKey,
  minDate,
  checkOutDate,
}: Props) {
  const apiCall = useApiCall<{ date: Date | null }>(unitsApi.getNextCheckoutDate);
  const { data: nextCheckoutDateResponse } = useQuery(
    [ServerStateKey.UNIT_NEXT_CHECKOUT_DATE, selectedUnit?.id],
    () => selectedUnit && apiCall({ params: { propertyId: selectedUnit?.propertyId, id: selectedUnit?.id } }),
    { enabled: !!selectedUnit && !checkOutDate },
  );

  const { date: nextCheckoutDate } = nextCheckoutDateResponse || {};

  const highlightedDate = checkOutDate ?? nextCheckoutDate;

  return (
    <SelectDate
      value={value}
      onChange={onChange}
      hideRepetition
      highlightDates={highlightedDate ? [new Date(highlightedDate)] : []}
      actionButton={actionButton}
      submitLabelKey={submitLabelKey}
      minDate={minDate}
    />
  );
}
