import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import unitStatusesInfo from '@constants/unitStatusesInfo';
import DailyOverviewSection from '@organisms/DailyOverviewSection';
import SearchBar from '@molecules/SearchBar';
import unitsApi from '@api/unitsApi';
import { UnitInfoType } from '@typings/types';
import DailyOverviewSectionPlaceholder from '@molecules/DailyOverviewSectionPlaceholder';
import UnitActions from '@organisms/UnitActions';
import {
  collapsedDailyViewUnitStatuses,
  dailyViewUnitStatusesWithRequiredRoles,
  expandedDailyViewUnitStatuses,
  maintainerDailyViewUnitStatuses,
  OPEN_TASKS_SECTION_KEY,
} from '@utils/unitUtils';
import { ServerStateKey, UnitStatus } from '@typings/enums';
import useApiCall from '@utils/hooks/useApiCall';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import { useTranslation } from 'react-i18next';
import { REFETCH_INTERVAL } from '@utils/queryUtils';
import CleaningProgressBar from '@organisms/CleaningProgressBar';
import usePropertiesState from '@context/propertiesContext';
import { useGetDailyViewUnits } from '@api/api';

export default function DailyOverview() {
  const [search, setSearch] = useState<string>();

  const { selectedProperty } = usePropertiesState();

  const [openedUnit, setOpenedUnit] = useState<UnitInfoType>();

  const { t } = useTranslation();

  const cleanedUnitsApiCall = useApiCall<{ count: number }>(unitsApi.countUnitsCleanedToday);
  const { data: unitsCleanedTodayCount } = useQuery(
    [ServerStateKey.UNITS_CLEANED_TODAY_COUNT, selectedProperty.id],
    () => cleanedUnitsApiCall({ params: { propertyId: selectedProperty.id } }),
    {
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
    },
  );

  useEffect(() => {
    setSearch('');
  }, [selectedProperty]);

  const { id: selectedPropertyId, timeZone, externalId } = selectedProperty;
  const {
    isIdle,
    error,
    data: units,
  } = useGetDailyViewUnits({
    propertyId: selectedPropertyId,
    timeZone,
  });
  const { getPropertyRoles, isOperationsRole, isMaintainerOnlyRole, isCleanerRole } = useRoleBasedUI();

  const roles = getPropertyRoles(selectedPropertyId);
  const isOperations = isOperationsRole(selectedPropertyId);
  const isCleaner = isCleanerRole(selectedPropertyId);
  const isMaintainerOnly = isMaintainerOnlyRole(selectedPropertyId);
  const isAllowedOccupiedCleaningTodayRole = isOperations || isCleaner || isMaintainerOnly;
  const renderSection = useCallback(
    (unitsByStatus, status: UnitStatus, defaultOpen: boolean) => {
      const { Icon } = unitStatusesInfo[status];
      if (status === UnitStatus.OCCUPIED_CLEANING_TODAY && !isAllowedOccupiedCleaningTodayRole) {
        return (
          <div className={'flex flex-col bg-white rounded-lg p-4 shadow-lg flex-shrink-0'}>
            <div className={'flex flex-row flex-1 items-center justify-between'}>
              <div className={'flex flex-row items-center space-x-3'}>
                <Icon className={'h-5 w-5 stroke-current text-th-brown'} aria-hidden="true" />
                <span className={'text-th-brown'}>{t(unitStatusesInfo[status].label)}</span>
              </div>
              <div className={'flex flex-row items-center space-x-3'}>
                <span>{unitsByStatus[status].length}</span>
              </div>
            </div>
          </div>
        );
      }
      return (
        <DailyOverviewSection
          key={status}
          sectionKey={status}
          name={t(unitStatusesInfo[status].label)}
          Icon={Icon}
          units={unitsByStatus[status]}
          property={selectedProperty}
          onUnitClick={setOpenedUnit}
          defaultOpen={defaultOpen}
        />
      );
    },
    [t, isOperations, isCleaner, isMaintainerOnly, selectedProperty],
  );

  if (isIdle || !units)
    return (
      <>
        {[1, 2, 3].map((i) => (
          <DailyOverviewSectionPlaceholder key={i} />
        ))}
      </>
    );

  if (error) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  const searchedUnits = search ? units.filter((r) => r.number.includes(search)) : units;

  const unitsByStatus = searchedUnits.reduce((acc: { [key: string]: UnitInfoType[] }, curr) => {
    if (!acc[curr.status]) {
      acc[curr.status] = [];
    }
    acc[curr.status].push(curr);
    return acc;
  }, {});

  if (isMaintainerOnly) {
    return (
      <>
        <SearchBar value={search} onChange={(v) => setSearch(v?.toLowerCase())} />
        {maintainerDailyViewUnitStatuses
          .filter((status) => !!unitsByStatus[status])
          .map((status) => renderSection(unitsByStatus, status, true))}
        {openedUnit && <UnitActions propertyExternalId={externalId} unit={openedUnit} setUnit={setOpenedUnit} />}
      </>
    );
  }

  const openTasksUnits = searchedUnits.filter(
    (u) => u.tasksCount > 0 && !dailyViewUnitStatusesWithRequiredRoles[u.status].some((r) => roles.includes(r)),
  );

  const unitsToCleanCount = units.filter((u) =>
    [UnitStatus.READY_TO_CLEAN, UnitStatus.STAYOVER_READY_TO_CLEAN, UnitStatus.OCCUPIED_CLEANING_TODAY].includes(
      u.status,
    ),
  ).length;
  const unitsToInspectCount = units.filter((u) =>
    [UnitStatus.READY_TO_INSPECT, UnitStatus.STAYOVER_READY_TO_INSPECT].includes(u.status),
  ).length;

  return (
    <>
      <CleaningProgressBar
        propertyId={selectedPropertyId}
        cleaned={unitsCleanedTodayCount?.count || 0}
        toInspect={unitsToInspectCount}
        toClean={unitsToCleanCount}
      />
      <SearchBar value={search} onChange={(v) => setSearch(v?.toLowerCase())} />
      {expandedDailyViewUnitStatuses
        .filter((status) => roles.some((r) => dailyViewUnitStatusesWithRequiredRoles[status].includes(r)))
        .filter((status) => !!unitsByStatus[status])
        .map((status) => renderSection(unitsByStatus, status, true))}
      {openTasksUnits.length > 0 && (
        <DailyOverviewSection
          key={OPEN_TASKS_SECTION_KEY}
          sectionKey={OPEN_TASKS_SECTION_KEY}
          name={t(unitStatusesInfo.OPEN_TASKS.label)}
          Icon={unitStatusesInfo.OPEN_TASKS.Icon}
          property={selectedProperty}
          units={openTasksUnits}
          onUnitClick={(unit: UnitInfoType) => setOpenedUnit(unit)}
          defaultOpen
        />
      )}
      {collapsedDailyViewUnitStatuses
        .filter((status) => roles.some((r) => dailyViewUnitStatusesWithRequiredRoles[status].includes(r)))
        .filter((status) => !!unitsByStatus[status])
        .map((status) => renderSection(unitsByStatus, status, false))}
      {openedUnit && <UnitActions propertyExternalId={externalId} unit={openedUnit} setUnit={setOpenedUnit} />}
    </>
  );
}
