import React, { MouseEventHandler, useState } from 'react';
import { useToggle } from '@utils/hooks/useToggle';
import Dialog from '@molecules/Dialog';
import TextInput from '@molecules/TextInput';
import Button, { ButtonType } from '@atoms/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
  onConfirm: (value: string) => void;
}

function AddPropertyArea({ actionButton, onConfirm }: Props) {
  const [value, setValue] = useState<string>();
  const [dialogOpen, toggleDialogOpen] = useToggle(false);
  const { t } = useTranslation();
  return (
    <>
      {actionButton(toggleDialogOpen)}
      <Dialog
        isOpen={dialogOpen}
        onClose={toggleDialogOpen}
        title={t('addArea')}
        isMobileSheet
        actionButton={
          <Button
            disabled={!value}
            type={ButtonType.UNSTYLED}
            onClick={() => {
              onConfirm(value!);
              toggleDialogOpen();
            }}
          >
            {t('confirm')}
          </Button>
        }
      >
        <div className={'px-3'}>
          <div className={'my-4 text-md text-gray-400'}>{t('addAreaDescription')}</div>
          <TextInput autoFocus value={value} placeholder={t('area')} onChange={(e) => setValue(e.target.value)} />
        </div>
      </Dialog>
    </>
  );
}

export default AddPropertyArea;
