import { PlusCircleIcon } from '@heroicons/react/solid';

import React from 'react';

interface AddSlotProps {
  handleNewSlot: () => void;
}

export const AddSlotButton = ({ handleNewSlot }: AddSlotProps) => {
  return (
    <button
      onClick={handleNewSlot}
      className="border bg-th-secondary rounded-md py-[6px] px-4 text-white font-normal text-sm leading-[18px] flex justify-center items-center gap-x-2"
    >
      <PlusCircleIcon width={18} height={18} />
      <span>New slot</span>
    </button>
  );
};
