import SelectProperties from '@organisms/SelectProperties';
import React, { useCallback, useMemo } from 'react';
import ActionItem from '@molecules/ActionItem';
import { useTranslation } from 'react-i18next';
import { BellIcon } from '@heroicons/react/solid';
import { PropertyType } from '@typings/types';
import { useGetNotificationSettings, useUpdateNotificationsMutation } from '@api/api';
import isEqual from 'lodash.isequal';
import { useQueryClient } from 'react-query';
import { ServerStateKey } from '@typings/enums';
import usePropertiesState from '@context/propertiesContext';

export default function NotificationSettings() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { isLoading: isLoadingProperties, getPropertiesById } = usePropertiesState();

  const { isLoading, error, data } = useGetNotificationSettings();

  const updateNotificationsMutation = useUpdateNotificationsMutation();

  const disabledProperties = data?.notificationSettings?.disabledProperties;

  const selectedProperties = useMemo(
    () => (disabledProperties ? getPropertiesById(disabledProperties) : []),
    [disabledProperties, getPropertiesById],
  );

  const onSelect = useCallback(
    (properties: PropertyType[]) => {
      const disabledProperties = properties.map((p) => p.id);
      if (!isEqual(disabledProperties, data?.notificationSettings?.disabledProperties)) {
        updateNotificationsMutation.mutate({ data: { disabledProperties } });
        queryClient.setQueryData(ServerStateKey.NOTIFICATION_SETTINGS, (oldData) => ({
          ...(oldData || {}),
          notificationSettings: { disabledProperties },
        }));
      }
    },
    [data],
  );

  if (isLoading || isLoadingProperties) {
    return null;
  }

  if (error) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  return (
    <SelectProperties
      value={selectedProperties}
      onSelect={onSelect}
      isToggle
      inverse
      actionButton={(onClick) => (
        <ActionItem name={t('notifications')} Icon={BellIcon} action={onClick} withRightArrow />
      )}
    />
  );
}
