import { UnitInfoType, UnitInfoTypeDto } from '@typings/types';
import dateUtils from '@utils/dateUtils';

export class UnitMapper {
  static toDailyUnit(model: UnitInfoTypeDto, timeZone: string): UnitInfoType {
    const { arrival: utcArrival, departure: utcDeparture, hskDelayUntil: utcHskDelayUntil, ...rest } = model;

    const arrival = utcArrival ? dateUtils.utcToZonedTime(utcArrival, timeZone) : null;
    const departure = utcDeparture ? dateUtils.utcToZonedTime(utcDeparture, timeZone) : null;
    const hskDelayUntil = utcHskDelayUntil ? dateUtils.utcToZonedTime(utcHskDelayUntil, timeZone) : null;

    const utcTime = {
      timeZone,
      arrival: utcArrival,
      departure: utcDeparture,
    };
    return {
      ...rest,
      arrival,
      departure,
      hskDelayUntil,
      utcTime,
    };
  }
}
