import Button from '@atoms/Button';
import Dialog from '@molecules/Dialog';

interface Props {
  className?: string;
  isOpen?: boolean;
  title: string;
  message: string;
  icon?: JSX.Element;
  onClose: () => void;
  primaryLabel: string;
  onClickPrimaryAction: () => void;
  secondaryLabel: string;
  onClickSecondaryAction: () => void;
}

export default function ConfirmationDialog(props: Props) {
  const {
    isOpen = false,
    title,
    icon,
    message,
    onClose,
    primaryLabel,
    onClickPrimaryAction,
    secondaryLabel,
    onClickSecondaryAction,
  } = props;

  const onClickAction = (fn: () => void) => {
    return () => {
      try {
        fn();
        onClose();
      } catch (e) {
        console.log(e);
      }
    };
  };
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col items-center px-3 w-72">
        <div className={'text-th-brown font-serif text-lg font-bold mb-2'}>{title}</div>
        {icon}
        <>
          <div className={'text-th-secondary text-center mb-6'}>{message}</div>
          <Button className={'w-full'} onClick={onClickAction(onClickPrimaryAction)}>
            {primaryLabel}
          </Button>
          <span
            className={'text-gray-400 font-serif my-1 py-2 w-full text-center cursor-pointer'}
            onClick={onClickAction(onClickSecondaryAction)}
          >
            {secondaryLabel}
          </span>
        </>
      </div>
    </Dialog>
  );
}
