import { RoomRackItemDto, RoomRackItem } from '@typings/types';
import { utcToZonedTime } from 'date-fns-tz';

export class RoomRackMapper {
  static toRoomRackType(roomRack: RoomRackItemDto, timeZone: string): RoomRackItem {
    const maintenances = roomRack.maintenances.map((mtn) => {
      const utcTime = {
        timeZone,
        from: new Date(mtn.from),
        to: new Date(mtn.to),
      };
      return {
        ...mtn,
        from: utcToZonedTime(mtn.from, timeZone),
        to: utcToZonedTime(mtn.to, timeZone),
        utcTime,
      };
    });
    const reservations = roomRack.reservations.map((reservation) => {
      const utcTime = {
        timeZone,
        checkIn: new Date(reservation.checkIn),
        checkOut: new Date(reservation.checkOut),
        checkedInAt: reservation.checkedInAt ? new Date(reservation.checkedInAt) : null,
        checkedOutAt: reservation.checkedOutAt ? new Date(reservation.checkedOutAt) : null,
      };
      return {
        ...reservation,
        checkIn: utcToZonedTime(reservation.checkIn, timeZone),
        checkOut: utcToZonedTime(reservation.checkOut, timeZone),
        checkedInAt: reservation.checkedInAt ? utcToZonedTime(reservation.checkedInAt, timeZone) : null,
        checkedOutAt: reservation.checkedOutAt ? utcToZonedTime(reservation.checkedOutAt, timeZone) : null,
        utcTime,
      };
    });

    return {
      ...roomRack,
      reservations: reservations,
      maintenances: maintenances,
    };
  }
}
