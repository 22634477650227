import TextInput from '@molecules/TextInput';
import Button from '@atoms/Button';
import { useTranslation } from 'react-i18next';

export default function LoginForm() {
  const { t } = useTranslation();

  return (
    <form className="max-w-md w-full mt-8 space-y-6 flex flex-col">
      <div className="rounded-md -space-y-px">
        <TextInput className={'mb-4'} name={'email'} type={'email'} label={'Email'} />
        <TextInput className={'mb-4'} name={'password'} type={'password'} label={'Password'} />
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">{/* forgot passoword */}</div>
      </div>

      <div>
        <Button className={'w-full'}>{t('login')}</Button>
      </div>
    </form>
  );
}
