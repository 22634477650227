import { RRule, rrulestr, Weekday } from 'rrule';
import { DateValue } from '@organisms/SelectDate/types';
import { ALL_WEEKDAYS, RepetitionOptions, TaskInfoType } from '@typings/types';
import { TaskType } from '@typings/enums';

export const toRRuleObject = (dateValue?: DateValue): RRule | null => {
  if (!dateValue?.recurrence) {
    return null;
  }
  const {
    date,
    recurrence: { frequency, interval, weekDays },
  } = dateValue;
  return new RRule({
    interval,
    dtstart: date,
    freq: frequency,
    byweekday: weekDays.length ? weekDays.map(Weekday.fromStr) : null,
  });
};

export const getFrequencyFromRRuleStr = (recurrence?: string | null): string | undefined => {
  if (!recurrence) {
    return '';
  }
  return recurrence
    .split(';')
    .find((v) => v.startsWith('FREQ'))
    ?.substring(5);
};

export const toRepeatOptions = (recurrence: string): RepetitionOptions => {
  const rrule = rrulestr(recurrence);
  const { freq, interval, byweekday } = rrule.options;
  return {
    frequency: freq,
    interval,
    weekDays: (byweekday || []).map((wn) => ALL_WEEKDAYS[wn]),
  };
};

export const getTranslationGroupForType = (info: TaskInfoType): string => {
  switch (info.type) {
    case TaskType.DAMAGE:
      return `standardizedDamageReports.${info.title}`;
    default:
      return info.title;
  }
};
