import { addDays } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import usePropertiesState from '@context/propertiesContext';

import { RoomRackTable } from './RoomRackTable';
import { RoomSlotSidebar } from './RoomSlotSidebar';
import { DateRangeNavigation, AddSlotButton } from './Header';

import { DateRangeType, SlotType } from './RoomRackTable/types';
import useGetRoomRackData from '@utils/hooks/useGetRoomRackData';
import { MaintenanceSlotType, RoomRackType } from '@typings/types';

const DEFAULT_DATE_RANGE_LENGTH = 14;

const ReservationDefaultData = {
  background: '#DDDDDD',
  border: '0px solid',
  color: 'black',
  fontFamily: 'Proxima-Nova, sans',
};

const MaintenanceDefaultData = {
  [MaintenanceSlotType.OUT_OF_SERVICE]: {
    background: '#6794F3',
    border: '0px solid',
    color: 'black',
    fontFamily: 'Proxima-Nova, sans',
  },
  [MaintenanceSlotType.OUT_OF_ORDER]: {
    background: '#EF8B69',
    border: '0px solid',
    color: 'black',
    fontFamily: 'Proxima-Nova, sans',
  },
  [MaintenanceSlotType.OUT_OF_INVENTORY]: {
    background: '#66AE7E',
    border: '0px solid',
    color: 'black',
    fontFamily: 'Proxima-Nova, sans',
  },
};

const formatSlotsData = (roomRack: RoomRackType) => {
  const slotData: SlotType[] = [];
  for (const { unitNumber, maintenances, reservations } of roomRack) {
    for (const { id, checkIn, checkOut, externalId } of reservations) {
      slotData.push({
        id,
        group: unitNumber,
        title: externalId,
        start_time: checkIn.getTime(),
        end_time: checkOut.getTime(),
        stackOrder: 0,
        itemProps: { style: { ...ReservationDefaultData } },
      });
    }
    for (const { id, from, to, type } of maintenances) {
      slotData.push({
        id,
        group: unitNumber,
        title: 'MNT',
        start_time: from.getTime(),
        end_time: to.getTime(),
        stackOrder: 1,
        itemProps: { style: { ...MaintenanceDefaultData[type] } },
      });
    }
  }
  return slotData;
};

export default function RoomRack() {
  const [isSlotDialogOpen, setIsSlotDialogOpen] = useState<boolean>(false);
  const [dateRangeLength, setDateRangeLength] = useState<number>(DEFAULT_DATE_RANGE_LENGTH);
  const [dateRange, setDateRange] = useState<DateRangeType>({
    start: new Date(),
    end: addDays(new Date(), DEFAULT_DATE_RANGE_LENGTH),
  });

  const { selectedProperty } = usePropertiesState();
  const { id: propertyId, timeZone } = selectedProperty;
  const { roomRack } = useGetRoomRackData({
    propertyId,
    timeZone,
    fromDate: dateRange.start.toISOString(),
    toDate: dateRange.end.toISOString(),
  });

  useEffect(() => {
    setDateRange({
      start: new Date(),
      end: addDays(new Date(), dateRangeLength),
    });
  }, [dateRangeLength]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setDateRangeLength(7);
      } else {
        setDateRangeLength(14);
      }
    };

    handleMediaQueryChange(mediaQuery as unknown as MediaQueryListEvent);
    mediaQuery.addListener(handleMediaQueryChange);
    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  const roomsData = useMemo(() => {
    if (!roomRack) return null;
    return roomRack.map(({ unitNumber }) => {
      return { id: unitNumber, title: `${unitNumber}` };
    });
  }, [roomRack]);

  const slotData = useMemo(() => (roomRack && formatSlotsData(roomRack)) ?? [], [roomRack]);

  const handleNewSlot = () => {
    setIsSlotDialogOpen(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-6 mt-2 font-sans text-th-brown text-sm leading-[18px] font-semibold">
        <AddSlotButton handleNewSlot={handleNewSlot} />
        <DateRangeNavigation dateRange={dateRange} setDateRange={setDateRange} dateRangeLength={dateRangeLength} />
      </div>
      {slotData && roomsData && (
        <>
          <div className="h-full overflow-scroll">
            <RoomRackTable dateRange={dateRange} slotsData={slotData} roomsData={roomsData} />
          </div>
          {isSlotDialogOpen && <RoomSlotSidebar rooms={roomsData} onClose={() => setIsSlotDialogOpen(false)} />}
        </>
      )}
    </div>
  );
}
