import { Task, TaskInfoType, UserRole } from '@typings/types';
import { AutomatedTaskType, TaskPriority, TaskType, Team } from '@typings/enums';
import partition from 'lodash.partition';
import dateUtils, { isTodayInProperty, setTime, toDate } from '@utils/dateUtils';
import { addDays, isAfter, startOfDay } from 'date-fns';
import { getTranslationGroupForType } from './repetitionUtils';
import i18n from '@i18n/i18n';
import { TaskAction } from '@api/taskAction.types';

export interface GroupedTasks {
  [key: string]: TaskInfoType[];
}

export function groupTasksBy(data: TaskInfoType[], key: keyof TaskInfoType) {
  return data.reduce((acc: GroupedTasks, curr) => {
    const value = curr[key]?.toString();
    if (!value) {
      return acc;
    }
    if (!acc[value]) {
      acc[value] = [];
    }
    acc[value].push(curr);
    return acc;
  }, {});
}

export function groupTasksByTeam(data: TaskInfoType[]): GroupedTasks {
  return groupTasksBy(data, 'assignedTo');
}

export function groupTasksByType(data: TaskInfoType[]): GroupedTasks {
  return groupTasksBy(data, 'type');
}

export function isUserInTheTeam(team: Team, userRoles: UserRole[]): boolean {
  return (
    userRoles.includes(UserRole.GX) ||
    userRoles.includes(UserRole.OPERATIONS) ||
    (userRoles.includes(UserRole.MAINTAINER) && team === Team.MAINTENANCE) ||
    ((userRoles.includes(UserRole.CLEANER) || userRoles.includes(UserRole.ROOMCHECKER)) && team === Team.HOUSE_KEEPING)
  );
}

export function isDueDatePassed(task: TaskInfoType): boolean {
  const nowInProperty = startOfDay(dateUtils.nowInProperty(task.property.timeZone));
  return dateUtils.isBefore(task.dueAt!, nowInProperty);
}

export function isDueDateBeforeTodayMidnight(task: Pick<TaskInfoType, 'utcTime' | 'dueAt'>): boolean {
  const { timeZone } = task.utcTime;
  const tomorrowInProperty = addDays(dateUtils.nowInProperty(timeZone), 1);
  const midnightToday = dateUtils.set(tomorrowInProperty, { hours: 0, minutes: 0, seconds: 0 });
  return dateUtils.isBefore(task.dueAt!, midnightToday);
}

export function taskPriorityToNumber(priority: TaskPriority) {
  return priority === TaskPriority.LOW ? 0 : 1;
}

export function numberToTaskPriority(value: number) {
  return value === 0 ? TaskPriority.LOW : TaskPriority.HIGH;
}

export function taskConfirmationIcon(task: TaskInfoType) {
  const priority = numberToTaskPriority(task.priority ?? 0);
  if (task.completedAt || priority === TaskPriority.LOW || task.type !== TaskType.STANDARD) {
    return '';
  }
  return !task.confirmedAt ? ' ⏳' : ' 🗓️';
}

export const GROUP_TASKS_KEY_OVERDUE = 'overdue';
export const GROUP_TASKS_KEY_COMPLETED = 'completed';

export interface TasksByDate {
  [key: string]: TaskInfoType[];
}

export function groupTasksByStatusAndDueDateInUTC(tasks: TaskInfoType[]): TasksByDate {
  return tasks.reduce((groupedTasks, task) => {
    const isPastTask = isDueDatePassed(task);
    const isResolved = !!task.completedAt;
    const itemDueTime = task.utcTime.dueAt;

    let key = '';

    if (isPastTask && !isResolved) {
      key = GROUP_TASKS_KEY_OVERDUE;
    } else {
      key = itemDueTime!.toISOString();
    }

    const copyGroupedTasks = [...(groupedTasks[key] ?? [])];
    return { ...groupedTasks, [key]: [...copyGroupedTasks, task] };
  }, {} as TasksByDate);
}

/**
 * If more values need to be added, just keep increasing the rank: -1, 0, 1, 2, etc.
 * */
export const sortTaskGroups = (tasksGroups: string[]) => {
  function getValueRank(value: string) {
    if (value === GROUP_TASKS_KEY_OVERDUE) return -1;
    return 0; // For dates
  }

  return tasksGroups.sort((a, b) => {
    const rankA = getValueRank(a);
    const rankB = getValueRank(b);

    if (rankA !== rankB) {
      return rankA - rankB;
    }

    return a.localeCompare(b);
  });
};

export function partitionTasksByOverdue(
  data: TaskInfoType[],
  timeZone: string,
): {
  overdueTasks: TaskInfoType[];
  tasks: TaskInfoType[];
} {
  const isTaskOverdueCurried = isTaskOverdue(timeZone);
  const [overdueTasks, tasks] = partition(data, isTaskOverdueCurried);
  return {
    tasks,
    overdueTasks,
  };
}

export function isTaskOverdue(timeZone: string) {
  const midnightInProperty = startOfDay(dateUtils.nowInProperty(timeZone));
  return (task: TaskInfoType) => {
    return (
      !task.completedAt && task.type !== TaskType.LOST_AND_FOUND && dateUtils.isBefore(task.dueAt!, midnightInProperty)
    );
  };
}

/**
 * Only STANDARD tasks with high priority can be confirmed.
 */
export function isTaskForConfirm(task: TaskInfoType | Task) {
  return task.type === TaskType.STANDARD && (task.priority || 0) > 0;
}

export function isConfirmTaskAvailable(task: TaskInfoType) {
  return !task.completedAt && isTaskForConfirm(task);
}

export function isUnconfirmedTask(task: TaskInfoType | Task) {
  return !task.confirmedAt && isTaskForConfirm(task);
}

/*
 * sort tasks by following conditions:
 * - unconfirmed tasks go first
 * - completed tasks goes at the end.
 * - uncompleted tasks are ordered by due date.
 * - if two tasks (single and repetitive) are due at the same time, single task goes before repetitive
 */
export const sortTasks = (data: TaskInfoType[]) =>
  data.sort((a, b) => {
    const isAForConfirm = isConfirmTaskAvailable(a) && !a.confirmedAt;
    const isBForConfirm = isConfirmTaskAvailable(b) && !b.confirmedAt;
    return (
      Number(isBForConfirm) - Number(isAForConfirm) ||
      Number(!!a.completedAt) - Number(!!b.completedAt) ||
      toDate(a.dueAt!).getTime() - toDate(b.dueAt!).getTime() ||
      Number(!!a.recurrence) - Number(!!b.recurrence)
    );
  });

export function filterBySearchCriteria(data: TaskInfoType[], search?: string) {
  if (!search) {
    return data;
  }
  return data?.filter(
    (task) =>
      (task.isStandardized ? i18n.t(`${getTranslationGroupForType(task)}`) : task.title)
        .toLowerCase()
        .includes(search) ||
      task.title.toLowerCase().includes(search) ||
      (task.unit?.number || task.area?.name)?.toLowerCase().includes(search),
  );
}

export function isConfirmButAfterWorkHoursToday(zonedDueAt: Date, timeZone: string, priority: TaskPriority) {
  const isApaleoTab = window.location.pathname.startsWith('/apaleo');
  if (!isApaleoTab || priority === TaskPriority.LOW) {
    return false;
  }
  const isAfter4pm = isAfter(zonedDueAt, setTime(zonedDueAt, '16:00'));
  return isTodayInProperty(zonedDueAt, timeZone) && isAfter4pm;
}

interface TaskTitleParams {
  task: TaskInfoType;
  taskAction: TaskAction | null;
  taskActionDefaultLang: TaskAction | null;
}

export function getTaskTitle(params: TaskTitleParams) {
  const { taskAction, taskActionDefaultLang, task } = params;
  const showTaskAction = false;
  if (task.isStandardized) {
    return {
      showTaskAction,
      title: i18n.t(getTranslationGroupForType(task)),
    };
  }
  const isTitleSame = taskAction && task.title === taskActionDefaultLang?.name;
  if (isTitleSame) {
    return {
      showTaskAction,
      title: taskAction?.name,
    };
  }
  return {
    showTaskAction: true,
    title: task.title,
  };
}

export function getKitchenUseActionId(automatedTasksActions: TaskAction[]) {
  return automatedTasksActions?.find((action) => {
    return action.automatedTask === AutomatedTaskType.KITCHEN_USE;
  })?.id;
}

interface TaskDescriptionParams {
  task: TaskInfoType;
  taskAction: TaskAction | null;
  taskActionDefaultLang: TaskAction | null;
}

export function getTaskDescription(params: TaskDescriptionParams) {
  const { taskAction, taskActionDefaultLang, task } = params;
  const isDescriptionSame = taskAction && task.description === taskActionDefaultLang?.description;
  if (isDescriptionSame) {
    return taskAction?.description;
  }
  return task.description;
}

export const ALL_TEAMS: string[] = Object.keys(Team);
