import './Loader.css';

interface Props {
  className?: string;
}

export default function Loader({ className }: Props) {
  return (
    <div className={`lds-ring ${className || ''}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
