import { ApiInfo } from '@api/types';

const reservationApi: ApiInfo = {
  getWeeklyView: ({ query }) => {
    const { propertyIds, from } = query!;
    return {
      url: `/reservations/weekly?from=${from}&propertyIds=${(propertyIds as string[]).join(',')}`,
    };
  },
  getNextMidstayClean: ({ query, params }) => {
    const { id } = params!;
    const { propertyId } = query!;
    return {
      url: `/reservations/${id}/midstays/next?propertyId=${propertyId}`,
    };
  },
  updateMidstayClean: ({ params, body }) => {
    const { id } = params!;
    return {
      url: `/reservations/${id}/midstays`,
      body,
      method: 'post',
    };
  },
  getOneByExternalId: ({ params }) => {
    const { externalId } = params!;
    return {
      url: `/reservations/external/${externalId}`,
    };
  },
  countLateCheckoutsOnDay: ({ query }) => {
    const { propertyId, dateOnly } = query!;
    return {
      url: `/reservations/count-late-checkouts?propertyId=${propertyId}&date=${dateOnly}`,
    };
  },
  updateOne: ({ params, body }) => {
    const { externalId } = params!;
    return {
      url: `/reservations/external/${externalId}`,
      method: 'put',
      body,
    };
  },
};

export default reservationApi;
