import routes, { goToUnitDetails } from '@constants/routes';
import CreateTask from '@organisms/CreateTask';
import TaskActions from '@organisms/TaskActions';
import TaskDetails from '@organisms/TaskDetails';
import { TaskInfoType } from '@typings/types';
import useAuth from '@utils/hooks/useAuth';
import useDeleteTask from '@utils/hooks/useDeleteTask';
import { openReservationInApaleo } from '@utils/unitUtils';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import IconWithText from '@molecules/IconWithText';
import icons from '@constants/icons';
import { useGetOneTask } from '@api/api';
import { useTranslation } from 'react-i18next';
import Header from '@organisms/Header';
import usePropertiesState from '@context/propertiesContext';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';

interface Props {
  id: string;
  propertyId: string;
}

function TaskView({ id, propertyId }: Props) {
  const history = useHistory();
  const location = useLocation<{ from?: string }>();
  const { t } = useTranslation();
  const [editTask, setEditTask] = useState<TaskInfoType | undefined>();

  const { isLoading, data, error, refetch } = useGetOneTask({ id, propertyId });

  const { isCleanerOnly } = useAuth();

  const goToUnitDetailsFn = goToUnitDetails(history);

  const { selectedProperty, onSelectProperties } = usePropertiesState();
  const { isGxOnlyRole: isGxOnlyRoleFn } = useRoleBasedUI();
  useEffect(() => {
    if (data && isGxOnlyRoleFn(selectedProperty?.id) && !selectedProperty?.id) {
      onSelectProperties([data.property]);
    }
  }, [data, selectedProperty]);

  const goBack = (propertyId: string) => {
    const pathname = location.pathname;
    // set in propertyContext if pathname is updated with search parameter
    const previousPathname = location?.state?.from;
    if (pathname !== previousPathname) {
      history.goBack();
    } else {
      history.push({ pathname: routes.ROOT, search: `?propertyId=${propertyId}` });
    }
  };

  const { setTaskForDeletion, renderDeleteConfirmationDialog } = useDeleteTask({
    onSuccess: () => goBack(propertyId),
  });

  if (isLoading || !selectedProperty?.id) {
    return null;
  }

  if (error || !data) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  const unitId = data.unit?.id;

  const reservationExtId = data.reservation?.externalId;
  const propertyExtId = data.property.externalId;

  return (
    <div>
      <Header />

      <div className={'flex flex-1 justify-between px-3 mt-6'}>
        <div>
          <ChevronLeftIcon className={'w-5 h-5 cursor-pointer text-th-secondary'} onClick={() => goBack(propertyId)} />
        </div>
        <div className="flex flex-row justify-end items-center space-x-2">
          {unitId && (
            <div
              className="cursor-pointer"
              onClick={() =>
                goToUnitDetailsFn({
                  unitId,
                  propertyId,
                  expandTasks: true,
                })
              }
            >
              <IconWithText Icon={icons.door} large />
            </div>
          )}
          {reservationExtId && (
            <div className="cursor-pointer" onClick={() => openReservationInApaleo(propertyExtId, reservationExtId)}>
              <IconWithText Icon={icons.apaleo} className="w-3 h-3" />
            </div>
          )}
          {!isCleanerOnly && <TaskActions task={data} onClickEdit={setEditTask} onClickDelete={setTaskForDeletion} />}
        </div>
      </div>

      <div className="shadow-md rounded-md mb-4">
        <TaskDetails task={data} onTaskAction={() => refetch()} />
      </div>

      {renderDeleteConfirmationDialog()}

      {editTask && <CreateTask editTask={editTask} onClose={() => setEditTask(undefined)} />}
    </div>
  );
}

export default TaskView;
