import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFuzzySearch } from '@utils/hooks/useFuzzySearch';
import { ConfirmButton } from '../Commons/ConfirmButton';

import { TaskAction } from '@api/taskAction.types';
import { AutocompleteOption } from '@molecules/AutocompleteInput/types';
import { ActionMultiSelectorValues, ActionMultiSelectorClick } from '../FilterTypes';

import filterStyles from '../filters.module.css';
import { FiltersParamsType } from '@context/filtersTasksContext';

const ActionItem = ({
  option,
  isOptionChecked,
  handleOptionChange,
}: {
  option: AutocompleteOption<TaskAction>;
  isOptionChecked: boolean;
  handleOptionChange: (optionId: string) => void;
}) => {
  if (!option) return null;
  return (
    <div
      className={`p-4 flex justify-between items-center gap-4 border-b border-b-[#F3F9F7]
        ${isOptionChecked && 'bg-[#F3F9F7]'} cursor-pointer`}
      onClick={() => handleOptionChange(option.value.id)}
    >
      <div>
        <p className="text-base font-sans font-normal">{option.title}</p>
        <div className="pt-1 flex justify-start items-center text-sm leading-[18px] font-normal font-sans">
          <p>{`${option.value.category} > ${option.value.subcategory}`}</p>
        </div>
      </div>
      <input
        type="checkbox"
        value={option.value.id}
        checked={isOptionChecked}
        className={filterStyles.selectorCheckbox}
        onChange={() => handleOptionChange(option.value.id)}
      />
    </div>
  );
};

interface ActionsMultiSelectorModalProps {
  id: keyof FiltersParamsType;
  options: AutocompleteOption<TaskAction>[];
  activeOptions: AutocompleteOption<TaskAction>[];
  values: ActionMultiSelectorValues;
  onClick: ActionMultiSelectorClick;
  onConfirm: () => void;
}

export const ActionsMultiSelectorModal = ({
  id,
  options,
  activeOptions,
  values,
  onClick,
  onConfirm,
}: ActionsMultiSelectorModalProps) => {
  const { t } = useTranslation();
  const { getSearchResults } = useFuzzySearch(options);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(values.value ?? []);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchedOptions, setSearchedOptions] = useState<AutocompleteOption<TaskAction>[]>([]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputNewValue = e.target.value;
    setInputValue(inputNewValue);

    const newSearchedOptions = getSearchResults(inputNewValue);
    setSearchedOptions(newSearchedOptions);
  };

  const handleOptionChange = (optionId: string) => {
    let newSelectedOptions;
    if (selectedOptions.includes(optionId)) {
      newSelectedOptions = [...selectedOptions].filter((item) => item !== optionId);
    } else {
      newSelectedOptions = [...selectedOptions, optionId];
    }
    setSelectedOptions(newSelectedOptions);
    onClick({ id, label: null, value: newSelectedOptions });
  };

  return (
    <div className="md:w-128">
      <div className="flex flex-col justify-center items-start w-full p-4">
        <input
          type="text"
          value={inputValue}
          onChange={handleSearchChange}
          placeholder="Search for actions..."
          className="w-full h-10 bg-[#F5F5F5] border-0 rounded-md"
        />
        <p className="font-sans font-normal text-sm text-[#8B9DA5] pt-2">{`${searchedOptions.length} ${t(
          'results',
        )}`}</p>
      </div>
      <div className="h-60 md:h-96 overflow-y-auto">
        {activeOptions.map((activeOption) => (
          <ActionItem
            key={activeOption.value.id}
            option={activeOption}
            handleOptionChange={handleOptionChange}
            isOptionChecked={selectedOptions.length > 0 && selectedOptions.includes(activeOption.value.id)}
          />
        ))}
        {searchedOptions.map((option) => (
          <ActionItem
            key={option.value.id}
            option={option}
            handleOptionChange={handleOptionChange}
            isOptionChecked={selectedOptions.length > 0 && selectedOptions.includes(option.value.id)}
          />
        ))}
      </div>
      <div className="py-4 px-5">
        <ConfirmButton isDisabled={!selectedOptions || selectedOptions?.length === 0} onConfirm={onConfirm} />
      </div>
    </div>
  );
};
