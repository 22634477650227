import { useState } from 'react';
import { Listbox } from '@headlessui/react';
import Transition from '@atoms/Transition';

interface Props {
  items: { value: string | number | null; label: string }[];
  value?: string | number | null;
  onChange: (v: string | number) => void;
  className?: string;
  disabled?: boolean;
  relativity?: boolean;
}

export default function Dropdown({
  items,
  value,
  onChange,
  className = '',
  disabled = false,
  relativity = true,
}: Props) {
  const initialValue = value !== null && value !== undefined ? items.find((i) => i.value === value) : items[0];
  const [selectedItem, setSelectedItem] = useState<{ value: string | number | null; label: string }>(initialValue!);
  return (
    <Listbox
      value={selectedItem}
      onChange={(v) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const item = items.find((i) => i.value === v)!;
        setSelectedItem(item);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange(v);
      }}
      disabled={disabled}
    >
      {({ open }) => (
        <div className={`${relativity ? 'relative' : ''}`}>
          <Listbox.Button className={`${className} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}>
            {selectedItem?.label}
          </Listbox.Button>
          <Transition show={open} className={'relative z-10'}>
            <Listbox.Options
              className="absolute py-1 px-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 focus:outline-none sm:text-sm"
              static
            >
              {items.map((o) => (
                <Listbox.Option key={o.value} value={o.value} className={'cursor-pointer'}>
                  {o.label}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
