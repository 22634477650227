import React, { MouseEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@molecules/Dialog';
import RadioButton from '@molecules/RadioButton';
import { SearchIcon } from '@heroicons/react/outline';
import LoadingMessage from '@atoms/LoadingMessage';
import { useToggle } from '@utils/hooks/useToggle';
import { SelectOption } from './types';

interface Props {
  title: string;
  value?: SelectOption;
  onSelect: (item?: SelectOption) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
  options: SelectOption[];
  isLoading?: boolean;
  error?: unknown;
  includeSearch?: boolean;
  customComponent?: (toggleDialog: () => void) => React.ReactNode;
}

// todo decide how to show error
export default function SelectionDialog(props: Props) {
  const {
    isLoading = false,
    includeSearch = true,
    title,
    value,
    onSelect,
    actionButton,
    options,
    customComponent,
  } = props;

  const [dialogOpen, toggleDialogOpen] = useToggle(false);

  const { t } = useTranslation();

  const [search, setSearch] = useState<string>();
  const filteredData = search ? options.filter((p) => p.label.toLowerCase().includes(search)) : options;

  const onClose = useCallback(() => {
    toggleDialogOpen(false);
    setSearch('');
  }, [toggleDialogOpen]);

  return (
    <>
      {actionButton(toggleDialogOpen)}
      <Dialog isOpen={dialogOpen} onClose={onClose} title={title} isMobileSheet>
        <div className={'md:w-96 flex flex-col'}>
          {includeSearch && (
            <div className={'shadow-sm p-3 pt-0'}>
              <div className={'rounded-md flex flex-row items-center px-3 py-2 mt-3 bg-gray-100'}>
                <SearchIcon className={'w-5 h-5 mr-2 text-gray-400'} />
                <input
                  placeholder={t('search')}
                  onChange={(v) => setSearch(v.target.value?.toLowerCase())}
                  className={
                    'block w-full p-0 focus:outline-none sm:text-sm border-0 bg-transparent placeholder-gray-400'
                  }
                />
              </div>
            </div>
          )}
          {customComponent?.(toggleDialogOpen)}
          <div className={'flex flex-col pb-10 md:pb-1 py-3 max-h-[70vh] overflow-y-scroll'}>
            {includeSearch && (
              <div className={'px-3 text-sm text-gray-400 lowercase'}>
                {filteredData.length} {filteredData.length === 1 ? t('result') : t('results')}
              </div>
            )}
            {isLoading && <LoadingMessage className={'ml-2 mt-2'} />}
            {!isLoading &&
              filteredData.map((item) => (
                <div key={item.value} className={'flex flex-col'}>
                  <RadioButton
                    id={item.value}
                    name={'item'}
                    label={item.label}
                    onClick={() => {
                      onSelect(item);
                      toggleDialogOpen();
                      setSearch('');
                    }}
                    checked={item.value === value?.value}
                    className={'pb-2 text-th-brown'}
                  />
                </div>
              ))}
          </div>
        </div>
      </Dialog>
    </>
  );
}
