import { Dispatch, SetStateAction, createContext, useState } from 'react';
import type translations from '@i18n/languages/en';
import { updateDefaultFiltersBasedOnUserRole } from '@molecules/Filters/utils';
import { TFunction } from 'react-i18next';
import { Team, TaskPriority, RoomsFilterType } from '@typings/enums';

export const FILTER_DATE_RANGE = 'FILTER_DATE_RANGE';
export const FILTER_TEAMS = 'FILTER_TEAMS';
export const FILTER_PRIORITY = 'FILTER_PRIORITY';
export const FILTER_ACTIONS = 'FILTER_ACTIONS';
export const FILTER_EXCLUDE_ACTIONS = 'FILTER_EXCLUDE_ACTIONS';
export const FILTER_RECURRENCE = 'FILTER_RECURRENCE';
export const FILTER_ROOMS = 'FILTER_ROOMS';

const filtersInitialData = {
  [FILTER_DATE_RANGE]: {
    title: 'dueDate' as keyof typeof translations,
    label: null as string | null,
    value: null as Date[] | null,
  },
  [FILTER_TEAMS]: {
    title: 'team' as keyof typeof translations,
    label: null as string[] | null,
    value: null as string[] | null,
  },
  [FILTER_PRIORITY]: {
    title: 'priority' as keyof typeof translations,
    label: null as string | null,
    value: null as TaskPriority | null,
  },
  [FILTER_ACTIONS]: {
    title: 'showActions' as keyof typeof translations,
    label: null as string[] | null,
    value: null as string[] | null,
  },
  [FILTER_EXCLUDE_ACTIONS]: {
    title: 'hideActions' as keyof typeof translations,
    label: null as string[] | null,
    value: null as string[] | null,
  },
  [FILTER_RECURRENCE]: {
    title: 'repetition' as keyof typeof translations,
    label: null as string | null,
    value: null as string | null,
  },
  [FILTER_ROOMS]: {
    title: 'rooms' as keyof typeof translations,
    label: null as string[] | null,
    value: null as { id: string; type: RoomsFilterType }[] | null,
  },
};

export type FiltersParamsType = typeof filtersInitialData;

export type FilterParamsValue = {
  title: string;
  label: null | string | string[];
  value: null | string | string[] | Date[] | { id: string; type: RoomsFilterType }[];
};

export interface TeamFilterActionValues {
  id: keyof FiltersParamsType;
  label: FilterParamsValue['label'];
  value: FilterParamsValue['value'];
}

export const TasksContext = createContext<{
  filterParams: FiltersParamsType | Record<string, never>;
  setFilterParams: Dispatch<SetStateAction<FiltersParamsType>>;
  updateFilterParams: (action: TeamFilterActionValues) => void;
  resetFilterParams: (
    userTeam: Team,
    t: TFunction<'translation', undefined>,
    id?: keyof typeof filtersInitialData,
  ) => void;
}>({
  filterParams: {},
  setFilterParams: () => {},
  updateFilterParams: () => {},
  resetFilterParams: () => {},
});

export const FiltersTasksProvider = ({ children }: { children: React.ReactNode }) => {
  const [filterParams, setFilterParams] = useState(filtersInitialData);

  const updateFilterParams = (action: TeamFilterActionValues) => {
    setFilterParams((prevState) => ({
      ...prevState,
      [action.id]: {
        ...prevState[action.id],
        label: action.label,
        value: action.value,
      },
    }));
  };

  const resetFilterParams = (
    userTeam: Team,
    t: TFunction<'translation', undefined>,
    id?: keyof typeof filtersInitialData,
  ) => {
    if (id) {
      if (id === FILTER_TEAMS || id === FILTER_DATE_RANGE) {
        updateDefaultFiltersBasedOnUserRole(userTeam, updateFilterParams, t, id);
      } else {
        setFilterParams((prevState) => ({
          ...prevState,
          [id]: { ...filtersInitialData[id] },
        }));
      }
    } else {
      // reset the filters with default values
      updateDefaultFiltersBasedOnUserRole(userTeam, updateFilterParams, t);
      // reset filters with no default value.
      setFilterParams((prevState) => ({
        ...prevState,
        [FILTER_PRIORITY]: { ...filtersInitialData[FILTER_PRIORITY] },
        [FILTER_ACTIONS]: { ...filtersInitialData[FILTER_ACTIONS] },
        [FILTER_EXCLUDE_ACTIONS]: { ...filtersInitialData[FILTER_EXCLUDE_ACTIONS] },
        [FILTER_RECURRENCE]: { ...filtersInitialData[FILTER_RECURRENCE] },
        [FILTER_ROOMS]: { ...filtersInitialData[FILTER_ROOMS] },
      }));
    }
  };

  return (
    <TasksContext.Provider value={{ filterParams, updateFilterParams, resetFilterParams, setFilterParams }}>
      {children}
    </TasksContext.Provider>
  );
};
