import CollapsibleSection from '@molecules/CollapsibleSection';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TimePicker from '@molecules/TimePicker/TimePicker';
import { Controller, useForm } from 'react-hook-form';
import Button, { ButtonType } from '@atoms/Button';
import { useGetDaySettings, useGetLateCheckoutsCount, useUpdateReservation } from '@api/api';
import { formatDate, REQUEST_DATE_FORMAT, TIME_FORMAT } from '@utils/dateUtils';
import { useQueryClient } from 'react-query';
import { NotificationType, ServerStateKey } from '@typings/enums';
import useNotifications from '@utils/hooks/useNotifications';

const EARLIEST_APPROVED_CHECKOUT_TIME = '11:00';
const LATEST_APPROVED_CHECKOUT_TIME = '13:00';

interface CheckoutSectionFormValues {
  approvedCheckoutTime: string;
}

interface Props {
  propertyId: string;
  reservationExternalId: string;
  requestedCheckout: Date;
  approvedCheckout: Date;
}

function GxCheckoutSection({ propertyId, reservationExternalId, requestedCheckout, approvedCheckout }: Props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { showNotification } = useNotifications();

  const dateOnly = formatDate(requestedCheckout, REQUEST_DATE_FORMAT);
  const { data: checkoutDaySettings } = useGetDaySettings(propertyId, dateOnly);

  const { data: lateCheckouts } = useGetLateCheckoutsCount(propertyId, dateOnly);

  const updateReservation = useUpdateReservation(reservationExternalId, {
    onSuccess: () => {
      queryClient.invalidateQueries(ServerStateKey.RESERVATION);
      queryClient.invalidateQueries(ServerStateKey.LATE_CHECKOUTS_COUNT);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      const message = error?.message || t('anErrorHasOccurred');
      showNotification(message, NotificationType.ERROR);
    },
  });

  const form = useForm<CheckoutSectionFormValues>({
    mode: 'onChange',
    defaultValues: {
      approvedCheckoutTime: formatDate(approvedCheckout, TIME_FORMAT),
    },
  });

  const { isDirty } = form.formState;

  const onSubmit = useCallback(() => {
    if (updateReservation.isLoading) {
      return;
    }
    const formValues = form.getValues();
    const checkOutTime = formValues.approvedCheckoutTime;
    const data = {
      checkOutTime,
    };
    updateReservation.mutate({
      data,
    });
    form.reset({}, { keepValues: true });
  }, [form, updateReservation]);

  const pickerDisabled = !checkoutDaySettings?.approvedLateCheckouts;

  return (
    <div className={'flex flex-col'}>
      <CollapsibleSection headerTitle={t('checkOut')}>
        <div className={'p-4 flex flex-col space-y-3'}>
          <div className={'flex flex-row items-center justify-between'}>
            <div>{t('requestedCheckoutTime')}:</div>
            <div className={'flex'}>
              <span>{formatDate(requestedCheckout, TIME_FORMAT)}</span>
            </div>
          </div>
          <div className={'flex flex-row items-center justify-between'}>
            <div>{t('approvedCheckoutTime')}:</div>
            <Controller
              control={form.control}
              name={'approvedCheckoutTime'}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  value={value}
                  onChange={onChange}
                  from={EARLIEST_APPROVED_CHECKOUT_TIME}
                  to={checkoutDaySettings?.latestAllowedCheckout ?? LATEST_APPROVED_CHECKOUT_TIME}
                  disabled={pickerDisabled}
                />
              )}
            />
          </div>
          {checkoutDaySettings && (
            <>
              <div className={'flex flex-row items-center justify-between'}>
                <div>{t('numberOfApprovedLCOs')}:</div>
                {checkoutDaySettings.approvedLateCheckouts === null ? (
                  <div>{t('notSet')}</div>
                ) : (
                  <div className={'flex flex-row items-center space-x-0.5'}>
                    <span>{lateCheckouts?.count}</span>
                    <span>/</span>
                    <span>{checkoutDaySettings.approvedLateCheckouts}</span>
                  </div>
                )}
              </div>
              {checkoutDaySettings.approvedLateCheckouts !== null && (
                <div className={'flex flex-row items-center justify-between'}>
                  <div>{t('lcoUntil')}:</div>
                  <div>{checkoutDaySettings.latestAllowedCheckout}</div>
                </div>
              )}
            </>
          )}
          <Button
            isLoading={updateReservation.isLoading}
            disabled={!isDirty}
            type={ButtonType.OUTLINED}
            onClick={form.handleSubmit(onSubmit)}
            className={'z-0'}
          >
            {t('save')}
          </Button>
        </div>
      </CollapsibleSection>
    </div>
  );
}

export default GxCheckoutSection;
