import { useGetRoomRack } from '@api/api';

interface Props {
  propertyId: string;
  timeZone: string;
  fromDate?: string;
  toDate?: string;
  enabled?: boolean;
}

function useGetRoomRackData(props: Props) {
  const { data, ...queryResult } = useGetRoomRack(props);

  return {
    ...queryResult,
    roomRack: data,
  };
}

export default useGetRoomRackData;
