import { useState } from 'react';

import { ConfirmButton } from '../Commons/ConfirmButton';
import { Selector } from '../Commons/Selector';

import { Option, SingleSelectorValues, SingleSelectorClick } from '../FilterTypes';
import { FiltersParamsType } from '@context/filtersTasksContext';

interface SingleSelectorModalProps {
  id: keyof FiltersParamsType;
  options: Option[];
  values: SingleSelectorValues;
  onClick: SingleSelectorClick;
  onConfirm: () => void;
}

export const SingleSelectorModal = ({ id, options, values, onClick, onConfirm }: SingleSelectorModalProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(values.value);

  const handleOptionChange = (option: Option) => {
    const { label, value } = option;

    setSelectedOption(value);
    onClick({ id, label, value });
  };

  return (
    <div className="md:min-w-[350px]">
      <div className="py-4">
        <Selector options={options} selectedOption={selectedOption} handleOptionChange={handleOptionChange} />
      </div>
      <div className="py-4 px-5">
        <ConfirmButton isDisabled={!selectedOption} onConfirm={onConfirm} />
      </div>
    </div>
  );
};
