import notificationsApi from '@api/notificationsApi';
import propertiesApi from '@api/propertiesApi';
import reservationApi from '@api/reservationApi';
import { TaskAction, TaskCategoryRecord } from '@api/taskAction.types';
import tasksApi from '@api/tasksApi';
import unitsApi from '@api/unitsApi';
import taskCategoriesApi from '@api/taskCategoriesApi';
import { AppLanguage } from '@organisms/SelectLanguage/SelectLanguage';
import { ServerStateKey, TaskPriority, TaskType } from '@typings/enums';
import {
  AssignedUnitsByFloor,
  AssignUnits,
  Cleaner,
  DataWrapper,
  DaySettings,
  MidClean,
  NextMidCleanDto,
  Notifications,
  NotificationsDto,
  NotificationSettingsType,
  PropertyType,
  Reservation,
  ReservationDto,
  RoomRackDto,
  Task,
  TaskInfoType,
  TaskInfoTypeDto,
  TaskNotification,
  ThinArea,
  UnitDetailsInfoType,
  UnitDetailsInfoTypeDto,
  UnitInfoType,
  UnitInfoTypeDto,
  UnitsAndAreas,
  UpdateMidstayClean,
  UpdateReservationDto,
  WeeklyViewData,
} from '@typings/types';
import useApiCall from '@utils/hooks/useApiCall';
import { REFETCH_INTERVAL } from '@utils/queryUtils';
import { ReservationMapper } from '@utils/reservationMapper';
import { TaskMapper } from '@utils/taskMapper';
import { RoomRackMapper } from '@utils/roomRackMapper';
import { partitionTasksByOverdue } from '@utils/taskUtils';
import { UnitMapper } from '@utils/unitMapper';
import { utcToZonedTime } from 'date-fns-tz';
import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { TaskActionMapper } from './taskAction.mapper';
import { useMemo } from 'react';
import roomRackApi from './roomRackApi';

export function useCreateTasks(propertyId: string, options?: UseMutationOptions<unknown, unknown, unknown>) {
  const apiCall = useApiCall<Task[]>(tasksApi.saveAll);
  return useMutation(
    ({ data: body }: { data: Task[] }) =>
      apiCall({
        params: { propertyId },
        body,
      }),
    options,
  );
}

export function useUpdateTask(propertyId: string, options?: UseMutationOptions<Task, unknown, unknown>) {
  const apiCall = useApiCall<Task>(tasksApi.updateOne);
  return useMutation(
    ({ id, data: body }: { id: string; data: Partial<Task> }) =>
      apiCall({
        params: { propertyId, id },
        body,
      }),
    options,
  );
}

export function useGetProperties() {
  const apiCall = useApiCall<PropertyType[]>(propertiesApi.getProperties);
  return useQuery(ServerStateKey.PROPERTIES, () => apiCall({}));
}

export function useCreateArea(propertyId: string) {
  const apiCall = useApiCall<ThinArea>(propertiesApi.saveArea);
  return useMutation(({ data }: { data: Omit<ThinArea, 'id'> }) => apiCall({ params: { propertyId }, body: data }));
}

export function useGetCleaners(propertyId: string) {
  const apiCall = useApiCall<Cleaner[]>(propertiesApi.getCleaners);
  return useQuery([ServerStateKey.CLEANERS, propertyId], () => apiCall({ params: { propertyId } }), {
    enabled: false,
  });
}

export function useGetAssignedUnits(propertyId: string) {
  const apiCall = useApiCall<AssignedUnitsByFloor>(propertiesApi.getAssignedUnits);
  return useQuery([ServerStateKey.ASSIGNED_ROOMS, propertyId], () => apiCall({ params: { propertyId } }));
}

export function useAssignUnits(propertyId: string) {
  const apiCall = useApiCall<AssignUnits>(propertiesApi.assignUnits);
  return useMutation(({ data }: { data: AssignUnits }) => apiCall({ params: { propertyId }, body: data }));
}

export function useGetPropertyUnitsAndAreas(propertyId?: string, enabled = false) {
  const apiCall = useApiCall<UnitsAndAreas>(propertiesApi.getPropertyUnitsAndAreas);
  return useQuery(
    [ServerStateKey.PROPERTY_UNITS_AND_AREAS, propertyId],
    () => (propertyId ? apiCall({ params: { propertyId } }) : undefined),
    {
      enabled,
    },
  );
}

export function useUnassignUnits(propertyId: string) {
  const apiCall = useApiCall<Pick<AssignUnits, 'unitIds'>>(propertiesApi.unassignUnits);
  return useMutation(({ data }: { data: Pick<AssignUnits, 'unitIds'> }) =>
    apiCall({ params: { propertyId }, body: data }),
  );
}

export function useGetNextMidClean(propertyId: string, reservationId: string) {
  const apiCall = useApiCall<DataWrapper<NextMidCleanDto>>(reservationApi.getNextMidstayClean);
  return useQuery<DataWrapper<NextMidCleanDto>>([ServerStateKey.NEXT_MIDSTAY_CLEAN, reservationId], () =>
    apiCall({
      params: {
        id: reservationId,
      },
      query: {
        propertyId,
      },
    }),
  );
}

export function useUpdateMidClean() {
  const apiCall = useApiCall<{ success: boolean } & MidClean>(reservationApi.updateMidstayClean);
  return useMutation(({ data: { reservationId, ...data } }: { data: { reservationId: string } & UpdateMidstayClean }) =>
    apiCall({
      params: {
        id: reservationId,
      },
      body: data,
    }),
  );
}

export function useGetWeeklyView(propertyIds: string[], from: string) {
  const apiCall = useApiCall<WeeklyViewData>(reservationApi.getWeeklyView);
  return useQuery([ServerStateKey.WEEKLY_VIEW, propertyIds, from], () => apiCall({ query: { propertyIds, from } }), {
    enabled: propertyIds.length > 0,
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
  });
}

export function useGetNotificationSettings() {
  const apiCall = useApiCall<{ notificationSettings: NotificationSettingsType | null }>(
    notificationsApi.getNotificationSettings,
  );
  return useQuery([ServerStateKey.NOTIFICATION_SETTINGS], () => apiCall({}));
}

export function useUpdateNotificationsMutation() {
  const apiCall = useApiCall<AssignUnits>(notificationsApi.updateNotificationSettings);
  return useMutation(({ data }: { data: NotificationSettingsType }) => apiCall({ body: data }));
}

export function useGetNotifications() {
  const apiCall = useApiCall<NotificationsDto>(notificationsApi.getNotifications);
  return useQuery<NotificationsDto, unknown, Notifications>([ServerStateKey.NOTIFICATIONS], () => apiCall({}), {
    select: (data) =>
      data.reduce((result, item) => {
        const { notifications: rawNotifications, ...rest } = item;
        const notifications: TaskNotification[] = rawNotifications.map(({ metadata, ...n }) => {
          const task = metadata?.task as unknown as TaskInfoTypeDto;
          const timeZone = task.property.timeZone;
          return { ...n, metadata: { ...metadata, task: TaskMapper.toTaskInfoType(task, timeZone) } };
        });
        const newItem = {
          ...rest,
          notifications,
        };
        result.push(newItem);
        return result;
      }, [] as Notifications),
  });
}

const typeToQueryKey: { [key: string]: ServerStateKey } = {
  [TaskType.STANDARD]: ServerStateKey.TASKS,
  [TaskType.DAMAGE]: ServerStateKey.DAMAGE_REPORT_TASKS,
  [TaskType.LOST_AND_FOUND]: ServerStateKey.LOST_AND_FOUND_TASKS,
};

interface GetTasksProps {
  propertyId: string;
  type: TaskType;
  timeZone: string;
  fromDate?: string;
  toDate?: string;
  priority?: TaskPriority;
  teams?: string[];
  actions?: string[];
  excludeActions?: string[];
  recurrence?: string;
  rooms?: string[];
  areas?: string[];
  enabled?: boolean;
}

/**
 * Based on type, if STANDARD type is passed,
 * it will additionally load overdue and unconfirmed tasks
 */
export function useGetTasks({
  type,
  propertyId,
  timeZone,
  fromDate,
  toDate,
  priority,
  teams,
  actions,
  excludeActions,
  recurrence,
  rooms,
  areas,
  enabled = true,
}: GetTasksProps) {
  const isStandardTask = type === TaskType.STANDARD;

  const {
    isFetching: isFetchingTasks,
    error,
    data,
  } = useGetTasksForDate({
    type,
    propertyId,
    timeZone,
    fromDate,
    toDate,
    priority,
    teams,
    actions,
    excludeActions,
    recurrence,
    rooms,
    areas,
    enabled,
  });

  const {
    isFetching: isFetchingOverdue,
    error: overdueError,
    data: overdueData,
  } = useGetOverdueTasks({
    propertyId,
    isStandardTask,
    timeZone,
    priority,
    teams,
    actions,
    excludeActions,
    recurrence,
    rooms,
    areas,
    enabled,
  });

  const {
    isFetching: isFetchingUnconfirmed,
    error: unconfirmedError,
    data: unconfirmedTasks,
  } = useGetUnconfirmedTasks({
    propertyId,
    isStandardTask,
    timeZone,
    teams,
    actions,
    excludeActions,
    recurrence,
    rooms,
    areas,
    enabled,
  });

  const isFetching = isFetchingTasks || isFetchingUnconfirmed;

  const dataByCategory = useMemo(() => {
    if (isStandardTask) {
      return {
        tasks: data,
        overdueTasks: overdueData,
      };
    }
    if (!data) return null;
    return partitionTasksByOverdue(data, timeZone);
  }, [isStandardTask, data, overdueData]);

  return {
    isFetching,
    isFetchingTasks,
    isFetchingOverdue,
    isFetchingUnconfirmed,
    error: error ?? overdueError ?? unconfirmedError,
    tasks: dataByCategory?.tasks,
    overdueTasks: dataByCategory?.overdueTasks,
    unconfirmedTasks,
  };
}

export function useGetTasksForDate({
  type,
  propertyId,
  timeZone,
  fromDate,
  toDate,
  priority,
  teams,
  actions,
  excludeActions,
  recurrence,
  rooms,
  areas,
  enabled = true,
}: {
  type: TaskType;
  propertyId: string;
  timeZone: string;
  fromDate?: string;
  toDate?: string;
  priority?: TaskPriority;
  teams?: string[];
  actions?: string[];
  excludeActions?: string[];
  recurrence?: string;
  rooms?: string[];
  areas?: string[];
  enabled?: boolean;
}) {
  const apiCall = useApiCall<TaskInfoTypeDto[]>(tasksApi.getTasks);
  return useQuery(
    [
      typeToQueryKey[type],
      propertyId,
      fromDate,
      toDate,
      priority,
      teams,
      actions,
      excludeActions,
      recurrence,
      rooms,
      areas,
    ].filter((v) => !!v),
    () =>
      apiCall({
        params: { propertyId },
        query: { type, fromDate, toDate, priority, teams, actions, excludeActions, recurrence, rooms, areas },
      }),
    {
      enabled: !!enabled,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
      select: (data) => data.map((t) => TaskMapper.toTaskInfoType(t, timeZone)),
      keepPreviousData: true,
    },
  );
}

export function useGetOverdueTasks({
  propertyId,
  isStandardTask,
  timeZone,
  priority,
  teams,
  actions,
  excludeActions,
  recurrence,
  rooms,
  areas,
  enabled = true,
}: {
  propertyId: string;
  isStandardTask: boolean;
  timeZone: string;
  priority?: TaskPriority;
  teams?: string[];
  actions?: string[];
  excludeActions?: string[];
  recurrence?: string;
  rooms?: string[];
  areas?: string[];
  enabled?: boolean;
}) {
  const apiCall = useApiCall<TaskInfoTypeDto[]>(tasksApi.getOverdueTasks);
  return useQuery(
    [
      ServerStateKey.OVERDUE_TASKS,
      propertyId,
      priority,
      teams,
      actions,
      excludeActions,
      recurrence,
      rooms,
      areas,
    ].filter((v) => !!v),
    () =>
      apiCall({
        params: { propertyId },
        query: { priority, teams, actions, excludeActions, recurrence, rooms, areas },
      }),
    {
      enabled: isStandardTask && !!enabled,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
      select: (data) => data.map((t) => TaskMapper.toTaskInfoType(t, timeZone)),
      keepPreviousData: true,
    },
  );
}

export function useGetUnconfirmedTasks({
  propertyId,
  isStandardTask,
  timeZone,
  teams,
  actions,
  excludeActions,
  recurrence,
  rooms,
  areas,
  enabled = true,
}: {
  propertyId: string;
  isStandardTask: boolean;
  timeZone: string;
  teams?: string[];
  actions?: string[];
  excludeActions?: string[];
  recurrence?: string;
  rooms?: string[];
  areas?: string[];
  enabled?: boolean;
}) {
  const apiCall = useApiCall<TaskInfoTypeDto[]>(tasksApi.getUnconfirmedTasks);
  return useQuery(
    [ServerStateKey.UNCONFIRMED_TASKS, propertyId, teams, actions, excludeActions, recurrence, rooms, areas],
    () => apiCall({ params: { propertyId }, query: { teams, actions, excludeActions, recurrence, rooms, areas } }),
    {
      enabled: isStandardTask && !!enabled,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
      select: (data) => data.map((t) => TaskMapper.toTaskInfoType(t, timeZone)),
      keepPreviousData: true,
    },
  );
}

interface GetOneTaskProps {
  id: string;
  propertyId: string;
  enabled?: boolean;
}

export function useGetOneTask({ id, propertyId, enabled }: GetOneTaskProps) {
  const apiCall = useApiCall<TaskInfoTypeDto>(tasksApi.getOne);
  return useQuery<TaskInfoTypeDto, unknown, TaskInfoType>(
    [ServerStateKey.TASK, id],
    () => apiCall({ params: { id, propertyId } }),
    {
      enabled,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
      select: (data) => TaskMapper.toTaskInfoType(data, data.property.timeZone),
    },
  );
}

export function useGetGxTasksForReservation({
  propertyId,
  apaleoReservationId,
  type = TaskType.STANDARD,
  unitId,
  timeZone,
}: {
  propertyId: string;
  apaleoReservationId: string;
  type?: TaskType;
  unitId?: string;
  timeZone: string;
}) {
  const apiCall = useApiCall<TaskInfoTypeDto[]>(tasksApi.getAllByReservationExternalId);
  return useQuery<TaskInfoTypeDto[], unknown, TaskInfoType[]>(
    [ServerStateKey.RESERVATION_TASKS, type, apaleoReservationId],
    () => apiCall({ params: { propertyId, apaleoReservationId }, query: { type, unitId } }),
    {
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
      select: (data) => data.map((t) => TaskMapper.toTaskInfoType(t, timeZone)),
    },
  );
}

export function useGetReservationByExternalId(externalId: string, timeZone?: string, enabled = true) {
  const apiCall = useApiCall<ReservationDto>(reservationApi.getOneByExternalId);
  return useQuery<ReservationDto, unknown, Reservation>(
    [ServerStateKey.RESERVATION],
    () => apiCall({ params: { externalId } }),
    {
      enabled,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
      retry: false,
      select: (data) => ReservationMapper.toReservation(data, timeZone!),
    },
  );
}

export function useGetDaySettings(propertyId: string, dateOnly: string) {
  const apiCall = useApiCall<DaySettings>(propertiesApi.getDaySettings);
  return useQuery([ServerStateKey.DAY_SETTINGS], () => apiCall({ params: { propertyId }, query: { dateOnly } }), {
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
  });
}

export function useGetLateCheckoutsCount(propertyId: string, dateOnly: string) {
  const apiCall = useApiCall<{ count: number }>(reservationApi.countLateCheckoutsOnDay);
  return useQuery([ServerStateKey.LATE_CHECKOUTS_COUNT], () => apiCall({ query: { propertyId, dateOnly } }), {
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
  });
}

export function useUpdateReservation(
  externalId: string,
  options?: UseMutationOptions<UpdateReservationDto, unknown, unknown>,
) {
  const apiCall = useApiCall<UpdateReservationDto>(reservationApi.updateOne);
  return useMutation(
    ({ data: body }: { data: UpdateReservationDto }) =>
      apiCall({
        params: { externalId },
        body,
      }),
    options,
  );
}

export function useGetUnitDetails({
  unitId,
  propertyId,
  timeZone,
}: {
  unitId: string;
  propertyId: string;
  timeZone: string;
}) {
  const apiCall = useApiCall<UnitDetailsInfoTypeDto>(unitsApi.getDetails);
  return useQuery<UnitDetailsInfoTypeDto, unknown, UnitDetailsInfoType>(
    [ServerStateKey.UNIT_DETAILS, unitId],
    () => apiCall({ params: { propertyId, id: unitId } }),
    {
      select: (data) => ({
        ...data,
        hskDelayUntil: data.hskDelayUntil && utcToZonedTime(data.hskDelayUntil, timeZone),
        tasks: data?.tasks.map((t) => TaskMapper.toTaskInfoType(t, timeZone)),
        reservation: data.reservation && ReservationMapper.toUnitDetailsReservation(data.reservation, timeZone),
        previousReservation:
          data.previousReservation && ReservationMapper.toUnitDetailsReservation(data.previousReservation, timeZone),
      }),
    },
  );
}

export function useGetDailyViewUnits({ propertyId, timeZone }: { propertyId: string; timeZone: string }) {
  const apiCall = useApiCall<UnitInfoTypeDto[]>(unitsApi.getDailyViewUnits);
  return useQuery<UnitInfoTypeDto[], unknown, UnitInfoType[]>(
    [ServerStateKey.DAILY_VIEW_UNITS, propertyId],
    () => apiCall({ params: { propertyId } }),
    {
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
      select: (data) => data.map((u) => UnitMapper.toDailyUnit(u, timeZone)),
    },
  );
}

export function useGetTaskCategories({ locale, enabled }: { locale: AppLanguage; enabled: boolean }) {
  const apiCall = useApiCall<TaskCategoryRecord[]>(taskCategoriesApi.getAllByLocale);
  return useQuery<TaskCategoryRecord[], unknown, TaskAction[]>(
    [ServerStateKey.TASK_CATEGORIES, locale],
    () => apiCall({ params: { locale } }),
    {
      enabled,
      refetchInterval: false,
      select: TaskActionMapper.toTaskActions,
    },
  );
}

interface GetRoomRackProps {
  propertyId: string;
  timeZone: string;
  fromDate?: string;
  toDate?: string;
  enabled?: boolean;
}

export function useGetRoomRack({ propertyId, timeZone, fromDate, toDate, enabled = true }: GetRoomRackProps) {
  const apiCall = useApiCall<RoomRackDto>(roomRackApi.getRoomRack);
  return useQuery(
    [propertyId, fromDate, toDate].filter((v) => !!v),
    () =>
      apiCall({
        params: { propertyId },
        query: { fromDate, toDate },
      }),
    {
      enabled: !!enabled,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: false,
      select: (data) => data.map((t) => RoomRackMapper.toRoomRackType(t, timeZone)),
      keepPreviousData: true,
    },
  );
}
