import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskInfoType } from '@typings/types';
import IconWithText from '@molecules/IconWithText';
import icons from '@constants/icons';
import Button, { ButtonType } from '@atoms/Button';
import dateUtils, { formatDate, toDate } from '@utils/dateUtils';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import useToggleCompleteTask from '@utils/hooks/useToggleCompleteTask';
import Carousel from '@organisms/Carousel';
import { numberToTaskPriority } from '@utils/taskUtils';
import { formatDistance, startOfDay } from 'date-fns';
import { DamageReportActionRequired, NotificationType, ServerStateKey, TaskPriority } from '@typings/enums';
import useApiCall from '@utils/hooks/useApiCall';
import { useMutation, useQueryClient } from 'react-query';
import tasksApi from '@api/tasksApi';
import { useLocation } from 'react-router-dom';
import routes from '@constants/routes';
import useNotifications from '@utils/hooks/useNotifications';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import SelectDateWithHighlightedCheckout from '@organisms/SelectDateWithHighlightedCheckout';
import TaskActions from '@organisms/TaskActions';
import Dialog from '@molecules/Dialog';
import useAppContext from '@context/appContext';
import FullScreenDialog from '@molecules/FullScreenDialog';
import usePropertiesState from '@context/propertiesContext';

interface Props {
  task?: TaskInfoType;
  isCleanerOnly?: boolean;
  closeDialog?: () => void;
  onClickEdit?: (task: TaskInfoType) => void;
  onClickDelete?: (task: TaskInfoType) => void;
}

export default function DamageReportDetails({ task, closeDialog, isCleanerOnly, onClickEdit, onClickDelete }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { isOperationsRole } = useRoleBasedUI();
  const isOperations = task ? isOperationsRole(task?.propertyId) : false;

  const { showNotification } = useNotifications();

  const { isApaleoTab } = useAppContext();
  const { getTimeZone } = usePropertiesState();

  const unitId = task?.unit?.id;

  const startWorkingRequest = useApiCall(tasksApi.startWorking);
  const startWorkingMutation = useMutation(
    ({ propertyId, id }: { propertyId: string; id: string }) =>
      startWorkingRequest({
        params: { propertyId, id },
      }),
    {
      onSuccess: () => {
        if (location.pathname === routes.DAMAGE_REPORT) {
          queryClient.invalidateQueries([ServerStateKey.DAMAGE_REPORT_TASKS, task?.propertyId]);
        }
        if (location.pathname === routes.NOTIFICATIONS) {
          queryClient.invalidateQueries(ServerStateKey.NOTIFICATIONS);
        }
        if (unitId && location.pathname === routes.ROOT) {
          queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS, unitId]);
        }
        closeDialog?.();
      },
    },
  );

  const scheduleWorkingTimeRequest = useApiCall(tasksApi.scheduleWorkingTime);
  const scheduleWorkingTimeMutation = useMutation(
    ({ propertyId, id, date }: { propertyId: string; id: string; date: string }) =>
      scheduleWorkingTimeRequest({
        params: { propertyId, id },
        body: { date },
      }),
    {
      onSuccess: (data, variables) => {
        if (location.pathname === routes.DAMAGE_REPORT) {
          queryClient.invalidateQueries([ServerStateKey.DAMAGE_REPORT_TASKS, task?.propertyId]);
        }
        if (location.pathname === routes.NOTIFICATIONS) {
          queryClient.invalidateQueries(ServerStateKey.NOTIFICATIONS);
        }
        if (unitId && location.pathname === routes.ROOT) {
          queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS, unitId]);
        }
        showNotification(
          t('popupNotifications.damageReportWorkingTimeScheduledNotification', { date: formatDate(variables.date) }),
          NotificationType.SUCCESS,
        );
        closeDialog?.();
      },
    },
  );

  const toggleCompleteMutation = useToggleCompleteTask({ onSuccess: closeDialog });

  const onClickToggleComplete = useCallback(() => {
    if (!toggleCompleteMutation.isLoading && task) {
      toggleCompleteMutation.mutate({ task });
    }
  }, [toggleCompleteMutation, task]);

  const onClickStartWorking = useCallback(() => {
    if (!startWorkingMutation.isLoading && task) {
      startWorkingMutation.mutate({ propertyId: task.propertyId, id: task.id });
    }
  }, [startWorkingMutation, task]);

  const onClickScheduleWorkingTime = useCallback(
    (date?: Date) => {
      if (!scheduleWorkingTimeMutation.isLoading && task && date) {
        const timeZone = getTimeZone(task.propertyId);
        const utcDate = dateUtils.getDueAtUtc(date, timeZone);
        scheduleWorkingTimeMutation.mutate({ propertyId: task.propertyId, id: task.id, date: utcDate });
      }
    },
    [scheduleWorkingTimeMutation, task, getTimeZone],
  );

  const getActionRequired = useCallback(
    (actionRequired) => {
      if (!actionRequired) {
        return '-';
      }
      if (Object.values(DamageReportActionRequired).includes(actionRequired)) {
        return t(`damageReportActionsRequired.${actionRequired}`);
      }
      return actionRequired;
    },
    [t],
  );

  if (!task) return null;

  const priority = task.priority !== null ? numberToTaskPriority(task.priority) : null;

  const showInProgressLabel = task.workingTimeEntryId && !task.completedAt;
  const showScheduledLabel = task.isScheduled && !task.workingTimeEntryId && !task.completedAt;
  const showScheduleButton = !isApaleoTab && !task.workingTimeEntryId && !task.completedAt;
  const showStartNowButton = !isApaleoTab && !task.workingTimeEntryId && !task.completedAt;
  const showCompleteButton = !isApaleoTab && (isOperations || (task.workingTimeEntryId && !task.completedAt));

  return (
    <Dialog
      isOpen={!!task}
      onClose={() => closeDialog?.()}
      isMobileSheet
      {...(!isCleanerOnly
        ? {
            actionButton: (
              <TaskActions
                task={task}
                onClickEdit={() => {
                  onClickEdit?.(task);
                }}
                onClickDelete={() => {
                  onClickDelete?.(task);
                }}
              />
            ),
          }
        : {})}
    >
      <div className={'flex flex-col p-4 space-y-4 overflow-scroll'}>
        <div className={'flex flex-col items-center'}>
          <FullScreenDialog disabled={!task.imageUrls.length}>
            {({ className, onClick }) => <Carousel urls={task.imageUrls} className={className} onClick={onClick} />}
          </FullScreenDialog>
        </div>
        <div className={'flex flex-row p-4 rounded-lg bg-gray-50 space-x-5 overflow-hidden'}>
          {task.unit && <IconWithText Icon={icons.door} text={task.unit.number} />}
          {task.area && <IconWithText Icon={OfficeBuildingIcon} text={task.area.name} />}
          <IconWithText Icon={icons.emptyCalendar} text={formatDate(task.dueAt ?? task.createdAt!)} />
          <IconWithText Icon={icons.user} text={task.createdByName} className={'flex-1'} />
        </div>
        <div className={'flex text-th-secondary font-semibold whitespace-pre-wrap'}>
          {task.isStandardized ? t(`standardizedDamageReports.${task.title}`) : task.title}
        </div>
        <div className={'flex flex-1 flex-row justify-between'}>
          <div className={'text-gray-400'}>{t('property')}</div>
          <div className={'font-bold'}>{task.property.name}</div>
        </div>
        <div className={'flex flex-1 flex-row justify-between'}>
          <div className={'text-gray-400'}>{t('area')}</div>
          <div className={'font-bold'}>{task.details?.area ? t(`damageReportAreas.${task.details.area}`) : '-'}</div>
        </div>
        <div className={'flex flex-1 flex-row justify-between'}>
          <div className={'text-gray-400'}>{t(task.createdByGX ? 'damageReportedByGX' : 'actionRequired')}</div>
          <div className={'font-bold'}>{getActionRequired(task.details?.actionRequired)}</div>
        </div>
        <div className={'flex flex-1 flex-row justify-between'}>
          <div className={'text-gray-400'}>{t('priority')}</div>
          <div className={`font-bold ${priority === TaskPriority.HIGH ? 'text-red-400 bg-red-100 rounded px-2' : ''}`}>
            {priority ? t(`taskPriorities.${priority}`) : '-'}
          </div>
        </div>
        <div className={'flex flex-1 flex-row justify-between'}>
          <div className={'text-gray-400'}>{t('created')}</div>
          <div className={'font-bold'}>
            {formatDistance(toDate(task.createdAt), dateUtils.now(), { addSuffix: true })}
          </div>
        </div>
        {showInProgressLabel && (
          <div className={'flex flex-1 flex-row justify-end'}>
            <div className={'px-2 py-1 rounded bg-th-secondary text-white text-sm font-bold'}>
              {t('inProgress').toUpperCase()}
            </div>
          </div>
        )}
        {showScheduledLabel && (
          <div className={'flex flex-1 flex-row justify-between'}>
            <div className={'flex flex-1 flex-row justify-end'}>
              <div className={'px-2 py-1 rounded bg-th-secondary text-white text-sm font-bold'}>
                {t('scheduled').toUpperCase()}
              </div>
            </div>
          </div>
        )}
        {(showScheduleButton || showStartNowButton) && (
          <div className={'flex flex-1 flex-row space-x-2'}>
            {showScheduleButton && (
              <SelectDateWithHighlightedCheckout
                // due at or midnight today
                value={{ date: task.dueAt ? task.dueAt! : startOfDay(dateUtils.now()) }}
                onChange={(v) => onClickScheduleWorkingTime(v.date)}
                {...(task.unit
                  ? {
                      selectedUnit: {
                        id: task.unit.id,
                        propertyId: task.unit.propertyId,
                      },
                    }
                  : {})}
                actionButton={(onClickSelectDate) => (
                  <Button
                    isLoading={scheduleWorkingTimeMutation.isLoading}
                    type={ButtonType.OUTLINED}
                    onClick={onClickSelectDate}
                    className={'flex flex-1'}
                    disabled={startWorkingMutation.isLoading || toggleCompleteMutation.isLoading}
                  >
                    {t('scheduleTime')}
                  </Button>
                )}
                submitLabelKey={'select'}
              />
            )}
            <Button
              isLoading={startWorkingMutation.isLoading}
              type={ButtonType.PRIMARY}
              onClick={onClickStartWorking}
              className={'flex flex-1'}
              disabled={scheduleWorkingTimeMutation.isLoading || toggleCompleteMutation.isLoading}
            >
              {t('startNow')}
            </Button>
          </div>
        )}
        {showCompleteButton && (
          <Button
            isLoading={toggleCompleteMutation.isLoading}
            type={ButtonType.OUTLINED}
            onClick={onClickToggleComplete}
            disabled={scheduleWorkingTimeMutation.isLoading || startWorkingMutation.isLoading}
          >
            {task.completedAt ? t('markAsUnresolved') : t('markAsResolved')}
          </Button>
        )}
      </div>
    </Dialog>
  );
}
