import Navigation from '@organisms/Navigation';
import HomeLayout from '@templates/HomeLayout';
import HomeContent from '@organisms/HomeContent';
import usePropertiesState from '@context/propertiesContext';

export default function Home() {
  const { isLoading } = usePropertiesState();
  if (isLoading) {
    return null;
  }
  return (
    <div className={'bg-gray-50 flex flex-1 w-full'}>
      <HomeLayout navigation={<Navigation />} content={<HomeContent />} />
    </div>
  );
}
