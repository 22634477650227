import React from 'react';
import { ChevronUpIcon } from '@heroicons/react/outline';

interface Props {
  open: boolean;
  children: React.ReactNode;
}

function SectionHeader({ open, children }: Props) {
  return (
    <div className={'bg-gray-100 flex flex-row justify-between items-center py-3 px-4 rounded-t-lg'}>
      <div className={'font-serif font-bold text-xl text-th-secondary'}>{children}</div>
      <ChevronUpIcon className={`${open ? 'transform rotate-180' : ''} w-5 h-5`} />
    </div>
  );
}

export default SectionHeader;
