import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import useAuth from '@utils/hooks/useAuth';
import Popover from '@molecules/Popover';
import DailyCleaningReport from '@organisms/DailyCleaningReport';

const MIN_PERCENTAGE_FOR_LABEL = 25;

interface Props {
  propertyId: string;
  cleaned: number;
  toInspect: number;
  toClean: number;
}

export default function CleaningProgressBar({ propertyId, cleaned, toInspect, toClean }: Props) {
  const { t } = useTranslation();
  // hack to fix popover content dynamic height (rerender after daily report data is loaded)
  const [renderKey, setRenderKey] = useState(0);

  const { isOperations, isRoomChecker } = useAuth();

  const total = cleaned + toInspect + toClean;
  const cleanedPercentage = Number(((cleaned * 100) / total).toFixed(2));

  const CleanedSectionLabel = () => {
    if (cleaned === total) return <>{`${t('allRoomsCleaned')} (${cleaned})`}</>;
    if (cleanedPercentage > MIN_PERCENTAGE_FOR_LABEL) return <>{`${cleaned} ${t('cleaned').toLowerCase()}`}</>;
    return <>{cleaned}</>;
  };

  const renderCleanedSection = useCallback(
    (cleanedRounded: string) => {
      const showDailyCleaningReport = isOperations || isRoomChecker;
      const cleanedSection = (
        <div
          className={`flex justify-center items-center text-sm ${cleanedRounded} bg-green-600`}
          style={{ width: showDailyCleaningReport ? '100%' : `${cleanedPercentage}%` }}
        >
          <CleanedSectionLabel />
        </div>
      );
      if (showDailyCleaningReport) {
        return (
          <div className={'flex flex-row items-center'} style={{ width: `${cleanedPercentage}%` }}>
            <Popover
              className={'w-full text-black'}
              targetClasses={`w-full text-white ${cleanedRounded}`}
              target={cleanedSection}
              desktopCustomPosition={{ left: 'calc(50% - 96px)' }}
              content={<DailyCleaningReport propertyId={propertyId} onDataLoaded={() => setRenderKey(renderKey + 1)} />}
            />
          </div>
        );
      }
      return cleanedSection;
    },
    [cleaned, total, isOperations, isRoomChecker, propertyId, renderKey, cleanedPercentage],
  );

  if (total === 0) {
    return (
      <div className={'rounded-lg flex flex-row'}>
        <div className={'bg-gray-100 flex flex-1 text-gray-400 justify-center text-sm md:text-md'}>
          {t('noRoomsForCleaningToday')}
        </div>
      </div>
    );
  }

  const toInspectPercentage = Number(((toInspect * 100) / total).toFixed(2));
  const toCleanPercentage = Number(((toClean * 100) / total).toFixed(2));

  const cleanedIsOnly = !toInspect && !toClean;
  const inspectIsOnly = !cleaned && !toClean;
  const toCleanIsOnly = !cleaned && !toInspect;

  const cleanedClass = () => {
    if (cleanedIsOnly) return 'rounded-[4px]';
    else return 'rounded-l-[4px]';
  };

  const inspectClass = () => {
    if (inspectIsOnly) return 'rounded-[4px]';
    else if (!cleaned) return 'rounded-l-[4px]';
    else if (!toClean) return 'rounded-r-[4px]';
    else return '';
  };

  const toCleanClass = () => {
    if (toCleanIsOnly) return 'rounded-[4px]';
    else return 'rounded-r-[4px]';
  };

  return (
    <div
      className={
        'rounded-[4px] text-white flex flex-row min-h-[20px] h-[20px] font-sans font-normal text-sm leading-[18px]'
      }
    >
      {!!cleaned && renderCleanedSection(cleanedClass())}
      {!!toInspect && (
        <div
          className={`bg-th-yellow-300 
            flex justify-center items-center ${inspectClass()}`}
          style={{ width: `${toInspectPercentage}%` }}
        >
          {toInspect}
          {toInspectPercentage > MIN_PERCENTAGE_FOR_LABEL ? <>&nbsp;{t('toInspect')}</> : ''}
        </div>
      )}
      {!!toClean && (
        <div
          className={`bg-th-accent flex justify-center items-center ${toCleanClass()}`}
          style={{ width: `${toCleanPercentage}%` }}
        >
          {toClean}
          {toCleanPercentage > MIN_PERCENTAGE_FOR_LABEL ? <>&nbsp;{t('toClean')}</> : ''}
        </div>
      )}
    </div>
  );
}
