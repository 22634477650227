import React, { useMemo } from 'react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import SelectAll from '@molecules/SelectAll';
import { AssignedRoom } from '@typings/types';
import CollapsibleSection from '@molecules/CollapsibleSection';
import FloorAssignmentItem from './FloorAssignmentItem';

interface Props {
  value: AssignedRoom[];
  data: AssignedRoom[];
  floorNumber: string;
  onSelectAll: (floorNumber: string, deselect?: boolean) => void;
  onSelect: (value: AssignedRoom, deselect?: boolean) => void;
}

function FloorAssignmentCard({ value = [], floorNumber, data, onSelect, onSelectAll }: Props) {
  const { t } = useTranslation();
  const rooms = useMemo(() => data.map((d) => d.number), [data]);
  const allSelectedRooms = value.map((v) => v.number);
  const isFloorSelected = rooms.every((room) => allSelectedRooms.includes(room));
  return (
    <CollapsibleSection
      defaultOpen
      renderHeader={(open) => (
        <div className={'flex flex-row items-center justify-between py-3'}>
          <div className={'pl-4 flex flex-row'}>
            <div className={'font-bold pr-2'}>
              {t('floor')} {floorNumber}
            </div>
            <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} h-5 w-5`} />
          </div>
          <SelectAll
            id={`${floorNumber}:all`}
            countSelected={rooms.length}
            onSelect={() => {
              onSelectAll(floorNumber, isFloorSelected);
            }}
            checked={isFloorSelected}
          />
        </div>
      )}
    >
      {data.map((item, index) => {
        const isSelected = allSelectedRooms.includes(item.number);
        return <FloorAssignmentItem key={index} data={item} selected={isSelected} onSelect={onSelect} />;
      })}
    </CollapsibleSection>
  );
}

export default FloorAssignmentCard;
