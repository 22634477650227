import useApiCall from '@utils/hooks/useApiCall';
import unitsApi from '@api/unitsApi';
import { useMutation, useQueryClient } from 'react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { UnitDetailsInfoType, UnitInfoType } from '@typings/types';
import Dialog from '@molecules/Dialog';
import Button, { ButtonType } from '@atoms/Button';
import { useTranslation } from 'react-i18next';
import { NotificationType, ServerStateKey } from '@typings/enums';
import useNotifications from '@utils/hooks/useNotifications';
import dateUtils, { formatDate, TIME_FORMAT } from '@utils/dateUtils';
import Dropdown from '@molecules/Dropdown';

const hskDelayOptions = [
  { value: '15:15', label: '15:15' },
  { value: '15:30', label: '15:30' },
  { value: '15:45', label: '15:45' },
  { value: '16:00', label: '16:00' },
  { value: '16:15', label: '16:15' },
  { value: '16:30', label: '16:30' },
  { value: '16:45', label: '16:45' },
  { value: '17:00', label: '17:00' },
  { value: '17:15', label: '17:15' },
  { value: '17:30', label: '17:30' },
  { value: '17:45', label: '17:45' },
  { value: '18:00', label: '18:00' },
];

interface Params {
  onSuccess?: () => void;
}

function useHskDelay({ onSuccess }: Params) {
  const [unit, setUnit] = useState<UnitDetailsInfoType | UnitInfoType>();
  const [initialTime, setInitialTime] = useState<string>();
  const [time, setTime] = useState<string>();

  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { showNotification } = useNotifications();

  const updateUnitInDailyView = useCallback(() => {
    if (!unit || !time) return;
    const stateKey = [ServerStateKey.DAILY_VIEW_UNITS, unit.propertyId];
    queryClient.setQueryData(stateKey, (oldData?: UnitInfoType[]) =>
      oldData
        ? oldData.map((uu) =>
            uu.id === unit.id
              ? {
                  ...uu,
                  // eslint-disable-next-line import/no-named-as-default-member
                  hskDelayUntil: dateUtils.setTime(dateUtils.now(), time),
                }
              : uu,
          )
        : [],
    );
  }, [unit, time]);

  const onCloseConfirmationDialog = useCallback(() => {
    setUnit(undefined);
    setTime(undefined);
  }, []);

  const hskDelayRequest = useApiCall<void>(unitsApi.hskDelay);
  const hskDelayMutation = useMutation(
    (v: { propertyId: string; id: string; time: string }) =>
      hskDelayRequest({
        params: { propertyId: v.propertyId, id: v.id },
        body: { time: v.time },
      }),
    {
      onSuccess: (data, variables) => {
        showNotification(
          t('popupNotifications.hskDelayNotification', { number: unit?.number, time: variables.time }),
          NotificationType.SUCCESS,
        );
        updateUnitInDailyView();
        onCloseConfirmationDialog();
        onSuccess?.();
      },
    },
  );

  const hskDelay = useCallback(() => {
    if (hskDelayMutation.isLoading || !unit || !time) {
      return;
    }
    hskDelayMutation.mutate({
      id: unit?.id,
      propertyId: unit?.propertyId ?? (unit as UnitDetailsInfoType)?.property.id,
      time,
    });
  }, [hskDelayMutation, unit, time]);

  useEffect(() => {
    if (!unit || !hskDelayOptions || !hskDelayOptions.length) {
      return;
    }
    let date = (unit as UnitDetailsInfoType)?.reservation?.hskDelayUntil;
    if (date) {
      const hskDelayTime = formatDate(date, TIME_FORMAT);
      setTime(hskDelayTime);
      setInitialTime(hskDelayTime);
    }
    date = (unit as UnitInfoType)?.hskDelayUntil;
    if (date) {
      const hskDelayTime = formatDate(date, TIME_FORMAT);
      setTime(hskDelayTime);
      setInitialTime(hskDelayTime);
    } else {
      setTime(hskDelayOptions[0].value);
    }
  }, [unit, hskDelayOptions]);

  const renderHskDelayQuestionDialog = useCallback(() => {
    return (
      <Dialog
        isOpen={!!unit}
        onClose={() => setUnit(undefined)}
        title={t('hskDelay')}
        actionButton={
          <Button
            type={ButtonType.UNSTYLED}
            isLoading={hskDelayMutation.isLoading}
            onClick={() => {
              hskDelay();
            }}
            disabled={time === initialTime}
          >
            {t('confirm')}
          </Button>
        }
        sheetHeight={0.6}
        isMobileSheet
      >
        <div className={'flex flex-col items-center px-3 pb-3 md:w-80'}>
          <div className={'text-th-secondary text-center mt-6 mb-6'}>
            {t('hskDelayMessage', { number: unit?.number })}
          </div>
          <Dropdown
            value={time}
            items={hskDelayOptions}
            onChange={(value) => {
              setTime(value as string);
            }}
            className={'font-bold px-1 rounded bg-gray-200'}
          />
        </div>
      </Dialog>
    );
  }, [hskDelayMutation, unit, hskDelay, hskDelayOptions]);

  return {
    onClickHskDelay: setUnit,
    renderHskDelayQuestionDialog,
  };
}

export default useHskDelay;
