import { useTranslation } from 'react-i18next';

import { FILTER_TEAMS } from '@context/filtersTasksContext';
import { MultiSelectorModal } from '../Modals/MultiSelectorModal';

import { Team } from '@typings/enums';
import { MultiSelectorValues, MultiSelectorClick } from '../FilterTypes';

export const TeamsFilterModal = ({
  values,
  onClick,
  onConfirm,
}: {
  values: MultiSelectorValues;
  onClick: MultiSelectorClick;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();

  const teamsOptions = [
    { label: t(Team.HOUSE_KEEPING), value: Team.HOUSE_KEEPING },
    { label: t(Team.OPERATIONS), value: Team.OPERATIONS },
    { label: t(Team.MAINTENANCE), value: Team.MAINTENANCE },
    { label: t(Team.ICT), value: Team.ICT },
  ];

  return (
    <MultiSelectorModal
      id={FILTER_TEAMS}
      values={values}
      options={teamsOptions}
      onClick={onClick}
      onConfirm={onConfirm}
    />
  );
};
