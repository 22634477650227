import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Dialog from '@molecules/Dialog';
import dateUtils from '@utils/dateUtils';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { DateValue } from '@organisms/SelectDate/types';
import { useToggle } from '@utils/hooks/useToggle';
import CustomRepetition from '@organisms/CustomRepetition';
import { RepetitionOptions } from '@typings/types';
import { toRRuleObject } from '@utils/repetitionUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: DateValue;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (date: DateValue) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
  hideRepetition?: boolean;
  submitLabelKey?: string;
  highlightDates?: Date[];
}

function SelectDate(props: Props) {
  const {
    value,
    actionButton,
    onChange,
    minDate,
    maxDate,
    hideRepetition = false,
    submitLabelKey = 'add',
    highlightDates = [],
  } = props;
  const [innerValue, setInnerValue] = useState<DateValue | undefined>();
  const [dialogOpen, toggleDialogOpen] = useToggle(false);
  const { t } = useTranslation();

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const onChangeDate = useCallback(
    (date: Date | null) => {
      if (date instanceof Date) {
        setInnerValue({
          ...innerValue,
          date,
        });
      }
    },
    [innerValue],
  );

  const onClickAddButton = useCallback(() => {
    onChange?.(innerValue!);
    toggleDialogOpen();
  }, [innerValue, toggleDialogOpen]);

  const onChangeRecurrence = useCallback(
    (option?: RepetitionOptions) => {
      setInnerValue({
        ...innerValue,
        recurrence: option,
      });
    },
    [innerValue],
  );

  return (
    <>
      {actionButton(toggleDialogOpen)}
      <Dialog
        isOpen={dialogOpen}
        onClose={toggleDialogOpen}
        title={t('date')}
        isMobileSheet
        actionButton={
          <button className={'text-green-400 font-bold font-serif'} onClick={onClickAddButton}>
            {t(submitLabelKey)}
          </button>
        }
      >
        <div className={'flex flex-1 justify-center'}>
          <div className={'pt-5'}>
            <DatePicker
              autoComplete="off"
              selected={innerValue?.date}
              minDate={minDate}
              maxDate={maxDate}
              onChange={onChangeDate}
              highlightDates={[{ 'highlight-day': highlightDates }] || []}
              disabledKeyboardNavigation
              inline
            />
            {!hideRepetition && (
              <div className={'border-t border-gray-300'}>
                <CustomRepetition
                  value={innerValue?.recurrence}
                  onSelect={onChangeRecurrence}
                  actionButton={(onClickRepetitionTime) => {
                    return (
                      <div className="px-4 text-md py-2 flex flex-1 flex-col" onClick={onClickRepetitionTime}>
                        <div className={'flex flex-row justify-between items-center'}>
                          <span className={'text-gray-600'}>{t('repetitionTime')}</span>
                          <span className={'flex flex-row items-end'}>
                            <ChevronRightIcon className={'h-5 w-5'} />
                          </span>
                        </div>
                        <div className="text-sm text-gray-400">{toRRuleObject(innerValue)?.toText()}</div>
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}

SelectDate.defaultProps = {
  value: {
    date: dateUtils.now(),
  },
  minDate: dateUtils.now(),
};

export default SelectDate;
