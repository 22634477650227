import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskInfoType } from '@typings/types';
import IconWithText from '@molecules/IconWithText';
import icons from '@constants/icons';
import Button, { ButtonType } from '@atoms/Button';
import { formatDate } from '@utils/dateUtils';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import useToggleCompleteTask from '@utils/hooks/useToggleCompleteTask';
import Carousel from '@organisms/Carousel';
import TaskActions from '@organisms/TaskActions';
import Dialog from '@molecules/Dialog';
import FullScreenDialog from '@molecules/FullScreenDialog';

interface Props {
  task?: TaskInfoType;
  isCleanerOnly?: boolean;
  closeDialog?: () => void;
  onClickEdit?: (task: TaskInfoType) => void;
  onClickDelete?: (task: TaskInfoType) => void;
}

export default function LostAndFoundItemDetails({
  task,
  isCleanerOnly,
  closeDialog,
  onClickEdit,
  onClickDelete,
}: Props) {
  const { t } = useTranslation();

  const toggleCompleteMutation = useToggleCompleteTask({ onSuccess: closeDialog });

  const onClickToggleComplete = useCallback(() => {
    if (!toggleCompleteMutation.isLoading && task) {
      toggleCompleteMutation.mutate({ task });
    }
  }, [toggleCompleteMutation, task]);

  if (!task) return null;

  return (
    <Dialog
      isOpen={!!task}
      onClose={() => closeDialog?.()}
      isMobileSheet
      {...(!isCleanerOnly
        ? {
            actionButton: (
              <TaskActions
                task={task}
                onClickEdit={() => {
                  onClickEdit?.(task);
                }}
                onClickDelete={() => {
                  onClickDelete?.(task);
                }}
              />
            ),
          }
        : {})}
    >
      <div className={'flex flex-col p-4 space-y-4'}>
        <div className={'flex flex-col items-center'}>
          <FullScreenDialog disabled={!task.imageUrls.length}>
            {({ className, onClick }) => <Carousel urls={task.imageUrls} className={className} onClick={onClick} />}
          </FullScreenDialog>
        </div>
        <div className={'flex flex-row p-4 rounded-lg bg-gray-50 space-x-5 overflow-hidden'}>
          {task.unit && <IconWithText Icon={icons.door} text={task.unit.number} />}
          {task.area && <IconWithText Icon={OfficeBuildingIcon} text={task.area.name} />}
          <IconWithText Icon={icons.emptyCalendar} text={formatDate(task.createdAt)} />
          <IconWithText Icon={icons.user} text={task.createdByName} className={'flex-1'} />
        </div>
        <div className={'flex text-th-secondary font-semibold whitespace-pre-wrap'}>
          {task.isStandardized ? t(`standardizedDamageReports.${task.title}`) : task.title}
        </div>
        <Button
          isLoading={toggleCompleteMutation.isLoading}
          type={ButtonType.OUTLINED}
          onClick={onClickToggleComplete}
          withDebounce={false}
        >
          {task.completedAt ? t('markAsUnresolved') : t('markAsResolved')}
        </Button>
      </div>
    </Dialog>
  );
}
