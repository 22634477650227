import React from 'react';

type Props = {
  url?: string | null;
};

function ImagePreview({ url }: Props) {
  if (!url) {
    return <div className={`rounded-lg bg-gray-200 w-full h-full`} />;
  }
  return <img src={url} className={`object-contain rounded-lg w-full h-full`} />;
}

export default React.memo(ImagePreview);
