import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import { NotificationType } from '@typings/enums';

interface Props {
  close: () => void;
  content?: string;
  type?: NotificationType;
  onClick: () => void;
}

const NOTIFICATION_DURATION = 5000; // 5 seconds

const notificationColorByType = {
  [NotificationType.SUCCESS]: { background: 'bg-green-100', text: 'text-green-400' },
  [NotificationType.INFO]: { background: 'bg-white', text: 'text-th-secondary' },
  [NotificationType.ERROR]: { background: 'bg-red-100', text: 'text-th-accent' },
};

export default function Notification({ close, onClick, content, type = NotificationType.INFO }: Props) {
  const [show, setShow] = useState(true);
  const color = notificationColorByType[type];
  let timeoutRef: ReturnType<typeof setTimeout>;

  useEffect(() => {
    timeoutRef = setTimeout(() => {
      setShow(false);
      close();
    }, NOTIFICATION_DURATION);
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, []);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`max-w-sm w-full shadow-lg rounded-lg pointer-events-auto overflow-hidden cursor-pointer ${color.background}`}
        onClick={onClick}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <CheckCircleIcon className={`h-6 w-6 ${color.text}`} aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className={`font-medium ${color.text}`}>{content}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className={`rounded-md inline-flex focus:outline-none ${color.text}`}
                onClick={() => {
                  if (timeoutRef) {
                    clearTimeout(timeoutRef);
                  }
                  setShow(false);
                  close();
                }}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
