import { useTranslation } from 'react-i18next';

import { FILTER_PRIORITY } from '@context/filtersTasksContext';
import { SingleSelectorModal } from '../Modals/SingleSelectorModal';

import { TaskPriority } from '@typings/enums';
import { SingleSelectorValues, SingleSelectorClick } from '../FilterTypes';

export const PriorityFilterModal = ({
  values,
  onClick,
  onConfirm,
}: {
  values: SingleSelectorValues;
  onClick: SingleSelectorClick;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();

  const priorityOptions = [
    { label: `${t(`taskPriorities.HIGH`)} ${t(`priority`)}`, value: TaskPriority.HIGH },
    { label: `${t(`taskPriorities.LOW`)} ${t(`priority`)}`, value: TaskPriority.LOW },
  ];

  return (
    <SingleSelectorModal
      id={FILTER_PRIORITY}
      values={values}
      options={priorityOptions}
      onClick={onClick}
      onConfirm={onConfirm}
    />
  );
};
