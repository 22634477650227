import { useState } from 'react';
import { Popover } from '@headlessui/react';
import { ItemRendererProps } from '../types';
import { format, fromUnixTime } from 'date-fns';

export const Slot = ({ item, itemContext, getItemProps }: ItemRendererProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const checkInRawDate = fromUnixTime(item.start_time / 1000);
  const checkoutRawDate = fromUnixTime(item.end_time / 1000);

  const checkInDate = format(checkInRawDate, 'dd/MM HH:mm');
  const checkoutDate = format(checkoutRawDate, 'dd/MM HH:mm');

  const customStyle = {
    style: {
      ...item.itemProps.style,
      zIndex: isHovered ? 50 : 30,
    },
  };

  return (
    <div {...getItemProps(customStyle)}>
      <Popover className={`relative w-full`}>
        <Popover.Button
          style={{ width: '100%' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className={`flex overflow-hidden overflow-ellipsis whitespace-nowrap w-full font-sans`}
            style={{
              height: itemContext.dimensions.height,
            }}
          >
            {item.isEarlyCheckIn && <div className="w-4 h-full bg-black text-white text-center">E</div>}
            <span className="ml-2">{itemContext.title}</span>
          </div>
        </Popover.Button>

        {isHovered && (
          <Popover.Panel
            static
            className={`absolute z-50 bg-white 
              rounded shadow-sm px-4 py-2 mt-2 
              ml-2 flex flex-col justify-start 
              items-start gap-1 whitespace-nowrap text-xs
              font-normal leading-4 font-sans`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div>{item.title}</div>
            <div>OPS - Angie Wu</div>
            <div className="whitespace-nowrap">
              <span className="text-th-brown-100">From</span> ⋅ {checkInDate}
            </div>
            <div className="whitespace-nowrap">
              <span className="text-th-brown-100">To</span> ⋅ {checkoutDate}
            </div>
          </Popover.Panel>
        )}
      </Popover>
    </div>
  );
};
