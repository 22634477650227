/**
 * The same values as in `Member benefits -> Benefit type` in numa-website.
 * https://numa-website.admin.datocms.com/schema/item_types/JVXe5WqMS9y8MqYsyZM3Rg/fields/SPsWhmQUTR2fqb86IfKVoA/edit
 */
export enum MemberBenefitsType {
  LATE_CHECK_OUT = 'late-check-out',
  EARLY_CHECK_IN = 'early-check-in',
  TEN_DISCOUNT = '10-discount',
  FREE_SNACKS = 'free-snacks',
}
const MEMBER_BENEFITS_ACTIVATED_TYPES = [
  MemberBenefitsType.EARLY_CHECK_IN,
  MemberBenefitsType.LATE_CHECK_OUT,
  MemberBenefitsType.FREE_SNACKS,
];

export const isMemberBenefitsActivated = (propertyMemberBenefits: string[]): boolean => {
  return propertyMemberBenefits.some((mb) => MEMBER_BENEFITS_ACTIVATED_TYPES.includes(mb as MemberBenefitsType));
};
