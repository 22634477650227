import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Ventilatore del bagno rumoroso',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Luce non funzionante',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Il lavandino è bloccato',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Lo scarico della doccia è bloccato',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filtro da bagno',
  [StandardizedDamageReport.SINK]: 'Lavello',
  [StandardizedDamageReport.TOILET]: 'Gabinetto',
  [StandardizedDamageReport.SHOWER]: 'Doccia',
  [StandardizedDamageReport.STOVE]: 'Fornello',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Frigo/Minibar',
  [StandardizedDamageReport.DOOR]: 'Porta',
  [StandardizedDamageReport.WINDOW]: 'Finestra',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Lampadina',
  [StandardizedDamageReport.CHAIRS]: 'Sedie',
  [StandardizedDamageReport.HEATING]: 'Il riscaldamento',
  [StandardizedDamageReport.TV]: 'tv',
  [StandardizedDamageReport.TABLES]: 'Tabelle',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Team Housekeeping',
  [Team.MAINTENANCE]: 'Team Manutenzione',
  [Team.OPERATIONS]: 'Team Operations',
  [Team.ICT]: 'ICT',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Cucina',
  [DamageReportArea.BED]: 'Letto',
  [DamageReportArea.BATHROOM]: 'Bagno',
  [DamageReportArea.SEATING_AREA]: 'Salotto',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Pareti/Soffitto/Pavimento',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Pulire',
  [DamageReportActionRequired.REPLACE]: 'Sostituire',
  [DamageReportActionRequired.REPAIR]: 'Riparazione',
  [DamageReportActionRequired.CHECK]: "Dai un'occhiata",
  [DamageReportActionRequired.OTHER]: 'Altro',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Standard',
  [TaskPriority.HIGH]: 'Alto',
};

const popupNotifications = {
  taskDeletionNotification: 'Il task è stato cancellato con successo',
  lostAndFoundDeletionNotification: 'Oggetto Lost & found è stato cancellato con successo',
  damageReportDeletionNotification: 'Resoconto danni è stato cancellato con successo',
  taskCreationNotification: 'Task è stato creato con successo',
  lostAndFoundCreationNotification: 'Oggetto Lost & found è stato creato con successo',
  damageReportCreationNotification: 'Resoconto danni è stato creato con successo',
  taskCompletionNotification: 'Il task è stato completato con successo',
  lostAndFoundCompletionNotification: 'Oggetto Lost & found è stato risolto con successo',
  damageReportCompletionNotification: 'Resoconto danni è stato risolto con successo',
  taskUpdateNotification: 'Il task è stato aggiornato con successo',
  lostAndFoundUpdateNotification: 'Oggetto Lost & found è stato aggiornato con successo',
  damageReportUpdateNotification: 'Resoconto danni è stato aggiornato con successo',
  pleaseSelectRoomsNotification: 'Per favore seleziona camere',
  noShowReportNotification: 'No show comunicato con sucesso per la camera {{ number }}',
  checkInReportNotification: 'Check-in segnalato con successo per la camera {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Segnalazione danni programmata con successo per {{date}}',
  hskDelayNotification: "Ritardo HSK riuscito per la stanza {{number}}. L'orario del check-in è aggiornato a {{time}}",
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Più filtri',
  resetAllFilters: 'Ripristina tutti i filtri',
  reset: 'Ripristina',
  cleaned: 'Pulito',
  allRoomsCleaned: `Tutte le camere pulite`,
  dirty: 'Sporco',
  readyToClean: 'Pronto per essere pulito',
  stayoverReadyToClean: 'Fermata - pronto per essere pulito',
  occupiedCleaningToday: 'Occupato - da pulire',
  readyToInspect: 'Pronto per ispezione',
  stayoverInspection: 'Fermata da ispezionare',
  vacant: 'Vuoto',
  occupied: 'Occupato ',
  clean: 'Pulito',
  daily: 'Ogni giorno',
  weekly: 'Ogni settimana',
  biweekly: 'Ogni 2 settimane',
  monthly: 'Ogni mese',
  none: 'Nessuno',
  tasks: 'Task',
  notifications: 'Notifiche',
  more: 'Piú',
  select: 'Selezionare',
  property: 'Proprietá',
  result: 'Risultato',
  results: 'Risultati',
  properties: 'Proprietá',
  rooms: 'Camere',
  team: 'Team',
  dueDate: 'Scadenza',
  date: 'Data',
  close: 'Chiuso',
  search: 'Cerca',
  login: 'Login',
  loading: 'Caricamento...',
  anErrorHasOccurred: 'C´é stato un errore!',
  chooseDate: 'Scegliere una data',
  selectDate: 'Seleziona data',
  selectADate: 'Seleziona una data',
  markAsClean: 'Marcare come pulita',
  markAsReadyToInspect: 'Marcare come pronta per ispezione',
  viewTasks: 'Vedere i task',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Registro della camera`,
  taskLog: `Registro delle attività`,
  seeDetails: 'Vedere i dettagli',
  assignedTasks: 'Task assegnati',
  delete: 'Cancella',
  deleteTask: 'Cancella task',
  deleteTaskConfirmationMessage: 'Sei sicuro di voler cancellare il task?',
  deleteTaskRepeatsConfirmationMessage:
    'Questo è un task che si ripete. Puoi cancellare solo questo o anche i task ripetuti in futuro',
  deleteOnlyThisTask: 'Cancella solo questo task ',
  deleteRepeatingTask: 'Cancella task ripetuti ',
  yes: 'Si',
  no: 'No',
  pleaseWaitAFewSeconds: 'Per favore, aspetta qualche secondo',
  deleting: 'Cancello',
  lostAndFound: 'Lost and found',
  current: 'Current',
  archive: 'Archive',
  edit: 'Modifica',
  markAsResolved: 'Marcare come risolto',
  markAsUnresolved: 'Marcare come non risolto',
  add: 'Aggiungere',
  area: 'Area',
  addTask: 'Aggiungere task',
  editTask: 'Modificare task',
  seeTaskDetails: 'Vedere compito',
  savePicturesAndStartCleaning: 'Salva e inizia la pulizia',
  savePictures: 'Salva immagini',
  takePhoto: 'Scattare una foto',
  addArea: 'Aggiungere area',
  addAreaDescription: "Aggiungere un'area dell'hotel per questo task",
  selectProperty: 'Seleziona proprietà',
  selectRooms: 'Seleziona camere',
  selectTeam: 'Seleziona team',
  selectDueDate: 'Seleziona data',
  hskTeam: 'HSK Team',
  save: 'Salva',
  actionIsRequired: `È richiesta un'azione. Elimina il titolo e seleziona un'azione dall'elenco.`,
  noActionSelected: `Nessuna azione selezionata`,
  changeSearchAction: `Prova a cambiare il termine di ricerca`,
  noActionFound: `Nessuna azione trovata`,
  selectActionHelp: `Non trovi un'azione? Contatta il team Operations in modo che possano richiederla.`,
  writeToSelectAction: `Scrivi per selezionare un'azione`,
  writeTask: 'Scrivi task',
  repetition: 'Ripetizione',
  noRepetition: 'Nessuna ripetizione',
  repetitionTime: 'Tempo di ripetizione',
  doesNotRepeat: 'Non ripetere',
  day: 'Giorno',
  week: 'Settimana',
  month: 'Mese',
  repeatOn: 'Ripeti di ',
  repeatsEvery: 'Ripeti ogni',
  description: 'Descrizione',
  title: 'Titolo',
  addTitle: 'Aggiungi titolo',
  titleIsRequired: 'Il titolo è richiesto',
  titleMaxLength: 'Il titolo dovrebbe essere inferiore a {{maxLength}} caratteri.',
  teamIsRequired: 'Team richiesto',
  openTasks: ' Tasks aperti',
  arrival: 'Arrivo',
  departure: 'Partenza',
  checkIn: 'Check In',
  times: 'Orario',
  guests: 'Ospiti',
  name: 'Nome',
  additionalInfo: 'Info aggiuntive',
  addLostAndFound: 'Aggiungi oggetto perso (lost & found)',
  addDamageReport: 'Aggiungi resoconto danni',
  unitIsRequired: 'Richiestà unità ',
  addItem: 'Aggiungi oggetto/info',
  editItem: 'Modifica oggetto/info',
  startCleaning: 'Inizio pulizia',
  endCleaning: 'Fine pulizia',
  endCleaningMessage: "C'è 1 task aperto. Sei sicuro che vuoi segnare fine pulizia?",
  endCleaningMessage_plural: 'Ci sono {{count}} task aperti. Sei sicuro che vuoi segnare fine pulizia?',
  cancelAndViewTasks: 'Cancella e vedi i task',
  ignoreAndMarkAsReadyToInspect: 'Ignora e marca come pronto da ispezionare',
  ignoreAndMarkAsCleaned: 'Ignora e marca come pulita',
  damageReports: 'Rapporto danneggiamenti',
  confirm: 'Conferma',
  selectRoomOrArea: 'Seleziona area / camera o..',
  createNewOne: 'Crea nuovo',
  today: 'Oggi',
  tomorrow: 'Domani',
  noTasksMessage: 'Non sono stati aggiunti task ',
  cleaningStatus: 'Pulizia',
  logOut: 'Log out',
  language: 'Lingua ',
  selectLanguage: 'Seleziona lingua',
  isStandardized: 'É standard? ',
  selectStandardizedTask: 'Seleziona task standard',
  overdue: 'Scaduto',
  arrivals: 'Arrivi',
  memberArrivals: 'Member arrivals',
  departures: 'Partenze',
  stayovers: 'stay cleaning	Pulizia in fermata',
  occupancy: 'OTB Occupazione',
  midstayCleaning: 'Pulizia in fermata',
  roomsToSell: 'Camere da vendere',
  editImages: 'Modifica immagine',
  toClean: 'Da pulire',
  toInspect: 'Da ispezionare',
  noRoomsForCleaningToday: 'Non ci sono camere da pulire oggi',
  noShow: 'No show',
  noShowReportConfirmationMessage: 'Per favore, conferma che il numero di camera {{number}} sia no show.',
  noShowReportQuestion: "L'ospite ha fatto il check-in ieri?",
  checkInReportConfirmationMessage:
    "Conferma che l'ospite ha effettuato il check-in con il numero della camera {{number}}.",
  noResultsSearchMessage: 'Non ci sono risultati che corrispondano ai filtri selezionati',
  cancel: 'Cancella',
  selectCleaner: 'Scegli addetto alle pulizie',
  selectAll: 'Seleziona tutto',
  floor: 'Piano',
  notAssigned: 'Non assegnato',
  assignXRooms: 'Assegna {{total}} camere ',
  assignRooms: 'Assegna camere',
  approvedLCOs: 'LCO approvati',
  cleaner: 'addetto alle pulizie',
  roomsCleanForToday: 'Tutte le stanze sono pulite per oggi!🎉🎉',
  viewDamageReports: 'Visualizza i rapporti sui danni',
  noTasks: 'Nessun compito',
  noDamageReports: 'Nessuna segnalazione di danni',
  dailyCleaningReport: 'Rapporto di pulizia giornaliero',
  stayover: 'Fermarsi',
  roomMoveFrom: 'Spostamento stanza da {{value}}',
  roomMoveTo: 'Spostamento della stanza a {{value}}',
  unassignAll: 'Annulla assegnazione tutto',
  unassignRoomsConfirmationMessage: "Sei sicuro di voler annullare l'assegnazione di {{total}} stanze?",
  unassignRoomsTitle: 'Annulla assegnazione stanze',
  noRoomAssigned: 'Nessuna stanza assegnata',
  hideActions: 'Nascondere azioni',
  showActions: 'Mostra azioni',
  action: 'Azione',
  disableActionTitleModal: 'Ripristina il filtro {{name}}',
  disableActionDescriptionModal:
    'Per utilizzare il filtro {{currentFilter}}, si prega di reimpostare il filtro {{resetFilter}}.',
  actionsSelected: '{{total}} azione(i) selezionata(e)',
  actionRequired: 'Azione richiesta',
  priority: 'Priorità',
  lcoUntil: 'LCO fino a',
  created: 'Creato',
  startNow: 'Parti ora',
  scheduleTime: 'Orario',
  viewInfo: 'Visualizza informazioni',
  reminder: 'Promemoria',
  reminderTaskNotificationText: 'La tua attività pianificata è prevista per oggi',
  inProgress: 'In corso',
  scheduled: 'Programmato',
  moveMidstayCleaning: 'Spostare Stayover Pulizia?',
  moveMidstayTitle: 'Spostati a metà soggiorno',
  moveMidstayDescription: 'Sei sicuro di voler spostare la pulizia intermedia a {{date}}?',
  moveMidstayNotPossible: 'Scusa! La pulizia intermedia per questa stanza non può essere spostata a un altro giorno!',
  moveMidstayNotification: 'La pulizia Stayover è stata spostata con successo a {{date}}.',
  pleaseContactOps: 'Si prega di contattare il team operativo',
  midCleanMovedTo: 'Pulizia Stayover spostata a: {{value}}',
  optional: 'opzionale',
  hskDelay: 'Ritardo HSK',
  hskDelays: 'HSK ritarda',
  hskDelayMessage: "Aggiorna l'orario di check-in per la camera {{number}}",
  notStarted: 'Non iniziato',
  resolved: 'Risolto',
  noDamageReportsToday: 'Nessun danno per oggi',
  yourTask: 'Il tuo compito',
  yourTaskGX: 'Il tuo compito da GX',
  damageReportedByGX: 'Danno riportato da GX',
  stay: 'Date di soggiorno',
  checkOut: 'Orario di check-out',
  noPermission: 'Non hai il permesso, per favore contatta il supporto.',
  approvedCheckoutTime: 'Orario di check-out approvato',
  requestedCheckoutTime: 'Orario di check-out richiesto',
  checkinTime: 'Orario di check-in',
  commentForHousekeeping: 'Commento per il servizio di pulizia.',
  numberOfApprovedLCOs: 'Numero di LCO approvati',
  notSet: 'Non impostato',
  vip: 'VIP',
  extraService: 'Servizio extra',
  reservationMissing: 'La prenotazione è molto probabilmente cancellata, passa invece alla scheda Generale.',
  confirmFor: 'Conferma per il {{date}}',
  confirmAnotherDay: 'Conferma per un altro giorno',
  taskConfirmed: 'Hai confermato il compito per il {{date}}.',
  confirmYourTask: 'Conferma il tuo compito',
  confirmYourTaskGX: 'Conferma il tuo compito da GX',
  confirmHighPriorityTaskMessage:
    'Vuoi davvero impostare la data di scadenza per oggi? È probabile che il compito non venga confermato e completato oggi.',
  setTomorrow: 'Imposta per domani',
  keepToday: 'Mantieni per oggi',
  createTask: 'Creare compito',
  confirmationNeeded: 'Conferma necessaria',
  pictureTooBlurry: `L'immagine è troppo sfocata. Per favore scatta una foto nitida e caricala.`,
  tryAgain: `Prova di nuovo`,
};
