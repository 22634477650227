import Markdown from 'react-markdown';

interface MarkdownRendererProps {
  markdown: string;
}

export default function MarkdownRenderer({ markdown }: MarkdownRendererProps) {
  const components = {
    a: ({ ...props }) => <a {...props} className="underline" target="_blank" rel="noopener noreferrer" />,
  };

  return <Markdown components={components}>{markdown}</Markdown>;
}
