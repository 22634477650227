export default function DailyOverviewSectionPlaceholder({}) {
  return (
    <div className={'animate-pulse flex flex-col rounded-lg overflow-hidden flex-shrink-0 shadow-lg'}>
      <div className={'flex flex-row p-4 bg-gray-200 justify-between'}>
        <div className={'bg-gray-400 rounded w-1/3 h-4'} />
        <div className={'bg-gray-400 rounded w-1/5 h-4'} />
      </div>
      <div className={'flex flex-row bg-white'}>
        <div className={'flex flex-col flex-1 p-4 space-y-4'}>
          <div className={'flex flex-col space-y-2'}>
            <div className={'bg-gray-100 rounded-md w-1/2 h-3'} />
            <div className={'bg-gray-100 rounded-md w-1/4 h-3'} />
          </div>
          <div className={'flex flex-col space-y-2'}>
            <div className={'bg-gray-100 rounded-md w-1/2 h-3'} />
            <div className={'bg-gray-100 rounded-md w-1/4 h-3'} />
          </div>
          <div className={'flex flex-col space-y-2'}>
            <div className={'bg-gray-100 rounded-md w-1/2 h-3'} />
            <div className={'bg-gray-100 rounded-md w-1/4 h-3'} />
          </div>
        </div>
        <div className={'flex flex-col flex-1 p-4 space-y-4'}>
          <div className={'flex flex-col space-y-2'}>
            <div className={'bg-gray-100 rounded-md w-1/2 h-3'} />
            <div className={'bg-gray-100 rounded-md w-1/4 h-3'} />
          </div>
          <div className={'flex flex-col space-y-2'}>
            <div className={'bg-gray-100 rounded-md w-1/2 h-3'} />
            <div className={'bg-gray-100 rounded-md w-1/4 h-3'} />
          </div>
          <div className={'flex flex-col space-y-2'}>
            <div className={'bg-gray-100 rounded-md w-1/2 h-3'} />
            <div className={'bg-gray-100 rounded-md w-1/4 h-3'} />
          </div>
        </div>
      </div>
    </div>
  );
}
