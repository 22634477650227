import { useGetPropertyUnitsAndAreas } from '@api/api';
import usePropertiesState from '@context/propertiesContext';
import { FILTER_ROOMS } from '@context/filtersTasksContext';

import { MultiSelectorModal } from '../Modals/MultiSelectorModal';

import { RoomsFilterType } from '@typings/enums';
import { RoomOnClick, RoomValues, MultiSelectorClickArgs } from '../FilterTypes';

export const UnitsAndAreasFilterModal = ({
  values,
  onClick,
  onConfirm,
}: {
  values: RoomValues;
  onClick: RoomOnClick;
  onConfirm: () => void;
}) => {
  const { selectedProperty } = usePropertiesState();
  const { id: propertyId } = selectedProperty;
  const { data, isLoading, isError } = useGetPropertyUnitsAndAreas(propertyId, true);

  const unitOptions =
    data?.units.map((unit) => ({ label: unit.number, value: unit.id, type: RoomsFilterType.UNIT })) ?? [];
  const areaOptions =
    data?.areas.map((area) => ({ label: area.name, value: area.id, type: RoomsFilterType.AREA })) ?? [];

  const handleOnClick = (option: MultiSelectorClickArgs) => {
    const roomAndAreasSelected = option.value.map((selectedValue) => {
      const unitType = unitOptions.find((room) => room.value === selectedValue);
      if (unitType) {
        return { id: selectedValue, type: RoomsFilterType.UNIT };
      }
      return { id: selectedValue, type: RoomsFilterType.AREA };
    });

    onClick({ ...option, value: roomAndAreasSelected });
  };

  const normalizeValues =
    values.value?.map((value) => {
      return value.id;
    }) ?? null;

  return (
    <MultiSelectorModal
      id={FILTER_ROOMS}
      values={{ ...values, value: normalizeValues }}
      options={[...areaOptions, ...unitOptions]}
      isLoading={isLoading}
      isError={isError}
      onClick={handleOnClick}
      onConfirm={onConfirm}
    />
  );
};
