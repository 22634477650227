import { MouseEventHandler } from 'react';

interface Props {
  id: string;
  name: string;
  label?: string;
  onClick: MouseEventHandler<HTMLInputElement>;
  checked?: boolean;
  className?: string;
}

export default function Checkbox({ id, name, label, onClick, checked, className }: Props) {
  return (
    <div
      className={`relative flex items-start justify-between px-3 py-2 ${className ?? ''} ${
        checked ? 'bg-green-50' : ''
      }`}
    >
      {label && (
        <div className="flex-1 mr-3">
          <label htmlFor={id} className="flex cursor-pointer">
            {label}
          </label>
        </div>
      )}
      <div className="flex items-center h-5">
        <input
          autoComplete="off"
          readOnly
          id={id}
          name={name}
          type="checkbox"
          className={'h-5 w-5 focus:ring-0 text-th-primary border-gray-300 rounded cursor-pointer'}
          onClick={onClick}
          checked={checked}
        />
      </div>
    </div>
  );
}
