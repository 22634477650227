import useApiCall from '@utils/hooks/useApiCall';
import unitsApi from '@api/unitsApi';
import { useMutation, useQueryClient } from 'react-query';
import { useCallback, useState } from 'react';
import Dialog from '@molecules/Dialog';
import { UnitDetailsInfoType, UnitInfoType } from '@typings/types';
import Button, { ButtonType } from '@atoms/Button';
import { ServerStateKey, UnitStatusAction } from '@typings/enums';
import useAuth from '@utils/hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface Params {
  queriesToInvalidate: ServerStateKey[];
  onSuccess?: () => void;
  onMarkCleanCancel?: () => void;
}

function useEndCleaning({ queriesToInvalidate, onSuccess, onMarkCleanCancel }: Params) {
  const queryClient = useQueryClient();
  const [endCleaningUnit, setEndCleaningUnit] = useState<UnitDetailsInfoType | UnitInfoType>();
  const { isRoomChecker, isOperations } = useAuth();

  const { t } = useTranslation();

  const endCleaningRequest = useApiCall(unitsApi.endCleaning);
  const endCleaningMutation = useMutation(
    ({ propertyId: propId, id }: { propertyId: string; id: string }) =>
      endCleaningRequest({
        params: { propertyId: propId, id },
        body: {
          action: isRoomChecker ? UnitStatusAction.CLEAN : UnitStatusAction.TO_INSPECT,
        },
      }),
    {
      onSuccess: () => {
        setEndCleaningUnit(undefined);
        queriesToInvalidate.forEach((key) => queryClient.invalidateQueries(key));
        onSuccess?.();
      },
    },
  );

  const endCleaning = useCallback(
    (unit: UnitDetailsInfoType | UnitInfoType) => {
      if (endCleaningMutation.isLoading) {
        return;
      }
      endCleaningMutation.mutate({
        id: unit?.id,
        propertyId: unit?.propertyId ?? (unit as UnitDetailsInfoType)?.property.id,
      });
    },
    [endCleaningMutation],
  );

  const onClickEndCleaning = useCallback(
    (unit: UnitDetailsInfoType | UnitInfoType) => {
      return () => {
        const openTasks =
          (unit as UnitInfoType).tasksCount ??
          (unit as UnitDetailsInfoType).tasks.filter((task) => !task.completedAt).length;
        if (!openTasks) {
          endCleaning(unit);
        } else {
          setEndCleaningUnit(unit);
        }
      };
    },
    [endCleaning],
  );

  const renderEndCleaningConfirmationDialog = useCallback(() => {
    const openTasks =
      (endCleaningUnit as UnitInfoType)?.tasksCount ??
      (endCleaningUnit as UnitDetailsInfoType)?.tasks.filter((task) => !task.completedAt).length;
    return (
      <Dialog isOpen={!!endCleaningUnit} onClose={() => setEndCleaningUnit(undefined)} isMobileSheet>
        <div className={'flex flex-col items-center px-3'}>
          <div className={'text-th-brown font-serif text-lg font-bold mb-2'}>{t('endCleaning')}</div>
          <div className={'text-th-secondary text-center mb-6'}>
            {t(openTasks === 1 ? 'endCleaningMessage' : 'endCleaningMessage_plural', { count: openTasks })}
          </div>
          <Button
            className={'w-full'}
            onClick={() => {
              setEndCleaningUnit(undefined);
              onMarkCleanCancel?.();
            }}
          >
            {t('cancelAndViewTasks')}
          </Button>
          <Button
            isLoading={endCleaningMutation.isLoading}
            type={ButtonType.SECONDARY}
            className={'my-1 py-2 w-full text-gray-400'}
            onClick={() => endCleaningUnit && endCleaning(endCleaningUnit)}
          >
            {isRoomChecker || isOperations ? t('ignoreAndMarkAsCleaned') : t('ignoreAndMarkAsReadyToInspect')}
          </Button>
        </div>
      </Dialog>
    );
  }, [endCleaningMutation, endCleaningUnit, endCleaning]);

  return {
    isLoading: endCleaningMutation.isLoading,
    onClickEndCleaning,
    renderEndCleaningConfirmationDialog,
  };
}

export default useEndCleaning;
