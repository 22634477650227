import React, { MouseEventHandler, useMemo } from 'react';
import SelectionDialog from '@molecules/SelectionDialog';
import { useTranslation } from 'react-i18next';
import { TaskPriority } from '@typings/enums';

interface Props {
  value?: TaskPriority;
  onSelect: (item: TaskPriority) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
}

export default function SelectPriority({ value, onSelect, actionButton }: Props) {
  const { t } = useTranslation();

  const items = useMemo(
    () => ({
      [TaskPriority.LOW]: { value: TaskPriority.LOW, label: t(`taskPriorities.${TaskPriority.LOW}`) },
      [TaskPriority.HIGH]: { value: TaskPriority.HIGH, label: t(`taskPriorities.${TaskPriority.HIGH}`) },
    }),
    [t],
  );

  const selectedItem = value && items[value];

  return (
    <SelectionDialog
      title={`${t('select')} ${t('priority').toLowerCase()}`}
      value={selectedItem}
      onSelect={(item) => onSelect(item?.value)}
      actionButton={actionButton}
      options={Object.values(items)}
      includeSearch={false}
    />
  );
}
