export function isMobileSafari() {
  return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
}

export function isPwa() {
  return window.matchMedia('(display-mode: standalone)').matches;
}

export const IS_PWA = isPwa();
export const IS_PWA_IOS_MOBILE = IS_PWA && isMobileSafari();

function getDeviceType() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows_phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'desktop';
}

export const deviceType = getDeviceType();
