import env from '@constants/env';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (env.isProd) {
  Sentry.init({
    dsn: env.sentryDsn,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
  });
}
