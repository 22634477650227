import { Fragment, useCallback } from 'react';
import { Transition } from '@headlessui/react';

import { useFuzzySearch } from '@utils/hooks/useFuzzySearch';
import icon from '@constants/icons';

import { AutocompleteOption } from './types';
import { useTranslation } from 'react-i18next';

const SHOW_OPTIONS_MIN_CHARS = 2;
const SHOW_ALL_OPTIONS_MIN_LENGTH = 30;

interface Props<T> {
  visible: boolean;
  value?: string;
  data: AutocompleteOption<T>[];
  onSelectOption: (value: AutocompleteOption<T>) => void;
}

type OptionsItemProps = {
  title: string;
  subTitle?: string;
};

const OptionsItem = ({ title, subTitle }: OptionsItemProps) => (
  <div className={`flex w-full items-center p-4 pb-2 border-b border-gray-200`}>
    <div className="flex flex-col gap-y-2">
      <div className="text-black text-base font-semibold">{title}</div>
      {subTitle && <div className="flex flex-1 text-th-secondary text-sm font-normal opacity-70">{subTitle}</div>}
    </div>
  </div>
);

function AutocompleteOptions<T>({ value, data, visible, onSelectOption }: Props<T>) {
  const { t } = useTranslation();
  const { getSearchResults } = useFuzzySearch<T>(data);

  const getOptions = useCallback(() => {
    if (data.length < SHOW_ALL_OPTIONS_MIN_LENGTH) {
      return data;
    }
    // do not show options if > char
    if (!value || value.length < SHOW_OPTIONS_MIN_CHARS) {
      return [];
    }
    return getSearchResults(value);
  }, [value, getSearchResults, data]);

  const options = getOptions();

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <Transition
      show={visible}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        onMouseDown={handleMouseDown}
        className={'absolute z-10 overflow-auto bg-white rounded-md max-h-60 focus:outline-none'}
        style={{
          top: '100px',
          width: 'calc(100% - 30px)',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        {data && options.length === 0 && value && (
          <div className="flex flex-1 cursor-pointer font-sans">
            <OptionsItem title={t('noActionFound')} subTitle={t('changeSearchAction')} />
          </div>
        )}

        {options.map((option, index) => (
          <div
            key={index}
            onClick={() => onSelectOption(option)}
            className="flex flex-1 px-1 cursor-pointer font-sans hover:bg-th-gray-100"
          >
            <OptionsItem title={option.title} subTitle={option.helperText} />
          </div>
        ))}

        {data && options && value && (
          <div className="bg-th-gray-100 text-th-brown flex items-start p-[10px] gap-x-1 font-sans">
            <div>
              <icon.info />
            </div>
            <div className="text-xs">{t('selectActionHelp')}</div>
          </div>
        )}
      </div>
    </Transition>
  );
}

export default AutocompleteOptions;
