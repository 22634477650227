import { Transition as HeadlessTransition } from '@headlessui/react';
import React from 'react';

interface Props {
  show?: boolean;
  className?: string;
  children: React.ReactNode;
}

function Transition({ show, className, children }: Props) {
  return (
    <HeadlessTransition
      className={className}
      show={show}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      {children}
    </HeadlessTransition>
  );
}

export default Transition;
