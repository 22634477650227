import { Reservation, ReservationDto, UnitDetailsReservation, UnitDetailsReservationDto } from '@typings/types';
import { utcToZonedTime } from 'date-fns-tz';

export class ReservationMapper {
  static toReservation(dto: ReservationDto, timeZone: string): Reservation {
    const checkIn = utcToZonedTime(dto.checkIn, timeZone);
    const requestedCheckOut = utcToZonedTime(dto.requestedCheckOut, timeZone);
    const approvedCheckOut = utcToZonedTime(dto.approvedCheckOut, timeZone);
    const checkedInAt = dto.checkedInAt ? utcToZonedTime(dto.checkedInAt, timeZone) : null;
    const checkedOutAt = dto.checkedOutAt ? utcToZonedTime(dto.checkedOutAt, timeZone) : null;
    const midClean = dto.midClean
      ? {
          ...dto.midClean,
          date: dto.midClean.date ? utcToZonedTime(dto.midClean.date, timeZone) : undefined,
          lastClean: dto.midClean.lastClean ? utcToZonedTime(dto.midClean.lastClean, timeZone) : undefined,
        }
      : undefined;

    const utcTime = {
      timeZone,
      checkIn: dto.checkIn,
      requestedCheckOut: dto.requestedCheckOut,
      approvedCheckOut: dto.approvedCheckOut,
      checkedInAt: dto.checkedInAt,
      checkedOutAt: dto.checkedOutAt,
    };
    return {
      ...dto,
      checkIn,
      requestedCheckOut,
      approvedCheckOut,
      checkedInAt,
      checkedOutAt,
      utcTime,
      midClean,
    };
  }

  static toUnitDetailsReservation(dto: UnitDetailsReservationDto, timeZone: string): UnitDetailsReservation {
    const arrival = utcToZonedTime(dto.arrival, timeZone);
    const departure = utcToZonedTime(dto.departure, timeZone);
    const hskDelayUntil = dto.hskDelayUntil ? utcToZonedTime(dto.hskDelayUntil, timeZone) : null;
    const midCleanMovedTo = dto.midCleanMovedTo ? utcToZonedTime(dto.midCleanMovedTo, timeZone) : null;

    const utcTime = {
      timeZone,
      arrival: dto.arrival,
      departure: dto.departure,
      hskDelayUntil: dto.hskDelayUntil,
    };

    return {
      ...dto,
      arrival,
      midCleanMovedTo,
      departure,
      hskDelayUntil,
      utcTime,
    };
  }
}
