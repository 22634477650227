import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useAuth0 } from '@auth0/auth0-react';
import Button, { ButtonType } from '@atoms/Button';
import SelectLanguage from '@organisms/SelectLanguage';
import storageService from '@utils/storageService';
import NotificationSettings from '@organisms/NotificationSettings';
import { fireWebViewEvent, WebViewEventType } from '@utils/mobileAppService';
import Header from '@organisms/Header';
import { useChat } from '@utils/hooks';
import ToggleNotificationSettings from '@organisms/ToggleNotificationSettings';
import useMessagingService from '@utils/hooks/useMessagingService';

export default function Settings() {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const { onLogoutChat } = useChat();
  const { t } = useTranslation();

  const history = useHistory();
  const { disableMessaging } = useMessagingService();

  const onBack = useCallback(() => {
    history.goBack();
  }, []);

  const onLogout = useCallback(async () => {
    onLogoutChat();
    await disableMessaging();
    const accessToken = await getAccessTokenSilently();
    if (storageService.isMobileApp()) {
      fireWebViewEvent({ type: WebViewEventType.ON_LOGOUT, payload: { accessToken } });
    }
    logout({ returnTo: window.location.origin });
  }, [getAccessTokenSilently, onLogoutChat]);

  return (
    <div className={'bg-gray-50 flex flex-1 w-full'}>
      <div className="max-w-3xl mx-auto flex flex-col flex-1 w-full">
        <div className={'mb-5'}>
          <Header />
        </div>
        <div className={'flex flex-col p-4'}>
          <div className="flex flex-col">
            <div className={'flex px-3 pb-6'}>
              <ChevronLeftIcon className={'w-5 h-5 cursor-pointer text-th-secondary'} onClick={onBack} />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-gray-100 rounded-full w-24 h-24 mb-4" />
            <span className="text-xl text-th-brown font-bold">{user?.name}</span>
          </div>
        </div>
        <div className={'bg-white flex flex-1 flex-col w-full px-4 pb-4 rounded-t-lg'}>
          <div className="flex flex-1 flex-col py-6 gap-y-6">
            <SelectLanguage />
            <NotificationSettings />
            <ToggleNotificationSettings />
          </div>
          <Button onClick={onLogout} type={ButtonType.OUTLINED}>
            {t('logOut')}
          </Button>
        </div>
      </div>
    </div>
  );
}
