import { InformationCircleIcon } from '@heroicons/react/outline';

export const ErrorInfo = ({ message }: { message: string }) => {
  return (
    <div className={'flex justify-start items-start bg-th-red-200 rounded-lg px-4 py-2 font-sans gap-x-2'}>
      <InformationCircleIcon width={16} height={16} className="text-black flex-shrink-0" />
      <div className={'text-th-red-300 text-xs font-normal'}>{message}</div>
    </div>
  );
};
