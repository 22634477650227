import { NavigationItemType } from '@typings/types';

interface Props {
  item: NavigationItemType;
}

export default function NavigationItem({ item: { name, Icon, onClick, active, count } }: Props) {
  return (
    <>
      <div
        className={`${
          active ? 'text-th-accent' : 'text-gray-600 hover:text-th-accent hover:opacity-50'
        } group items-center hidden md:flex cursor-pointer`}
        onClick={onClick}
      >
        <Icon
          className={`${
            active ? 'text-th-accent' : 'text-gray-600 group-hover:text-th-accent'
          } flex-shrink-0 mr-2 h-5 w-5 stroke-current`}
          aria-hidden="true"
        />
        <span className="truncate">{name}</span>
        {count ? (
          <span
            className={
              'ml-2 bg-th-accent text-white inline-block w-5 h-5 flex justify-center items-center text-xs rounded-full font-bold leading-none flex-shrink-0'
            }
          >
            {count}
          </span>
        ) : null}
      </div>
      <a
        className={`${
          active ? 'text-th-accent' : 'text-gray-600'
        } group flex flex-1 flex-col items-center text-tiny px-2 sm:px-5 py-3 md:hidden`}
        aria-current={active ? 'page' : undefined}
        onClick={onClick}
      >
        <div className={'relative'}>
          <Icon
            className={`${active ? 'text-th-accent' : 'text-gray-600'} flex h-6 w-6 pb-1 stroke-current`}
            aria-hidden="true"
          />
          {count ? (
            <span
              className={
                'ml-1 bg-th-accent text-white inline-block w-4 h-4 flex justify-center items-center text-tiny rounded-full absolute -top-2 -right-2 ring-2 ring-white font-bold leading-none flex-shrink-0'
              }
            >
              {count}
            </span>
          ) : null}
        </div>
        <div className={'flex leading-none'}>
          <span className="truncate">{name}</span>
        </div>
      </a>
    </>
  );
}
