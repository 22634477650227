import React, { MouseEventHandler } from 'react';
import { PropertyType } from '@typings/types';
import { toSelectOption } from '@molecules/SelectionDialog';
import { mapArrayToObjectById } from '@utils/arrayUtils';
import { useTranslation } from 'react-i18next';
import MultiSelectionDialog from '@molecules/MultiSelectionDialog';
import usePropertiesState from '@context/propertiesContext';

interface Props {
  value: PropertyType[];
  inverse?: boolean;
  onSelect: (property: PropertyType[]) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
  isToggle?: boolean;
}

export default function SelectProperties(props: Props) {
  const { value, onSelect, actionButton, isToggle = false, inverse = false } = props;

  const { t } = useTranslation();

  const { data, error } = usePropertiesState();

  const propertiesById = mapArrayToObjectById(data || []);
  const properties = data.map(toSelectOption);

  return (
    <MultiSelectionDialog
      error={error}
      title={t('property')}
      value={value.map(toSelectOption)}
      onSelect={(options) => onSelect(options.map((o) => propertiesById[o?.value]))}
      actionButton={actionButton}
      options={properties}
      isToggle={isToggle}
      inverse={inverse}
    />
  );
}
