// todo change
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCZeDTom-CMlZp7xRijsJzfxm7nek_MXD8',
  authDomain: 'shine-3a39e.firebaseapp.com',
  projectId: 'shine-3a39e',
  storageBucket: 'shine-3a39e.appspot.com',
  messagingSenderId: '613992555168',
  appId: '1:613992555168:web:c9f881afd6ae767a4b9fc5',
  measurementId: 'G-HT34BV0R2K',
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
