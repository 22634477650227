import { useState } from 'react';

import SelectDateRange from '@organisms/SelectDateRange';
import { ConfirmButton } from '../Commons/ConfirmButton';
import { DATE_FORMAT_SHORT, formatDate } from '@utils/dateUtils';

import { FiltersParamsType } from '@context/filtersTasksContext';
import { CalendarValues, CalendarClick } from '../FilterTypes';

interface CalendarModalProps {
  id: keyof FiltersParamsType;
  values: CalendarValues;
  onClick: CalendarClick;
  onConfirm: () => void;
}

export const CalendarModal = ({ id, values, onClick, onConfirm }: CalendarModalProps) => {
  const [startDate, setStartDate] = useState<Date | null>(values?.value?.[0] ?? null);
  const [endDate, setEndDate] = useState<Date | null>(values?.value?.[1] ?? null);

  const handleDateChange = (start: Date | null, end: Date | null) => {
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      const dueStartDateLabel = formatDate(start, DATE_FORMAT_SHORT);
      const dueEndDateLabel = formatDate(end, DATE_FORMAT_SHORT);
      if (start.toDateString() === end.toDateString()) {
        onClick({ id, label: dueStartDateLabel, value: [start, end] });
      } else {
        onClick({ id, label: `${dueStartDateLabel} - ${dueEndDateLabel}`, value: [start, end] });
      }
    }
  };

  return (
    <div className="flex flex-col justify-between items-stretch h-full">
      <div className="pt-2 flex justify-center items-center px-2">
        <SelectDateRange onChange={handleDateChange} startDate={startDate} endDate={endDate} />
      </div>
      <div className="py-4 px-5 border-t border-t-th-gray-250">
        <ConfirmButton isDisabled={!startDate || !endDate} onConfirm={onConfirm} />
      </div>
    </div>
  );
};
