import { ApiInfo } from '@api/types';
import { TaskPriority, TaskType } from '@typings/enums';

const basePath = (propertyId: string) => `/${propertyId}/tasks`;

const tasksApi: ApiInfo = {
  saveAll: ({ params, body }) => {
    const { propertyId } = params!;
    return {
      url: basePath(propertyId),
      method: 'post',
      body,
    };
  },
  updateOne: ({ params, body }) => {
    const { id, propertyId } = params!;
    return {
      url: `${basePath(propertyId)}/${id}`,
      method: 'put',
      body,
    };
  },
  toggleComplete: ({ params }) => {
    const { propertyId, id } = params!;
    return {
      url: `${basePath(propertyId)}/${id}/toggle-complete`,
      method: 'post',
    };
  },
  deleteOne: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `${basePath(propertyId)}/${id}`,
      method: 'delete',
      body,
    };
  },
  getTasks: ({ params, query }) => {
    const { propertyId } = params!;
    const { type, fromDate, toDate, priority, teams, actions, excludeActions, recurrence, rooms, areas } = query! as {
      type: TaskType;
      fromDate?: string;
      toDate?: string;
      priority?: TaskPriority;
      teams?: string[];
      actions?: string[];
      excludeActions?: string[];
      recurrence?: string;
      rooms?: string[];
      areas?: string[];
    };

    const queryParams = new URLSearchParams();

    if (type) queryParams.append('type', type);
    if (fromDate) queryParams.append('fromDate', fromDate);
    if (toDate) queryParams.append('toDate', toDate);
    if (priority) queryParams.append('priority', priority);
    if (teams) queryParams.append('teams', teams.join(','));
    if (actions) queryParams.append('actions', actions.join(','));
    if (excludeActions) queryParams.append('excludeActions', excludeActions.join(','));
    if (recurrence) queryParams.append('recurrence', recurrence);
    if (rooms) queryParams.append('rooms', rooms.join(','));
    if (areas) queryParams.append('areas', areas.join(','));

    return {
      url: `${basePath(propertyId)}?${queryParams.toString()}`,
    };
  },
  getAllByReservationExternalId: ({ params, query }) => {
    const { propertyId, apaleoReservationId } = params!;
    const { type, unitId } = query!;
    return {
      url: `${basePath(propertyId)}/reservations/${apaleoReservationId}?type=${type}${
        unitId ? `&unitId=${unitId}` : ''
      }`,
    };
  },
  getOverdueTasks: ({ params, query }) => {
    const { propertyId } = params!;
    const { priority, teams, actions, excludeActions, recurrence, rooms, areas } = query! as {
      priority?: TaskPriority;
      teams?: string[];
      actions?: string[];
      excludeActions?: string[];
      recurrence?: string;
      rooms?: string[];
      areas?: string[];
    };

    const queryParams = new URLSearchParams();

    if (priority) queryParams.append('priority', priority);
    if (teams) queryParams.append('teams', teams.join(','));
    if (actions) queryParams.append('actions', actions.join(','));
    if (excludeActions) queryParams.append('excludeActions', excludeActions.join(','));
    if (recurrence) queryParams.append('recurrence', recurrence);
    if (rooms) queryParams.append('rooms', rooms.join(','));
    if (areas) queryParams.append('areas', areas.join(','));

    return {
      url: `${basePath(propertyId)}/overdue?${queryParams.toString()}`,
    };
  },
  getUnconfirmedTasks: ({ params, query }) => {
    const { propertyId } = params!;
    const { teams, actions, excludeActions, recurrence, rooms, areas } = query! as {
      teams?: string[];
      actions?: string[];
      excludeActions?: string[];
      recurrence?: string;
      rooms?: string[];
      areas?: string[];
    };

    const queryParams = new URLSearchParams();

    if (teams) queryParams.append('teams', teams.join(','));
    if (actions) queryParams.append('actions', actions.join(','));
    if (excludeActions) queryParams.append('excludeActions', excludeActions.join(','));
    if (recurrence) queryParams.append('recurrence', recurrence);
    if (rooms) queryParams.append('rooms', rooms.join(','));
    if (areas) queryParams.append('areas', areas.join(','));

    return {
      url: `${basePath(propertyId)}/unconfirmed?${queryParams.toString()}`,
    };
  },
  startWorking: ({ params }) => {
    const { propertyId, id } = params!;
    return {
      url: `${basePath(propertyId)}/${id}/start-working`,
      method: 'post',
    };
  },
  scheduleWorkingTime: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `${basePath(propertyId)}/${id}/schedule-time`,
      method: 'post',
      body,
    };
  },
  confirmDate: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `${basePath(propertyId)}/${id}/confirm`,
      method: 'post',
      body,
    };
  },
  getOne: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `${basePath(propertyId)}/${id}`,
      method: 'get',
      body,
    };
  },
};

export default tasksApi;
