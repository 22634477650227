import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType } from '@atoms/Button';
import Dialog from '@molecules/Dialog';
import { useToggle } from '@utils/hooks/useToggle';
import { AssignedRoom } from '@typings/types';

type Props = {
  value: AssignedRoom[];
  onConfirm: () => void;
};

function UnassignAll({ value, onConfirm }: Props) {
  const [showConfirmation, toggleConfirmation] = useToggle(false);
  const { t } = useTranslation();
  const onClick = useCallback(() => {
    toggleConfirmation();
  }, [toggleConfirmation]);

  const totalAssignedRooms = value.filter((v) => v.cleaningAssignments.length).length;

  const onClickConfirmUnassign = useCallback(() => {
    onConfirm();
    toggleConfirmation();
  }, [onConfirm, toggleConfirmation]);

  const renderConfirmationDialog = useCallback(() => {
    return (
      <Dialog
        isOpen={showConfirmation}
        onClose={() => {
          toggleConfirmation(false);
        }}
      >
        <div className={'flex flex-col items-center px-3 w-72'}>
          <div className={'text-th-brown font-serif text-lg font-bold mb-2'}>{t('unassignRoomsTitle')}</div>
          <>
            <div className={'text-th-secondary text-center mb-6'}>
              {t('unassignRoomsConfirmationMessage', { total: totalAssignedRooms })}
            </div>
            <Button
              className={'w-full'}
              onClick={() => {
                onClickConfirmUnassign();
              }}
            >
              {t('yes')}
            </Button>
            <span
              className={'text-gray-400 font-serif my-1 py-2 w-full text-center cursor-pointer'}
              onClick={() => {
                toggleConfirmation(false);
              }}
            >
              {t('no')}
            </span>
          </>
          <div />
        </div>
      </Dialog>
    );
  }, [showConfirmation, toggleConfirmation]);

  return (
    <>
      <div className={'text-th-gray-300 flex flex-row items-center px-4'} onClick={onClick}>
        <Button disabled={!totalAssignedRooms} type={ButtonType.SECONDARY} className={'font-bold'}>
          {t('unassignAll', { total: totalAssignedRooms })}
        </Button>
      </div>
      {renderConfirmationDialog()}
    </>
  );
}

export default UnassignAll;
