import Button, { ButtonType } from '@atoms/Button';
import icons from '@constants/icons';
import usePropertiesState from '@context/propertiesContext';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import FullScreenDialog from '@molecules/FullScreenDialog';
import IconWithText from '@molecules/IconWithText';
import Carousel from '@organisms/Carousel';
import SelectDate from '@organisms/SelectDate';
import { TaskPriority } from '@typings/enums';
import { TaskInfoType } from '@typings/types';
import dateUtils, { DATE_FORMAT_SHORT, formatDate, isTodayInProperty, DATE_AND_TIME_FORMAT } from '@utils/dateUtils';
import useConfirmTask from '@utils/hooks/useConfirmTask';
import useToggleCompleteTask from '@utils/hooks/useToggleCompleteTask';
import { isConfirmTaskAvailable, numberToTaskPriority, taskConfirmationIcon } from '@utils/taskUtils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Chatbox from '@molecules/Chatbox';
import Popover from '@molecules/Popover';
import { useTaskTitleAndDescription } from '@utils/hooks';
import { TASK_CATEGORIES_DELIMITER } from '@api/taskAction.mapper';
import MarkdownRenderer from '@atoms/MarkdownRenderer';

interface Props {
  task: TaskInfoType;
  onTaskAction?: () => void;
}

export default function TaskDetails({ task, onTaskAction }: Props) {
  const { t } = useTranslation();

  const toggleCompleteMutation = useToggleCompleteTask({ onSuccess: onTaskAction });
  const { onConfirmDate, isLoading: isConfirmLoading } = useConfirmTask({ task, onSuccess: onTaskAction });

  const { selectedProperty } = usePropertiesState();

  const { title, description, taskAction } = useTaskTitleAndDescription(task);

  const actionPath = [taskAction?.categoryAndSubcategory, taskAction?.name]
    .filter((action) => action)
    .join(` ${TASK_CATEGORIES_DELIMITER} `);

  const onClickToggleComplete = useCallback(() => {
    if (!toggleCompleteMutation.isLoading && task) {
      toggleCompleteMutation.mutate({ task });
    }
  }, [toggleCompleteMutation, task]);

  const priority = task.priority !== null ? numberToTaskPriority(task.priority) : null;
  const confirmationIcon = taskConfirmationIcon(task);

  const showConfirmForToday = isConfirmTaskAvailable(task) && !task.confirmedAt;
  const showConfirmForAnotherDay = isConfirmTaskAvailable(task);

  const { timeZone } = selectedProperty;
  const minDate = dateUtils.nowInProperty(timeZone);
  const dueAt = task.dueAt ?? undefined;

  const isTaskToday = dueAt && isTodayInProperty(dueAt, selectedProperty.timeZone);
  const dateLabel = isTaskToday ? t('today') : formatDate(dueAt, DATE_FORMAT_SHORT);

  const UserIcon = icons.userFilter;
  const completedByName = task.completedBy?.fullName ?? 'User not found';

  return (
    <div className="flex flex-1 flex-col mt-4">
      <div className={'flex flex-col md:flex-row px-4 pt-4 pb-4 md:pt-[20px] bg-white rounded-md md:w-[800px]'}>
        {/* details  */}
        <div className="flex w-auto md:w-2/5 flex-col space-y-4 md:max-w-lg">
          <div className={'flex flex-col items-center'}>
            <FullScreenDialog disabled={!task.imageUrls.length}>
              {({ className, onClick }) => <Carousel urls={task.imageUrls} className={className} onClick={onClick} />}
            </FullScreenDialog>
          </div>
          <div className={'flex-1 flex flex-row p-4 rounded-lg bg-gray-50 space-x-5'}>
            {task.unit && <IconWithText Icon={icons.door} text={task.unit.number} />}
            {task.area && <IconWithText Icon={OfficeBuildingIcon} text={task.area.name} />}
            <IconWithText Icon={icons.emptyCalendar} text={formatDate(dueAt, DATE_FORMAT_SHORT) + confirmationIcon} />
            <IconWithText className="max-w-[100px] md:max-w-none" Icon={icons.user} text={task.createdByName} />
          </div>
          <div className={'flex flex-col text-th-secondary font-normal whitespace-pre-wrap'}>
            <div className={'font-semibold'}>{title}</div>
            <div className="text-gray-400">{actionPath}</div>
            <div className={'mt-2'}>
              <MarkdownRenderer markdown={description ?? ''}></MarkdownRenderer>
            </div>
          </div>
          <div className={'flex flex-1 flex-row justify-between'}>
            <div className={'text-gray-400'}>{t('dueDate')}</div>
            <div className={'font-bold'}>{formatDate(dueAt) + confirmationIcon}</div>
          </div>
          <div className={'flex flex-1 flex-row justify-between'}>
            <div className={'text-gray-400'}>{t('property')}</div>
            <div className={'font-bold'}>{task.property.name}</div>
          </div>
          <div className={'flex flex-1 flex-row justify-between'}>
            <div className={'text-gray-400'}>{t('team')}</div>
            <div className={'font-bold'}>{t(task.assignedTo)}</div>
          </div>
          <div className={'flex flex-1 flex-row justify-between'}>
            <div className={'text-gray-400'}>{t('priority')}</div>
            <div
              className={`font-bold ${priority === TaskPriority.HIGH ? 'text-red-400 bg-red-100 rounded px-2' : ''}`}
            >
              {priority ? t(`taskPriorities.${priority}`) : '-'}
            </div>
          </div>
          <div className={'flex flex-1 flex-row justify-between'}>
            <div className={'text-gray-400'}>{t('created')}</div>
            <div className={'font-bold'}>{formatDate(task.createdAt, DATE_AND_TIME_FORMAT)}</div>
          </div>
          {task?.completedAt && (
            <div className={'flex flex-1 flex-row justify-between'}>
              <div className={'text-gray-400'}>{t('resolved')}</div>
              <Popover
                target={<div className={'font-bold'}>{formatDate(task.completedAt, DATE_AND_TIME_FORMAT)}</div>}
                desktopCustomPosition={{ right: '0', marginTop: '2px' }}
                content={
                  <div className={'font-bold text-sm py-1 px-2 flex justify-center items-center'}>
                    <UserIcon className="w-3 h-3" />
                    <span className="ml-2 whitespace-nowrap">{completedByName}</span>
                  </div>
                }
              />
            </div>
          )}

          {/* // absolute */}
          <div className="sticky md:relative bottom-0 w-full md:w-auto md:max-w-20 bg-white pt-[16px]">
            <div className="flex justify-strech flex-col space-y-4 py-4">
              {showConfirmForToday ? (
                <Button
                  isLoading={isConfirmLoading}
                  type={ButtonType.PRIMARY}
                  onClick={() => onConfirmDate(task.dueAt!)}
                  Icon={icons.taskCalendarChecked}
                >
                  {t('confirmFor', { date: dateLabel })}
                </Button>
              ) : (
                <Button
                  isLoading={toggleCompleteMutation.isLoading}
                  type={!task.completedAt ? ButtonType.PRIMARY : ButtonType.OUTLINED}
                  onClick={onClickToggleComplete}
                  Icon={!task.completedAt ? icons.taskCheckmark : undefined}
                >
                  {task.completedAt ? t('markAsUnresolved') : t('markAsResolved')}
                </Button>
              )}
              {showConfirmForAnotherDay && (
                <SelectDate
                  minDate={minDate}
                  value={{
                    date: dueAt,
                  }}
                  onChange={({ date }) => {
                    date && onConfirmDate(date);
                  }}
                  hideRepetition
                  actionButton={(onClickSelectDate) => (
                    <Button
                      isLoading={isConfirmLoading}
                      type={ButtonType.OUTLINED}
                      onClick={onClickSelectDate}
                      Icon={icons.taskCalendar}
                    >
                      {t('confirmAnotherDay')}
                    </Button>
                  )}
                />
              )}
            </div>
          </div>
        </div>

        {/* chat */}
        <div className={'flex w-auto md:w-3/5 flex-col items-center mt-5 mb-2 md:mt-0'}>
          <Chatbox
            {...{
              id: task.id,
              createdById: task.createdById,
              createdByName: task.createdByName,
            }}
          />
        </div>
      </div>
    </div>
  );
}
