import { useMemo } from 'react';

import { TaskActionMapper } from '@api/taskAction.mapper';
import useTaskActionsState from '@context/taskActionsContext';
import { FILTER_ACTIONS, FILTER_EXCLUDE_ACTIONS, FiltersParamsType } from '@context/filtersTasksContext';

import { ActionsMultiSelectorModal } from '../Modals/ActionsMultiSelectorModal';

import { TaskAction } from '@api/taskAction.types';
import { AutocompleteOption } from '@molecules/AutocompleteInput/types';
import { ActionMultiSelectorValues, ActionMultiSelectorClick } from '../FilterTypes';

const ActionsFilterModal = ({
  id,
  values,
  onClick,
  onConfirm,
}: {
  id: keyof FiltersParamsType;
  values: ActionMultiSelectorValues;
  onClick: ActionMultiSelectorClick;
  onConfirm: () => void;
}) => {
  const { data: taskActions } = useTaskActionsState();
  const taskActionOptions = taskActions.map(TaskActionMapper.toAutocompleteOption);

  const activeTaskActionOptions = useMemo(() => {
    return taskActionOptions.reduce(
      (acc, option) => {
        if (values.value?.includes(option.value.id)) {
          return { ...acc, activeTaskActionOptions: [...acc.activeTaskActionOptions, option] };
        }
        return { ...acc, taskActionOptions: [...acc.taskActionOptions, option] };
      },
      {
        activeTaskActionOptions: [] as AutocompleteOption<TaskAction>[],
        taskActionOptions: [] as AutocompleteOption<TaskAction>[],
      },
    );
  }, [values.value, taskActionOptions]);

  return (
    <ActionsMultiSelectorModal
      id={id}
      values={values}
      activeOptions={activeTaskActionOptions.activeTaskActionOptions}
      onClick={onClick}
      onConfirm={onConfirm}
      options={activeTaskActionOptions.taskActionOptions}
    />
  );
};

export const IncludeActionsFilterModal = ({
  values,
  onClick,
  onConfirm,
}: {
  values: ActionMultiSelectorValues;
  onClick: ActionMultiSelectorClick;
  onConfirm: () => void;
}) => {
  return <ActionsFilterModal id={FILTER_ACTIONS} values={values} onClick={onClick} onConfirm={onConfirm} />;
};

export const ExcludeActionsFilterModal = ({
  values,
  onClick,
  onConfirm,
}: {
  values: ActionMultiSelectorValues;
  onClick: ActionMultiSelectorClick;
  onConfirm: () => void;
}) => {
  return <ActionsFilterModal id={FILTER_EXCLUDE_ACTIONS} values={values} onClick={onClick} onConfirm={onConfirm} />;
};
