import { TaskInfoType } from '@typings/types';
import React, { useCallback } from 'react';
import LoadingMessage from '@atoms/LoadingMessage';
import CollapsibleSection from '@molecules/CollapsibleSection';
import EmptyTaskSection from './EmptyTaskSection';
import { DATE_FORMAT_LONG, formatDate, isTodayInProperty, isTomorrowInProperty } from '@utils/dateUtils';
import { GROUP_TASKS_KEY_OVERDUE } from '@utils/taskUtils';
import { useTranslation } from 'react-i18next';
import { utcToZonedTime } from 'date-fns-tz';
import { TaskType } from '@typings/enums';
import usePropertiesState from '@context/propertiesContext';

interface Props {
  /**
   * If date is today, section will be default open
   * */
  utcDateString: string;
  type?: TaskType;
  search?: string;
  isFetching?: boolean;
  tasks: TaskInfoType[];
  renderTask: (task: TaskInfoType) => React.ReactNode;
}

function TaskSection({ utcDateString, type, search, isFetching = false, tasks, renderTask }: Props) {
  const { t } = useTranslation();

  const { selectedProperty } = usePropertiesState();
  const timeZone = selectedProperty.timeZone;

  const date = utcToZonedTime(utcDateString, timeZone);
  const isDateToday = isTodayInProperty(date, timeZone);

  const isStandardOverdue = type === TaskType.STANDARD && utcDateString === GROUP_TASKS_KEY_OVERDUE;
  const isExpandedByDefault = isDateToday || isStandardOverdue;

  const getTitle = useCallback(() => {
    if (utcDateString === GROUP_TASKS_KEY_OVERDUE) {
      return t('overdue');
    }
    if (type === TaskType.LOST_AND_FOUND) {
      return t('lostAndFound');
    }
    if (type === TaskType.DAMAGE) {
      return t('damageReports');
    }

    // eslint-disable-next-line no-nested-ternary
    const dayPrefix = isDateToday
      ? `${t('today')}, `
      : isTomorrowInProperty(date, timeZone)
      ? `${t('tomorrow')}, `
      : '';
    return `${dayPrefix}${formatDate(date, DATE_FORMAT_LONG)}`;
  }, [utcDateString, type, date, isDateToday, t]);

  const title = getTitle();

  return (
    <CollapsibleSection
      defaultOpen={isExpandedByDefault}
      headerTitle={
        <>
          {title} {tasks.length > 0 && <span className={'text-sm'}>({tasks.length})</span>}
        </>
      }
    >
      <>
        {tasks.map(renderTask)}
        {!isFetching && tasks.length === 0 && <EmptyTaskSection search={search} />}
        {isFetching && <LoadingMessage className={'p-4'} />}
      </>
    </CollapsibleSection>
  );
}

export default TaskSection;
