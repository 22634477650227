import { TaskInfoType } from '@typings/types';
import useDeleteTask from '@utils/hooks/useDeleteTask';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DamageReportDetails from '@organisms/DamageReportDetails';
import TaskInfo from '@organisms/TaskInfo';

interface Props {
  tasks: TaskInfoType[];
  onEditTask: (task: TaskInfoType) => void;
}

export default function UnitAdditionalTasks({ tasks, onEditTask }: Props) {
  const { t } = useTranslation();
  const { setTaskForDeletion, renderDeleteConfirmationDialog } = useDeleteTask({});

  const [openedItem, setOpenedItem] = useState<TaskInfoType>();

  if (tasks.length === 0) {
    return <div className={'p-4'}>{t('noDamageReports')}</div>;
  }

  return (
    <div className={'flex flex-col'}>
      {tasks.map((task) => (
        <TaskInfo
          key={task.id}
          task={task}
          onClick={setOpenedItem}
          onClickEdit={onEditTask}
          onClickDelete={setTaskForDeletion}
        />
      ))}
      {renderDeleteConfirmationDialog()}
      <DamageReportDetails
        task={openedItem}
        closeDialog={() => setOpenedItem(undefined)}
        onClickEdit={(item) => {
          onEditTask(item);
          // must close the current one to prevent <FocusTrap /> error
          setOpenedItem(undefined);
        }}
        onClickDelete={(item) => {
          setTaskForDeletion(item);
          setOpenedItem(undefined);
        }}
      />
    </div>
  );
}
