import Resizer from 'react-image-file-resizer';

/**
 * maxSize: max size of height/width, will keep aspect-ratio
 * */
export async function resizeImage(image: File, maxSize: number): Promise<File> {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      maxSize,
      maxSize,
      image.type.toUpperCase(),
      100,
      0,
      (result) => resolve(result as File),
      'file',
    );
  });
}
