import { useCallback, useContext, useState } from 'react';
import { ApplyOption, TaskInfoType, UnitDetailsInfoType, UnitInfoType } from '@typings/types';
import { useMutation, useQueryClient } from 'react-query';
import tasksApi from '@api/tasksApi';
import { NotificationType, ServerStateKey, TaskType } from '@typings/enums';
import Loader from '@molecules/Loader';
import { ReactComponent as DeleteTaskImage } from '@assets/images/delete-task.svg';
import Button from '@atoms/Button';
import Dialog from '@molecules/Dialog';
import useApiCall from '@utils/hooks/useApiCall';
import { useTranslation } from 'react-i18next';
import useNotifications from '@utils/hooks/useNotifications';
import { formatDate, REQUEST_DATE_FORMAT } from '@utils/dateUtils';
import routes from '@constants/routes';
import { useLocation } from 'react-router-dom';
import { isDueDateBeforeTodayMidnight, isDueDatePassed, isUnconfirmedTask } from '@utils/taskUtils';
import {
  FILTER_ACTIONS,
  FILTER_DATE_RANGE,
  FILTER_EXCLUDE_ACTIONS,
  FILTER_PRIORITY,
  FILTER_RECURRENCE,
  FILTER_ROOMS,
  FILTER_TEAMS,
  TasksContext,
} from '@context/filtersTasksContext';
import { getUnitsAndAreas } from '@molecules/Filters/utils';

const notificationTranslationKeyByType = {
  [TaskType.STANDARD]: 'taskDeletionNotification',
  [TaskType.LOST_AND_FOUND]: 'lostAndFoundDeletionNotification',
  [TaskType.DAMAGE]: 'damageReportDeletionNotification',
  [TaskType.MISCONDUCT]: '',
};

export default function useDeleteTask({ onSuccess }: { onSuccess?: () => void }) {
  const queryClient = useQueryClient();
  const { filterParams } = useContext(TasksContext);

  const location = useLocation();
  const { t } = useTranslation();

  const [taskForDeletion, setTaskForDeletion] = useState<TaskInfoType>();
  const { showNotification } = useNotifications();

  const updateList = useCallback((taskId: string, oldTasks?: TaskInfoType[]): TaskInfoType[] => {
    return oldTasks ? oldTasks?.filter((item) => item.id !== taskId) : [];
  }, []);

  const updateTasksView = useCallback(
    (task: TaskInfoType, apply?: boolean) => {
      const { propertyId } = task;

      const dateRange = filterParams[FILTER_DATE_RANGE].value;
      const fromDate = dateRange ? formatDate(dateRange[0], REQUEST_DATE_FORMAT) : undefined;
      const toDate = dateRange ? formatDate(dateRange[1], REQUEST_DATE_FORMAT) : undefined;
      const teams = filterParams[FILTER_TEAMS].value;
      const priority = filterParams[FILTER_PRIORITY].value;
      const actions = filterParams[FILTER_ACTIONS].value;
      const excludeActions = filterParams[FILTER_EXCLUDE_ACTIONS].value;
      const recurrence = filterParams[FILTER_RECURRENCE].value;
      const unitsAndAreas = getUnitsAndAreas(filterParams[FILTER_ROOMS].value);

      const filtersTasksStateKey = [
        fromDate,
        toDate,
        priority,
        teams,
        actions,
        excludeActions,
        recurrence,
        unitsAndAreas?.units,
        unitsAndAreas?.areas,
      ].filter((v) => !!v);

      const unconfirmedFiltersTasksStateKey = [
        teams,
        actions,
        excludeActions,
        recurrence,
        unitsAndAreas?.units,
        unitsAndAreas?.areas,
      ].filter((v) => !!v);

      const filtersOverdueTasksStateKey = [
        priority,
        teams,
        actions,
        excludeActions,
        recurrence,
        unitsAndAreas?.units,
        unitsAndAreas?.areas,
      ].filter((v) => !!v);

      const tasksStateKey = [ServerStateKey.TASKS, propertyId, ...filtersTasksStateKey];
      const overdueTasksStateKey = [ServerStateKey.OVERDUE_TASKS, propertyId, ...filtersOverdueTasksStateKey];
      const unconfirmedTasksStateKey = [
        ServerStateKey.UNCONFIRMED_TASKS,
        propertyId,
        ...unconfirmedFiltersTasksStateKey,
      ];

      if (task.recurrence && !apply) {
        queryClient.invalidateQueries(overdueTasksStateKey);
        queryClient.invalidateQueries(tasksStateKey);
        return;
      }

      if (queryClient.getQueryState(overdueTasksStateKey)) {
        if (isDueDatePassed(task)) {
          queryClient.setQueryData(overdueTasksStateKey, (oldData?: TaskInfoType[]) => updateList(task.id, oldData));
        }
      }

      const isTaskUnconfirmed = isUnconfirmedTask(task);
      if (isTaskUnconfirmed && queryClient.getQueriesData(unconfirmedTasksStateKey)) {
        queryClient.invalidateQueries(unconfirmedTasksStateKey);
      }

      if (queryClient.getQueryState(tasksStateKey)) {
        queryClient.setQueryData(tasksStateKey, (oldData?: TaskInfoType[]) => updateList(task.id, oldData));
      }
    },
    [filterParams],
  );

  const updateDailyOverview = useCallback((task: TaskInfoType, apply?: boolean) => {
    const { propertyId } = task;
    if (!task.unit) return;

    const unitId = task.unit.id;

    const unitDetailsStateKey = [ServerStateKey.UNIT_DETAILS, unitId];
    const dailyOverviewStateKey = [ServerStateKey.DAILY_VIEW_UNITS, propertyId];

    if (task.recurrence && !apply) {
      queryClient.invalidateQueries(unitDetailsStateKey);
      queryClient.invalidateQueries(dailyOverviewStateKey);
      return;
    }

    const isDueTodayOrBefore = isDueDateBeforeTodayMidnight(task);
    if (isDueTodayOrBefore && queryClient.getQueryState(unitDetailsStateKey)) {
      queryClient.setQueryData(unitDetailsStateKey, (oldData?: UnitDetailsInfoType) => ({
        ...oldData!,
        tasks: updateList(task.id, oldData!.tasks),
      }));
    }

    if (!task.completedAt && isDueTodayOrBefore && queryClient.getQueryState(dailyOverviewStateKey)) {
      queryClient.setQueryData(dailyOverviewStateKey, (oldData?: UnitInfoType[]) =>
        oldData
          ? oldData.map((u) =>
              u.id === unitId
                ? {
                    ...u,
                    tasksCount: u.tasksCount - 1,
                  }
                : u,
            )
          : [],
      );
    }
  }, []);

  const updateStandardTaskStates = useCallback(
    (task: TaskInfoType, apply?: boolean) => {
      if (location.pathname === routes.TASKS) {
        updateTasksView(task, apply);
      }

      if (location.pathname === routes.ROOT) {
        updateDailyOverview(task, apply);
      }
    },
    [queryClient, location, filterParams],
  );

  const updateAdditionalTaskStates = useCallback(
    (task: TaskInfoType) => {
      const { propertyId, unit } = task;
      if (location.pathname === routes.ROOT && task.type === TaskType.DAMAGE) {
        queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS, unit?.id]);
        queryClient.invalidateQueries([ServerStateKey.DAILY_VIEW_UNITS, propertyId]);
        return;
      }
      const stateKey = [
        task.type === TaskType.LOST_AND_FOUND
          ? ServerStateKey.LOST_AND_FOUND_TASKS
          : ServerStateKey.DAMAGE_REPORT_TASKS,
        propertyId,
      ];
      queryClient.setQueryData(stateKey, (oldData?: TaskInfoType[]) => updateList(task.id, oldData));
    },
    [queryClient, location],
  );

  const apiCall = useApiCall<void>(tasksApi.deleteOne);
  const deleteTaskMutation = useMutation(
    (v: { task: TaskInfoType; apply?: ApplyOption }) =>
      apiCall({
        params: { id: v.task.id, propertyId: v.task.propertyId },
        body: { apply: v.apply },
      }),
    {
      onSuccess: (data, variables) => {
        const { task } = variables;
        showNotification(
          t(`popupNotifications.${notificationTranslationKeyByType[task.type]}`),
          NotificationType.SUCCESS,
        );
        setTaskForDeletion(undefined);
        if (location.pathname === routes.APALEO_HOUSEKEEPING) {
          queryClient.invalidateQueries([ServerStateKey.RESERVATION_TASKS, task.type, task.reservation?.externalId]);
          return;
        }
        if (task.type === TaskType.STANDARD) {
          updateStandardTaskStates(task);
        }
        if (task.type === TaskType.LOST_AND_FOUND || task.type === TaskType.DAMAGE) {
          updateAdditionalTaskStates(task);
        }
        // todo miscounduct task

        onSuccess?.();
      },
    },
  );

  const deleteTask = useCallback(
    (apply?: ApplyOption) => {
      if (!taskForDeletion) return;
      if (!deleteTaskMutation.isLoading) {
        deleteTaskMutation.mutate({
          task: taskForDeletion,
          apply,
        });
      }
    },
    [deleteTaskMutation, taskForDeletion, filterParams],
  );

  const renderDeleteConfirmationDialogContent = useCallback(() => {
    if (deleteTaskMutation.isLoading) {
      return (
        <>
          <div className={'text-th-brown font-serif text-lg font-bold mb-3'}>{t('deleting')}...</div>
          <Loader className={'mb-3'} />
          <div className={'text-th-secondary text-center'}>{t('pleaseWaitAFewSeconds')}</div>
        </>
      );
    }
    return (
      <>
        <div className={'text-th-brown font-serif text-lg font-bold mb-2'}>{t('deleteTask')}?</div>
        <DeleteTaskImage className={'w-24 mb-4'} />
        {taskForDeletion?.recurrence ? (
          <>
            <div className={'text-th-secondary text-center mb-6'}>{t('deleteTaskRepeatsConfirmationMessage')}</div>
            <Button className={'w-full'} onClick={() => deleteTask()}>
              {t('deleteOnlyThisTask')}
            </Button>
            <span
              className={'text-gray-400 text-sm font-serif my-1 py-2 w-full text-center cursor-pointer'}
              onClick={() => deleteTask(ApplyOption.THIS_AND_FOLLOWING_TASKS)}
            >
              {t('deleteRepeatingTask')}
            </span>
          </>
        ) : (
          <>
            <div className={'text-th-secondary text-center mb-6'}>{t('deleteTaskConfirmationMessage')}</div>
            <Button className={'w-full'} onClick={() => deleteTask()}>
              {t('yes')}
            </Button>
            <span
              className={'text-gray-400 font-serif my-1 py-2 w-full text-center cursor-pointer'}
              onClick={() => setTaskForDeletion(undefined)}
            >
              {t('no')}
            </span>
          </>
        )}
      </>
    );
  }, [deleteTaskMutation]);

  const renderDeleteConfirmationDialog = useCallback(
    () => (
      <Dialog isOpen={!!taskForDeletion} onClose={() => setTaskForDeletion(undefined)}>
        <div className={'flex flex-col items-center px-3 w-72'}>{renderDeleteConfirmationDialogContent()}</div>
      </Dialog>
    ),
    [taskForDeletion, deleteTaskMutation],
  );

  return {
    setTaskForDeletion,
    renderDeleteConfirmationDialog,
  };
}
