import Button, { ButtonType } from '@atoms/Button';
import FloorAssignmentCard from '@organisms/FloorAssignmentCard';
import useSelectRooms from '@utils/hooks/useSelectRooms';
import { useTranslation } from 'react-i18next';
import SelectCleaners from '@organisms/SelectCleaners';
import { useCallback, useEffect } from 'react';
import { AssignUnits, Cleaner } from '@typings/types';
import { useAssignUnits, useGetAssignedUnits, useUnassignUnits } from '@api/api';
import { NotificationType } from '@typings/enums';
import useNotifications from '@utils/hooks/useNotifications';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import UnassignAll from '@molecules/UnassignAll';
import usePropertiesState from '@context/propertiesContext';

function RoomAssignment() {
  const history = useHistory();
  const { showNotification } = useNotifications();
  const { t } = useTranslation();
  const { selectedProperty } = usePropertiesState();
  const { data: assignedUnits, isFetched, refetch } = useGetAssignedUnits(selectedProperty.id);
  const unitsByFloor = Object.values(assignedUnits || []);
  const { value, setValue, onSelectRoom, onSelectFloor, totalRooms } = useSelectRooms(assignedUnits);
  const noDirtyRooms = isFetched && unitsByFloor.length === 0;

  const assignUnitsMutation = useAssignUnits(selectedProperty.id);
  const unassignUnitsMutation = useUnassignUnits(selectedProperty.id);

  useEffect(() => {
    setValue([]);
  }, [selectedProperty]);

  const onSelectCleaner = useCallback(
    (cleaner: Cleaner) => {
      if (assignUnitsMutation.isLoading) {
        return;
      }
      const request: AssignUnits = {
        userId: cleaner.id,
        unitIds: value.map((v) => v.id),
      };
      assignUnitsMutation.mutate(
        {
          data: request,
        },
        {
          onSuccess: () => {
            setValue([]);
            refetch();
          },
        },
      );
    },
    [value, assignUnitsMutation, setValue, refetch],
  );

  const onClickAssignRooms = useCallback(
    (onClick) => {
      if (!value.length) {
        showNotification(t('popupNotifications.pleaseSelectRoomsNotification'), NotificationType.ERROR);
        return;
      }
      onClick();
    },
    [value],
  );

  const onClickUnassignRooms = useCallback(() => {
    if (unassignUnitsMutation.isLoading) {
      return;
    }
    const request: Pick<AssignUnits, 'unitIds'> = {
      unitIds: value.map((v) => v.id),
    };
    unassignUnitsMutation.mutate(
      {
        data: request,
      },
      {
        onSuccess: () => {
          setValue([]);
          refetch();
        },
      },
    );
  }, [value, unassignUnitsMutation]);

  const onBack = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <>
      <div className={'flex flex-col flex-1 w-full'}>
        <div className={'flex px-3 pt-3 pb-6'}>
          <ChevronLeftIcon className={'w-5 h-5 cursor-pointer text-th-secondary'} onClick={onBack} />
        </div>
        <div className={'flex flex-1 flex-col'}>
          {noDirtyRooms && <div className={'text-center'}>{t('roomsCleanForToday')}</div>}
          {unitsByFloor.map((floor, index) => {
            const floorUnits = Object.values(floor);
            const { floorNumber } = floorUnits[floorUnits.length - 1];
            return (
              <div key={index} className={'pb-5'}>
                <FloorAssignmentCard
                  floorNumber={floorNumber}
                  value={value}
                  data={floorUnits}
                  onSelect={onSelectRoom}
                  onSelectAll={onSelectFloor}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={'max-w-3xl w-full fixed lg:static lg:align-middle bottom-0 z-10 mx-auto'}>
        <div className={'pl-4 pr-3 flex flex-row bg-white justify-between py-4 shadow-md'}>
          <SelectCleaners
            propertyId={selectedProperty.id}
            totalUnits={totalRooms}
            onSelect={onSelectCleaner}
            actionButton={(onClick) => (
              <Button type={ButtonType.OUTLINED} onClick={() => onClickAssignRooms(onClick)}>
                {t('assignXRooms', { total: value.length })}
              </Button>
            )}
          />
          <UnassignAll value={value} onConfirm={onClickUnassignRooms} />
        </div>
      </div>
    </>
  );
}

export default RoomAssignment;
