import React, { createContext, useContext } from 'react';
import useSearchParams from '@utils/hooks/useSearchParams';

interface AppContext {
  isApaleoTab: boolean;
}

export const AppContext = createContext({} as AppContext);

interface Props {
  children: React.ReactNode;
}
export function AppProvider(props: Props) {
  const searchParams = useSearchParams();
  const isApaleoTab = searchParams.has('subjectId');
  const state: AppContext = {
    isApaleoTab,
  };
  return <AppContext.Provider value={state} {...props} />;
}

export default function useAppContext() {
  return useContext(AppContext);
}
