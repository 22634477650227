import { Switch } from '@headlessui/react';
import classNames from 'classnames';

interface Props {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  reverse?: boolean;
  fullWidth?: boolean;
}

export default function Toggle({ label, value, onChange, reverse = false, fullWidth = false }: Props) {
  const switchComponent = (
    <Switch
      checked={value}
      onChange={onChange}
      className={classNames(
        value ? 'bg-th-primary' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          value ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        )}
      />
    </Switch>
  );
  const labelComponent = <span className={'text-sm'}>{label}</span>;
  return (
    <div className={`px-3 py-2 flex flex-row items-center ${fullWidth ? 'flex-1 justify-between' : 'space-x-2'}`}>
      {reverse ? (
        <>
          {switchComponent}
          {labelComponent}
        </>
      ) : (
        <>
          {labelComponent}
          {switchComponent}
        </>
      )}
    </div>
  );
}
