import cs from './languages/cs';
import de from './languages/de';
import en from './languages/en';
import vi from './languages/vi';
import it from './languages/it';
import hr from './languages/hr';
import sr from './languages/sr';
import uk from './languages/uk';
import ru from './languages/ru';
import es from './languages/es';
import ro from './languages/ro';
import fr from './languages/fr';
import no from './languages/no';
import pl from './languages/pl';
import nl from './languages/nl';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  vi: {
    translation: vi,
  },
  cs: {
    translation: cs,
  },
  it: {
    translation: it,
  },
  sr: {
    translation: sr,
  },
  hr: {
    translation: hr,
  },
  uk: {
    translation: uk,
  },
  ru: {
    translation: ru,
  },
  es: {
    translation: es,
  },
  ro: {
    translation: ro,
  },
  fr: {
    translation: fr,
  },
  no: {
    translation: no,
  },
  pl: {
    translation: pl,
  },
  nl: {
    translation: nl,
  },
};

export default resources;
