interface Props {
  label: string;
  value: string | JSX.Element;
  className?: string;
}

export default function LabelWithValue({ label, value, className }: Props) {
  return (
    <div className={`flex flex-col ${className || ''}`}>
      <span className={'flex text-gray-400'}>{label}</span>
      <div className={'flex text-th-secondary font-semibold'}>{value}</div>
    </div>
  );
}
