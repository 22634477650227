import { PropertyType, TaskInfoType, ThinUnit } from '@typings/types';
import { LostAndFoundItemFormValues } from '@utils/hooks/useCreateOrUpdateTask';
import { ImageFile } from '@utils/hooks/useImageUrls';

interface Props {
  property: PropertyType;
  defaultUnit?: ThinUnit;
  editTask?: TaskInfoType;
  images: ImageFile[];
}

export function getDefaultFormValues(props: Props): Partial<LostAndFoundItemFormValues> {
  const { property, defaultUnit, editTask, images } = props;

  const values: Partial<LostAndFoundItemFormValues> = {
    property,
  };

  /**
   * Default unit is set when from Daily View chooses to add L&F item for the room,
   * which gets passed via location.state
   */
  if (defaultUnit) {
    values.unit = defaultUnit;
  }

  if (editTask) {
    values.title = editTask.title ?? '';
    values.unit = editTask?.unit ?? editTask?.area ?? undefined;
    values.images = images;
  }
  return values;
}
