import useApiCall from '@utils/hooks/useApiCall';
import tasksApi from '@api/tasksApi';
import { useMutation, useQueryClient } from 'react-query';
import { NotificationType, ServerStateKey, TaskType } from '@typings/enums';
import { useTranslation } from 'react-i18next';
import useNotifications from '@utils/hooks/useNotifications';
import { TaskInfoType, UnitDetailsInfoType, UnitInfoType } from '@typings/types';
import dateUtils, { formatDate, REQUEST_DATE_FORMAT } from '@utils/dateUtils';
import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '@constants/routes';
import { isDueDateBeforeTodayMidnight, isDueDatePassed } from '@utils/taskUtils';
import {
  FILTER_ACTIONS,
  FILTER_DATE_RANGE,
  FILTER_EXCLUDE_ACTIONS,
  FILTER_PRIORITY,
  FILTER_RECURRENCE,
  FILTER_ROOMS,
  FILTER_TEAMS,
  TasksContext,
} from '@context/filtersTasksContext';
import { getUnitsAndAreas } from '@molecules/Filters/utils';

const notificationTranslationKeyByType = {
  [TaskType.STANDARD]: 'taskCompletionNotification',
  [TaskType.LOST_AND_FOUND]: 'lostAndFoundCompletionNotification',
  [TaskType.DAMAGE]: 'damageReportCompletionNotification',
  [TaskType.MISCONDUCT]: '',
};

export default function useToggleCompleteTask({ onSuccess }: { onSuccess?: () => void }) {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { filterParams } = useContext(TasksContext);

  const { t } = useTranslation();
  const { showNotification } = useNotifications();

  const updateList = useCallback((taskId: string, oldTasks?: TaskInfoType[]): TaskInfoType[] => {
    return oldTasks
      ? oldTasks?.map((item) =>
          item.id === taskId
            ? {
                ...item,
                completedAt: !item.completedAt ? dateUtils.now() : null,
              }
            : item,
        )
      : [];
  }, []);

  const updateTasksView = useCallback(
    (task: TaskInfoType) => {
      const { propertyId } = task;

      const dateRange = filterParams[FILTER_DATE_RANGE].value;
      const fromDate = dateRange ? formatDate(dateRange[0], REQUEST_DATE_FORMAT) : undefined;
      const toDate = dateRange ? formatDate(dateRange[1], REQUEST_DATE_FORMAT) : undefined;
      const teams = filterParams[FILTER_TEAMS].value;
      const priority = filterParams[FILTER_PRIORITY].value;
      const actions = filterParams[FILTER_ACTIONS].value;
      const excludeActions = filterParams[FILTER_EXCLUDE_ACTIONS].value;
      const recurrence = filterParams[FILTER_RECURRENCE].value;
      const unitsAndAreas = getUnitsAndAreas(filterParams[FILTER_ROOMS].value);

      const filtersTasksStateKey = [
        fromDate,
        toDate,
        priority,
        teams,
        actions,
        excludeActions,
        recurrence,
        unitsAndAreas?.units,
        unitsAndAreas?.areas,
      ].filter((v) => !!v);

      const filtersOverdueTasksStateKey = [
        priority,
        teams,
        actions,
        excludeActions,
        recurrence,
        unitsAndAreas?.units,
        unitsAndAreas?.areas,
      ].filter((v) => !!v);

      const overdueTasksStateKey = [ServerStateKey.OVERDUE_TASKS, propertyId, ...filtersOverdueTasksStateKey];
      if (queryClient.getQueryState(overdueTasksStateKey)) {
        if (isDueDatePassed(task)) {
          queryClient.setQueryData(overdueTasksStateKey, (oldData?: TaskInfoType[]) => {
            if (!oldData) return [];
            return !task.completedAt
              ? oldData.filter((item) => item.id !== task.id)
              : [...oldData, { ...task, completedAt: null }];
          });
        }
      }

      if (task.recurrence && !task.completedAt) {
        queryClient.invalidateQueries([ServerStateKey.TASKS, propertyId, ...filtersTasksStateKey]);
        return;
      }

      const tasksStateKey = [ServerStateKey.TASKS, propertyId, ...filtersTasksStateKey];
      if (queryClient.getQueryState(tasksStateKey)) {
        queryClient.setQueryData(tasksStateKey, (oldData?: TaskInfoType[]) => updateList(task.id, oldData));
      }
    },
    [filterParams],
  );

  const updateDailyOverview = useCallback((task: TaskInfoType) => {
    const { propertyId } = task;
    if (!task.unit) {
      return;
    }

    const unitId = task.unit.id;

    const unitDetailsStateKey = [ServerStateKey.UNIT_DETAILS, unitId];
    const dailyOverviewStateKey = [ServerStateKey.DAILY_VIEW_UNITS, propertyId];

    if (task.recurrence && !task.completedAt) {
      queryClient.invalidateQueries(unitDetailsStateKey);
      queryClient.invalidateQueries(dailyOverviewStateKey);
      return;
    }

    const isDueTodayOrBefore = isDueDateBeforeTodayMidnight(task);
    if (isDueTodayOrBefore && queryClient.getQueryState(unitDetailsStateKey)) {
      queryClient.setQueryData(unitDetailsStateKey, (oldData?: UnitDetailsInfoType) => ({
        ...oldData!,
        tasks: updateList(task.id, oldData!.tasks),
      }));
    }

    if (isDueTodayOrBefore && queryClient.getQueryState(dailyOverviewStateKey)) {
      queryClient.setQueryData(dailyOverviewStateKey, (oldData?: UnitInfoType[]) =>
        oldData
          ? oldData.map((u) =>
              u.id === unitId
                ? {
                    ...u,
                    tasksCount: task.completedAt ? u.tasksCount + 1 : u.tasksCount - 1,
                  }
                : u,
            )
          : [],
      );
    }
  }, []);

  const updateReservationTasksView = useCallback((task: TaskInfoType) => {
    queryClient.setQueryData(
      [ServerStateKey.RESERVATION_TASKS, task.type, task.reservation?.externalId],
      (oldData?: TaskInfoType[]) => updateList(task.id, oldData),
    );
  }, []);

  const updateStandardTaskStates = useCallback(
    (task: TaskInfoType) => {
      if (location.pathname === routes.TASKS) {
        return updateTasksView(task);
      }

      if (location.pathname === routes.APALEO_HOUSEKEEPING) {
        return updateReservationTasksView(task);
      }

      updateDailyOverview(task);
    },
    [queryClient, location, filterParams],
  );

  const updateAdditionalTaskStates = useCallback(
    (task: TaskInfoType) => {
      const { propertyId, unit } = task;
      if (location.pathname !== routes.DAMAGE_REPORT && task.type === TaskType.DAMAGE) {
        queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS, unit?.id]);
        queryClient.invalidateQueries([ServerStateKey.DAILY_VIEW_UNITS, propertyId]);
        return;
      }
      const stateKey = [
        task.type === TaskType.LOST_AND_FOUND
          ? ServerStateKey.LOST_AND_FOUND_TASKS
          : ServerStateKey.DAMAGE_REPORT_TASKS,
        propertyId,
      ];
      queryClient.setQueryData(stateKey, (oldData?: TaskInfoType[]) => updateList(task.id, oldData));
    },
    [queryClient, location],
  );

  const updateNotificationStates = useCallback(() => {
    if (location.pathname === routes.NOTIFICATIONS) {
      queryClient.invalidateQueries([ServerStateKey.NOTIFICATIONS]);
    }
    queryClient.invalidateQueries([ServerStateKey.NOTIFICATIONS_COUNT]);
  }, [queryClient, location]);

  const apiCall = useApiCall<void>(tasksApi.toggleComplete);
  return useMutation(
    // task is used for updating cache and showing notification
    (v: { task: TaskInfoType }) => apiCall({ params: { id: v.task.id, propertyId: v.task.propertyId } }),
    {
      onSuccess: (data, { task }) => {
        if (!task.completedAt) {
          showNotification(
            t(`popupNotifications.${notificationTranslationKeyByType[task.type]}`),
            NotificationType.SUCCESS,
          );
        }
        if (task.type === TaskType.STANDARD) {
          updateStandardTaskStates(task);
          updateNotificationStates();
        }
        if (task.type === TaskType.LOST_AND_FOUND) {
          updateAdditionalTaskStates(task);
        }
        if (task.type === TaskType.DAMAGE) {
          updateAdditionalTaskStates(task);
          updateNotificationStates();
        }
        // todo miscounduct task

        onSuccess?.();
      },
    },
  );
}
