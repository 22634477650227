import React, { useCallback } from 'react';
import { CleaningInterval, Reservation, ReservationDto } from '@typings/types';
import { useTranslation } from 'react-i18next';
import CollapsibleSection from '@molecules/CollapsibleSection';
import dateUtils, { DATE_AND_DAY_OF_WEEK, formatDate, MIDCLEAN_DATE_FORMAT } from '@utils/dateUtils';
import { endOfDay, isAfter } from 'date-fns';
import SelectDate from '@organisms/SelectDate';
import { useUpdateMidClean } from '@api/api';
import { DateValue } from '@organisms/SelectDate/types';
import { ServerStateKey } from '@typings/enums';
import { useQueryClient } from 'react-query';

interface Props {
  reservation: Reservation;
  minDate?: Date;
  maxDate?: Date;
}

const NO_VALUE_PLACEHOLDER = '--';

function GxMidstayCleaning({ reservation, minDate, maxDate }: Props) {
  const { t } = useTranslation();
  const { id: reservationId, propertyId, midClean } = reservation;
  const queryClient = useQueryClient();
  const lastClean = formatDate(reservation.midClean?.lastClean, DATE_AND_DAY_OF_WEEK);
  const nextDate = reservation.midClean?.date;
  // this should always be set however there's a guard to fallback to weekly
  const midCleanType = reservation.midClean?.type ?? CleaningInterval.NONE;
  const midCleanMovedTo =
    nextDate && midClean?.scheduledByUser && isAfter(nextDate, endOfDay(dateUtils.now())) ? nextDate : undefined;

  const updateMidCleanMutation = useUpdateMidClean();
  const onChangeNextDate = useCallback(
    async ({ date }: DateValue) => {
      const newMidClean = {
        ...midClean!,
        // backend saves date only in db, therefore we do formatting here to exact time zoned value given from date picker
        date: date ? formatDate(date, MIDCLEAN_DATE_FORMAT) : undefined,
        lastClean: midClean?.lastClean ? formatDate(midClean?.lastClean, MIDCLEAN_DATE_FORMAT) : undefined,
      };
      const { success, ...data } = await updateMidCleanMutation.mutateAsync({
        data: {
          ...newMidClean,
          reservationId,
          propertyId,
        },
      });
      if (success) {
        queryClient.setQueryData([ServerStateKey.RESERVATION], (oldData?: ReservationDto) => ({
          ...oldData!,
          midClean: data,
        }));
      }
    },
    [midClean, reservationId, propertyId],
  );
  return (
    <div className={'flex flex-col'}>
      <CollapsibleSection headerTitle={t('cleaningInterval.stayoverCleaning')}>
        <div className={'p-4 flex flex-col space-y-3'}>
          <div className={'flex flex-row items-center justify-between'}>
            <div>{t('cleaningInterval.interval')}</div>
            <div>{t(`cleaningInterval.${midCleanType}`)}</div>
          </div>
          <div className={'flex flex-row items-center justify-between'}>
            <div>{t('cleaningInterval.next')}</div>
            <div>
              <SelectDate
                minDate={minDate}
                maxDate={maxDate}
                value={{
                  date: nextDate,
                }}
                hideRepetition
                onChange={onChangeNextDate}
                actionButton={(onClickSelectDate) => (
                  <div onClick={onClickSelectDate} className={'font-bold px-1 rounded bg-gray-200 cursor-pointer'}>
                    {nextDate ? formatDate(nextDate, DATE_AND_DAY_OF_WEEK) : NO_VALUE_PLACEHOLDER}
                  </div>
                )}
                submitLabelKey={t('save')}
              />
            </div>
          </div>
          {midCleanMovedTo && (
            <span className={'text-red-500 font-bold text-sm'}>
              {t('midCleanMovedTo', {
                value: formatDate(midCleanMovedTo, DATE_AND_DAY_OF_WEEK),
              })}
            </span>
          )}
          <div className={'flex flex-row items-center justify-between'}>
            <div>{t('cleaningInterval.lastCleaning')}</div>
            <div>{lastClean || '-'}</div>
          </div>
        </div>
      </CollapsibleSection>
    </div>
  );
}

export default GxMidstayCleaning;
