import React, { useState } from 'react';
import { TaskInfoType, ThinUnit } from '@typings/types';
import CollapsibleSection from '@molecules/CollapsibleSection';
import TaskInfo from '@organisms/TaskInfo/TaskInfo';
import useDeleteTask from '@utils/hooks/useDeleteTask';
import { useGetGxTasksForReservation } from '@api/api';
import { sortTaskGroups } from '@utils/taskUtils';
import { useTranslation } from 'react-i18next';
import SearchBar from '@molecules/SearchBar';
import useTaskHelpers from '@utils/hooks/useTaskHelpers';
import Button, { ButtonType } from '@atoms/Button';
import CreateTask from '@organisms/CreateTask';
import { EmptyTaskSection, TaskSection } from '@organisms/TaskSection';
import { TaskDetailsDialog } from '@organisms/TaskDetails';
import { PlusCircleIcon } from '@heroicons/react/outline';
import usePropertiesState from '@context/propertiesContext';
import { useToggle } from '@utils/hooks/useToggle';
import useSearchParam from '@utils/hooks/useSearchParam';

interface Props {
  selectedUnit: ThinUnit;
  propertyId: string;
  apaleoReservationId: string;
  minDate?: Date;
  maxDate?: Date;
}

function GxTasks({ selectedUnit, propertyId, apaleoReservationId, minDate, maxDate }: Props) {
  const [editTask, setEditTask] = useState<TaskInfoType>();
  const setTaskId = useSearchParam({ key: 'taskId' })[1];
  const setTaskType = useSearchParam({ key: 'taskType' })[1];
  const { setTaskForDeletion, renderDeleteConfirmationDialog } = useDeleteTask({});
  const { t } = useTranslation();

  const {
    selectedProperty: { timeZone },
  } = usePropertiesState();

  const { data: items, isFetching } = useGetGxTasksForReservation({
    propertyId,
    apaleoReservationId,
    timeZone,
  });
  const [taskDialogOpen, toggleTaskDialog] = useToggle(false);

  const { tasks, search, setSearch } = useTaskHelpers({
    items,
  });

  if (isFetching && !tasks) {
    return null;
  }

  const dateKeys = sortTaskGroups(Object.keys(tasks));

  const onClick = (task: TaskInfoType) => {
    setTaskId(task.id);
    setTaskType(task.type);
  };

  return (
    <div className={'flex flex-col'}>
      <CollapsibleSection headerTitle={t('tasks')}>
        <div className={'p-2'}>
          <Button onClick={toggleTaskDialog} Icon={PlusCircleIcon} type={ButtonType.OUTLINED}>
            {t('addTask')}
          </Button>
          {(taskDialogOpen || editTask) && (
            <CreateTask
              apaleoReservationId={apaleoReservationId}
              minDate={minDate}
              maxDate={maxDate}
              editTask={editTask}
              selectedUnit={selectedUnit}
              onClose={() => {
                toggleTaskDialog(false);
                setEditTask(undefined);
              }}
            />
          )}
          <div className={'pt-4'}>
            <SearchBar onChange={(v) => setSearch(v?.toLowerCase())} />
          </div>
          {dateKeys?.length === 0 && <EmptyTaskSection search={search} />}
          <div className={'pt-4 space-y-4'}>
            {dateKeys.map((date) => (
              <TaskSection
                utcDateString={date}
                search={search}
                key={date}
                isFetching={isFetching}
                tasks={tasks[date]}
                renderTask={(item) => (
                  <TaskInfo
                    canResolveTask={false}
                    key={item.id}
                    task={item}
                    onClickEdit={setEditTask}
                    onClickDelete={setTaskForDeletion}
                    onClick={onClick}
                  />
                )}
              />
            ))}
          </div>
          {renderDeleteConfirmationDialog()}
        </div>
      </CollapsibleSection>
      <TaskDetailsDialog
        propertyId={propertyId}
        onClickEdit={(item) => {
          setEditTask(item);
        }}
        onClickDelete={(item) => {
          setTaskForDeletion(item);
        }}
      />
    </div>
  );
}

export default GxTasks;
