import { useState } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';

interface DateSelectorProps {
  label: string;
  value: Date | null;
  minDate: Date;
  maxDate: Date;
  onChangeDate: (date: Date) => void;
  error?: string;
}

export const DateSelector = ({ label, value, minDate, maxDate, onChangeDate, error }: DateSelectorProps) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const isActive = !!value;

  const handleDateChange = (date: Date) => {
    if (date) {
      onChangeDate(date);
      setIsCalendarOpen(false);
    }
  };

  return (
    <div className="relative w-3/5 font-sans">
      <span className={`${error ? 'text-th-red-200' : 'text-th-brown-100'} text-xs font-normal`}>{label}</span>
      <button
        type="button"
        className={`w-full pb-1 border-b ${isActive ? 'text-th-brown' : 'text-th-brown-100'} ${
          error ? 'border-th-red-200' : 'border-th-brown-50'
        } text-left`}
        onClick={(e) => {
          e.preventDefault();
          setIsCalendarOpen(!isCalendarOpen);
        }}
      >
        {isActive ? format(value, 'dd.MM.yyyy') : 'DD.MM.YYYY'}
      </button>
      {error && <p className="mt-1 text-red-500 text-xs">{error}</p>}
      {isCalendarOpen && (
        <div className="absolute top-6 right-0 z-50">
          <DatePicker
            autoComplete="off"
            selected={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleDateChange}
            highlightDates={[{ 'highlight-day': value ? [value] : [] }]}
            disabledKeyboardNavigation
            inline
            onClickOutside={() => setIsCalendarOpen(false)}
          />
        </div>
      )}
    </div>
  );
};
