import { createContext, useContext, useState } from 'react';
import Talk from 'talkjs';

interface ChatContext {
  isReady: boolean;
  setReady: (v: boolean) => void;

  user: Talk.User | null;
  setUser: (v: Talk.User | null) => void;

  session: Talk.Session | null;
  setSession: (v: Talk.Session | null) => void;
}

export const ChatContext = createContext({} as ChatContext);

interface Props {
  children: React.ReactNode;
}
export function ChatProvider(props: Props) {
  const [isReady, setReady] = useState(false);

  const [user, setUser] = useState<Talk.User | null>(null);
  const [session, setSession] = useState<Talk.Session | null>(null);

  const state: ChatContext = {
    isReady,
    setReady,
    user,
    setUser,
    session,
    setSession,
  };
  return <ChatContext.Provider value={state} {...props} />;
}

export default function useChatState() {
  const context = useContext(ChatContext);
  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useChatState was used outside of its Provider');
  }
  return context;
}
