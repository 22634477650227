import Dialog from '@molecules/Dialog';
import TaskActions from '@organisms/TaskActions';
import { TaskInfoType } from '@typings/types';
import TaskDetails from './TaskDetails';
import { useMemo } from 'react';
import { useGetOneTask } from '@api/api';

import useSearchParam from '@utils/hooks/useSearchParam';
import { AutomatedTaskType, TaskType } from '@typings/enums';
import KitchenUseTaskModal from '@organisms/KitchenUseTaskModal';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTaskActionsState from '@context/taskActionsContext';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';

interface Props {
  propertyId: string;
  isCleanerOnly?: boolean;
  closeDialog?: () => void;
  onClickEdit?: (task: TaskInfoType) => void;
  onClickDelete?: (task: TaskInfoType) => void;
  onTaskAction?: () => void;
}

export default function TaskDetailsDialog(props: Props) {
  const { propertyId, isCleanerOnly = false, onClickEdit, onClickDelete, closeDialog, onTaskAction } = props;

  const { t } = useTranslation();
  const { isRoomcheckerRole } = useRoleBasedUI();
  const { id: unitId } = useParams<{ id: string }>();
  const isRoomChecker = isRoomcheckerRole(propertyId);
  const [taskId, setTaskId] = useSearchParam({ key: 'taskId' });
  const { getTaskActionByAutomatedType } = useTaskActionsState();
  const [taskType, setTaskType] = useSearchParam<TaskType>({ key: 'taskType', defaultValue: TaskType.STANDARD });
  const isStandardTask = taskType === TaskType.STANDARD;
  const enabled = !!taskId && !!propertyId && isStandardTask;

  const { data } = useGetOneTask({ id: taskId, propertyId, enabled });

  const task = useMemo(() => {
    return data?.id === taskId && data.type === TaskType.STANDARD ? data : null;
  }, [taskId, data]);

  const kitchenUseTask = useMemo(() => {
    const kitchenUseActionId = getTaskActionByAutomatedType(AutomatedTaskType.KITCHEN_USE)?.id;

    if (
      task?.dueAt &&
      !data?.completedAt &&
      data?.id === taskId &&
      data.type === TaskType.STANDARD &&
      data.actionId === kitchenUseActionId &&
      (isCleanerOnly || isRoomChecker)
    ) {
      return data;
    }
    return undefined;
  }, [taskId, data, getTaskActionByAutomatedType]);

  const isOpen = !!task && isStandardTask;

  const onClose = () => {
    setTaskId(null);
    setTaskType(null);
    closeDialog?.();
  };

  if (!isOpen) {
    return null;
  }

  if (kitchenUseTask) {
    return (
      <KitchenUseTaskModal
        unitId={unitId}
        saveButtonLabel={t('savePictures')}
        kitchenUseTask={kitchenUseTask}
        onClose={() => onClose()}
      />
    );
  }

  return (
    <Dialog
      isOpen
      onClose={onClose}
      isMobileSheet
      {...(!!task && !isCleanerOnly
        ? {
            actionButton: (
              <TaskActions
                task={task}
                onClickEdit={() => {
                  onClickEdit?.(task);
                  onClose();
                }}
                onClickDelete={() => {
                  onClickDelete?.(task);
                  onClose();
                }}
              />
            ),
          }
        : {})}
    >
      <div className="flex flex-1 overflow-y-auto">
        <TaskDetails
          task={task!}
          onTaskAction={() => {
            onTaskAction?.();
            onClose();
          }}
        />
      </div>
    </Dialog>
  );
}
