import type translations from '@i18n/languages/en';
import {
  FILTER_ACTIONS,
  FILTER_DATE_RANGE,
  FILTER_EXCLUDE_ACTIONS,
  FILTER_PRIORITY,
  FILTER_ROOMS,
  FILTER_RECURRENCE,
  FILTER_TEAMS,
  FiltersParamsType,
} from '@context/filtersTasksContext';
import { CalendarValues, MultiSelectorValues, RoomValue, RoomValues, SingleSelectorValues } from '../FilterTypes';
import { TeamsFilterModal } from './TeamsFilterModal';
import { PriorityFilterModal } from './PriorityFilterModal';
import { DateRangeFilterModal } from './DateRangeFilterModal';
import { ExcludeActionsFilterModal, IncludeActionsFilterModal } from './ActionsFilterModal';
import { RecurrenceFilterModal } from './RecurrenceFilterModal';
import { UnitsAndAreasFilterModal } from './UnitsAndAreasFilterModal';

type onClickArgsType = {
  id: keyof FiltersParamsType;
  label: string | string[];
  value: string | string[] | Date[] | RoomValue[];
};

type OptionFilterModalContentType = ({
  values,
  onClick,
  onConfirm,
}: {
  values: SingleSelectorValues | MultiSelectorValues | CalendarValues | RoomValues;
  onClick: (optionFilter: onClickArgsType) => void;
  onConfirm: () => void;
}) => JSX.Element;

type OptionFilterModalType = {
  [K in keyof FiltersParamsType]: {
    titleModal: keyof typeof translations;
    modal: OptionFilterModalContentType;
  };
};

export const Filters: OptionFilterModalType = {
  [FILTER_TEAMS]: {
    titleModal: 'team',
    modal: TeamsFilterModal as OptionFilterModalContentType,
  },
  [FILTER_PRIORITY]: {
    titleModal: 'priority',
    modal: PriorityFilterModal as OptionFilterModalContentType,
  },
  [FILTER_DATE_RANGE]: {
    titleModal: 'dueDate',
    modal: DateRangeFilterModal as OptionFilterModalContentType,
  },
  [FILTER_ACTIONS]: {
    titleModal: 'showActions',
    modal: IncludeActionsFilterModal as OptionFilterModalContentType,
  },
  [FILTER_EXCLUDE_ACTIONS]: {
    titleModal: 'hideActions',
    modal: ExcludeActionsFilterModal as OptionFilterModalContentType,
  },
  [FILTER_RECURRENCE]: {
    titleModal: 'repetition',
    modal: RecurrenceFilterModal as OptionFilterModalContentType,
  },
  [FILTER_ROOMS]: {
    titleModal: 'rooms',
    modal: UnitsAndAreasFilterModal as OptionFilterModalContentType,
  },
};
