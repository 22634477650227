import Home from '@pages/Home';
import SignIn from '@pages/SignIn';
import routes from '@constants/routes';
import Settings from '@pages/Settings';
import HousekeepingTab from '@pages/HousekeepingTab';
import TaskPage from './TaskPage';

export default [
  // todo remove later component, it is unused
  { component: SignIn, path: routes.SIGN_IN },
  { component: Settings, path: routes.SETTINGS },
  { component: TaskPage, path: routes.TASK_DETAILS },
  { component: HousekeepingTab, path: routes.APALEO_HOUSEKEEPING },
  // must go last!
  { component: Home, path: routes.ROOT },
];
