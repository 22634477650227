import { RowType } from '../types';

export const RowHeader = ({ group }: { group: RowType }) => {
  const [roomNumber, roomTitle] = group.title.split(' ');
  return (
    <div className="h-full text-xs leading-4 font-normal font-sans flex flex-col justify-center items-center bg-[#F9FAFB]">
      <div>{roomNumber}</div>
      <div>{roomTitle}</div>
    </div>
  );
};
