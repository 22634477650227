import { TaskInfoType } from '@typings/types';
import { TaskPriority, TaskType } from '@typings/enums';
import { useState } from 'react';
import partition from 'lodash.partition';
import { useGetTasks } from '@api/api';
import { filterBySearchCriteria, sortTasks } from '@utils/taskUtils';

interface Props {
  propertyId: string;
  type: TaskType;
  timeZone: string;
  fromDate?: string;
  toDate?: string;
  priority?: TaskPriority;
  teams?: string[];
  actions?: string[];
  excludeActions?: string[];
  recurrence?: string;
  rooms?: string[];
  areas?: string[];
  enabled?: boolean;
}

function useGetTaskData(props: Props) {
  const [search, setSearch] = useState<string>();

  const { tasks, overdueTasks, unconfirmedTasks, ...queryResult } = useGetTasks(props);

  const getCurrentAndArchivedTasks = (data: TaskInfoType[], type: TaskType) => {
    // for standardized tasks - include resolved in current tasks
    const currentAndArchived = type === TaskType.STANDARD ? [data, []] : partition(data, (task) => !task.completedAt);
    return currentAndArchived.map((t) => filterBySearchCriteria(t, search)).map(sortTasks);
  };

  const taskStatus = tasks ? getCurrentAndArchivedTasks(tasks, props.type) : null;

  const getOverdueTasks = overdueTasks ? filterBySearchCriteria(sortTasks(overdueTasks), search) : null;

  const getUnconfirmedTasks = unconfirmedTasks
    ? filterBySearchCriteria(sortTasks(unconfirmedTasks ?? null), search)
    : null;

  return {
    ...queryResult,
    search,
    setSearch,
    tasks: taskStatus ? taskStatus[0] : null,
    overdueTasks: getOverdueTasks,
    unconfirmedTasks: getUnconfirmedTasks,
    archivedTasks: taskStatus ? taskStatus[1] : null,
  };
}

export default useGetTaskData;
