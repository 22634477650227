import { ApiInfo } from '@api/types';

const devicesApi: ApiInfo = {
  addDevice: ({ body }) => {
    return {
      url: '/devices',
      method: 'post',
      body,
    };
  },
  deleteDevice: ({ body }) => {
    return {
      url: '/devices',
      method: 'delete',
      body,
    };
  },
};

export default devicesApi;
