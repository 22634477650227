import { ApiInfo } from '@api/types';

const notificationsApi: ApiInfo = {
  getNotifications: ({}) => {
    return { url: '/notifications' };
  },
  notificationsCount: ({}) => {
    return { url: '/notifications/count' };
  },
  getNotificationSettings: ({}) => {
    return { url: '/notifications/settings' };
  },
  updateNotificationSettings: ({ body }) => {
    return { url: '/notifications/settings', method: 'post', body };
  },
};

export default notificationsApi;
