import {
  addDays,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isPast,
  isSameDay,
  set,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  subDays,
} from 'date-fns';
import { utcToZonedTime as fnsUtcToZonedTime, zonedTimeToUtc as fnsZonedTimeToUtc } from 'date-fns-tz';
import { WeekDay } from '@typings/types';

export function datesBetween(startDate: Date, endDate: Date): Date[] {
  const days = differenceInDays(endDate, startDate);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [...Array(days + 1).keys()].map((i) => addDays(startDate, i));
}

export const DEFAULT_TIMEZONE = 'Europe/Berlin';

const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_AND_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const MIDCLEAN_DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT_SHORT = 'dd.MM';
export const DATE_FORMAT_LONG = 'EEEE dd/MM';
export const REQUEST_DATE_FORMAT = 'MM-dd-yyyy';
export const DATE_AND_DAY_OF_WEEK = 'dd MMM (EEEE)';
export const DATE_ISO_NO_TZ = "yyyy-MM-dd'T'HH:mm:ss.sss";

export function formatDate(dateValue?: string | Date, dateFormat = DATE_FORMAT) {
  const date = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;
  return date ? format(date, dateFormat) : '';
}

export function toDate(value: string | Date) {
  return new Date(value);
}

export function isTodayInProperty(propertyDate: Date, timeZone: string) {
  const now = nowInProperty(timeZone);
  return isSameDay(propertyDate, now);
}

export function isTomorrowInProperty(propertyDate: Date, timeZone: string) {
  const tomorrow = addDays(nowInProperty(timeZone), 1);
  return isSameDay(propertyDate, tomorrow);
}

export function getDatesBetweenDays(from: Date, to: Date): Date[] {
  const countDaysBetween = differenceInDays(to, from);
  return [...new Array(countDaysBetween)].map((_, index) => addDays(from, index));
}

export const weekDayOptions: WeekDay[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

export const setTime = (date: Date, time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), minutes), hours);
};

const now = () => new Date();

const nowInProperty = (timeZone: string) => utcToZonedTime(now(), timeZone);

const zonedTimeToUtc = (date: Date | string, timeZone: string) => {
  return fnsZonedTimeToUtc(date, timeZone);
};

const utcToZonedTime = (date: Date | string, timeZone: string) => {
  return fnsUtcToZonedTime(date, timeZone);
};

const formatISO = (date: Date) => {
  return formatDate(date, DATE_ISO_NO_TZ) + 'Z';
};

const getDueAtUtc = (date: Date, timeZone: string): string => {
  return zonedTimeToUtc(date, timeZone).toISOString();
};

export default {
  now,
  nowInProperty,
  isPast,
  subDays,
  isAfter,
  isBefore,
  set,
  setTime,
  toDate,
  zonedTimeToUtc,
  utcToZonedTime,
  getDueAtUtc,
  formatISO,
};
