import { PlusIcon } from '@heroicons/react/solid';
import { MouseEventHandler } from 'react';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function AddButton({ onClick }: Props) {
  return (
    <div className={'absolute right-4 bottom-20'}>
      <button className={'bg-th-primary rounded-full px-4 py-4 shadow-md'} onClick={onClick}>
        <PlusIcon width={26} height={26} className={'text-white'} />
      </button>
    </div>
  );
}
