import { Team } from '@typings/enums';
import { SelectOption } from '@molecules/SelectionDialog';

export const teamOptions: { [key: string]: SelectOption<Team> } = {
  [Team.MAINTENANCE]: {
    label: Team.MAINTENANCE,
    value: Team.MAINTENANCE,
  },
  [Team.HOUSE_KEEPING]: {
    label: 'hskTeam',
    value: Team.HOUSE_KEEPING,
  },
  [Team.OPERATIONS]: {
    label: Team.OPERATIONS,
    value: Team.OPERATIONS,
  },
  [Team.ICT]: {
    label: Team.ICT,
    value: Team.ICT,
  },
};
