import env from '@constants/env';
import { getMessaging, getToken, isSupported, Messaging, onMessage, deleteToken } from 'firebase/messaging';
import useApiCall from '@utils/hooks/useApiCall';
import devicesApi from '@api/devicesApi';
import { useCallback, useEffect, useState } from 'react';
import useNotifications from '@utils/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { DeviceType, NotificationType, ServerStateKey } from '@typings/enums';
import storageService from '@utils/storageService';

import firebaseApp from '@utils/firebaseApp';

function useMessagingService() {
  const { showNotification } = useNotifications();
  const queryClient = useQueryClient();

  const [messaging, setMessaging] = useState<Messaging | null>(null);
  const [token, setToken] = useState<string | null>(storageService.getFcmToken());

  const addDeviceApiCall = useApiCall<void>(devicesApi.addDevice);
  const removeDeviceApiCall = useApiCall<void>(devicesApi.deleteDevice);

  const initMessaging = useCallback(async () => {
    const browserSupported = await isSupported();
    if (browserSupported) {
      const messaging = getMessaging(firebaseApp);
      setMessaging(messaging);
      return messaging;
    }
    console.log('Browser push notifications are not supported');
    return null;
  }, []);

  useEffect(() => {
    initMessaging();
  }, []);

  useEffect(() => {
    if (!messaging) {
      return;
    }
    /**
     * Enable in app message notifications
     */
    const unsubscribeOnMessage = onMessage(messaging, (payload) => {
      const message = payload.data?.body || '';
      const metadata = payload.data;
      showNotification(message, NotificationType.INFO, metadata);
      queryClient.invalidateQueries(ServerStateKey.NOTIFICATIONS);
      queryClient.invalidateQueries(ServerStateKey.NOTIFICATIONS_COUNT);
    });

    return () => {
      unsubscribeOnMessage();
    };
  }, [messaging]);

  const getMessagingToken = useCallback(() => {
    if (!messaging) {
      return null;
    }
    return getToken(messaging, { vapidKey: env.firebaseVapidKey })
      .then((currentToken) => {
        if (currentToken) {
          setToken(currentToken);
          addDeviceApiCall({ body: { token: currentToken, type: DeviceType.WEB } });
          storageService.saveFcmToken(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }, [messaging, addDeviceApiCall]);

  const disableMessaging = async () => {
    if (token) {
      setToken(null);
      storageService.removeFcmToken();
      await removeDeviceApiCall({
        body: {
          token,
        },
      });
    }
    if (messaging) {
      await deleteToken(messaging);
      setMessaging(null);
    }
  };

  return {
    token,
    messaging,
    initMessaging,
    getMessagingToken,
    disableMessaging,
  };
}

export default useMessagingService;
