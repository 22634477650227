import { useMemo } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid';
import { useForm, Controller } from 'react-hook-form';
import { addDays, differenceInMinutes, endOfDay, isAfter } from 'date-fns';
import usePropertiesState from '@context/propertiesContext';

import TextArea from '@molecules/TextArea';
import { Selector } from './components/Selector';
import { DateSelector } from './components/DateSelector';
import { TimeSelector } from './components/TimeSelector';
import { ErrorInfo } from './components/ErrorInfo';

import { defaultMinMaxDate, defaultOptions, errorOverbooking, slotTypeInfo } from './constants';
import { formattedOption, getDateAndTime, getNextHourDefault } from './utils';
import './styleSidebar.css';

interface RoomSlotSidebarProps {
  onClose: () => void;
  rooms: {
    id: string;
    title: string;
  }[];
}

type FormTypes = {
  slotType: string;
  reason: string;
  room: string;
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
  description: string;
  cleanAfterBlock: boolean;
};

export const RoomSlotSidebar = ({ rooms, onClose }: RoomSlotSidebarProps) => {
  const { t } = useTranslation();
  const { selectedProperty } = usePropertiesState();
  const todayPropertyTimezone = utcToZonedTime(new Date(), selectedProperty.timeZone);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormTypes>({
    defaultValues: {
      slotType: '',
      reason: '',
      room: '',
      startDate: todayPropertyTimezone,
      endDate: addDays(todayPropertyTimezone, 1),
      startTime: getNextHourDefault(todayPropertyTimezone),
      endTime: '11:00',
      description: '',
      cleanAfterBlock: true,
    },
    mode: 'onSubmit',
  });

  const options = useMemo(() => ({ ...defaultOptions, rooms: formattedOption(rooms) }), [rooms]);

  const onSubmit = (data: FormTypes) => {
    const { startDate, startTime, endDate, endTime } = data;
    const startDateFormatted = getDateAndTime(startDate, startTime);
    const endDateFormatted = getDateAndTime(endDate, endTime);

    if (isAfter(startDateFormatted, endDateFormatted)) {
      setError('endDate', {
        type: 'manual',
        message: 'End date cannot be before start date',
      });
      setError('endTime', {
        type: 'manual',
        message: 'End time cannot be before start time',
      });
      return;
    }
    if (differenceInMinutes(endDateFormatted, startDateFormatted) < 30) {
      setError('endTime', {
        type: 'manual',
        message: 'The minimum slot duration is 30 minutes',
      });
      return;
    }
    clearErrors(['endDate', 'endTime']);
  };

  return (
    <div className="fixed right-0 top-0 z-50 h-screen bg-white w-72 font-sans flex flex-col shadow-lg rounded-2xl">
      <div className="w-full flex justify-start items-center mt-8 p-4 border-b border-b-th-brown-50 gap-x-4">
        <button
          type="button"
          className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="text-base font-bold font-serif">Room Slot</div>
      </div>
      <div className="flex-1 overflow-y-auto md:overflow-visible">
        <form onSubmit={handleSubmit(onSubmit)} className="p-6 flex flex-col gap-y-4">
          <Controller
            name={'slotType'}
            control={control}
            rules={{ required: `Slot Type is required` }}
            render={({ field }) => (
              <Selector
                label={'Slot Type'}
                infoText={slotTypeInfo}
                placeholder={`Select type`}
                options={options['slotType']}
                error={errors['slotType']?.message}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name={'reason'}
            control={control}
            rules={{ required: `Reason is required` }}
            render={({ field }) => (
              <Selector
                label={'Reason'}
                placeholder={`Select reason`}
                options={options['reason']}
                error={errors['reason']?.message}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <div className="flex flex-col justify-center items-start gap-y-4">
            <div>
              <span className="text-th-brown-300 font-semibold text-xs leading-4">Select date and time</span>
            </div>
            <div className="flex justify-between items-start gap-x-2 w-full">
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: 'Start date is required',
                  validate: {
                    notPast: (value) => {
                      if (!value) return;
                      const selectedDate = endOfDay(value);
                      return !isAfter(todayPropertyTimezone, selectedDate) || 'Start date cannot be in the past';
                    },
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <DateSelector
                    minDate={addDays(todayPropertyTimezone, -defaultMinMaxDate)}
                    maxDate={addDays(todayPropertyTimezone, defaultMinMaxDate)}
                    label="Start date"
                    onChangeDate={onChange}
                    value={value}
                    error={errors['startDate']?.message}
                  />
                )}
              />
              <Controller
                name="startTime"
                control={control}
                rules={{ required: 'Start time is required' }}
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    label="Start time"
                    onChangeTime={onChange}
                    value={value}
                    error={errors['startTime']?.message}
                  />
                )}
              />
            </div>
            <div className="flex justify-between items-start gap-x-2 w-full">
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: 'End date is required',
                }}
                render={({ field: { onChange, value } }) => (
                  <DateSelector
                    minDate={addDays(todayPropertyTimezone, -defaultMinMaxDate)}
                    maxDate={addDays(todayPropertyTimezone, defaultMinMaxDate)}
                    label="End date"
                    onChangeDate={onChange}
                    value={value}
                    error={errors['endDate']?.message}
                  />
                )}
              />
              <Controller
                name="endTime"
                control={control}
                rules={{ required: 'End time is required' }}
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    label="End time"
                    onChangeTime={onChange}
                    value={value}
                    error={errors['endTime']?.message}
                  />
                )}
              />
            </div>
          </div>
          <Controller
            name={'room'}
            control={control}
            rules={{ required: `Room is required` }}
            render={({ field }) => (
              <Selector
                label={'Room'}
                placeholder={`Select room`}
                options={options['rooms']}
                error={errors['room']?.message}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <ErrorInfo message={errorOverbooking} />
          <Controller
            name={'cleanAfterBlock'}
            control={control}
            render={({ field }) => (
              <label
                htmlFor="cleanAfterBlock"
                className="cursor-pointer font-semibold text-sm leading-[18px] flex justify-start items-center gap-x-2"
              >
                <input
                  id="cleanAfterBlock"
                  type="checkbox"
                  value={'field.value'}
                  checked={field.value}
                  onChange={field.onChange}
                />
                Clean after block
              </label>
            )}
          />
          <Controller
            name={'description'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="bg-gray-50 rounded-md">
                <div className={'bg-white rounded-md m-2 text-sm'}>
                  <TextArea
                    value={value}
                    onChange={onChange}
                    placeholder={t('addDescription')}
                    error={errors.description}
                  />
                </div>
              </div>
            )}
          />
          <button
            type="submit"
            className="bg-th-secondary text-white font-normal text-base py-4 rounded-[6px] hover:opacity-90"
          >
            Add block
          </button>
        </form>
      </div>
    </div>
  );
};
