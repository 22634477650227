import { TaskInfoType } from '@typings/types';
import { useMemo, useState } from 'react';
import { filterBySearchCriteria, sortTasks, groupTasksByStatusAndDueDateInUTC, TasksByDate } from '@utils/taskUtils';

interface Props {
  items?: TaskInfoType[];
}

function useTaskHelpers({ items = [] }: Props) {
  const [search, setSearch] = useState<string>();

  const tasks = useMemo(() => {
    const groupedTasks = groupTasksByStatusAndDueDateInUTC(items);
    return Object.keys(groupedTasks).reduce((res, curr) => {
      const currentTasks = groupedTasks[curr] ?? [];
      if (currentTasks.length > 0) {
        res[curr] = sortTasks(filterBySearchCriteria(currentTasks, search));
      }
      return res;
    }, {} as TasksByDate);
  }, [search, items]);

  return {
    tasks,
    search,
    setSearch,
  };
}

export default useTaskHelpers;
