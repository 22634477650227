import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@molecules/Dialog';
import { SearchIcon } from '@heroicons/react/outline';
import LoadingMessage from '@atoms/LoadingMessage';
import { useToggle } from '@utils/hooks/useToggle';
import { SelectOption } from '@molecules/SelectionDialog';
import Button, { ButtonType } from '@atoms/Button';
import Checkbox from '@molecules/Checkbox';
import Toggle from '@molecules/Toggle';

interface Props {
  title: string;
  value: SelectOption[];
  onSelect: (items: SelectOption[]) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
  options: SelectOption[];
  isLoading?: boolean;
  error?: unknown;
  includeSearch?: boolean;
  customComponent?: (toggleDialog: () => void) => React.ReactNode;
  isToggle?: boolean;
  inverse?: boolean;
}

// todo decide how to show error
export default function MultiSelectionDialog(props: Props) {
  const {
    isLoading = false,
    includeSearch = true,
    title,
    value,
    onSelect,
    actionButton,
    options,
    customComponent,
    isToggle = false,
    inverse = false,
  } = props;

  const [selectedItems, setSelectedItems] = useState(value);
  const [dialogOpen, toggleDialogOpen] = useToggle(false);

  useEffect(() => {
    setSelectedItems(value);
  }, [value]);

  const { t } = useTranslation();

  const [search, setSearch] = useState<string>();
  const filteredData = search ? options.filter((p) => p.label.toLowerCase().includes(search)) : options;

  const renderItem = useCallback(
    (item: SelectOption) => {
      const selectedItem = selectedItems.find((i) => i.value === item.value);
      const isSelected = inverse ? !selectedItem : !!selectedItem;
      if (isToggle) {
        return (
          <Toggle
            value={isSelected}
            label={item.label}
            fullWidth
            onChange={() => {
              if (!selectedItem) {
                setSelectedItems([...selectedItems, item]);
              } else {
                setSelectedItems(selectedItems.filter((i) => i.value !== item.value));
              }
            }}
          />
        );
      }
      return (
        <Checkbox
          id={item.value}
          name={item.value}
          label={item.label}
          onClick={() => {
            if (!selectedItem) {
              setSelectedItems([...selectedItems, item]);
            } else {
              setSelectedItems(selectedItems.filter((i) => i.value !== item.value));
            }
          }}
          checked={isSelected}
          className={'pb-2 text-th-brown'}
        />
      );
    },
    [selectedItems],
  );

  return (
    <>
      {actionButton(toggleDialogOpen)}
      <Dialog
        isOpen={dialogOpen}
        onClose={() => {
          setSelectedItems(value);
          toggleDialogOpen();
          setSearch('');
        }}
        title={title}
        actionButton={
          <Button
            type={ButtonType.UNSTYLED}
            onClick={() => {
              onSelect(selectedItems);
              toggleDialogOpen();
              setSearch('');
            }}
          >
            {t('select')}
          </Button>
        }
        isMobileSheet
      >
        <div className={'md:w-96 flex flex-col'}>
          {includeSearch && (
            <div className={'shadow-sm p-3 pt-0'}>
              <div className={'rounded-md flex flex-row items-center px-3 py-2 mt-3 bg-gray-100'}>
                <SearchIcon className={'w-5 h-5 mr-2 text-gray-400'} />
                <input
                  placeholder={t('search')}
                  onChange={(v) => setSearch(v.target.value?.toLowerCase())}
                  className={
                    'block w-full p-0 focus:outline-none sm:text-sm border-0 bg-transparent placeholder-gray-400'
                  }
                />
              </div>
            </div>
          )}
          {customComponent?.(toggleDialogOpen)}
          <div className={'pb-1 py-3 md:max-h-[70vh] overflow-scroll'}>
            {includeSearch && (
              <div className={'px-3 text-sm text-gray-400 lowercase'}>
                {filteredData.length} {filteredData.length === 1 ? t('result') : t('results')}
              </div>
            )}
            {isLoading && <LoadingMessage className={'ml-2 mt-2'} />}
            {!isLoading &&
              filteredData.map((item) => (
                <div key={item.value} className={'flex flex-col'}>
                  {renderItem(item)}
                </div>
              ))}
          </div>
        </div>
      </Dialog>
    </>
  );
}
