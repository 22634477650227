import { startOfDay, isToday, getTime } from 'date-fns';
import Table, { SidebarHeader, TimelineHeaders, TimelineKeys } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import './styles/RoomRackTableOverride.css';

import { Slot } from './components/Slot';
import { ColumnHeader } from './components/ColumnHeader';
import { RowHeader } from './components/RowHeader';

import { RowType, SlotType, RoomRackTableProps } from './types';

const keys: TimelineKeys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'data',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start_time',
  itemTimeEndKey: 'end_time',
};

export const RoomRackTable = ({ roomsData, slotsData, dateRange }: RoomRackTableProps) => {
  return (
    <Table<SlotType, RowType>
      groups={roomsData}
      items={slotsData}
      stackItems={true}
      itemRenderer={(itemProps) => <Slot {...itemProps} />}
      canMove={false}
      canResize={false}
      canChangeGroup={false}
      sidebarWidth={56}
      visibleTimeStart={getTime(startOfDay(dateRange.start))}
      visibleTimeEnd={getTime(startOfDay(dateRange.end))}
      buffer={1}
      keys={keys}
      verticalLineClassNamesForTime={(date) => {
        return isToday(date) ? ['vertical-line-today'] : [''];
      }}
      groupRenderer={RowHeader}
    >
      <TimelineHeaders>
        <SidebarHeader>
          {({ getRootProps }) => {
            return (
              <div
                className="bg-transparent flex justify-center items-center font-sans text-xs font-semibold"
                {...getRootProps()}
              >
                Oct
              </div>
            );
          }}
        </SidebarHeader>
        <ColumnHeader />
      </TimelineHeaders>
    </Table>
  );
};
