import React from 'react';
import { useToggle } from '@utils/hooks/useToggle';
import Dialog from '@molecules/Dialog';
import cn from 'classnames';

type Props = {
  disabled?: boolean;
  children: (props: { className: string; onClick?: () => void }) => React.ReactNode;
};

function FullScreenDialog({ disabled, children }: Props) {
  const [visible, toggleVisible] = useToggle(false);
  return (
    <div>
      <div>
        {children({
          className: cn('w-32 h-32', !disabled && 'cursor-zoom-in'),
          onClick: toggleVisible,
        })}
      </div>
      {!disabled && (
        <Dialog isOpen={visible} onClose={toggleVisible} isMobileSheet sheetFullHeight fullScreen>
          <div className={'flex flex-col items-center w-full min-h-[80vh] md:min-h-screen'}>
            {children({
              className: cn('h-[70vh] w-[90vw]'),
            })}
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default FullScreenDialog;
