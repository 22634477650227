import CollapsibleSection from '@molecules/CollapsibleSection';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Toggle from '@molecules/Toggle';
import TextArea from '@molecules/TextArea';
import Button, { ButtonType } from '@atoms/Button';
import TimeInput from '@molecules/TimeInput';
import { useUpdateReservation } from '@api/api';
import { formatDate, TIME_FORMAT } from '@utils/dateUtils';

interface ExtraServicesSectionFormValues {
  checkInTime: string;
  isVip: boolean;
  commentForHousekeeping?: string | null;
}

type Props = {
  reservationExternalId: string;
  checkIn: Date;
  isVip: boolean;
  commentForHousekeeping?: string | null;
};

function GxExtraServicesSection({ checkIn, isVip, commentForHousekeeping, reservationExternalId }: Props) {
  const { t } = useTranslation();

  const updateReservation = useUpdateReservation(reservationExternalId);

  const checkInTime = useMemo(() => formatDate(checkIn, TIME_FORMAT), [checkIn]);

  const form = useForm<ExtraServicesSectionFormValues>({
    mode: 'onChange',
    defaultValues: {
      checkInTime,
      isVip,
      commentForHousekeeping,
    },
  });

  const onSubmit = useCallback(async () => {
    if (updateReservation.isLoading) {
      return;
    }
    const values = form.getValues();
    updateReservation.mutate({
      data: {
        checkInTime: values.checkInTime,
        isVip: values.isVip,
        additionalInfo: values.commentForHousekeeping ?? '',
      },
    });
    form.reset({}, { keepValues: true });
  }, [form, updateReservation]);

  return (
    <div className={'flex flex-col'}>
      <CollapsibleSection headerTitle={`${t('checkinTime')} & ${t('extraService')}`}>
        <div className={'px-2 py-4 flex flex-col space-y-3'}>
          <div className={'px-2 flex flex-row items-center justify-between'}>
            <div>{t('checkinTime')}:</div>
            <Controller
              control={form.control}
              name={'checkInTime'}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => <TimeInput value={value} onChange={onChange} />}
            />
          </div>
          <div className={'pl-2 flex flex-row items-center justify-between'}>
            <div>{t('vip')}:</div>
            <Controller
              control={form.control}
              name={'isVip'}
              render={({ field: { onChange, value } }) => <Toggle value={value} onChange={onChange} label={''} />}
            />
          </div>
          <div className={'px-2 flex flex-col'}>
            <div>{t('commentForHousekeeping')}:</div>
            <div className={'border-b border-gray-400'}>
              <Controller
                control={form.control}
                name={'commentForHousekeeping'}
                render={({ field: { onChange, value } }) => (
                  <TextArea rows={3} value={value ?? ''} onChange={onChange} />
                )}
              />
            </div>
          </div>
          <Button
            isLoading={updateReservation.isLoading}
            disabled={!form.formState.isDirty}
            type={ButtonType.OUTLINED}
            onClick={form.handleSubmit(onSubmit)}
          >
            {t('save')}
          </Button>
        </div>
      </CollapsibleSection>
    </div>
  );
}

export default GxExtraServicesSection;
