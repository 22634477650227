async function uploadFile({
  file,
  uploadedUrl,
  uploadUrl,
}: {
  file: File;
  uploadUrl: string;
  uploadedUrl: string;
}): Promise<string | null> {
  try {
    await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'image/jpeg',
      },
    });
    return uploadedUrl;
  } catch (e) {
    return null;
  }
}

export default {
  uploadFile,
};
