import React, { useState } from 'react';
import { TaskInfoType, ThinUnit } from '@typings/types';
import { useGetGxTasksForReservation } from '@api/api';
import { TaskType } from '@typings/enums';
import { sortTaskGroups } from '@utils/taskUtils';
import { EmptyTaskSection, TaskSection } from '@organisms/TaskSection';
import TaskInfo from '@organisms/TaskInfo/TaskInfo';
import useTaskHelpers from '@utils/hooks/useTaskHelpers';
import useDeleteTask from '@utils/hooks/useDeleteTask';
import { useTranslation } from 'react-i18next';
import CollapsibleSection from '@molecules/CollapsibleSection';
import SearchBar from '@molecules/SearchBar';
import AddDamageReport from '@organisms/AddDamageReport';
import DamageReportDetails from '@organisms/DamageReportDetails';
import { PlusCircleIcon } from '@heroicons/react/outline';
import Button, { ButtonType } from '@atoms/Button';
import usePropertiesState from '@context/propertiesContext';
import { useToggle } from '@utils/hooks/useToggle';

interface Props {
  selectedUnit: ThinUnit;
  propertyId: string;
  apaleoReservationId: string;
  minDate?: Date;
  reservationCheckOutDate?: Date;
}

function GxDamageReports({ selectedUnit, propertyId, apaleoReservationId, minDate, reservationCheckOutDate }: Props) {
  const [editTask, setEditTask] = useState<TaskInfoType>();
  const [openedItem, setOpenedItem] = useState<TaskInfoType>();
  const { setTaskForDeletion, renderDeleteConfirmationDialog } = useDeleteTask({});
  const { t } = useTranslation();

  const {
    selectedProperty: { timeZone },
  } = usePropertiesState();

  const { data: items, isFetching } = useGetGxTasksForReservation({
    propertyId,
    apaleoReservationId,
    timeZone,
    type: TaskType.DAMAGE,
    unitId: selectedUnit?.id,
  });

  const { tasks, search, setSearch } = useTaskHelpers({
    items,
  });

  const [taskDialogOpen, toggleTaskDialogOpen] = useToggle(false);

  if (isFetching && !items) {
    return null;
  }

  const dateKeys = sortTaskGroups(Object.keys(tasks));

  return (
    <div className={'fle isFetchingx flex-col'}>
      <CollapsibleSection headerTitle={t('damageReports')}>
        <div className={'p-2'}>
          <Button onClick={toggleTaskDialogOpen} Icon={PlusCircleIcon} type={ButtonType.OUTLINED}>
            {t('addDamageReport')}
          </Button>
          {(taskDialogOpen || editTask) && (
            <AddDamageReport
              apaleoReservationId={apaleoReservationId}
              editTask={editTask}
              selectedUnit={selectedUnit}
              onClose={() => {
                toggleTaskDialogOpen();
                setEditTask(undefined);
              }}
              minDate={minDate}
              reservationCheckOutDate={reservationCheckOutDate}
            />
          )}
          <div className={'pt-4'}>
            <SearchBar onChange={(v) => setSearch(v?.toLowerCase())} />
          </div>
          {dateKeys?.length === 0 && <EmptyTaskSection search={search} />}
          <div className={'pt-4 space-y-4'}>
            {dateKeys.map((date) => (
              <TaskSection
                utcDateString={date}
                search={search}
                key={date}
                isFetching={isFetching}
                tasks={tasks[date]}
                renderTask={(item) => (
                  <TaskInfo
                    canResolveTask={false}
                    key={item.id}
                    task={item}
                    onClickEdit={setEditTask}
                    onClickDelete={setTaskForDeletion}
                    onClick={setOpenedItem}
                  />
                )}
              />
            ))}
          </div>
          {renderDeleteConfirmationDialog()}
        </div>
      </CollapsibleSection>
      <DamageReportDetails
        task={openedItem}
        closeDialog={() => setOpenedItem(undefined)}
        onClickEdit={(item) => {
          setEditTask(item);
          // must close the current one to prevent <FocusTrap /> error
          setOpenedItem(undefined);
        }}
        onClickDelete={(item) => {
          setTaskForDeletion(item);
          setOpenedItem(undefined);
        }}
      />
    </div>
  );
}

export default GxDamageReports;
