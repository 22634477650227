import { MouseEventHandler } from 'react';
import { IconType } from '@typings/types';
import { ChevronRightIcon } from '@heroicons/react/outline';

interface Props {
  Icon: IconType;
  name: string;
  count?: number;
  countBadgeColor?: string;
  action: (() => void) | MouseEventHandler<HTMLElement>;
  isDelete?: boolean;
  isLast?: boolean;
  withRightArrow?: boolean;
}

export default function ActionItem(props: Props) {
  const { Icon, name, count, action, isDelete, isLast, withRightArrow, countBadgeColor } = props;
  return (
    <div className={'flex flex-row items-start justify-start cursor-pointer'} onClick={action}>
      <Icon className={`${isDelete ? 'text-red-500' : 'text-th-brown'} w-6 h-6 mr-2 flex-shrink-0`} />
      <div className={`${!isLast ? 'border-b pb-[14px]' : 'pb-0'} flex flex-1 flex-row items-center`}>
        <span className={`${isDelete ? 'text-red-500' : 'text-th-brown'} leading-6`}>{name}</span>
        {count ? (
          <span
            className={`ml-2 text-white inline-block w-4 h-4 flex justify-center items-center text-tiny rounded-full font-bold ${
              !countBadgeColor ? 'bg-th-accent' : ''
            }`}
            style={countBadgeColor ? { backgroundColor: countBadgeColor } : {}}
          >
            {count}
          </span>
        ) : null}
        {withRightArrow && (
          <div className={'flex flex-1 justify-end'}>
            <ChevronRightIcon className={'h-4 w-4'} />
          </div>
        )}
      </div>
    </div>
  );
}
