import useApiCall from '@utils/hooks/useApiCall';
import tasksApi from '@api/tasksApi';
import { useMutation, useQueryClient } from 'react-query';
import { TaskInfoType } from '@typings/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useNotifications from '@utils/hooks/useNotifications';
import { DATE_FORMAT_LONG, formatDate, REQUEST_DATE_FORMAT } from '@utils/dateUtils';
import { ServerStateKey } from '@typings/enums';
import routes from '@constants/routes';
import { useLocation } from 'react-router-dom';
import { isDueDatePassed } from '@utils/taskUtils';

export default function useConfirmTask({ task, onSuccess }: { task: TaskInfoType; onSuccess?: () => void }) {
  const { t } = useTranslation();
  const { showNotification } = useNotifications();
  const location = useLocation();
  const queryClient = useQueryClient();

  const apiCall = useApiCall<void>(tasksApi.confirmDate);
  const mutation = useMutation(({ propertyId, id, date }: { propertyId: string; id: string; date: Date }) =>
    apiCall({
      params: { propertyId, id },
      body: { date },
    }),
  );

  const onConfirmDate = useCallback(
    (date: Date) => {
      mutation.mutate(
        {
          date,
          id: task.id,
          propertyId: task.propertyId,
        },
        {
          onSuccess: () => {
            const unitId = task.unit?.id;

            if (location.pathname === routes.APALEO_HOUSEKEEPING) {
              queryClient.invalidateQueries([ServerStateKey.RESERVATION_TASKS, task.type]);
            }
            if (location.pathname === routes.TASKS) {
              queryClient.invalidateQueries([ServerStateKey.UNCONFIRMED_TASKS, task.propertyId]);

              if (isDueDatePassed(task)) {
                queryClient.invalidateQueries([ServerStateKey.OVERDUE_TASKS, task.propertyId]);
              } else {
                queryClient.invalidateQueries([
                  ServerStateKey.TASKS,
                  task.propertyId,
                  formatDate(task.dueAt!, REQUEST_DATE_FORMAT),
                ]);
              }
            }

            if (location.pathname === routes.NOTIFICATIONS) {
              queryClient.invalidateQueries([ServerStateKey.NOTIFICATIONS]);
            }

            if (location.pathname === routes.ROOT) {
              queryClient.invalidateQueries([ServerStateKey.UNIT_DETAILS, unitId]);
            }

            showNotification(t('taskConfirmed', { date: formatDate(date, DATE_FORMAT_LONG) }));
            onSuccess?.();
          },
        },
      );
    },
    [task],
  );

  return {
    ...mutation,
    onConfirmDate,
  };
}
