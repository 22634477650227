import useApiCall from '@utils/hooks/useApiCall';
import unitsApi from '@api/unitsApi';
import { useMutation, useQueryClient } from 'react-query';
import { useCallback, useState } from 'react';
import { UnitDetailsInfoType, UnitInfoType } from '@typings/types';
import { ServerStateKey, UnitStatusAction } from '@typings/enums';
import Dialog from '@molecules/Dialog';
import Button, { ButtonType } from '@atoms/Button';
import { useTranslation } from 'react-i18next';

interface Params {
  queriesToInvalidate: ServerStateKey[];
  onSuccess?: () => void;
  onMarkCleanCancel?: () => void;
}

function useMarkAsClean({ queriesToInvalidate, onSuccess, onMarkCleanCancel }: Params) {
  const queryClient = useQueryClient();

  const [cleaningUnit, setCleaningUnit] = useState<UnitDetailsInfoType | UnitInfoType>();

  const { t } = useTranslation();

  const changeUnitStatusRequest = useApiCall<void>(unitsApi.changeUnitStatus);
  const changeUnitStatusMutation = useMutation(
    (v: { propertyId: string; id: string }) =>
      changeUnitStatusRequest({
        params: { propertyId: v.propertyId, id: v.id },
        body: { action: UnitStatusAction.CLEAN },
      }),
    {
      onSuccess: () => {
        setCleaningUnit(undefined);
        queriesToInvalidate.forEach((key) => queryClient.invalidateQueries(key));
        onSuccess?.();
      },
    },
  );

  const markAsClean = useCallback(
    (unit: UnitDetailsInfoType | UnitInfoType) => {
      if (changeUnitStatusMutation.isLoading) {
        return;
      }
      changeUnitStatusMutation.mutate({
        id: unit?.id,
        propertyId: unit?.propertyId ?? (unit as UnitDetailsInfoType)?.property.id,
      });
    },
    [changeUnitStatusMutation],
  );

  const onClickMarkAsClean = useCallback(
    (unit: UnitDetailsInfoType | UnitInfoType) => {
      return () => {
        const openTasks =
          (unit as UnitInfoType).tasksCount ??
          (unit as UnitDetailsInfoType).tasks.filter((task) => !task.completedAt).length;
        if (!openTasks) {
          markAsClean(unit);
        } else {
          setCleaningUnit(unit);
        }
      };
    },
    [markAsClean],
  );

  const renderCleaningConfirmationDialog = useCallback(() => {
    const openTasks =
      (cleaningUnit as UnitInfoType)?.tasksCount ??
      (cleaningUnit as UnitDetailsInfoType)?.tasks.filter((task) => !task.completedAt).length;
    return (
      <Dialog isOpen={!!cleaningUnit} onClose={() => setCleaningUnit(undefined)} isMobileSheet>
        <div className={'flex flex-col items-center px-3'}>
          <div className={'text-th-brown font-serif text-lg font-bold mb-2'}>{t('endCleaning')}</div>
          <div className={'text-th-secondary text-center mb-6'}>
            {t(openTasks === 1 ? 'endCleaningMessage' : 'endCleaningMessage_plural', { count: openTasks })}
          </div>
          <Button
            className={'w-full'}
            onClick={() => {
              setCleaningUnit(undefined);
              onMarkCleanCancel?.();
            }}
          >
            {t('cancelAndViewTasks')}
          </Button>
          <Button
            isLoading={changeUnitStatusMutation.isLoading}
            type={ButtonType.SECONDARY}
            className={'my-1 py-2 w-full text-gray-400'}
            onClick={() => cleaningUnit && markAsClean(cleaningUnit)}
          >
            {t('ignoreAndMarkAsCleaned')}
          </Button>
        </div>
      </Dialog>
    );
  }, [changeUnitStatusMutation, cleaningUnit, markAsClean]);

  return {
    onClickMarkAsClean,
    renderCleaningConfirmationDialog,
  };
}

export default useMarkAsClean;
