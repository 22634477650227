import { ApiInfo } from '@api/types';

const propertiesApi: ApiInfo = {
  getProperties: () => ({
    url: '/properties',
  }),
  getPropertyUnitsAndAreas: ({ params }) => {
    const { propertyId } = params!;
    return {
      url: `/properties/${propertyId}/units`,
    };
  },
  getUploadUrl: ({ params, query }) => {
    const { propertyId } = params!;
    const { type } = query!;
    return {
      url: `/properties/${propertyId}/upload-url?type=${type}`,
    };
  },
  saveArea: ({ params, body }) => {
    const { propertyId } = params!;
    return {
      url: `/properties/${propertyId}/areas`,
      method: 'post',
      body,
    };
  },
  getCleaners: ({ params }) => {
    const { propertyId } = params!;
    return {
      url: `/properties/${propertyId}/cleaners`,
    };
  },
  getAssignedUnits: ({ params }) => {
    const { propertyId } = params!;
    return {
      url: `/properties/${propertyId}/cleaners/assign`,
    };
  },
  assignUnits: ({ params, body }) => {
    const { propertyId } = params!;
    return {
      url: `/properties/${propertyId}/cleaners/assign`,
      method: 'post',
      body,
    };
  },
  unassignUnits: ({ params, body }) => {
    const { propertyId } = params!;
    return {
      url: `/properties/${propertyId}/cleaners/unassign`,
      method: 'post',
      body,
    };
  },
  saveDaySettings: ({ params, body }) => {
    const { propertyId } = params!;
    return {
      url: `/properties/${propertyId}/day-settings`,
      method: 'post',
      body,
    };
  },
  getDaySettings: ({ params, query }) => {
    const { propertyId } = params!;
    const { dateOnly } = query!;
    return {
      url: `/properties/${propertyId}/day-settings?date=${dateOnly}`,
    };
  },
};

export default propertiesApi;
