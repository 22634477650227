/**
 * This web app is loaded in mobile app through web view component
 * This file contains:
 * - event types that are sent to mobile app
 * - function for triggering events
 * - attaching function for opening notifications, that will be called when notification on mobile is clicked
 */

import routes from '@constants/routes';

export enum WebViewEventType {
  ON_LOAD = 'ON_LOAD',
  ON_LOGOUT = 'ON_LOGOUT',
}

interface WebViewEvent {
  type: WebViewEventType;
  payload: Record<string, string | number>;
}

export function fireWebViewEvent({ type, payload }: WebViewEvent) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.ReactNativeWebView.postMessage(JSON.stringify({ type, payload }));
}

/**
 * Attach webViewBridge object with openNotification function to window object, so it can be called
 * by the mobile app when notification is clicked
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function attachOpenNotificationFn(history: any) {
  const openNotification = (taskId?: string) =>
    history.push({
      pathname: routes.NOTIFICATIONS,
      search: taskId ? `task=${taskId}` : '',
    });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.webViewBridge = { openNotification };
}
