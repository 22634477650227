import useTaskActionsState from '@context/taskActionsContext';
import { TaskInfoType } from '@typings/types';
import { getTaskDescription, getTaskTitle } from '@utils/taskUtils';

export function useTaskTitleAndDescription(task: TaskInfoType) {
  const { getTaskActionById, getDefaultLangTaskActionById } = useTaskActionsState();
  const taskActionDefaultLang = task.actionId ? getDefaultLangTaskActionById(task.actionId) : null;
  const taskAction = task.actionId ? getTaskActionById(task.actionId) : null;

  const { showTaskAction, title } = getTaskTitle({ taskAction, taskActionDefaultLang, task });
  const description = getTaskDescription({ taskAction, taskActionDefaultLang, task });
  return {
    title,
    description,
    showTaskAction,
    taskAction,
    taskActionDefaultLang,
  };
}
