import TaskInfo from '@organisms/TaskInfo';
import { TaskInfoType } from '@typings/types';
import { useTranslation } from 'react-i18next';

interface Props {
  tasks: TaskInfoType[];
  onClick: (task: TaskInfoType) => void;
  onEditTask: (task: TaskInfoType) => void;
  onDeleteTask: (task: TaskInfoType) => void;
}

export default function UnitTasks({ tasks, onClick, onEditTask, onDeleteTask }: Props) {
  const { t } = useTranslation();

  if (tasks.length === 0) {
    return <div className={'p-4'}>{t('noTasks')}</div>;
  }

  return (
    <div className={'flex flex-col'}>
      {tasks.map((task) => (
        <TaskInfo key={task.id} task={task} onClick={onClick} onClickEdit={onEditTask} onClickDelete={onDeleteTask} />
      ))}
    </div>
  );
}
